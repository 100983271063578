import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from 'common/components/AppWrapper';
import {Provider} from 'react-redux';
import {store} from './store';
import {BrowserRouter as Router} from 'react-router-dom';
import {ROOT_PARAM} from './config';
import {ErrorBoundary} from './common/components/';
import Routes from './routes';
import {initSetup} from 'common/lib/setup';
import SmokeTest from 'modules/app/containers/SmokeTest';
import {DebugEx} from 'common/lib/DebugEx';

function App({children}: {children: JSX.Element}) {
    return (
        <AppWrapper>
            <ErrorBoundary>
                <Provider store={store}>{children}</Provider>
            </ErrorBoundary>
        </AppWrapper>
    );
}

DebugEx.LogAppInfo('YodiFEM');

const isSmokeTest = window.location.href.includes('smoketest');

if (isSmokeTest) {
    ReactDOM.render(
        <App>
            <SmokeTest />
        </App>,
        document.getElementById('root')
    );
} else {
    const appRender = () =>
        ReactDOM.render(
            <StrictMode>
                <App>
                    <Router basename={ROOT_PARAM}>
                        <Routes />
                    </Router>
                </App>
            </StrictMode>,
            document.getElementById('root')
        );
    initSetup(appRender);
}
