import {UserManager} from './UserManager';

const getRequest = (url: string, opt: RequestInit = {headers: {}}) => {
    return fetch(url, {
        headers: {
            Authorization: UserManager.GetInstance()?.getBearer(),
            Accept: 'application/json',
            ...opt.headers,
        },
    });
};

export const postRequest = (
    url: string,
    body: Object,
    opt: RequestInit = {headers: {}}
) => {
    return fetch(url, {
        headers: {
            Authorization: UserManager.GetInstance()?.getBearer(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...opt.headers,
        },
        method: 'POST',
        body: JSON.stringify(body),
    });
};

export default getRequest;
