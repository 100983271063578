import {MenuItem, alpha, Box, Typography} from '@mui/material';
import {DropdownSelectionIcon} from 'common/icons';
import {useMemo} from 'react';
import {ItemRendererProps} from '../index';
import clsx from 'clsx';
import {grey} from 'common/colors';
import {OrganizationOption} from 'modules/admin/components/OrganizationSelector';

export type OrganizationRendererProps<
    T extends OrganizationOption = OrganizationOption
> = ItemRendererProps<T>;

export default function OrganizationRenderer<
    T extends OrganizationOption = OrganizationOption
>({
    option,
    label,
    value,
    width,
    selected,
    selectionVariant,
    ...rest
}: OrganizationRendererProps<T>) {
    const checkboxWidth = 24;
    const horizontalPadding = 16;
    const typographyWidth = useMemo(() => {
        if (width) {
            const typoWidth =
                width - checkboxWidth - horizontalPadding - 4;
            return `${typoWidth > 0 ? typoWidth : 0}px`;
        }
    }, [width]);
    const className = clsx({
        'unique-details': true,
        isSelected: selected,
    });
    return (
        <MenuItem
            {...rest}
            className={className}
            data-testid="mui-select-option-test"
            sx={{
                backgroundColor: 'grey.100',
                py: '8px',
                pl: `${horizontalPadding}px`,
                pr: '4px',
                height: '66px',
                '&:hover': {
                    backgroundColor: (theme) =>
                        theme.palette.common.dynamicLight.hover(grey[100]),
                },
                '&:active': {
                    backgroundColor: (theme) =>
                        theme.palette.common.dynamicLight.pressed(
                            grey[100]
                        ),
                },
                '&.isSelected': {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.light, 0.32),
                },
            }}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        data-testid="mui-select-option-label"
                        variant="body2"
                        color="grey.900"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        width={typographyWidth}
                    >
                        {label}
                    </Typography>
                    <Box
                        data-testid="mui-select-unique-with-details-subtitles"
                        sx={{
                            width: typographyWidth || 148,
                            whiteSpace: 'no-wrap',
                            textOverflow: 'ellipsis',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography
                            data-testid="mui-select-unique-with-details-subtitles"
                            variant="caption"
                            color="primary.main"
                            sx={{
                                marginTop: '4px',
                                textDecorationLine: 'underline',
                            }}
                        >
                            {option.Url}
                        </Typography>
                        {option.CreationDate && (
                            <Typography
                                data-testid="mui-select-unique-with-details-subtitles"
                                variant="menu"
                                color="grey.900"
                                sx={{
                                    marginTop: '4px',
                                }}
                            >
                                {option.CreationDate}
                            </Typography>
                        )}
                    </Box>
                </Box>
                {selected && (
                    <DropdownSelectionIcon
                        sx={{color: 'common.brandColor.500'}}
                    />
                )}
            </Box>
        </MenuItem>
    );
}
