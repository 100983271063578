import {Box, Typography} from '@mui/material';
import {IconButton} from 'common/components';
import {FilterIcon, CloseLargeIcon} from 'common/icons';

export interface FilterAreaMobileProps {
    onClose: () => void;
}
export default function FilterAreaMobile({
    onClose,
}: FilterAreaMobileProps) {
    return (
        <Box
            className="filter-area-mobile-header"
            sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    m: 0,
                    p: '8px 16px 0px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <FilterIcon />
                <Typography variant="subtitle" mx={1}>
                    Filters
                </Typography>
                <IconButton
                    onClick={onClose}
                    sx={{
                        ml: 'auto',
                    }}
                >
                    <CloseLargeIcon />
                </IconButton>
            </Box>
        </Box>
    );
}
