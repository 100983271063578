import {Box, Modal} from '@mui/material';
import {Button} from 'common/components';
import _ from 'lodash';
import {ReactNode} from 'react';
import Drawer from './Drawer';
import DrawerHeader from './Drawer/DrawerHeader';
import {
    BuildingFormValues,
    FloorFormValues,
    ZoneFormValues,
} from './forms.types';

interface FormContainerProps {
    isOpen: boolean;
    isEdit: boolean;
    isMobile: boolean;
    onSubmit: () => void;
    handleClose: () => void;
    values: BuildingFormValues | FloorFormValues | ZoneFormValues | null;
    subtitleHierarchy?: string[];
    children: ReactNode[];
    hierarchyLevel: 'building' | 'floor' | 'zone';
}

function FormContainer({
    isOpen,
    isEdit,
    values,
    handleClose,
    onSubmit,
    children,
    subtitleHierarchy,
    isMobile,
    hierarchyLevel,
}: FormContainerProps) {
    if (isMobile) {
        return (
            <Modal open={isOpen}>
                <Box
                    sx={{
                        zIndex: (theme) => theme.zIndex.modal,
                        m: 0,
                        pt: '18px',
                        width: '100%',
                        height: '100%',
                        bgcolor: 'grey.100',
                        overflowY: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box flexShrink={0} px={2}>
                        <DrawerHeader
                            title={
                                !isEdit
                                    ? `You are currently adding a new ${_.capitalize(
                                          hierarchyLevel
                                      )}`
                                    : `You are currently editing ${values?.name} (ID:${values?.id})`
                            }
                            subtitleHierarchy={subtitleHierarchy}
                            onClose={handleClose}
                        />
                    </Box>
                    <Box
                        mx="8px"
                        px="8px"
                        py="16px"
                        sx={{overflowY: 'scroll'}}
                    >
                        {children}
                    </Box>
                    <Box
                        flexShrink={0}
                        my="16px"
                        sx={{
                            '::-webkit-scrollbar': {
                                position: 'relative',
                                right: '4px',
                            },
                        }}
                    >
                        <Button
                            size="large"
                            variant="contained"
                            sx={{
                                mx: 'auto',
                            }}
                            onClick={onSubmit}
                        >
                            {isEdit
                                ? 'Save changes'
                                : `Add ${hierarchyLevel}`}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        );
    } else {
        return (
            <Drawer
                isDrawerOpen={isOpen}
                title={
                    !isEdit
                        ? `You are currently adding a new ${_.capitalize(
                              hierarchyLevel
                          )}`
                        : `You are currently editing ${values?.name} (ID:${values?.id})`
                }
                subtitleHierarchy={subtitleHierarchy}
                submitButtonLabel={
                    isEdit ? 'Save changes' : `Add ${hierarchyLevel}`
                }
                handleClose={handleClose}
                handleSave={onSubmit}
            >
                {children}
            </Drawer>
        );
    }
}

export default FormContainer;
