import {Column, Row} from 'react-table';
import TableRowContainer from './TableRowContainer';
import {useTheme, useMediaQuery, TableRowProps} from '@mui/material';
import DataTreeMobile from './DataTreeMobile';
import DataTreeDesktop from './DataTreeDesktop';

export type UnknownObject = {
    [key: string]: unknown;
};

export type RowType = {
    type: string;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type AnyObject = object;

export type NodeType = {
    id: number;
    parentId: string | null;
    iRef: string;
    type: string;
};
export type CategoryLevels = {
    [key: string]: number;
};
export type FieldColumnMap = {
    [key: string]: Array<string>;
};
export interface RowSubComponentProps {
    row: Row<any>;
    rowProps: TableRowProps;
    virtualized?: boolean;
    visibleColumns: any;
}
export interface RowActions {
    onClickAdd: (row: any) => void;
    onClickEdit: (row: any) => void;
    onClickDelete: (row: any) => void;
    onClickVisible: (row: any) => void;
}

export type TreeTableProps<T extends NodeType> = {
    data: T[];
    isLoading: boolean;
    isEmptyState: boolean;
    errorMessage?: string | null;
    columns: Array<Column<T>>;
    label: string;
    fieldColumnMap: FieldColumnMap;
    colorMap?: Readonly<Array<string>>;
    categoryLevels: CategoryLevels;
    caption?: React.ReactNode | string;
    virtualized?: boolean;
    onTableWidthChange: (width: number) => void;
    tableWidth: number;
    rowActions: RowActions;
};
export default function DataTreeTable<T extends NodeType>(
    props: TreeTableProps<T>
) {
    const {
        breakpoints: {up},
    } = useTheme();

    const isMobile = !useMediaQuery(up('desktopSm'));
    if (isMobile) {
        return <DataTreeMobile {...props} />;
    } else {
        return <DataTreeDesktop {...props} />;
    }
}

export {TableRowContainer};
