import {ReactNode} from 'react';
import {Business, Map} from '@mui/icons-material';

export type YodideskSectionName = 'overview' | 'versions';
export type YodideskSection = {
    name: YodideskSectionName;
    label: string;
    icon?: ReactNode;
};

const sections: YodideskSection[] = [
    {
        name: 'overview',
        label: 'Overview',
        icon: <Business fontSize="small" />,
    },
    {
        name: 'versions',
        label: 'Versions',
        icon: <Map fontSize="small" />,
    },
];

export default sections;
