import {alpha, Box, Drawer} from '@mui/material';
import {grey} from 'common/colors';
import {Module, ModuleName} from 'common/constants/modules';
import ProductCard from './ProductCard';

interface ProductMenuDesktopProps {
    isOpen: boolean;
    drawerWidth: string | number;
    products: Module[];
    activeProductName: ModuleName;
    onBackdropClick: () => void;
    onProductClick: (productName: ModuleName) => void;
}

export default function ProductMenuDesktop({
    isOpen,
    products,
    drawerWidth,
    activeProductName,
    onBackdropClick = () => {},
    onProductClick = () => {},
}: ProductMenuDesktopProps) {
    return (
        <Drawer
            anchor="top"
            open={isOpen}
            ModalProps={{
                onBackdropClick,
            }}
            sx={{
                zIndex: (theme) => theme.zIndex.appBar - 1,
                width: drawerWidth,
                display: 'flex',
                flexFlow: 'column',
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    height: {
                        desktopMd: 'calc(100% - 24px)',
                        mobile: 'calc(100% - 16px)',
                    },
                    mb: '24px',
                    borderRadius: '0 0 8px 8px',
                    boxShadow: `0px 8px 24px ${alpha(grey[800], 0.8)};`,
                },
            }}
        >
            <Box
                sx={{
                    mt: '102px',
                    pt: '14px',
                    borderTop: (theme) =>
                        `2px solid ${theme.palette.common.overlay.dark}`, //cheating a bit
                }}
            >
                {products.map((product) => (
                    <ProductCard
                        activeProductName={activeProductName}
                        onClick={onProductClick}
                        key={product.name}
                        product={product}
                    />
                ))}
            </Box>
        </Drawer>
    );
}
