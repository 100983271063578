import {Box, Typography} from '@mui/material';
import {LogoutIcon} from 'common/icons';
export default function ModuleDropdown() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-end',
                padding: '0 16px 16px',

                width: '100%',
                height: '72px',
            }}
        >
            <Box
                sx={{
                    width: '328px',
                    left: '16px',
                    top: 0,
                    borderTop: '1px solid #EEEEEE',
                    margin: '0px 0px',
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    padding: '16px 0px 0px',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                }}
                onClick={() => {
                    console.log('Logout clicked...');
                }}
            >
                <Typography
                    variant="body1"
                    sx={{
                        margin: '0px 8px',
                        flex: 'none',
                        order: 0,
                        flexGrow: 0,
                    }}
                >
                    Logout
                </Typography>{' '}
                <LogoutIcon />
            </Box>
        </Box>
    );
}
