import * as React from 'react';
import {
    default as MuiSnackbar,
    SnackbarProps as MuiSnackbarProps,
} from '@mui/material/Snackbar';
import IconButton from '../IconButton';
import {alpha, Box, Stack, Typography, useTheme} from '@mui/material';
import {ErrorIcon, CloseLargeIcon, SuccessIcon} from 'common/icons';
import {useMemo} from 'react';
import {grey} from 'common/colors';

interface ToastMessage {
    message: string;
    key: number;
}

export interface ToastProps extends MuiSnackbarProps {
    toastValue?: string;
    autohide?: boolean;
    type: 'success' | 'error';
}

export default function Toast({
    toastValue,
    type,
    autohide,
    ...props
}: Omit<ToastProps, 'message'>) {
    const [toastPack, setToastPack] = React.useState<
        readonly ToastMessage[]
    >([]);
    const [open, setOpen] = React.useState(false);
    const [messageInfo, setMessageInfo] = React.useState<
        ToastMessage | undefined
    >(undefined);

    React.useEffect(() => {
        if (toastPack.length && !messageInfo) {
            // Open a new snack when we don't have an active one and there is a backlog
            setMessageInfo({...toastPack[0]});
            setToastPack((prev) => prev.slice(1));
            setOpen(true);
        }
    }, [toastPack, messageInfo, open]);

    React.useEffect(() => {
        // New snack message wrapper
        if (typeof toastValue !== 'string') {
            return;
        }
        setToastPack((prev) => [
            ...prev,
            {message: toastValue, key: new Date().getTime()},
        ]);
    }, [toastValue]);

    const handleClose = (
        _event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleExited = () => {
        setMessageInfo(undefined);
    };
    const closeAction = (
        <IconButton
            aria-label="close"
            color="inherit"
            sx={{p: 0.5}}
            onClick={handleClose}
        >
            <CloseLargeIcon />
        </IconButton>
    );
    const theme = useTheme();
    const typeColor = useMemo(() => {
        switch (type) {
            default:
                return theme.palette.common.messages.verification;
            case 'error':
                return theme.palette.common.messages.error;
        }
    }, [theme.palette, type]);
    return (
        <MuiSnackbar
            key={messageInfo ? messageInfo.key : undefined}
            open={open}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            autoHideDuration={
                autohide ? props.autoHideDuration ?? 3000 : null
            }
            onClose={handleClose}
            TransitionProps={{onExited: handleExited}}
            message={messageInfo ? messageInfo.message : undefined}
            action={closeAction}
            {...props}
        >
            <Box
                width="380px"
                height="48px"
                borderRadius="4px"
                bgcolor="grey.100"
                px="8px"
                display="flex"
                borderLeft={`4px solid ${typeColor}`}
                boxShadow={`0px 2px 10px ${alpha(grey[800], 0.24)}`}
            >
                <Stack
                    width="100%"
                    direction="row"
                    alignItems="center"
                    alignSelf="center"
                >
                    {type === 'success' && (
                        <SuccessIcon
                            sx={{
                                color: typeColor,
                            }}
                        />
                    )}
                    {type === 'error' && (
                        <ErrorIcon
                            sx={{
                                color: typeColor,
                            }}
                        />
                    )}
                    <Typography
                        variant="body2"
                        pl="8px"
                        pr="16px"
                        width="100%"
                        color="grey.900"
                    >
                        {messageInfo?.message}
                    </Typography>
                    {closeAction}
                </Stack>
            </Box>
        </MuiSnackbar>
    );
}
