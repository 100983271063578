import {
    CSSProperties,
    useState,
    Fragment,
    useEffect,
    useRef,
    useMemo,
    useCallback,
} from 'react';
import {ClickAwayListener, Popper, Stack, Box} from '@mui/material';
import {Column, SortingRule} from 'react-table';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList} from 'react-window';
import TableTile from './TableTile/index';
import {UnknownObject, AnyObject, Entry} from '../index';
import TableActionsMobilePopups from './TableActionsMenu/TablePopups/index';
import Pagination, {
    limitOptions,
} from 'common/components/Pagination/index';
import _ from 'lodash';
import TableActionsMobile from 'common/components/Table/TableMobile/TableActionsMenu/TableActions/index';
import {HierarchyLevel} from 'modules/admin/config/hierarchyKeys';
import EmptyState from 'common/components/EmptyStates';
import TreeMobilePopupContent from 'modules/admin/components/DataTreeTable/DataTreeMobile/TreeMobilePopupContent';

export type TableMobileProps<T extends AnyObject = UnknownObject> = {
    data: T[];
    allColumns: Array<Column<T>>;
    isLoading?: boolean;
    onChangeSorting: (sortingProps: SortingRule<T>) => void;
    sortBy?: Array<{id: string; desc: Boolean}>;
    limit?: limitOptions;
    page: number;
    onPageChange: (page: number) => void;
    controlled?: boolean;
    dataTotal?: number;
    onAddRow: (type: HierarchyLevel, row?: Entry) => void;
    onEditRow: (type: HierarchyLevel, row: Entry) => void;
    hierarchyLevel?: HierarchyLevel;
    errorMessage?: string | null;
    isEmptyState?: boolean;
};

export default function TableMobile({
    limit = 13,
    data,
    allColumns,
    isLoading,
    onChangeSorting,
    sortBy,
    onPageChange,
    isEmptyState,
    controlled,
    dataTotal,
    page,
    errorMessage,
    onAddRow,
    onEditRow,
    hierarchyLevel = 'buildings',
}: TableMobileProps) {
    let start = (page - 1) * limit;
    let end = start + limit;
    let limitedData = controlled ? data : _.slice(data, start, end);
    let total = controlled && dataTotal ? dataTotal : data.length;
    const totalPages = Math.ceil(total / limit);

    const [downloadAs, setDownloadType] = useState<'csv' | 'pdf' | 'xls'>(
        'pdf'
    );
    const [drawerVariant, setDrawerVariant] = useState<
        'export' | 'columns-managment' | null
    >(null);
    const [hiddenColumnsIds, sethiddenColumns] = useState<any[]>([]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorItemIndex, setAnchorItemIndex] = useState<null | number>(
        null
    );
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const visibleColumns =
        hiddenColumnsIds.length > 0
            ? _.filter(allColumns, (column) => {
                  return !_.includes(hiddenColumnsIds, column.id);
              })
            : allColumns;

    const handleDownload = (value: 'csv' | 'pdf' | 'xls') => {
        console.log('downloadAs->', downloadAs);
        setDownloadType(value);
    };

    const handleDrawerClose = () => {
        setDrawerVariant(null);
    };

    const handleColumnsManagementMenuClicked = () => {
        setDrawerVariant('columns-managment');
    };

    const handleExportClicked = () => {
        setDrawerVariant('export');
    };

    const handleApplyHideColumns = (hiddenColumnsIds: string[]) => {
        sethiddenColumns(hiddenColumnsIds);
        setDrawerVariant(null);
    };

    const handleClickMore = useCallback(
        (event: React.MouseEvent<HTMLElement>, index: number) => {
            event?.stopPropagation();
            setAnchorEl((state) => {
                setAnchorItemIndex(anchorEl ? null : index);
                return state ? null : event.currentTarget;
            });
        },
        [anchorEl]
    );

    const handleScroll = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const tableMobileItemTileHeight = useMemo(() => {
        let columnsNum = visibleColumns.length;
        let heightPerColumnContent = 26;
        let contentMargin = 16;
        let rowHeight = heightPerColumnContent + contentMargin;
        let rowsNum = _.ceil(columnsNum / 2);
        let tileHeight = rowsNum * rowHeight;
        return tileHeight;
    }, [visibleColumns]);

    const emptyState = useMemo(() => {
        if (isEmptyState || errorMessage) {
            return (
                <EmptyState
                    type={errorMessage ? 'noData' : 'noResults'}
                    errorCode={errorMessage}
                />
            );
        }
    }, [errorMessage, isEmptyState]);

    const renderTableTilesList = useCallback(
        ({index, style}: {index: number; style: any}) => {
            return (
                <TableTile
                    key={index}
                    style={{
                        ...style,
                        height: tableMobileItemTileHeight,
                        top: index * tableMobileItemTileHeight,
                    }}
                    onClickMore={(e) => handleClickMore(e, index)}
                    fieldData={limitedData[index]}
                    columns={visibleColumns}
                />
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [limitedData, tableMobileItemTileHeight, visibleColumns]
    );

    //During loading
    function renderTableTilesListSkeleton({
        style,
        index,
    }: {
        index: number;
        style: CSSProperties | undefined;
    }) {
        return (
            <TableTile
                key={`skeleton-${index}`}
                isLoading={true}
                onClickMore={() => {}}
                style={{...style, height: '216px'}}
                fieldData={limitedData[index]}
                columns={visibleColumns}
            />
        );
    }

    const listRef = useRef();
    //Scroll on lists top when page changes
    useEffect(() => {
        if (!_.isNil(listRef?.current)) {
            (listRef.current as any).scrollToItem(0);
        }
    }, [page]);

    return (
        <Fragment>
            <TableActionsMobilePopups
                isOpen={!_.isNull(drawerVariant)}
                onClose={handleDrawerClose}
                onApply={handleApplyHideColumns}
                onSortingChange={onChangeSorting}
                onDownload={handleDownload}
                hiddenColumnsIds={hiddenColumnsIds}
                sortBy={sortBy}
                allColumns={allColumns}
                drawerVariant={drawerVariant}
            />
            <Stack
                data-testid="table-mobile"
                direction="column"
                p={1}
                height="calc(100% - 48px)"
                width="100%"
                sx={{backgroundColor: 'grey.100', borderRadius: '8px'}}
            >
                <TableActionsMobile
                    onAddRow={onAddRow}
                    hierarchyLevel={hierarchyLevel}
                    onExportClick={handleExportClicked}
                    onColsManagmentClick={
                        handleColumnsManagementMenuClicked
                    }
                />
                <Box height="100%" mt={1}>
                    {isLoading ? (
                        <Box
                            data-testid="table-mobile-skeleton"
                            height="100%"
                            width="100%"
                        >
                            <AutoSizer>
                                {({height, width}) => (
                                    <FixedSizeList
                                        itemCount={3}
                                        height={height}
                                        width={width}
                                        itemSize={332}
                                    >
                                        {renderTableTilesListSkeleton}
                                    </FixedSizeList>
                                )}
                            </AutoSizer>
                        </Box>
                    ) : !emptyState ? (
                        <AutoSizer>
                            {({height, width}) => (
                                <FixedSizeList
                                    onScroll={handleScroll}
                                    ref={(list) => {
                                        if (
                                            !_.isNil(listRef?.current) &&
                                            !_.isNull(list)
                                        ) {
                                            listRef.current = list as any;
                                        }
                                    }}
                                    itemCount={limitedData.length}
                                    height={height}
                                    width={width}
                                    itemSize={tableMobileItemTileHeight}
                                >
                                    {renderTableTilesList}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    ) : (
                        emptyState
                    )}
                </Box>
            </Stack>
            <ClickAwayListener onClickAway={(_) => setAnchorEl(null)}>
                <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    sx={{
                        zIndex: (theme) => theme.zIndex.modal,
                    }}
                >
                    <TreeMobilePopupContent
                        variant={hierarchyLevel}
                        onEdit={() =>
                            onEditRow(
                                hierarchyLevel,
                                data[anchorItemIndex ?? 0]
                            )
                        }
                        onRemove={() => console.log('remo')}
                    />
                </Popper>
            </ClickAwayListener>

            {totalPages === 1 ? null : (
                <Box
                    sx={{
                        width: '100%',
                        marginTop: (theme) => theme.spacing(1),
                    }}
                >
                    <Pagination
                        total={
                            controlled && dataTotal
                                ? dataTotal
                                : data.length
                        }
                        page={page}
                        limit={limit}
                        onChangePage={onPageChange}
                        onChangeLimit={() => {}}
                    />
                </Box>
            )}
        </Fragment>
    );
}
