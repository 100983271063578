import _ from 'lodash';
import ContentTile from 'common/components/ContentTile';
import {useCallback} from 'react';

interface InfrastructureTreeColumnProps {
    keyParam?: string;
    value?: string;
    hierarchyLevel?: number;
    tableWidth: number;
    columnType: 'hierarchy' | 'middle';
}

export default function InfrastructureTreeColumn({
    keyParam,
    value,
    hierarchyLevel,
    columnType,
    tableWidth,
}: InfrastructureTreeColumnProps) {
    const calulateContentWidth = useCallback((hierarchyLevel: number) => {
        const width = 190;
        return `${width - hierarchyLevel * 20}px`;
    }, []);

    const calulateWidth = useCallback(() => {
        const firstColWidth = 224;
        const actionsColWidth = 216;
        const padding = 16;
        const middleColumnsWidth =
            _.floor(tableWidth - firstColWidth - actionsColWidth) / 7 -
            padding;
        return middleColumnsWidth;
    }, [tableWidth]);

    return (
        <ContentTile
            title={keyParam}
            content={value}
            sx={{
                width:
                    columnType === 'hierarchy'
                        ? calulateContentWidth(hierarchyLevel ?? 0)
                        : calulateWidth,
            }}
        />
    );
}
