import {ReactElement} from 'react';
import {Box, Collapse, alpha, Stack, Typography} from '@mui/material';
import {Button} from 'common/components';
export type FilterAreaDesktopProps = {
    isOpen: boolean;
    renderToggleComponent: () => ReactElement;
    renderFilterComponent: () => ReactElement;
    onReset: () => void;
    resetEnabled: boolean;
};

export default function FilterAreaDesktop({
    isOpen,
    renderToggleComponent,
    renderFilterComponent,
    onReset,
    resetEnabled,
}: FilterAreaDesktopProps) {
    const toggleComponent = renderToggleComponent();
    const filterComponent = renderFilterComponent();
    return (
        <Collapse in={isOpen}>
            <Box bgcolor="grey.100" borderRadius="0px 0px 8px 8px">
                <Box
                    p={2}
                    m={0}
                    width="100%"
                    sx={{
                        bgcolor: (theme) =>
                            alpha(theme.palette.primary.light, 0.32),
                        borderRadius: '0px 0px 8px 8px',
                    }}
                >
                    <Box mt={1}>
                        {toggleComponent}
                        <Stack
                            direction="row"
                            pt={1}
                            width="100%"
                            justifyContent="space-between"
                        >
                            {filterComponent}
                            <Button
                                disabled={!resetEnabled}
                                size="small"
                                onClick={onReset}
                                variant="text"
                                sx={{
                                    justifySelf: 'flex-end',
                                    alignSelf: 'end',
                                    minWidth: 'auto',
                                    width: '68px',
                                    overflow: 'visible',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: 'primaryMain',
                                    }}
                                >
                                    Reset filters
                                </Typography>
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Box>
        </Collapse>
    );
}
