import {SvgIconComponent} from '@mui/icons-material';
import {HierarchyLevel} from './hierarchyKeys';
import {
    ZonesSmallIcon,
    BuildingsSmallIcon,
    FloorsSmallIcon,
} from 'common/icons';

const hierarchyOptions: Array<{
    value: HierarchyLevel;
    label: string;
    icon: SvgIconComponent;
}> = [
    {
        value: 'buildings',
        label: 'Buildings',
        icon: BuildingsSmallIcon,
    },
    {
        value: 'floors',
        label: 'Floors',
        icon: FloorsSmallIcon,
    },
    {
        value: 'zones',
        label: 'Zones',
        icon: ZonesSmallIcon,
    },
];

export default hierarchyOptions;
