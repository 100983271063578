import {DropdownCloseIcon, DropdownOpenIcon} from 'common/icons';
import React, {forwardRef} from 'react';

import {Button, Typography} from '@mui/material';

export type DropdownTriggerProps = {
    autoExpand?: boolean;
    clearable?: boolean;
    controlLabel: string;
    disabled?: boolean;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    onControlChange: (value: string) => void;
    open: boolean;
    placeholder?: string;
    width: number | string;
    backgroundColor: string;
};
const DropdownTriggerButton = forwardRef<
    HTMLButtonElement,
    DropdownTriggerProps
>((props, ref) => {
    const {
        controlLabel,
        onClick,
        open,
        width,
        autoExpand,
        backgroundColor,
    } = props;
    const endIcon = controlLabel ? (
        open ? (
            <DropdownOpenIcon />
        ) : (
            <DropdownCloseIcon />
        )
    ) : undefined;
    return (
        <Button
            aria-label="Dropdown standard trigger"
            endIcon={endIcon}
            onClick={onClick}
            ref={ref}
            variant="text"
            sx={{
                height: '40px',
                justifyContent: 'space-between',
                pl: 2,
                py: '8px',
                pr: '4px',
                width: autoExpand ? '100%' : width,
                '& .MuiButton-endIcon svg': {
                    fontSize: 24,
                },
                backgroundColor,
                '&:hover': {
                    backgroundColor: (theme) =>
                        theme.palette.common.dynamicBrandLight.hover(
                            backgroundColor
                        ),
                },
                '&:focus': {
                    backgroundColor: (theme) =>
                        theme.palette.common.dynamicBrandLight.focus(
                            backgroundColor
                        ),
                },
                '&:active': {
                    backgroundColor: (theme) =>
                        theme.palette.common.dynamicBrandLight.pressed(
                            backgroundColor
                        ),
                },
            }}
        >
            <Typography variant="button" color="grey.900">
                {controlLabel}
            </Typography>
        </Button>
    );
});

export default DropdownTriggerButton;
