import {Switch as MuiSwitch, SwitchProps, Box, alpha} from '@mui/material';

export default function Switch(props: SwitchProps) {
    return (
        <Box sx={{mr: '2px'}}>
            <MuiSwitch
                {...props}
                sx={{
                    width: 52,
                    height: 40,
                    '.MuiSwitch-thumb': {
                        width: 20,
                        height: 20,
                        boxShadow: (theme: any) =>
                            `0px 2px 10px ${alpha(
                                theme.palette.grey[800],
                                0.24
                            )}`,
                    },
                    '.MuiSwitch-track': {
                        width: 34,
                        height: 14,
                        borderRadius: '8px',
                        opacity: 1,
                        bgcolor: 'grey.400',
                    },
                    '.MuiSwitch-switchBase': {
                        ':hover': {
                            bgcolor: 'common.light.hover',
                        },
                        ':active': {
                            bgcolor: 'common.light.pressed',
                        },
                        ':focus': {
                            bgcolor: 'common.light.focus',
                        },
                    },
                    '.Mui-checked': {
                        '&.MuiSwitch-switchBase': {
                            transform: 'translateX(18px)',
                            '+.MuiSwitch-track': {
                                bgcolor: 'common.brandColor.200',
                                opacity: 1,
                            },
                            ':hover': {
                                bgcolor: 'common.brandLight.hover',
                            },
                            ':active': {
                                bgcolor: 'common.brandLight.pressed',
                            },
                            ':focus': {
                                bgcolor: 'common.brandLight.focus',
                            },
                        },
                    },
                }}
            />
        </Box>
    );
}
