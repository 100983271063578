import hierarchyOptions from '../../../config/hierarchyOptions';
import _ from 'lodash';
import {SvgIcon} from '@mui/material';

export interface RowBoxProps {
    size: number;
    hierarchyLevel?: string;
}

export default function RowBox({size, hierarchyLevel}: RowBoxProps) {
    let icon = _.first(
        _.filter(
            hierarchyOptions,
            (option) => option.value === hierarchyLevel
        )
    )?.icon as any;
    return (
        <SvgIcon
            component={icon}
            sx={{
                height: '24px',
                width: '24px',
                marginRight: '2px',
                color: 'grey.400',
            }}
        />
    );
}
