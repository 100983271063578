import {Grid, useMediaQuery, useTheme} from '@mui/material';
import {useEffect, useState} from 'react';

import {SelectionButton} from 'common/components';
import {SvgIconComponent} from '@mui/icons-material';
import _ from 'lodash';

export interface SelectionButtonGroupProps<T> {
    alwaysActive?: boolean;
    disabled?: boolean;
    value: Array<T>;
    options: Array<{label: string; value: T; icon: SvgIconComponent}>;
    onChange: (values: Array<T>) => void;
}

export default function HierarchyButtonGroup<T extends string>({
    value = [],
    alwaysActive,
    disabled,
    onChange,
    options,
}: SelectionButtonGroupProps<T>) {
    const [currentValues, setCurrentValues] = useState(value);
    const {
        breakpoints: {up},
    } = useTheme();
    const isMobile = !useMediaQuery(up('desktopSm'));
    useEffect(() => {
        setCurrentValues(value);
    }, [value]);
    const handleChange = (newValue: any) => {
        let newValues;
        if (_.includes(currentValues, newValue)) {
            //remove it
            newValues = _.filter(
                currentValues,
                (item) => item !== newValue
            );

            if (alwaysActive && !newValues.length) {
                return;
            }
        } else {
            currentValues.push(newValue);
            newValues = currentValues;
        }
        onChange(newValues);
    };
    // Create icons
    return (
        <Grid
            container
            justifyContent="left"
            columnSpacing={2}
            rowGap="12px"
            mb={2}
        >
            {_.map(options, ({label, value, icon}, idx) => {
                return (
                    <Grid item key={idx}>
                        <SelectionButton
                            isClickable={
                                !(
                                    currentValues.length === 1 &&
                                    _.includes(currentValues, value)
                                )
                            }
                            disabled={disabled}
                            icon={icon}
                            label={label}
                            isSelected={_.includes(currentValues, value)}
                            onClick={() => handleChange(value)}
                            sx={{
                                width: isMobile ? '156px' : 'fit-content',
                                justifyContent: 'left',
                            }}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
}
