type Formatter = {
    [key: string]: (item: any, row: any) => string;
};
function formatEmpty<T>(value: T | undefined): string {
    return value !== undefined && value !== null ? String(value) : '-';
}
export const fieldFormatters: Formatter = {
    name: (item: string, row) => `${row.name} (ID: ${row.id})`,
    buildings: (item: number, row) => `${formatEmpty(row.numBuildings)}`,
    /*location: (item: [number, number], row) =>
        `Location: ${
            row.Lat !== undefined && row.Lon !== undefined
                ? row.Lat + ', ' + row.Lon
                : ''
        } `,*/
    address: (item: string, row) => `${formatEmpty(row.address)}`,
    description: (item: number, row) => `${formatEmpty(row.description)}`,
    supply_cost: (item: number, row) => ``,
    currency: (item, row) => `${formatEmpty(row.currency)}`,
    timezone: (item: string, row) => `${formatEmpty(row.timezone)} `,
    working_hours: (item: string, row) =>
        `${formatEmpty(row.workingHours)} `,
    area: (item: string, row) => `${formatEmpty(row.area)} `,
    floorplan_name: (item: string, row) => ``,
    created_at: (item: string, row) => `${formatEmpty(row.created)} `,
};

export const fieldToLabel: {[key: string]: string} = {
    buildings: `Buildings`,
    address: `Address`,
    description: `Description`,
    supply_cost: ``,
    currency: `Currency`,
    timezone: `Timezone`,
    working_hours: `Work Hours`,
    area: `Area (sq.m.)`,
    floorplan_name: `Floorplan Name`,
    created_at: `Created`,
};
