import React from 'react';
import {
    useMemo,
    useCallback,
    useState,
    useEffect,
    ComponentProps,
} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {Box, useMediaQuery, useTheme} from '@mui/material';
import qs from 'qs';
import {useLocationChange} from 'common/hooks';
import {Toast, Area} from 'common/components';
import InfrastructureTable from '../components/InfrastructureTable';
import InfrastructureTree from '../components/InfrastructureTree';
import Results from '../components/Results';
import InfrastructureFilter from '../components/InfrastructureFilter';
import {HierarchyLevel} from '../config/hierarchyKeys';
import {useFilters, useHierarchy} from '../hooks';
import {Organization} from 'common/reducers/app.slice';
import {queryLevels} from '../config/hierarchyKeys';
import hierarchyOptions from '../config/hierarchyOptions';
import BuildingForm from '../components/Forms/BuildingForm';
import FloorForm from '../components/Forms/FloorForm';
import ZoneForm from '../components/Forms/ZoneForm';
import _ from 'lodash';
import {CompleteFilterDescriptor} from '../components/InfrastructureFilter/FilterFormSingle';
export const OrgSelectorContext = React.createContext({
    selectedOrganization: {} as Organization | null,
    allOrganizations: {} as Organization[] | null,
    handleOrgSelection: (orgId: number) => {},
    isLoading: true,
});

type TableProps = ComponentProps<typeof InfrastructureTable>;

export default function InfrastructureContainer() {
    const handleDeleteRow = useCallback<TableProps['onDeleteRow']>(
        (row) => {},
        []
    );
    const handleClickExpander = useCallback<TableProps['onClickExpander']>(
        (row) => {},
        []
    );

    const location = useLocation();
    const history = useHistory();

    const [sortBy, setSortBy] = useState<
        Array<{id: string; desc: Boolean}>
    >([]);

    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [addEditData, setAddEditData] = useState<{
        row: any;
        parentRow?: any;
        grandParentRow?: any;
        type: HierarchyLevel;
        isEdit: boolean;
    }>({
        row: null,
        parentRow: null,
        grandParentRow: null,
        type: 'buildings',
        isEdit: false,
    });

    const {
        hierarchy,
        setHierarchy,
        setPage,
        setLimit,
        setFilteringQuery,
        isLoading: hierarchyLoading,
        dataTable,
        dataTree,
        count,
        errorOrganizations,
        errorInfrastructure,
    } = useHierarchy();
    const {
        isLoading: filtersLoading,
        fullEntitiesError,
        combinatorsError,
        fullEntityInfo,
        setFilters,
        query,
    } = useFilters();

    useEffect(() => {
        setFilteringQuery(query);
    }, [query, setFilteringQuery]);

    const hasFilters = useMemo(() => {
        return !_.isEmpty(query);
    }, [query]);

    const handleFilterChange = useCallback(
        (newFilters: CompleteFilterDescriptor[]) => {
            setFilters(newFilters);
        },
        [setFilters]
    );

    const [snackbarMessage, setSnackbarMessage] = useState<
        string | undefined
    >(undefined);

    useEffect(() => {
        const errors = [
            errorOrganizations,
            fullEntitiesError,
            combinatorsError,
        ];
        errors.forEach((e) => (_.isNil(e) ? null : setSnackbarMessage(e)));
    }, [combinatorsError, errorOrganizations, fullEntitiesError]);

    const sortHierarchy = useCallback(
        (a: HierarchyLevel, b: HierarchyLevel) => {
            if (queryLevels[a] > queryLevels[b]) {
                return 1;
            } else if (queryLevels[a] < queryLevels[b]) {
                return -1;
            }
            return 0;
        },
        []
    );

    const {
        breakpoints: {up},
    } = useTheme();

    const isMobile = !useMediaQuery(up('desktopSm'));

    const handleAddEditRow = useCallback(
        (row: any, type: HierarchyLevel, isEdit: boolean) => {
            let data = (hierarchy.length > 1 ? dataTree : dataTable) ?? [];

            let parentRow = isEdit
                ? data.find((e: any) => row.parentId === e.iRef)
                : row;
            let grandParentRow =
                type === 'zones'
                    ? data.find((e: any) => parentRow?.parentId === e.iRef)
                    : undefined;
            row = isEdit ? row : undefined;

            setAddEditData({
                row,
                parentRow,
                grandParentRow,
                type,
                isEdit,
            });
            setDrawerOpen(true);
        },
        [dataTree, dataTable, hierarchy.length]
    );

    useLocationChange(() => {
        const options = hierarchyOptions
            .map((item) => item.value)
            .sort(sortHierarchy) as HierarchyLevel[];

        const params = qs.parse(location.search, {
            comma: true,
            ignoreQueryPrefix: true,
        });

        const validateHierarchy = (level: HierarchyLevel) =>
            options.includes(level);

        const hierarchy = params.hierarchy as unknown;
        if (hierarchy && validateHierarchy(hierarchy as HierarchyLevel)) {
            setHierarchy([hierarchy as HierarchyLevel]);
        } else if (hierarchy && _.isArray(hierarchy) && hierarchy.length) {
            const result = _(hierarchy)
                .compact()
                .filter(validateHierarchy)
                .value();
            setHierarchy(result.sort(sortHierarchy));
        } else {
            setHierarchy(options);
        }
    });

    const handleChangeSorting = useCallback((columnProps) => {
        setSortBy([columnProps]);
    }, []);

    const filteredTableData = useMemo<Record<string, unknown>[]>(() => {
        let sortedBy: string[] = [];
        let sortedByOrder: any[] = [];

        _.forEach(sortBy, (item) => {
            sortedBy.push(item.id);
            sortedByOrder.push(item.desc ? 'desc' : 'asc');
        });

        return _.orderBy<Record<string, unknown>>(
            dataTable,
            sortedBy,
            sortedByOrder
        );
    }, [sortBy, dataTable]);

    const initialState = useMemo(
        () => ({
            sortBy: [{id: 'description', desc: true}],
        }),
        []
    );
    const handleChangeHierarchy = useCallback(
        (values: Array<HierarchyLevel>) => {
            history.push({
                pathname: location.pathname,
                search:
                    '?' +
                    qs.stringify(
                        {hierarchy: values},
                        {arrayFormat: 'comma', encode: false}
                    ),
            });
        },
        [history, location.pathname]
    );

    const [isDesktopOpen, setDesktopFilterExpanded] = useState(false);
    const addEditForm = useMemo(() => {
        if (!isDrawerOpen) {
            return null;
        }
        const {row, parentRow, grandParentRow, type, isEdit} = {
            ...addEditData,
        };
        switch (type) {
            case 'buildings':
                return (
                    <BuildingForm
                        isEdit={isEdit}
                        isOpen={isDrawerOpen}
                        values={row}
                        subtitleHierarchy={[
                            isEdit ? row.name : 'New Building',
                        ]}
                        handleSave={() => setDrawerOpen(false)}
                        handleClose={() => setDrawerOpen(false)}
                    />
                );
            case 'floors':
                return (
                    <FloorForm
                        isEdit={isEdit}
                        isOpen={isDrawerOpen}
                        values={row}
                        subtitleHierarchy={[
                            `${parentRow?.name ?? 'Parent name'} (ID:${
                                parentRow?.id ?? 'parentId'
                            })`,
                            `${
                                isEdit
                                    ? row.name + ' (ID:' + row.id + ')'
                                    : 'New Floor'
                            }`,
                        ]}
                        handleSave={() => setDrawerOpen(false)}
                        handleClose={() => setDrawerOpen(false)}
                    />
                );
            case 'zones':
                return (
                    <ZoneForm
                        isEdit={isEdit}
                        isOpen={isDrawerOpen}
                        values={row}
                        subtitleHierarchy={[
                            `${
                                grandParentRow?.name ?? 'Grandpa name'
                            } (ID:${grandParentRow?.id ?? 'grandpaId'})`,
                            ` ${parentRow?.name ?? 'Parent Name'} (ID:${
                                parentRow?.id ?? 'parentId'
                            })`,
                            ` ${
                                isEdit
                                    ? row.name + ' (ID:' + row.id + ')'
                                    : 'New Zone'
                            }`,
                        ]}
                        handleSave={() => setDrawerOpen(false)}
                        handleClose={() => setDrawerOpen(false)}
                    />
                );
        }
    }, [addEditData, isDrawerOpen]);

    const areaHeight = useMemo(() => {
        if (isMobile) {
            return '100%';
        } else if (isDesktopOpen) {
            return 'calc(100% - 299px)';
        } else {
            return 'calc(100% - 136px)';
        }
    }, [isDesktopOpen, isMobile]);

    const infrastructureTree = useMemo(() => {
        return (
            hierarchy.length > 1 && (
                <InfrastructureTree
                    data={dataTree || []}
                    hierarchy={hierarchy}
                    isLoading={hierarchyLoading}
                    errorMessage={errorInfrastructure}
                    isEmptyState={
                        !_.isNil(dataTree) && dataTree.length === 0
                    }
                    handleAddRow={(type, row) =>
                        handleAddEditRow(row, type, false)
                    }
                    handleEditRow={(type, row) =>
                        handleAddEditRow(row, type, true)
                    }
                />
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataTree, hierarchyLoading, errorInfrastructure]);

    const infrastructureTable = useMemo(
        () =>
            hierarchy.length === 1 && (
                <InfrastructureTable
                    hierarchy={hierarchy[0]}
                    height={areaHeight}
                    isDesktopOpen={isDesktopOpen}
                    isEmptyState={
                        dataTable && _.isEmpty(filteredTableData)
                    }
                    data={filteredTableData}
                    errorMessage={errorInfrastructure}
                    sortBy={sortBy}
                    isLoading={hierarchyLoading}
                    tableInitialState={initialState}
                    onChangeSorting={handleChangeSorting}
                    onAddRow={(type, row) =>
                        handleAddEditRow(row, type, false)
                    }
                    onEditRow={(type, row) =>
                        handleAddEditRow(row, type, true)
                    }
                    onDeleteRow={handleDeleteRow}
                    onClickExpander={handleClickExpander}
                    onPageChange={setPage}
                    onLimitChange={setLimit}
                    dataTotal={count[hierarchy[0]]}
                />
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            areaHeight,
            count,
            dataTable,
            errorInfrastructure,
            filteredTableData,
            hierarchyLoading,
            initialState,
            isDesktopOpen,
            sortBy,
        ]
    );

    const mainComponent = useMemo(() => {
        if (hierarchy.length === 1) {
            return (
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {infrastructureTable}
                </Box>
            );
        } else {
            return (
                <Area
                    variant="lower"
                    sx={{
                        overflow:
                            hierarchy.length === 1 ? 'auto' : 'hidden',
                        width: '100%',
                        height: areaHeight,
                    }}
                >
                    {infrastructureTree}
                </Area>
            );
        }
    }, [
        areaHeight,
        hierarchy.length,
        infrastructureTable,
        infrastructureTree,
    ]);

    return (
        <Box
            width="100%"
            height="100%"
            className="infrastructure-container"
            display={isMobile ? 'flex' : undefined}
            flexDirection="column"
        >
            <InfrastructureFilter
                filtersExist={hasFilters}
                hierarchy={hierarchy}
                isLoading={hierarchyLoading || filtersLoading}
                onChangeHierarchy={handleChangeHierarchy}
                hierarchyOptions={hierarchyOptions}
                isDesktopOpen={isDesktopOpen}
                setDesktopFilterExpanded={setDesktopFilterExpanded}
                dataTree={dataTree}
                onChangeFilters={handleFilterChange}
                fullEntityInfo={fullEntityInfo}
            />
            {!isMobile && (
                <Results
                    hierarchy={hierarchy}
                    isLoading={hierarchyLoading}
                    count={count}
                    hierarchyOptions={hierarchyOptions}
                />
            )}

            {mainComponent}
            {addEditForm}
            <Toast toastValue={snackbarMessage} type="error" />
        </Box>
    );
}
