import {
    createSlice,
    createAsyncThunk,
    AsyncThunk,
    ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import {Organization} from 'common/reducers/app.slice';
import {
    fetchBuilding,
    fetchFloor,
    fetchHierarchy,
    fetchZone,
} from 'common/services/hierarchy.service';
import {getErrorMessage} from '../helpers/fetchBaseQueryErrors';
import {QueryArgument} from '../helpers/filterQuery';
import {
    buildingValidator,
    fetchHierarchyValidator,
    floorValidator,
    zoneValidator,
} from './infrastructure.types';

export interface InfrastructureState {
    loading: boolean;
    data: any;
    error?: string | null;
}

const initialState: InfrastructureState = {
    loading: false,
    data: null,
    error: null,
};

export const levelsRequestMap = {
    buildings: {
        level: 2,
        request: fetchBuilding,
        validator: buildingValidator,
    },
    floors: {
        level: 3,
        request: fetchFloor,
        validator: floorValidator,
    },
    zones: {
        level: 4,
        request: fetchZone,
        validator: zoneValidator,
    },
};

export const fetchHierarchyAll = createAsyncThunk(
    'infrastructure/fetchHierarchyAll',
    async (
        params: {filteringQuery: QueryArgument[]},
        {rejectWithValue, getState}
    ) => {
        const orgId = (
            (getState() as any).app.selectedOrganization as Organization
        ).orgId;
        try {
            const response: Response = await fetchHierarchy(
                {filteringQuery: params.filteringQuery},
                orgId
            );
            const body = await response.json();
            if (response.status >= 400) {
                throw body;
            }
            const validator = fetchHierarchyValidator;
            return validator.parse(body);
        } catch (e) {
            const errorMessage = getErrorMessage(e);
            return rejectWithValue(errorMessage);
        }
    }
);

export const fetchHierarchyLevel = createAsyncThunk(
    'infrastructure/fetchHierarchyLevel',
    async (
        params: {
            key: keyof typeof levelsRequestMap;
            page?: number;
            limit?: number;
            filteringQuery: QueryArgument[];
        },
        {rejectWithValue, getState}
    ) => {
        const orgId = (
            (getState() as any).app.selectedOrganization as Organization
        ).orgId;
        try {
            const response = await levelsRequestMap[params.key].request(
                {
                    filteringQuery: params.filteringQuery,
                    limit: params.limit,
                    page: params.page,
                },
                orgId
            );
            const body = await response.json();
            if (response.status >= 400) {
                throw body;
            }
            const validator = levelsRequestMap[params.key].validator;
            return validator.parse(body);
        } catch (e) {
            const errorMessage = getErrorMessage(e);
            return rejectWithValue(errorMessage);
        }
    }
);

const buildStandardCases = (
    builder: ActionReducerMapBuilder<InfrastructureState>,
    thunk: AsyncThunk<any, any, any>
) => {
    builder.addCase(thunk.pending, (state: InfrastructureState) => {
        state.loading = true;
        state.data = null;
        state.error = null;
    });
    builder.addCase(
        thunk.fulfilled,
        (state: InfrastructureState, {payload}) => {
            state.loading = false;
            state.data = payload;
            state.error = null;
        }
    );
    builder.addCase(
        thunk.rejected,
        (state: InfrastructureState, {payload}) => {
            state.loading = false;
            state.error = payload as string;
        }
    );
};

export const infrastructureSlice = createSlice({
    name: 'infrastructure',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        buildStandardCases(builder, fetchHierarchyLevel);
        buildStandardCases(builder, fetchHierarchyAll);
    },
});

export default infrastructureSlice.reducer;
