import React, {useMemo} from 'react';
import {AnyObject, UnknownObject, CUSTOM_COLUMNS} from './index';
import {
    ColumnInstance,
    Hooks,
    TableInstance,
    ensurePluginOrder,
} from 'react-table';

function useInstance<D extends AnyObject = UnknownObject>(
    instance: TableInstance<D>
) {
    ensurePluginOrder(
        instance.plugins,
        ['useColumnOrder'],
        'useMoveColumn'
    );

    const {columns} = instance;

    const customColumnsCount = CUSTOM_COLUMNS.reduce((prev, cur) => {
        if (columns.find((item) => item.id === cur)) {
            prev += 1;
        }
        return prev;
    }, 0);

    instance.moveColumn = (
        dragIndex: number,
        hoverIndex: number,
        columnIds: string[]
    ) => {
        if (dragIndex === undefined) {
            return;
        }
        columnIds = [...columnIds];
        const dragCol = columnIds[dragIndex];
        columnIds.splice(dragIndex, 1);
        columnIds.splice(
            Math.min(hoverIndex, columnIds.length - customColumnsCount),
            0,
            dragCol
        );
        instance.setColumnOrder(() => columnIds);
    };
    const menuColumn = columns.find(
        (col) => col.id === '_menu_'
    ) as ColumnInstance<D>;

    useMemo(() => {
        if (menuColumn && menuColumn.Header) {
            const HeaderComponent = menuColumn.Header as React.ElementType;
            menuColumn.Header = ({allColumns}) => (
                <HeaderComponent
                    allColumns={allColumns}
                    moveColumn={instance.moveColumn}
                />
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance, columns]);
}

export default function useMoveColumn<D extends AnyObject = UnknownObject>(
    hooks: Hooks<D>
): void {
    hooks.useInstance.push(useInstance);
}
useMoveColumn.pluginName = 'useMoveColumn';
