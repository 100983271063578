import {Box, SxProps} from '@mui/material';
import {grey} from 'common/colors';

export interface SelectionCardProps {
    index: number;
    length: number;
    sx?: SxProps;
    leftEl?: React.ReactNode;
    label?: string | React.ReactNode;
    rightEl?: React.ReactNode;
    onClick?: (item: any) => void;
    selected?: boolean;
}

export default function SelectionCard({
    length,
    index,
    selected,
    sx,
    leftEl,
    label,
    rightEl,
    onClick,
}: SelectionCardProps) {
    return (
        <Box
            className={selected ? 'Mui-selected' : ''}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: 0,

                position: 'static',
                height: '48px',
                left: '0px',
                top: index * 64,

                borderRadius: '100px',
                backgroundColor: 'grey.100',
                flex: 'none',

                order: index,
                alignSelf: 'stretch',
                flexGrow: 0,
                ...sx,
                '&:hover': {
                    bgcolor: (theme) =>
                        theme.palette.common.dynamicLight.hover(grey[100]),
                    cursor: 'pointer',
                },
                '&:active': {
                    bgcolor: (theme) =>
                        theme.palette.common.dynamicLight.pressed(
                            grey[100]
                        ),
                },
                '&.Mui-selected': {
                    backgroundColor: 'grey.800',
                    color: 'grey.100',
                },
            }}
            onClick={onClick}
        >
            {leftEl}
            {label}
            {rightEl}
        </Box>
    );
}
