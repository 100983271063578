import {HierarchyLevel} from 'modules/admin/config/hierarchyKeys';
import './HierarchyDropdowns.scss';
import {DropdownSingle} from 'common/components/Dropdown';

type HierarchyOption = {
    value: HierarchyLevel;
    label: string;
};

interface DropdownHierarchyProps {
    hierarchyDropdownOptions: {label: string; value: HierarchyLevel}[];
    onChange: (value: HierarchyOption | null) => void;
    value: HierarchyOption | null;
}

export default function DropdownHierarchy({
    hierarchyDropdownOptions,
    onChange,
    value,
}: DropdownHierarchyProps) {
    return (
        <DropdownSingle<HierarchyOption>
            virtualized
            supportDynamicHeight
            autoExpand
            variant="outlined"
            placeholder="Select name"
            formatLabel={(option) => option.label}
            options={hierarchyDropdownOptions}
            onChange={onChange}
            value={value}
        />
    );
}
