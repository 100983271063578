import FabButton from '../../../../../common/components/FabButton';
import ProductCard from '../ProductCard';
import {Area} from '../../../../../common/components';
import {Box, Collapse} from '@mui/material';
import {Module, ModuleName} from 'common/constants/modules';
import _ from 'lodash';

export interface ProductMenuMobileModuleDropdownProps {
    activeProductIcon: any;
    isProductMenuExpanded: boolean;
    adminSelected: boolean;
    activeProductLabel: string | undefined;
    setProductMenu: Function;
    products: Module[];
    activeProductName: ModuleName;
    onProductClick: (productName: ModuleName) => void;
}
export default function ProductMenuMobileModuleDropdown({
    activeProductIcon,
    isProductMenuExpanded,
    adminSelected,
    activeProductLabel,
    setProductMenu,
    products,
    activeProductName,
    onProductClick,
}: ProductMenuMobileModuleDropdownProps) {
    const ProductIcon = ({label}: {label?: string}) => {
        const ProductIcon = activeProductIcon;
        return ProductIcon ? <ProductIcon aria-label={label} /> : null;
    };
    return (
        <Collapse
            in={isProductMenuExpanded}
            collapsedSize={44}
            timeout={{exit: 0, enter: 200}}
        >
            <Box
                sx={{
                    height: '44px',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <FabButton
                    ariaLabel="Menu button"
                    sx={{
                        width: '100%',
                    }}
                    isUnselected={adminSelected}
                    icon={<ProductIcon aria-label={activeProductLabel} />}
                    label={activeProductLabel || 'Select Module'}
                    isOpen={isProductMenuExpanded}
                    onClick={() => {
                        setProductMenu(!isProductMenuExpanded);
                    }}
                />
            </Box>
            <Area
                variant="lower"
                sx={{
                    position: 'relative',
                    zIndex: 'speedDial',
                    width: '100%',
                    height: 'fit-content',
                    borderRadius: '32px',
                    display: 'flex',
                    flexDirection: 'column',
                    py: 2,
                    gap: 3,
                }}
            >
                {_.map(products, (product) => (
                    <ProductCard
                        activeProductName={activeProductName}
                        onClick={onProductClick}
                        key={product.name}
                        product={product}
                    />
                ))}
            </Area>
        </Collapse>
    );
}
