import {useLocation} from 'react-router-dom';
import AdminSideNav from '../components/AdminSideNav';
import sections from '../config/sections';
import {useEffect, useState} from 'react';
import {setAdminSelected, setTitle} from 'common/reducers/app.slice';
import {useDispatch, useModuleRouter, useSelector} from 'common/hooks';
import {useLocationChange} from 'common/hooks';
import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import AppLayout from 'modules/app/components/AppLayout';
import AppContainer from 'modules/app/containers/AppContainer';
import {MainMenuMobile} from 'common/components/MainMenu';
import {InfrastructureIcon, UserManagementIcon} from 'common/icons';
export interface AdminContainerProps {
    children: React.ReactNode | React.ReactNode[];
}
export default function AdminContainer({children}: AdminContainerProps) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [name, setName] = useState('');

    const {transitionTo} = useModuleRouter();

    const handleChangeTab = (tab: string) => {
        transitionTo(tab);
    };
    const getSectionLabel = (name: string): string => {
        const section = sections.find((item) => item.name === name);
        if (section) {
            return section.label;
        } else {
            return '';
        }
    };

    useLocationChange(() => {
        setName(location.pathname.split('/')[2]);
    });

    useEffect(() => {
        dispatch(setAdminSelected(true));
        return () => {
            dispatch(setTitle(''));
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(setTitle(getSectionLabel(name)));
    }, [name, dispatch]);

    const organizations = useSelector((state) => state.app.organizations);
    const {
        breakpoints: {up},
    } = useTheme();
    const isMobile = !useMediaQuery(up('desktopSm'));
    return (
        <>
            {name && (
                <AppContainer
                    mobileMenu={
                        <MainMenuMobile
                            options={mobileOptions}
                            value={name}
                            onChange={handleChangeTab}
                        />
                    }
                >
                    {!isMobile && (
                        <AdminSideNav
                            defaultValue={name}
                            onChange={handleChangeTab}
                        />
                    )}
                    <Box
                        className="content-admin"
                        width="100%"
                        height="100%"
                        position="absolute"
                    >
                        <AppLayout sideNavWidth={isMobile ? 0 : undefined}>
                            {organizations.loading ? (
                                <Typography p={2}>
                                    Loading. Please wait...
                                </Typography>
                            ) : (
                                children
                            )}
                        </AppLayout>
                    </Box>
                </AppContainer>
            )}
        </>
    );
}

const mobileOptions = [
    {
        label: 'Infrastructure Management',
        value: 'infrastructure-management',
        icon: <InfrastructureIcon />,
    },
    {
        label: 'User Management',
        value: 'user-management',
        icon: <UserManagementIcon />,
    },
];
