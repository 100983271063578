import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getUserRights} from '../services/userRights.service';
export interface UserState {
    loading: boolean;
    data: Record<string, unknown> | null;
    error?: string | null;
}

const initialState = {
    loading: false,
    data: null,
};

export const fetchUser = createAsyncThunk<Record<string, unknown>>(
    'user/fetchUserData',
    async (_, {rejectWithValue}) => {
        const res = await getUserRights()
            .then((data) => data.json())
            .catch((e) => rejectWithValue({error: 'Error ' + e}));
        return res;
    }
);

// Note: reducers get wrapped by Immer via rtk library (no need to make them immutable)
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUser.pending, (state: UserState) => {
            state.loading = true;
        });
        builder.addCase(
            fetchUser.fulfilled,
            (state: UserState, {payload}) => {
                state.loading = false;
                state.data = payload;
            }
        );
        builder.addCase(
            fetchUser.rejected,
            (state: UserState, {payload}) => {
                state.loading = false;
                state.error = payload as string;
            }
        );
    },
});

export default userSlice.reducer;
