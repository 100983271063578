import {Box, Tooltip} from '@mui/material';
import {Row} from 'react-table';
import {AnyObject, UnknownObject} from './index';
import {
    EditIcon,
    AddIcon,
    DeleteIcon,
    DataIcon,
    WeatherIcon,
} from '../../icons';
import {IconButton} from '..';
import {HierarchyLevel} from 'modules/admin/config/hierarchyKeys';
import {useCallback} from 'react';
import {hierarchyNameTransform} from 'modules/admin/lib';

export interface TableRowActionsProps<
    T extends AnyObject = UnknownObject
> {
    onClickAdd?: (row: T) => void;
    onClickEdit?: (row: T) => void;
    onClickDelete?: (row: Row<T>) => void;
    onClickVisible?: (row: Row<T>) => void;
    showWeatherIndicator?: boolean;
    children?: JSX.Element;
    row: Row<T>;
    hierarchy: HierarchyLevel[];
    type: HierarchyLevel;
}
export default function TableRowActions<
    T extends AnyObject = UnknownObject
>({
    onClickAdd,
    type,
    onClickEdit,
    onClickDelete,
    onClickVisible,
    children,
    row,
    showWeatherIndicator,
    hierarchy,
}: TableRowActionsProps<T>) {
    const handleClickAdd = () => {
        if (onClickAdd) {
            onClickAdd(row.original);
        }
    };
    const handleClickEdit = useCallback(() => {
        if (onClickEdit) {
            onClickEdit(row.original);
        }
    }, [onClickEdit, row]);
    const handleClickDelete = useCallback(() => {
        if (onClickDelete) {
            onClickDelete(row);
        }
    }, [onClickDelete, row]);
    const handleClickVisibility = useCallback(() => {
        if (onClickVisible) {
            onClickVisible(row);
        }
    }, [onClickVisible, row]);
    const showAddButton = useCallback(
        (type: HierarchyLevel) => {
            switch (type) {
                case 'buildings':
                    return hierarchy.includes('floors');
                case 'floors':
                    return hierarchy.includes('zones');
                case 'zones':
                    return false;
            }
        },
        [hierarchy]
    );

    return (
        <Box
            data-testid="table-actions"
            sx={{display: 'flex', flexDirection: 'row'}}
        >
            {showWeatherIndicator ? (
                <Tooltip
                    arrow
                    title="Weather data collection"
                    enterDelay={500}
                >
                    <Box mr={2}>
                        <IconButton
                            disabled
                            sx={{
                                '&.Mui-disabled': {
                                    color: 'common.messages.verification',
                                    bgcolor:
                                        'common.messages.verificationBg',
                                },
                            }}
                        >
                            <WeatherIcon />
                        </IconButton>
                    </Box>
                </Tooltip>
            ) : null}
            {onClickVisible && (
                <IconButton
                    tooltip="View Data"
                    onClick={handleClickVisibility}
                    aria-label="show row"
                    sx={{
                        marginRight: '2px',
                    }}
                >
                    <DataIcon sx={{color: 'common.brandColor.500'}} />
                </IconButton>
            )}
            {showAddButton((row as any).original.type) && onClickAdd ? (
                <IconButton
                    tooltip={`Add ${hierarchyNameTransform(type, [
                        'Child',
                    ])}`}
                    onClick={handleClickAdd}
                    aria-label="add row"
                    sx={{
                        marginRight: '2px',
                    }}
                >
                    <AddIcon sx={{color: 'grey.600'}} />
                </IconButton>
            ) : (
                <Box width={32} height={32} m={0.5} mr={0.25}></Box>
            )}
            {onClickEdit && (
                <IconButton
                    tooltip={`Edit ${hierarchyNameTransform(type)}`}
                    onClick={handleClickEdit}
                    aria-label="edit row"
                    sx={{
                        marginRight: '2px',
                    }}
                >
                    <EditIcon sx={{color: 'grey.600'}} />
                </IconButton>
            )}
            {onClickDelete && (
                <IconButton
                    tooltip={`Remove ${hierarchyNameTransform(type)}`}
                    onClick={handleClickDelete}
                    aria-label="delete row"
                    sx={{
                        marginRight: '2px',
                    }}
                >
                    <DeleteIcon sx={{color: 'grey.600'}} />
                </IconButton>
            )}
            {children}
        </Box>
    );
}
