import {
    Log,
    User,
    UserManager as OIDCUserManager,
    UserManagerSettings,
} from 'oidc-client';

import config from '../../config';

const {AUTH} = config;
export class UserManager {
    private static _instance: UserManager | null = null;
    public static GetInstance(): UserManager {
        if (this._instance === null) {
            this._instance = new UserManager();
        }
        return this._instance;
    }
    private userManager: OIDCUserManager;
    private user: User | null = null;
    constructor() {
        Log.logger = console;
        Log.level = Log.INFO;

        const settings: UserManagerSettings = {
            authority: AUTH.authority,
            client_id: AUTH.client_id,
            client_secret: AUTH.client_secret,
            redirect_uri: AUTH.redirect_uri,
            silent_redirect_uri: AUTH.silent_redirect_uri,
            post_logout_redirect_uri: AUTH.post_logout_redirect_uri,

            // Code based auth
            response_type: AUTH.response_type,
            scope: AUTH.scope,
            filterProtocolClaims: true,
            loadUserInfo: true,

            automaticSilentRenew: true,
        };
        this.userManager = new OIDCUserManager(settings);

        // Save user information
        this.userManager.events.addUserLoaded((user) => {
            console.error('User Loaded: ', user);
            this.user = user;
        });
    }
    public async init() {
        try {
            let user = await this.userManager.getUser();
            if (user === null) {
                console.error('failed to get user');
                this.login();
                return null;
            } else if (user?.expired) {
                console.error('Old User expired: ', user);
                this.user = await this.userManager.signinSilent();
            } else {
                this.user = user;
            }
        } catch (ex) {
            console.error(ex);
            // not login request it
            this.login();
        }

        // Set timer to check if the user is valid and renew it
        console.error('User:', this.user);
        return this.user;
    }
    public getAccessToken() {
        return this.user?.access_token ?? null;
    }
    public getBearer() {
        return `Bearer ${this.getAccessToken()}`;
    }

    public login(): Promise<void> {
        return this.userManager.signinRedirect({
            state: {urlSearch: window.location.search},
        });
    }
    public loginCb(): Promise<User> {
        return this.userManager.signinRedirectCallback(
            window.location.href
        );
    }

    public logout(): Promise<void> {
        this.user = null;
        return this.userManager.signoutRedirect();
    }
}
