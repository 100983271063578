import {UserManager} from '../lib/UserManager';
import getRequest from '../lib/request';

import config from '../../config';

const {SERVICE} = config;

export const getUserRights = async () => {
    // TODO experimental
    return getRequest(`${SERVICE.userRights}UserRights/`, {
        headers: {
            Accept: 'application/json',
            Authorization: UserManager.GetInstance()?.getBearer(),
        },
    });
};
