import {
    Box,
    MenuItem,
    MenuItemProps,
    Typography,
    alpha,
} from '@mui/material';
import {
    DropdownCheckboxSelectedIcon,
    DropdownCheckboxUnselectedIcon,
    DropdownSelectionIcon,
} from 'common/icons';

import _ from 'lodash';
import clsx from 'clsx';
import {useMemo} from 'react';
import {grey} from 'common/colors';
export type DropdownOptionProps = {
    label: string;
    value: number | string;
    item?: React.ReactNode;
    selectionVariant: 'unique' | 'multiple' | 'unique-with-details';
    width?: number;
} & MenuItemProps;

export default function DropdownOption({
    label,
    value,
    item,
    selectionVariant,
    selected,
    width,
    ...rest
}: DropdownOptionProps) {
    const className = clsx({
        unique: selectionVariant === 'unique',
        multiple: selectionVariant === 'multiple',
        'unique-details': selectionVariant === 'unique-with-details',
        isSelected: selected,
    });
    const checkboxWidth = 24;
    const horizontalPadding = 12;
    const extraPadding = selectionVariant === 'multiple' ? 12 : 8;
    const typographyWidth = useMemo(() => {
        if (width) {
            if (typeof width === 'number') {
                const typoWidth =
                    width -
                    checkboxWidth -
                    horizontalPadding -
                    extraPadding;
                return `${typoWidth > 0 ? typoWidth : 0}px`;
            }
            return 'auto';
        }
    }, [extraPadding, width]);
    const leftElement =
        selectionVariant !== 'multiple' ? null : selected ? (
            <DropdownCheckboxSelectedIcon
                sx={{
                    pr: 1,
                    color: (theme) => 'primary.main',
                    width: `${checkboxWidth + 8}px`,
                }}
            />
        ) : (
            <DropdownCheckboxUnselectedIcon
                data-testid="mui-select-multiple-checkbox-test"
                sx={{
                    color: 'grey.600',
                    width: `${checkboxWidth + 8}px`,
                    pr: 1,
                }}
            />
        );

    const rightElement =
        selectionVariant !== 'multiple' && selected ? (
            <DropdownSelectionIcon
                sx={{
                    color: 'common.brandColor.500',
                    mr: `${extraPadding}px`,
                }}
            />
        ) : null;

    const bottomElement =
        selectionVariant !== 'unique-with-details' &&
        !_.isUndefined(item) &&
        !_.isNull(item)
            ? null
            : item;
    delete rest?.style?.height; //remove this when dividers are implemented
    return (
        <MenuItem
            data-testid="mui-select-option-test"
            {...rest}
            className={className}
            sx={{
                backgroundColor: 'grey.100',
                height: '32px',
                py: '4px',
                px: `${horizontalPadding}px`,
                '&.multiple': {
                    paddingLeft: `${extraPadding}px`,
                },
                '&.unique': {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                },
                '&.unique-details': {
                    display: 'flex',
                    flexDirection: 'row',
                    height: '64px',
                },
                '&:hover': {
                    backgroundColor: (theme) =>
                        theme.palette.common.dynamicLight.hover(grey[100]),
                },
                '&:active': {
                    backgroundColor: (theme) =>
                        theme.palette.common.dynamicLight.pressed(
                            grey[100]
                        ),
                },
                '&.isSelected': {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.light, 0.32),
                },
                ...rest.sx,
            }}
            value={value}
        >
            {leftElement}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="calc(100% - 4px)"
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Typography
                        data-testid="mui-select-option-label"
                        variant="body2"
                        color="grey.900"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        width={typographyWidth}
                    >
                        {label}
                    </Typography>
                    {bottomElement}
                </Box>
                {rightElement}
            </Box>
        </MenuItem>
    );
}
