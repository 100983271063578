import {Box} from '@mui/material';

export default function HorizontalLine() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <Box
                className="horizontal-line"
                sx={{
                    width: '8px',
                    borderTop: '1px solid',
                    color: 'common.brandColor.100',
                }}
            ></Box>
        </Box>
    );
}
