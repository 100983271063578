import {useEffect, useState} from 'react';
import {Box, TextareaAutosize, Typography} from '@mui/material';
import config from '../../../config';

const fetchApi = (url: string, opt?: RequestInit, token?: string) => {
    const headers = opt || {};
    headers.headers = {
        'Content-Type': 'application/json',
        ...(headers.headers || {}),
    };
    if (token) {
        headers.headers = {
            ...headers.headers,
            Authorization: `Bearer ${token}`,
        };
    }
    return fetch(`${config.SERVICE.infrastructure}${url}`, headers).then(
        (response) => {
            if (!response.ok) {
                throw Error(
                    `${response.url} (${response.status}): ${response.statusText}`
                );
            }
            return response.json();
        }
    );
};

const fetchToken = () => {
    return fetchApi('AuthLogin', {
        method: 'POST',
        body: JSON.stringify(config.TEST_USER),
    });
};

const fetchBuilding = (token: string, buildingId: number) => {
    return fetchApi(
        `Buildings/${buildingId}`,
        {
            method: 'GET',
        },
        token
    );
};

export default function SmokeTestContainer() {
    const [token, setToken] = useState();
    const [building, setBuilding] = useState();
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        fetchToken()
            .then(({token}) => {
                setToken(token);
                return fetchBuilding(token, 2).then((building) => {
                    setBuilding(building);
                });
            })
            .catch((error) => {
                setError(error);
            });
    }, []);

    return (
        <>
            <Typography variant="h5" mt={2} mx={4}>
                Smoke test (demo@yodiwo.com)
            </Typography>
            <Box m={4}>
                {token ? (
                    <>
                        {error ? <Box>Error - {error.message}</Box> : null}
                        <Box>Token:</Box>
                        <TextareaAutosize
                            value={token}
                            maxRows={10}
                            style={{width: 300}}
                        />
                        {!building && !error && token ? (
                            <Box>Fetching Buildings/2...</Box>
                        ) : null}
                        {building ? (
                            <>
                                <Box mt={2}>/Buildings/2:</Box>
                                <TextareaAutosize
                                    value={JSON.stringify(
                                        building,
                                        null,
                                        4
                                    )}
                                    maxRows={40}
                                    style={{width: 400}}
                                />
                            </>
                        ) : null}
                    </>
                ) : (
                    <Box>Fetching token...</Box>
                )}
            </Box>
        </>
    );
}
