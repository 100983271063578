import {Button, Stack, SxProps, Typography} from '@mui/material';
import {ComponentProps} from 'react';

export interface ContentTileProps {
    title?: string;
    content?: string;
    onClick?: ComponentProps<'button'>['onClick'];
    sx?: SxProps;
}

function formatEmpty(value?: string | undefined): string {
    return value !== undefined && value !== null && value !== ''
        ? value
        : '-';
}

function ContentTile({title, content, onClick, sx}: ContentTileProps) {
    return (
        <Stack
            direction="column"
            height="26px"
            textAlign="left"
            justifyContent="center"
        >
            <Typography variant="menu" color="grey.600">
                {title}
            </Typography>
            {onClick ? (
                <Button
                    onClick={onClick}
                    sx={{
                        color: (theme) => theme.palette.primary.main,
                        p: '0',
                    }}
                    variant="text"
                >
                    <Typography variant="overline">{content}</Typography>
                </Button>
            ) : (
                <Typography
                    variant="overline"
                    color="grey.900"
                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        ...sx,
                    }}
                >
                    {formatEmpty(content)}
                </Typography>
            )}
        </Stack>
    );
}

export default ContentTile;
