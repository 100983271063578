import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import {AddIcon, ExportIcon, ColumnsIcon} from 'common/icons/index';
import Pagination, {
    limitOptions,
} from 'common/components/Pagination/index';
import {Button} from 'common/components';
import {HierarchyLevel} from 'modules/admin/config/hierarchyKeys';
import {Entry} from '../../../index';
import clsx from 'clsx';
import {useMemo} from 'react';

export interface TableActionsDesktopProps {
    onColsManagmentClick?: (e: any) => void;
    onAddRow: (type: HierarchyLevel, row?: Entry) => void;
    onExportClick?: (e: any) => void;
    onPageChange?: (page: number) => void;
    onLimitChange?: (limit: limitOptions) => void;
    hierarchyLevel: HierarchyLevel;
    limit: limitOptions;
    total: number;
    page: number;
    pressedButtonsrc?: 'export' | 'columns-managment' | null;
}
export default function TableActionsDesktop({
    pressedButtonsrc,
    onColsManagmentClick,
    onAddRow,
    onExportClick,
    onLimitChange = (limit: limitOptions) => {},
    onPageChange = (page: number) => {},
    hierarchyLevel,
    limit,
    total,
    page,
}: TableActionsDesktopProps) {
    let isEmptyState = total === 0;
    const {
        breakpoints: {down},
    } = useTheme();
    const isDesktopMd = useMediaQuery(down('desktopMd'));
    const btnMdStyles = useMemo(
        () =>
            isDesktopMd
                ? {
                      p: 0,
                      minWidth: '40px',
                      height: '40px',
                      '& svg': {
                          marginRight: 0,
                      },
                  }
                : {},
        [isDesktopMd]
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: 'calc(100%-16px)',
                margin: (theme) =>
                    `0px ${theme.spacing(1)} ${theme.spacing(
                        1
                    )} ${theme.spacing(1)}`,
            }}
        >
            <Button
                data-testid="table-actions-desktop-add-row"
                size="large"
                variant="contained"
                onClick={() => {
                    onAddRow(hierarchyLevel, undefined);
                }}
                buttonSx={{
                    width: 'fit-content',
                    my: 1,
                    pl: 1.5,
                    pr: 2,
                    py: 1,
                }}
            >
                <Box display="flex" alignItems="center">
                    <AddIcon />
                    <Typography variant="button" sx={{ml: 1}}>
                        {hierarchyLevel === 'buildings'
                            ? 'Add building'
                            : hierarchyLevel === 'floors'
                            ? 'Add floor'
                            : 'Add zone'}
                    </Typography>
                </Box>
            </Button>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginLeft: (theme: any) => theme.spacing(4),
                    }}
                >
                    <Box
                        sx={{
                            marginRight: (theme) => theme.spacing(2),
                        }}
                    >
                        <Button
                            data-testid="table-actions-desktop-cols-mgmnt"
                            variant="outlined"
                            size="large"
                            onClick={onColsManagmentClick}
                            className={clsx({
                                isPressed:
                                    pressedButtonsrc ===
                                    'columns-managment',
                            })}
                            buttonSx={{
                                color: 'black',
                                ...btnMdStyles,
                            }}
                            disabled={isEmptyState}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <ColumnsIcon
                                    sx={{
                                        marginRight: (theme) =>
                                            theme.spacing(1),
                                    }}
                                />
                                {!isDesktopMd && (
                                    <Typography variant="button">
                                        Columns management
                                    </Typography>
                                )}
                            </Box>
                        </Button>
                    </Box>

                    <Box>
                        <Button
                            data-testid="table-actions-desktop-export"
                            variant="outlined"
                            size="large"
                            onClick={onExportClick}
                            className={clsx({
                                isPressed: pressedButtonsrc === 'export',
                            })}
                            buttonSx={{
                                color: 'black',
                                ...btnMdStyles,
                            }}
                            disabled={isEmptyState}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <ExportIcon
                                    sx={{
                                        marginRight: (theme) =>
                                            theme.spacing(1),
                                    }}
                                />
                                {!isDesktopMd && (
                                    <Typography variant="button">
                                        Export
                                    </Typography>
                                )}
                            </Box>
                        </Button>
                    </Box>

                    {isEmptyState ? null : (
                        <Box
                            sx={{
                                marginLeft: (theme: any) =>
                                    theme.spacing(4),
                            }}
                        >
                            <Pagination
                                total={total}
                                page={page}
                                limit={limit}
                                onChangeLimit={onLimitChange}
                                onChangePage={onPageChange}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
