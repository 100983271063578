import {Box} from '@mui/material';
import SelectionButtonGroup from './SelectionButtonGroup';
import {HierarchyLevel} from 'modules/admin/config/hierarchyKeys';
import {SvgIconComponent} from '@mui/icons-material';

export type HierarchyToggleButtonsProps = {
    hierarchy: HierarchyLevel[];
    isLoading: boolean;
    hierarchyOptions: {
        value: HierarchyLevel;
        label: string;
        icon: SvgIconComponent;
    }[];
    onChange: (values: HierarchyLevel[]) => void;
};
export default function HierarchyToggleButtons({
    hierarchy,
    isLoading,
    hierarchyOptions,
    onChange,
}: HierarchyToggleButtonsProps) {
    return (
        <Box width="100%">
            <SelectionButtonGroup
                alwaysActive
                onChange={onChange}
                options={hierarchyOptions}
                value={
                    hierarchy ||
                    hierarchyOptions.map((option) => option.value)
                }
            />
        </Box>
    );
}
