import {HierarchyLevel} from 'modules/admin/config/hierarchyKeys';
import {SvgIconComponent} from '@mui/icons-material';
import {Box, useMediaQuery, useTheme} from '@mui/material';
import FilterFormSingle, {
    CompleteFilterDescriptor,
    FilterDescriptor,
} from './FilterFormSingle';
import FilterFormMultiple from './FilterFormMultiple';
import OrganizationSelector from '../OrganizationSelector';
import FilterTrigger from './FilterTrigger';

import {useOrganization} from '../../hooks';
import {OrgSelectorContext} from 'modules/admin/containers/InfrastructureContainer';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useMemo,
    useState,
} from 'react';
import {FullEntityInfo} from 'modules/admin/hooks/useFilters.types';

export interface InfrastructureFilterProps {
    hierarchy: HierarchyLevel[];
    hierarchyOptions: {
        value: HierarchyLevel;
        label: string;
        icon: SvgIconComponent;
    }[];
    isLoading: boolean;
    isDesktopOpen: boolean;
    isMobileOpen: boolean;
    onClose: () => void;
    setDesktopFilterExpanded: Dispatch<SetStateAction<boolean>>;
    onChangeHierarchy: (values: Array<HierarchyLevel>) => void;
    dataTree: Array<any>;
    onChangeFilters: (filters: CompleteFilterDescriptor[]) => void;
    fullEntityInfo?: FullEntityInfo | null;
    initialFilters?: FilterDescriptor[];
    filtersExist?: boolean;
}

export default function InfrastructureFilter(
    props: Omit<
        InfrastructureFilterProps,
        'onClose' | 'onApply' | 'onReset' | 'isMobileOpen'
    >
) {
    const {hierarchy, isDesktopOpen, setDesktopFilterExpanded} = props;

    const orgSelectorContextContent = useOrganization();
    const {
        breakpoints: {down},
    } = useTheme();
    const isMobile = useMediaQuery(down('mobile'));

    const [isMobileOpen, setMobileFilterExpanded] = useState(false);

    const onClose = useCallback(
        () => setMobileFilterExpanded(false),
        [setMobileFilterExpanded]
    );

    const managerProps = useMemo(
        () => ({onClose, isMobileOpen, isDesktopOpen}),
        [onClose, isMobileOpen, isDesktopOpen]
    );

    const borderRadius = useMemo(() => {
        if (isMobile) {
            return 0;
        } else if (isDesktopOpen) {
            return '8px 8px 0 0';
        }
        return '8px';
    }, [isDesktopOpen, isMobile]);

    return (
        <OrgSelectorContext.Provider value={orgSelectorContextContent}>
            <Box
                sx={{
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        backgroundColor: 'grey.100',
                        borderRadius,
                        mb: isMobile ? 2 : 0,
                        mt: isMobile ? '2px' : 0,
                        mx: isMobile ? '-16px' : 0,
                    }}
                >
                    <Box ml="16px" my="8px" borderRadius="4px">
                        <OrganizationSelector />
                    </Box>
                    <FilterTrigger
                        isDesktopFilterExpanded={isDesktopOpen}
                        setDesktopFilterExpanded={setDesktopFilterExpanded}
                        setMobileFilterExpanded={setMobileFilterExpanded}
                        hasIndicator={props.filtersExist}
                    />
                </Box>
                {hierarchy.length > 1 ? (
                    <FilterFormMultiple {...props} {...managerProps} />
                ) : (
                    <FilterFormSingle {...props} {...managerProps} />
                )}
            </Box>
        </OrgSelectorContext.Provider>
    );
}
