import {Box, Stack, Typography} from '@mui/material';
import Input from 'common/components/Inputs';
import LabeledInput from 'common/components/Inputs/LabelledInput';
import {format, getYear, isValid, parse} from 'date-fns';
import {useCallback, useMemo, useState} from 'react';
import DatePickerBase from '../DatePickerBase';
import '../index.scss';
import DateRangeHeader from './DateRangeHeader';

export type DateRangePickerProps = {
    onChange?: (startDate: Date | null, endDate: Date | null) => void;
    disableFuture?: boolean;
    disablePast?: boolean;
    datePredicate?: (date: Date) => boolean;
    label?: string;
    optional?: boolean;
    autoExpand?: boolean;
    width?: number;
};

export default function DateRangePicker({
    onChange = () => {},
    disableFuture = false,
    disablePast = false,
    datePredicate,
    label,
    optional,
    autoExpand,
    width = 298,
}: DateRangePickerProps) {
    const [selectedStartDate, setSelectedStartDate] =
        useState<Date | null>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(
        null
    );
    const [startText, setStartText] = useState<string | undefined>(
        undefined
    );
    const [endText, setEndText] = useState<string | undefined>(undefined);

    const DATE_FORMAT = 'dd / MM / yyyy';

    const handleStartChange = useCallback(
        (selection: Date | null) => {
            if (
                selection !== null &&
                selectedEndDate !== null &&
                selection > selectedEndDate
            ) {
                handleEndChange(null);
            }
            setSelectedStartDate(selection);
            if (selection !== selectedStartDate) {
                onChange(selectedStartDate, selectedEndDate);
            }
            let formattedSelection = selection
                ? format(selection, DATE_FORMAT)
                : '';
            setStartText(formattedSelection);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onChange, selectedEndDate, selectedStartDate]
    );
    const handleEndChange = useCallback(
        (selection: Date | null) => {
            if (
                selection !== null &&
                selectedStartDate !== null &&
                selection < selectedStartDate
            ) {
                handleStartChange(null);
            }
            setSelectedEndDate(selection);
            if (selection !== selectedEndDate) {
                onChange(selectedStartDate, selection);
            }
            let formattedSelection = selection
                ? format(selection, DATE_FORMAT)
                : '';
            setEndText(formattedSelection);
        },
        [handleStartChange, onChange, selectedEndDate, selectedStartDate]
    );
    const handleRawStartChange = useCallback(
        (c) => {
            if (typeof c !== 'string') {
                return;
            }
            const textChange = c as string;
            setStartText(textChange);
            const trimmedText = textChange.replaceAll(' ', '');
            const parsedDate = parse(
                trimmedText,
                DATE_FORMAT.replaceAll(' ', ''),
                new Date()
            );
            if (
                isValid(parsedDate) &&
                getYear(parsedDate).toString().length === 4 &&
                (parsedDate >= new Date() || !disablePast) &&
                (parsedDate <= new Date() || !disableFuture) &&
                (!datePredicate || datePredicate(parsedDate))
            ) {
                handleStartChange(parsedDate);
            }
        },
        [datePredicate, disableFuture, disablePast, handleStartChange]
    );

    const handleRawEndChange = useCallback(
        (c) => {
            if (typeof c !== 'string') {
                return;
            }
            const textChange = c as string;
            setEndText(textChange);
            const trimmedText = textChange.replaceAll(' ', '');
            const parsedDate = parse(
                trimmedText,
                DATE_FORMAT.replaceAll(' ', ''),
                new Date()
            );
            if (
                isValid(parsedDate) &&
                getYear(parsedDate).toString().length === 4 &&
                (parsedDate >= new Date() || !disablePast) &&
                (parsedDate <= new Date() || !disableFuture) &&
                (!datePredicate || datePredicate(parsedDate))
            ) {
                handleEndChange(parsedDate);
            }
        },
        [datePredicate, disableFuture, disablePast, handleEndChange]
    );

    const handleStartEndChange = useCallback(
        (dates) => {
            if (!Array.isArray(dates)) {
                handleStartChange(dates);
                return;
            }
            handleStartChange(dates[0]);
            handleEndChange(dates[1]);
        },
        [handleEndChange, handleStartChange]
    );
    const inputWidth = useMemo(() => {
        return (width - 30) / 2;
    }, [width]);
    return (
        <Stack direction="row" width={`${width}px`}>
            <DatePickerBase
                autoExpand={autoExpand}
                rangeSelector={selectedEndDate === null}
                selectsStart={selectedEndDate !== null}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                onChange={handleStartEndChange}
                value={startText}
                onChangeRaw={handleRawStartChange}
                minDate={disablePast ? new Date() : null}
                maxDate={disableFuture ? new Date() : null}
                filterDate={datePredicate}
                customInput={
                    label ? (
                        <LabeledInput
                            width={inputWidth}
                            noAutocomplete
                            label={label}
                            optional={optional}
                            autoExpand={autoExpand}
                        />
                    ) : (
                        <Input
                            width={inputWidth}
                            noAutocomplete
                            autoExpand={autoExpand}
                        />
                    )
                }
                monthsShown={2}
                renderCustomHeader={DateRangeHeader}
            />
            <Box
                sx={{
                    alignSelf: 'center',
                    px: '8.5px',
                    pt: label && '24px',
                }}
            >
                <Typography variant="body2">to</Typography>
            </Box>
            <DatePickerBase
                autoExpand={autoExpand}
                selectsEnd
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                onChange={handleEndChange}
                value={endText}
                onChangeRaw={handleRawEndChange}
                minDate={disablePast ? new Date() : null}
                maxDate={disableFuture ? new Date() : null}
                filterDate={datePredicate}
                customInput={
                    <Input
                        autoExpand={autoExpand}
                        width={inputWidth}
                        noAutocomplete
                        sx={{pt: label && '24px'}}
                    />
                }
                monthsShown={2}
                renderCustomHeader={DateRangeHeader}
            />
        </Stack>
    );
}
