import * as React from 'react';
import {DropdownOpenIcon, DropdownCloseIcon} from '../../icons';
import {Typography, Fab, Box, alpha, SxProps} from '@mui/material';

export type FabButtonProps = {
    sx?: SxProps;
    label?: string | React.ReactNode;
    ariaLabel?: string;
    icon: React.ReactNode;
    isOpen: boolean;
    disabled?: boolean;
    isUnselected?: boolean;
    onClick: () => void;
};

export default function FabButton({
    isUnselected,
    sx,
    icon,
    label,
    ariaLabel,
    disabled,
    isOpen,
    onClick,
}: FabButtonProps) {
    let styles = isUnselected
        ? {
              border: (theme: any) =>
                  `1px solid ${theme.palette.primary.main}`,
              bgcolor: 'grey.100',
              color: 'grey.900',
              '&:hover': {
                  bgcolor: (theme: any) =>
                      theme.palette.common.dynamicBrandLight.hover(
                          theme.palette.grey[100]
                      ),
                  boxShadow: (theme: any) =>
                      `0px 6px 10px ${alpha(
                          theme.palette.primary.main,
                          0.32
                      )}`,
              },
              '&:focus': {
                  bgcolor: (theme: any) =>
                      theme.palette.common.dynamicBrandLight.focus(
                          theme.palette.grey[100]
                      ),
                  boxShadow: (theme: any) =>
                      `0px 6px 10px ${alpha(
                          theme.palette.primary.main,
                          0.32
                      )}`,
              },
              '&.pressed,&:active': {
                  bgcolor: (theme: any) =>
                      theme.palette.common.dynamicBrandLight.pressed(
                          theme.palette.grey[100]
                      ),
                  boxShadow: (theme: any) =>
                      `0px 4px 8px ${alpha(
                          theme.palette.primary.main,
                          0.32
                      )}`,
              },
          }
        : {
              bgcolor: 'primary.main',
              color: 'white',
              '&:hover': {
                  bgcolor: (theme: any) =>
                      theme.palette.common.dynamicBrandDark.hover(
                          theme.palette.primary.main
                      ),
                  boxShadow: (theme: any) =>
                      `0px 6px 10px ${alpha(
                          theme.palette.primary.main,
                          0.32
                      )}`,
              },
              '&:focus': {
                  bgcolor: (theme: any) =>
                      theme.palette.common.dynamicBrandDark.focus(
                          theme.palette.primary.main
                      ),
                  boxShadow: (theme: any) =>
                      `0px 6px 10px ${alpha(
                          theme.palette.primary.main,
                          0.32
                      )}`,
              },
              '&.pressed, &:active': {
                  bgcolor: (theme: any) =>
                      theme.palette.common.dynamicBrandDark.pressed(
                          theme.palette.primary.main
                      ),
                  boxShadow: (theme: any) =>
                      `0px 4px 8px ${alpha(
                          theme.palette.primary.main,
                          0.32
                      )}`,
              },
          };

    return (
        <Fab
            aria-label={ariaLabel}
            variant="extended"
            className={isOpen ? 'pressed' : undefined}
            disabled={disabled}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            sx={{
                height: '44px',
                width: '172px',
                pr: 2,
                pl: 3,
                borderRadius: '24px',
                boxShadow: (theme) =>
                    `0px 6px 10px ${alpha(
                        theme.palette.primary.main,
                        0.32
                    )}`,
                display: 'flex',
                justifyContent: 'flex-start',
                gap: 1,
                ...styles,
                ...sx,
            }}
            onClick={onClick}
        >
            {!isUnselected && (
                <Box sx={{color: 'primary.light'}}>{icon}</Box>
            )}
            <Typography
                variant="button"
                minWidth={isUnselected ? 100 : 68}
                textTransform="initial"
                textAlign="left"
            >
                {label}
            </Typography>
            {isOpen ? (
                <DropdownOpenIcon
                    sx={{
                        color: isUnselected ? 'grey.600' : 'primary.light',
                        ml: 'auto',
                    }}
                />
            ) : (
                <DropdownCloseIcon
                    sx={{
                        color: isUnselected
                            ? 'grey.600'
                            : 'primary.light',
                        ml: 'auto',
                    }}
                />
            )}
        </Fab>
    );
}
