import {Toolbar, AppBar as MuiAppBar, useTheme} from '@mui/material';
import {grey} from 'common/colors';
import AppBarLogoMobile from './AppBarLogoMobile';

export interface AppBarMobileProps {
    menuIsOpen?: boolean;
    onClickMenuButton: () => void;
    adminSelected: boolean;
    mobileMenu?: React.ReactNode;
}

export default function AppBarMobile(props: AppBarMobileProps) {
    const {menuIsOpen, mobileMenu} = props;
    const theme = useTheme();
    return (
        <MuiAppBar
            elevation={1}
            position="sticky"
            sx={{
                height: '56px',
                [theme.breakpoints.up(780)]: {
                    minHeight: '56px',
                },
                borderBottom: 0,
                boxShadow: 0,
                borderColor: grey[200],
                bgcolor: 'white',
                zIndex: (theme) => theme.zIndex.appBar,
            }}
        >
            <Toolbar
                disableGutters={true}
                sx={{
                    height: '100%',
                    [theme.breakpoints.up(780)]: {
                        minHeight: '56px',
                    },
                }}
            >
                <AppBarLogoMobile {...props} />
                {!menuIsOpen && mobileMenu}
            </Toolbar>
        </MuiAppBar>
    );
}
