import {
    Box,
    Typography,
    Tooltip,
    useMediaQuery,
    useTheme,
    Stack,
} from '@mui/material';
import {useState} from 'react';
import {
    FormProps,
    BuildingFormValues,
    Hours,
    Timezone,
} from './forms.types';
import Dialog from './Dialog';
import LabelledInput from 'common/components/Inputs/LabelledInput';
import NumericalInput from 'common/components/Inputs/NumericalInput';
import DoubleDropdownInput from 'modules/admin/components/Forms/DoubleDropdownInput';
import Button from 'common/components/Button';
import {DropdownSingle} from 'common/components/Dropdown';
import Toggle from 'common/components/Toggle';
import {InfoSmallIcon, LocationIcon, WeatherIcon} from 'common/icons';
import FormContainer from './FormContainer';

const emptyState: BuildingFormValues = {
    name: '',
    timezone: {label: '', value: ''},
    from: {label: '09:00', value: '09:00'},
    to: {label: '17:00', value: '17:00'},
    area: 1,
    maxPeople: 1,
    weatherDataCollection: false,
    type: 'buildings',
};

export default function BuildingForm({
    isOpen,
    isEdit,
    values,
    handleSave,
    handleClose,
    subtitleHierarchy,
}: FormProps<BuildingFormValues>) {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [hasBeenEditted, setHasBeenEditted] = useState(false);
    const [formData, setFormData] = useState<BuildingFormValues>(
        values ?? emptyState
    );

    const {
        breakpoints: {up},
    } = useTheme();

    const isMobile = !useMediaQuery(up('desktopSm'));

    const handleChange = (value: any, key: keyof BuildingFormValues) => {
        if (!hasBeenEditted) {
            setHasBeenEditted(true);
        }
        setFormData({
            ...formData,
            [key]: value,
        });
    };

    const validateValues = (values: BuildingFormValues) =>
        values.name !== '' &&
        values.timezone.label !== '' &&
        (values.area === undefined || values.area > 0) &&
        (values.maxPeople === undefined || values.maxPeople > 0);

    const onSubmit = () => {
        if (validateValues(formData)) {
            handleSave(formData);
        } else {
            //TODO: create throw error
        }
    };

    return (
        <FormContainer
            isMobile={isMobile}
            hierarchyLevel="building"
            isOpen={isOpen}
            isEdit={isEdit}
            onSubmit={onSubmit}
            handleClose={() =>
                hasBeenEditted ? setDialogOpen(true) : handleClose()
            }
            subtitleHierarchy={subtitleHierarchy}
            values={values}
        >
            <Box
                display="flex"
                flexDirection="column"
                gap={3}
                height="100%"
            >
                <LabelledInput
                    label="Name"
                    placeholder="Enter name"
                    variant="Counter"
                    maxLength={128}
                    value={formData?.name}
                    onChange={(v) => handleChange(v, 'name')}
                    width="100%"
                />
                <LabelledInput
                    optional
                    label="Description"
                    placeholder="Enter description"
                    variant="Counter"
                    maxLength={256}
                    value={formData?.description}
                    onChange={(v) => handleChange(v, 'description')}
                    width="100%"
                    overrideTextFieldProps={{multiline: true, minRows: 3}}
                    inputSx={{height: 'auto'}}
                />
                <Box display="flex">
                    <Box flexGrow={1}>
                        <LabelledInput
                            optional
                            label="Address"
                            placeholder="Enter address"
                            value={formData?.address}
                            onChange={(v) => handleChange(v, 'address')}
                            width="100%"
                        />
                    </Box>
                    <Button
                        variant="outlined"
                        size="large"
                        onClick={() => {}}
                        sx={{
                            ml: 1,
                            alignSelf: 'flex-end',
                        }}
                        buttonSx={{
                            p: 1,
                            minWidth: 0,
                            minHeight: 0,
                            width: 40,
                            height: 40,
                            boxSizing: 'border-box',
                        }}
                    >
                        <LocationIcon />
                    </Button>
                </Box>
                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    gap={isMobile ? 2 : 6}
                >
                    <NumericalInput
                        optional
                        autoExpand={true}
                        label="Area(sq.m)"
                        placeholder="Enter area"
                        value={formData?.area?.toString()}
                        min={1}
                        onChange={(v) => handleChange(v, 'area')}
                        overrideTextFieldProps={{
                            helperText: 'the minimum value is 1',
                        }}
                        onClickUp={() =>
                            handleChange(
                                formData.area !== undefined
                                    ? formData.area + 1
                                    : 1,
                                'area'
                            )
                        }
                        onClickDown={() =>
                            handleChange(
                                formData.area !== undefined
                                    ? formData.area - 1
                                    : 1,
                                'area'
                            )
                        }
                    />
                    <NumericalInput
                        optional
                        autoExpand={true}
                        label="Max people count"
                        placeholder="Enter max people"
                        value={formData?.maxPeople?.toString()}
                        min={1}
                        width="100%"
                        onChange={(v) => handleChange(v, 'maxPeople')}
                        overrideTextFieldProps={{
                            helperText: 'the minimum value is 1',
                        }}
                        onClickUp={() => {
                            handleChange(
                                formData.maxPeople !== undefined
                                    ? formData.maxPeople + 1
                                    : 1,
                                'maxPeople'
                            );
                        }}
                        onClickDown={() =>
                            handleChange(
                                formData.maxPeople !== undefined
                                    ? formData.maxPeople - 1
                                    : 1,
                                'maxPeople'
                            )
                        }
                    />
                </Stack>
                <Box mb={2.25} p={0}>
                    <Typography variant="overline">Timezone</Typography>
                    <DropdownSingle<Timezone>
                        onChange={(v) => handleChange(v, 'timezone')}
                        placeholder="Select or type timezone"
                        //TODO: to add timezones
                        options={[]}
                        formatLabel={(option) => option.label}
                        width="100%"
                        variant="outlined"
                        value={formData?.timezone as Timezone}
                        autoExpand={true}
                    />
                </Box>
                <DoubleDropdownInput
                    autoExpand={true}
                    label="Working hours"
                    dropdownWidth="100%"
                    optional
                    onChange={handleChange}
                    fromValue={formData?.from as Hours}
                    toValue={formData?.to as Hours}
                />
                <Box
                    display="flex"
                    alignItems="center"
                    bgcolor="grey.200"
                    borderRadius={1}
                    width="100%"
                    mt={1}
                    py={2}
                    pl={4}
                    pr={2.5}
                >
                    <WeatherIcon sx={{mr: 1}} />
                    <Typography variant="button" sx={{mr: 0.25}}>
                        Weather data collection
                    </Typography>
                    <Typography variant="overline" sx={{mr: 0.5}}>
                        (optional)
                    </Typography>
                    <Tooltip title="By enabling this option, weather service will get activated and our system will try to define the City ID based on the building's location using https://openweathermap.org">
                        <InfoSmallIcon
                            sx={{color: 'common.messages.info'}}
                        />
                    </Tooltip>
                    <Toggle
                        checked={formData?.weatherDataCollection}
                        onChange={(_, v) => {
                            handleChange(v, 'weatherDataCollection');
                        }}
                        containerSx={{ml: 'auto', mr: 0}}
                    />
                </Box>
            </Box>
            <Dialog
                isOpen={isDialogOpen}
                name={values?.name ?? 'new building'}
                handleClose={() => setDialogOpen(false)}
                handleConfirm={handleClose}
                subtitleHierarchy={subtitleHierarchy}
                isMobile={isMobile}
            />
        </FormContainer>
    );
}
