import {Stack, Typography, Box, Skeleton, SxProps} from '@mui/material';
import {IconButton} from 'common/components';
import {DataIcon, MoreIcon, WeatherIcon} from 'common/icons';
import {ComponentProps} from 'react';

export interface TreeMobileItemHeaderProps {
    onClickMore: ComponentProps<'button'>['onClick'];
    title: string;
    isLoading?: boolean;
    sx?: SxProps;
}
function TreeMobileItemHeader({
    onClickMore,
    title,
    isLoading,
    sx,
}: TreeMobileItemHeaderProps) {
    return (
        <Stack
            direction="row"
            columnGap="3px"
            height="40px"
            alignItems="center"
            sx={{overflowX: 'clip', ...sx}}
            width="100%"
        >
            {isLoading ? (
                <Skeleton
                    variant="rectangular"
                    width="110px"
                    height="15px"
                />
            ) : (
                <Typography
                    width="100%"
                    variant="overline"
                    component="div"
                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                    }}
                >
                    {title}
                </Typography>
            )}
            <Stack direction="row" columnGap="4px">
                <Box
                    sx={{
                        my: '6px',
                        mx: '6px',
                        backgroundColor: (theme) =>
                            theme.palette.common.messages.verificationBg,
                        width: '28px',
                        height: '28px',
                        borderRadius: '28px',
                        display: 'flex',
                    }}
                >
                    <WeatherIcon
                        sx={{
                            m: 'auto',
                            color: (theme) =>
                                theme.palette.common.messages.verification,
                        }}
                    />
                </Box>
                <IconButton variant="brandDark">
                    <DataIcon />
                </IconButton>
                <IconButton onClick={onClickMore}>
                    <MoreIcon />
                </IconButton>
            </Stack>
        </Stack>
    );
}

export default TreeMobileItemHeader;
