import {Box} from '@mui/material';

export interface VerticalLineProps {
    height: number;
}

export default function VerticalLine({height}: VerticalLineProps) {
    return (
        <Box
            className="vertical-line"
            sx={{
                height: `${height}px`,
                borderRight: '1px solid ',
                position: 'absolute',
                bottom: '55%',
                zIndex: 5,
                color: 'common.brandColor.100',
            }}
        ></Box>
    );
}
