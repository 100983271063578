const grey = {
    100: '#FFFFFF',
    200: '#F7F7F7',
    300: '#EEEEEE',
    400: '#C1C1C1',
    500: '#888888',
    600: '#555555',
    700: '#434343',
    800: '#313131',
    900: '#090909',
};

export const greyStates = {
    light: '#dddddd',
    dark: '#070707',
};
export default grey;
