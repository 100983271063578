import {Box, TableCell, TableRow, SxProps} from '@mui/material';
export interface TableRowContainerProps {
    children?: JSX.Element | JSX.Element[];
    sx?: SxProps;
    colSpan?: number;
}

export default function TableRowContainer({
    children,
    sx,
    colSpan,
}: TableRowContainerProps) {
    return (
        <TableRow>
            <TableCell colSpan={colSpan}>
                <Box sx={sx}>{children}</Box>
            </TableCell>
        </TableRow>
    );
}
