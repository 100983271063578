import {CssBaseline} from '@mui/material';
import theme from './../../../theme/index';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {ThemeProvider as MUIThemeProvider} from '@mui/material/styles';

export default function AppWrapper({...props}) {
    return (
        <MUIThemeProvider theme={theme}>
            <CssBaseline>
                <DndProvider backend={HTML5Backend}>
                    {props.children}
                </DndProvider>
            </CssBaseline>
        </MUIThemeProvider>
    );
}
