import {
    Box,
    Typography,
    alpha,
    IconButton,
    Divider,
    MenuItem,
} from '@mui/material';
import {MenuCloseIcon} from 'common/icons/index';
import _ from 'lodash';

export interface ExportDesktopPopupProps {
    onClick: (value: 'pdf' | 'csv' | 'xls') => void;
    onClose: () => void;
    selections: {
        label: string;
        icon: any;
        value: 'pdf' | 'csv' | 'xls';
    }[];
}
export default function ExportDesktopPopup({
    onClose,
    onClick,
    selections,
}: ExportDesktopPopupProps) {
    const renderSelections = () => {
        return _.map(selections, (item) => {
            return (
                <MenuItem
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        height: '40px',
                        padding: '4px 0px',
                        marginTop: '4px',
                        marginBottom: '4px',
                        backgroundColor: (theme) =>
                            theme.palette.grey[100],
                        '&:hover': {
                            bgcolor: 'common.light.hover',
                        },
                        '&:active': {
                            bgcolor: 'common.light.pressed',
                        },
                    }}
                    onClick={() => {
                        onClick(item.value);
                        onClose();
                    }}
                >
                    <Box
                        sx={{
                            margin: (theme) =>
                                `0px ${theme.spacing(
                                    1
                                )} 0px ${theme.spacing(2)}`,
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {item.icon}
                    </Box>
                    <Typography variant="button">{item.label}</Typography>
                </MenuItem>
            );
        });
    };

    const Header = () => {
        return (
            <>
                <Box
                    className="filter-area-mobile-header"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            width: 'calc(100% - 32px)',
                            margin: '0px auto',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            variant="subtitle"
                            sx={{color: 'grey.900'}}
                        >
                            Export data
                        </Typography>
                        <IconButton
                            onClick={onClose}
                            size="small"
                            sx={{
                                my: 1,
                                mr: 1,
                                ml: 'auto',
                            }}
                        >
                            <MenuCloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Divider
                    sx={{
                        width: 'calc(100% - 32px)',
                        margin: (theme) =>
                            `0 auto ${theme.spacing(2)} auto`,
                    }}
                />
            </>
        );
    };

    const Body = () => {
        return (
            <Box
                id="popup-card-test"
                sx={{
                    height: '40px',
                    marginBottom: (theme) => theme.spacing(1),
                }}
            >
                {renderSelections()}
            </Box>
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '328px',
                height: `200px`,
                backgroundColor: (theme) => theme.palette.grey[100],
                boxShadow: (theme) =>
                    `0px 4px 10px ${alpha(theme.palette.grey[800], 0.24)}`,
                zIndex: 15000000,
            }}
        >
            <Header />
            <Body />
        </Box>
    );
}
