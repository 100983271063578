const infrastructureTableColumns = {
    buildings: [
        {
            id: 'building_name',
            Header: 'Building name',
            accessor: 'name',
            canSort: true,
            sortDescFirst: true,
            minWidth: 200,
            isAlwaysVisible: true,
        },
        {
            id: 'tags',
            Header: 'Tags',
            accessor: 'tags',
            canSort: true,
            sortDescFirst: true,
            minWidth: 200,
        },
        {
            id: 'description',
            Header: 'Description',
            accessor: 'description',
            canSort: true,
            sortDescFirst: true,
            maxWidth: 200,
        },
        {
            id: 'address',
            Header: 'Address',
            accessor: 'address',
            canSort: true,
            sortDescFirst: true,
            maxWidth: 200,
        },
        {
            id: 'area',
            className: 'col-buildings-area',
            Header: 'Area (sq.m.)',
            accessor: 'area',
            canSort: true,
            sortDescFirst: true,
            minWidth: 107,
            maxWidth: 107,
        },
        {
            id: 'timezone',
            className: 'col-buildings-timezone',
            Header: 'Timezone',
            accessor: 'timezone',
            canSort: true,
            sortDescFirst: true,
            minWidth: 107,
            maxWidth: 107,
        },
        {
            id: 'work_hours',
            Header: 'Work hours',
            accessor: 'workingHours',
            canSort: true,
            sortDescFirst: true,
            maxWidth: 200,
        },
        {
            id: 'created_at',
            Header: 'Creation date',
            accessor: 'created',
            canSort: true,
            sortDescFirst: true,
        },
    ],
    floors: [
        {
            id: 'floor_name',
            Header: 'Floor name',
            accessor: 'name',
            canSort: true,
            sortDescFirst: true,
            minWidth: 200,
            isAlwaysVisible: true,
        },
        {
            id: 'tags',
            Header: 'Tags',
            accessor: 'tags',
            canSort: true,
            sortDescFirst: true,
            minWidth: 200,
        },
        {
            id: 'description',
            Header: 'Description',
            accessor: 'description',
            canSort: true,
            sortDescFirst: true,
            maxWidth: 200,
        },
        {
            id: 'area',
            Header: 'Area (sq.m.)',
            accessor: 'area',
            canSort: true,
            sortDescFirst: true,
        },
        {
            id: 'max_allowed_people',
            className: 'col-floors-max-allowed-people',
            Header: 'Max allowed people',
            accessor: 'max_allowed_people',
            canSort: true,
            sortDescFirst: true,
            maxWidth: 192,
            minWidth: 192,
        },
        {
            id: 'floorplans',
            Header: 'Floorplans',
            accessor: 'floorplans',
            canSort: true,
            sortDescFirst: true,
            maxWidth: 200,
        },
        {
            id: 'created_at',
            Header: 'Creation date',
            accessor: 'created',
            canSort: true,
            sortDescFirst: true,
        },
    ],
    zones: [
        {
            id: 'zone_name',
            Header: 'Zone name',
            accessor: 'name',
            canSort: true,
            sortDescFirst: true,
            minWidth: 200,
            isAlwaysVisible: true,
        },
        {
            id: 'tags',
            Header: 'Tags',
            accessor: 'tags',
            canSort: true,
            sortDescFirst: true,
            minWidth: 200,
        },

        {
            id: 'description',
            Header: 'Description',
            accessor: 'description',
            canSort: true,
            sortDescFirst: true,
            maxWidth: 200,
        },
        {
            id: 'area',
            Header: 'Area (sq.m.)',
            accessor: 'area',
            canSort: true,
            sortDescFirst: true,
        },
        {
            id: 'floorplans',
            Header: 'Floorplans',
            accessor: 'floorplans',
            canSort: true,
            sortDescFirst: true,
            maxWidth: 200,
        },
        {
            id: 'created_at',
            Header: 'Creation date',
            accessor: 'created',
            canSort: true,
            sortDescFirst: true,
        },
    ],
} as const;

export default infrastructureTableColumns;
