import _ from 'lodash';
import {CompleteFilterDescriptor} from '../components/InfrastructureFilter/FilterFormSingle';

export type QueryArgument = {
    entityName: string;
    attributePath: string[];
    predicate: string;
    values: string[];
    combinator: string;
};

export const constructQuery = (
    filters: CompleteFilterDescriptor[] = [],
    combinator: string
): QueryArgument[] => {
    const query: QueryArgument[] = [];
    filters.forEach((filter) => {
        if (
            filter.field &&
            filter.operator &&
            filter.value &&
            filter.operator.predicate
        ) {
            const hasMultiplePredicates =
                filter.operator.predicate.length > 1;
            if (hasMultiplePredicates) {
                query.push(
                    ...constructArgumentsForMultiplePredicates(
                        filter,
                        combinator
                    )
                );
            } else {
                let val = Array.isArray(filter.value)
                    ? filter.value.map((f) => f.toString())
                    : [filter.value.toString()];
                query.push({
                    entityName: filter.hierarchy,
                    predicate: filter.operator.predicate[0],
                    combinator,
                    values: val,
                    attributePath: [filter.field.attributeName],
                });
            }
        }
    });
    if (!_.isEmpty(query)) {
        query[query.length - 1].combinator = 'TERMINAL';
    }
    return query;
};

export const constructArgumentsForMultiplePredicates = (
    filter: CompleteFilterDescriptor,
    combinator: string
): QueryArgument[] => {
    const queries: QueryArgument[] = [];
    filter!.operator!.predicate!.forEach((predicate, index) => {
        const val = (filter.value as Array<any>)[index]!.toString();
        queries.push({
            entityName: filter.hierarchy,
            predicate,
            combinator,
            values: [val],
            attributePath: [filter.field.attributeName],
        });
    });
    return queries;
};
