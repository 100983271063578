import {
    Toolbar,
    AppBar as MuiAppBar,
    Box,
    Grid,
    Typography,
} from '@mui/material';
import AppBarLogo from './AppBarLogo';
import AppBarTabs from './AppBarTabs';
import AppBarActions, {AppBarActionsProps} from './AppBarActions';
import {BASE_URL} from '../../../../../config';
import {grey} from 'common/colors';
import {SvgIconComponent} from '@mui/icons-material';

export interface AppBarDesktopProps extends AppBarActionsProps {
    activeProductLabel?: string;
    activeProductIcon?: SvgIconComponent;
    menuIsOpen?: boolean;
    title?: string;
    togglerWidth: string | number;
    children?: React.ReactNode;
    adminSelected: boolean;
    onClickMenuButton: () => void;
    onClickLogo: () => void;
}
export default function AppBarDesktop({
    menuIsOpen,
    activeProductLabel,
    activeProductIcon,
    togglerWidth,
    adminSelected,
    title = 'Choose',
    onClickLogo,
    onClickMenuButton,
    onClickReporting = () => {},
    onClickSettings = () => {},
    onClickAccount = () => {},
    onClickIncidents = () => {},
    onClickRules = () => {},
}: AppBarDesktopProps) {
    const logoSrc = `${BASE_URL}/logo.svg`;
    return (
        <MuiAppBar
            elevation={1}
            position="sticky"
            sx={{
                height: '102px',
                borderBottom: 0,
                boxShadow: 0,
                borderColor: grey[200],
                bgcolor: 'white',
                zIndex: (theme) => theme.zIndex.appBar,
            }}
        >
            <Toolbar
                disableGutters={true}
                sx={{minHeight: 'auto', height: '100%'}}
            >
                <AppBarLogo
                    togglerWidth={togglerWidth}
                    logoSrc={logoSrc}
                    onClickLogo={onClickLogo}
                    onClickMenuButton={onClickMenuButton}
                    activeProductLabel={activeProductLabel}
                    activeProductIcon={activeProductIcon}
                    menuIsOpen={menuIsOpen}
                    adminSelected={adminSelected}
                />
                <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    flexDirection="column"
                >
                    <Grid
                        container
                        className="generic-nav"
                        typography="body2"
                        height={48}
                        borderBottom={2}
                        borderColor="grey.200"
                        flexDirection="row"
                        alignItems="center"
                        bgcolor="grey.100"
                        color="white"
                        px={3}
                    >
                        <Grid item xs={8} color="grey.900">
                            <Typography variant="h1">{title}</Typography>
                        </Grid>
                        <AppBarActions
                            onClickReporting={onClickReporting}
                            onClickSettings={onClickSettings}
                            onClickAccount={onClickAccount}
                            adminSelected={adminSelected}
                            onClickIncidents={onClickIncidents}
                            onClickRules={onClickRules}
                            xs={4}
                        />
                    </Grid>
                    <AppBarTabs />
                </Box>
            </Toolbar>
        </MuiAppBar>
    );
}
