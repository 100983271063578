import {Box, useMediaQuery, useTheme} from '@mui/material';
import React, {useMemo} from 'react';

export interface LayoutProps {
    children: React.ReactNode;
    expanderButton?: boolean;
    expanderButtonWidth?: number;
    sideNavWidth?: number;
    subMenuOpen?: boolean;
    subMenuExtended?: boolean;
    togglerWidth?: number;
    togglerWidthExtended?: number;
}

const Layout = ({
    children,
    subMenuExtended,
    expanderButtonWidth = 24,
    sideNavWidth = 78,
    subMenuOpen,
    togglerWidth = 208,
    togglerWidthExtended = 400,
}: LayoutProps) => {
    const {
        breakpoints: {up},
    } = useTheme();
    const isGreaterThanSmallDesktop = useMediaQuery(up('desktopSm'));
    const isGreaterThanMdDesktop = useMediaQuery(up('desktopMd'));
    const calculateLeftMargin = useMemo(() => {
        const gap = isGreaterThanMdDesktop ? 24 : 16;
        if (subMenuExtended) {
            return togglerWidthExtended + sideNavWidth + gap;
        } else if (subMenuOpen) {
            return togglerWidth + sideNavWidth + gap;
        } else {
            return sideNavWidth + expanderButtonWidth + gap;
        }
    }, [
        expanderButtonWidth,
        isGreaterThanMdDesktop,
        sideNavWidth,
        subMenuExtended,
        subMenuOpen,
        togglerWidth,
        togglerWidthExtended,
    ]);
    const left = isGreaterThanSmallDesktop ? calculateLeftMargin : 0;
    const theme = useTheme();
    const padding = isGreaterThanMdDesktop
        ? theme.spacing(3)
        : theme.spacing(2);
    return (
        <Box
            bgcolor="grey.200"
            bottom={0}
            className="yodiwo-layout"
            height="100%"
            left={left}
            paddingBottom={padding}
            paddingLeft={padding}
            paddingRight={padding}
            paddingTop={isGreaterThanSmallDesktop ? '22px' : 0}
            position="absolute"
            right={0}
            top={0}
        >
            {children}
        </Box>
    );
};

export default Layout;
