import {Typography, Box} from '@mui/material';
import {DropdownNoResultIcon} from 'common/icons';

export default function DropdownNoResults() {
    return (
        <Box
            data-testid="mui-no-results-test"
            sx={{
                height: '140px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '24px',
            }}
        >
            <DropdownNoResultIcon
                sx={{
                    height: '80px',
                    width: '80px',
                    color: 'common.brandColor.300',
                }}
                viewBox="0 0 80 80"
                fill="none"
            />
            <Typography
                paddingTop={(theme) => theme.spacing(2)}
                variant="body1"
            >
                No results found
            </Typography>
        </Box>
    );
}
