import {
    useFetchCombinatorsQuery,
    useFetchFullEntityInfoQuery,
    useFetchValuesInfoQuery,
} from '../reducers/infrastructureEntityFilter.slice';
import {getErrorMessage} from '../helpers/fetchBaseQueryErrors';
import {useCallback, useEffect, useMemo, useState} from 'react';
import _ from 'lodash';
import {constructQuery} from '../helpers/filterQuery';
import {
    filterFieldTypeFromAttribute,
    fullEntityInfoSchema,
} from './useFilters.types';
import {CompleteFilterDescriptor} from '../components/InfrastructureFilter/FilterFormSingle';
import {hierarchyNameTransform} from '../lib';

export default function useFilters() {
    const [filters, setFilters] = useState<CompleteFilterDescriptor[]>([]);
    const debounceSetFilters = _.debounce(setFilters, 500);

    const fullEntities = useFetchFullEntityInfoQuery([]);
    const valuesInfo = useFetchValuesInfoQuery([]);
    const combinators = useFetchCombinatorsQuery([]);
    const isLoading = useMemo(
        () =>
            valuesInfo.isLoading ||
            fullEntities.isLoading ||
            combinators.isLoading,
        [
            combinators.isLoading,
            fullEntities.isLoading,
            valuesInfo.isLoading,
        ]
    );

    // Transform entities
    const fullEntityInfo = useMemo(() => {
        if (!fullEntities.isSuccess || isLoading) {
            return null;
        }
        const transformedObject = _(fullEntities.data)
            .map((entity) => {
                const entityHasExtractedValues = valuesInfo.data?.find(
                    (valueInfoEntity) =>
                        valueInfoEntity.entityName === entity.entityName
                );
                return {
                    entityName: hierarchyNameTransform(entity.entityName, [
                        'PluralForm',
                    ]),
                    supportedAttributes:
                        entity.supportedEntityFilterEntityInfo.supportedAttributes
                            .filter((attr) => !attr.internal)
                            .map((attr) => {
                                const attributeIsDefined =
                                    entityHasExtractedValues?.attributeValueExtractorInfos.find(
                                        (valueInfoAttr) =>
                                            valueInfoAttr.attributeName ===
                                            attr.attributeName
                                    );
                                const hasExtractedValues =
                                    attributeIsDefined?.hasDefinedValuesExtractor;
                                return {
                                    ...attr,
                                    attributeTypeName:
                                        filterFieldTypeFromAttribute(
                                            attr,
                                            hasExtractedValues
                                        ),
                                };
                            }),
                };
            })
            .value();

        return fullEntityInfoSchema.safeParse(transformedObject);
    }, [
        fullEntities.data,
        fullEntities.isSuccess,
        isLoading,
        valuesInfo.data,
    ]);

    const combinatorsError = useMemo(
        () => getErrorMessage(combinators.error),
        [combinators.error]
    );
    const fullEntitiesError = useMemo(
        () => getErrorMessage(fullEntities.error),
        [fullEntities.error]
    );

    //Eventually we should adopt the hierarchy names used in the api everywhere to avoid these transformations (e.g. 'buildings'->'Building')
    const entityNameFromHierarchyLevel = useCallback(
        (levelName: string) => {
            return hierarchyNameTransform(levelName, ['Capitalize']);
        },
        []
    );

    // Check combinators are supported
    useEffect(() => {
        if (
            combinators.isSuccess &&
            (!combinators.data.includes('AND') ||
                !combinators.data.includes('TERMINAL'))
        ) {
            console.error('Not all combinators are supported');
        }
    }, [combinators.data, combinators.isSuccess]);

    const query = useMemo(() => {
        const transformedFilters = filters.map((f) => ({
            ...f,
            hierarchy: entityNameFromHierarchyLevel(f.hierarchy),
        }));
        return constructQuery(transformedFilters, 'AND');
    }, [entityNameFromHierarchyLevel, filters]);

    return {
        isLoading,
        fullEntitiesError,
        combinatorsError,
        fullEntityInfo: fullEntityInfo?.success
            ? fullEntityInfo.data
            : null,
        setFilters: debounceSetFilters,
        query,
    };
}
