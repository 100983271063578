import {Drawer} from '@mui/material';
import {SortingRule, Column} from 'react-table';
import {UnknownObject, AnyObject} from '../../../index';
import TableMobileColumnsManagmentPopover from './ColumnsManagment';
import ExportMobilePopup from './ExportData';
import {XLSIcon, CSVIcon, PDFIcon} from 'common/icons/index';
export type TableActionsMobilePopupsProps<
    T extends AnyObject = UnknownObject
> = {
    allColumns: Array<Column<T>>;
    isOpen: boolean;
    onClose: () => void;
    onApply: (hiddenColsIds: string[]) => void;
    onSortingChange: (sortingProps: SortingRule<T>) => void;
    onDownload: (value: 'csv' | 'pdf' | 'xls') => void;
    sortBy?: Array<{id: string; desc: Boolean}>;
    drawerVariant: 'export' | 'columns-managment' | null;
    hiddenColumnsIds: string[];
};
export default function TableActionsMobilePopups({
    isOpen,
    onClose,
    onApply,
    onDownload,
    onSortingChange,
    drawerVariant,
    hiddenColumnsIds,
    sortBy,
    allColumns,
}: TableActionsMobilePopupsProps) {
    const selections: {
        label: string;
        icon: any;
        value: 'pdf' | 'csv' | 'xls';
    }[] = [
        {
            label: 'CSV',
            icon: <CSVIcon />,
            value: 'csv',
        },
        {
            label: 'PDF',
            icon: <PDFIcon />,
            value: 'pdf',
        },
        {
            label: 'XLS',
            icon: <XLSIcon />,
            value: 'xls',
        },
    ];

    return (
        <Drawer
            anchor="top"
            open={isOpen}
            onClose={onClose}
            sx={{
                zIndex: (theme) => theme.zIndex.appBar,
                width: '100%',
                display: 'flex',
                flexFlow: 'column',
                '& .MuiDrawer-paper': {
                    width: '100%',
                    height: '100%',
                },
            }}
        >
            {drawerVariant === 'columns-managment' ? (
                <TableMobileColumnsManagmentPopover
                    onClose={onClose}
                    hiddenColumnsIds={hiddenColumnsIds}
                    allColumns={allColumns}
                    onApply={onApply}
                    onChangeSorting={onSortingChange}
                    sortBy={sortBy}
                />
            ) : (
                <ExportMobilePopup
                    onClose={onClose}
                    onClick={onDownload}
                    selections={selections}
                />
            )}
        </Drawer>
    );
}
