import React, {useCallback, useEffect, useState} from 'react';
import {useTheme, useMediaQuery} from '@mui/material';

import {Column, Row, SortingRule} from 'react-table';
import {TableRowProps} from '@mui/material';

import TableRowActions from './TableRowActions';
import TableRowExpander from './TableRowExpander';
import TableRowContainer from './TableRowContainer';
import {filterCustomColumns} from './dataTableHelpers';

import TableDesktop from './TableDesktop';
import TableMobile from './TableMobile';
import {limitOptions} from 'common/components/Pagination/index';
import {HierarchyLevel} from 'modules/admin/config/hierarchyKeys';

export const CUSTOM_COLUMNS = ['_actions_', '_menu_'];

export type UnknownObject = {[key: string]: unknown};

// eslint-disable-next-line @typescript-eslint/ban-types
export type AnyObject = object;

export interface RowSubComponentProps<
    T extends AnyObject = UnknownObject
> {
    row: Row<T>;
    rowProps: TableRowProps;
    visibleColumns: any;
}

export type Entry = Record<string, unknown>;
export type TableProps<T extends AnyObject = UnknownObject> = {
    data: T[];
    dataTotal?: number;
    stickyHeader?: boolean;
    columns: Array<Column<T>>;
    initialState?: Partial<{sortBy: Array<SortingRule<T>>}>;
    manualSortBy?: boolean;
    borderCollapse?: string;
    disableSortRemove?: boolean;
    label?: string;
    onChangeSorting: (sortingProps: SortingRule<T>) => void;
    caption?: React.ReactNode | string;
    renderRowSubComponent?: (props: RowSubComponentProps<T>) => void;
    isLoading?: boolean;
    sortBy?: Array<{id: string; desc: Boolean}>;
    onPageChange?: (page: number) => void;
    onLimitChange?: (limit: limitOptions) => void;
    controlled?: boolean;
    hierarchyLevel?: HierarchyLevel;
    onAddRow: (type: HierarchyLevel, row?: Entry) => void;
    onEditRow: (type: HierarchyLevel, row: Entry) => void;
    errorMessage?: string | null;
    isEmptyState?: boolean;
};

export default function Table(props: TableProps) {
    const {
        breakpoints: {up},
    } = useTheme();
    const isMobile = !useMediaQuery(up('desktopSm'));
    const [limit, setLimit] = useState<limitOptions>(13);
    const [page, setPage] = useState<number>(1);
    useEffect(() => {
        if (props.dataTotal) {
            setPage(1);
        }
    }, [props.dataTotal]); //reset paging on total data change

    const pageChangeHandler = useCallback(
        (page: number) => {
            setPage(page);
            if (props.onPageChange) {
                props.onPageChange(page);
            }
        },
        [props]
    );

    const limitChangeHandler = useCallback(
        (limit: limitOptions) => {
            setLimit(limit);
            if (props.onLimitChange) {
                props.onLimitChange(limit);
            }
        },
        [props]
    );

    if (isMobile) {
        return (
            <TableMobile
                {...props}
                allColumns={props.columns}
                limit={limit}
                page={page}
                onPageChange={pageChangeHandler}
            />
        );
    } else {
        return (
            <TableDesktop
                {...props}
                onAddRow={props.onAddRow}
                limit={limit}
                page={page}
                onLimitChange={limitChangeHandler}
                onPageChange={pageChangeHandler}
            />
        );
    }
}

export {
    TableRowActions,
    TableRowExpander,
    TableRowContainer,
    filterCustomColumns,
};
