import {Box, Typography, useTheme} from '@mui/material';
import {useMemo, useState} from 'react';
import {TableRowActions} from 'common/components/Table';
import Button from 'common/components/Button';
import DataTreeTable from '../DataTreeTable';
import {Column, Row} from 'react-table';
import {TreeNode} from './InfrastructureTree.types';
import {fieldFormatters, fieldToLabel} from './InfrastructureTreeFormats';
import {categoryLevels} from '../../config/hierarchyKeys';
import InfrastructureTreeColumn from './InfrastructureTreeColumn';
import {HierarchyLevel} from 'modules/admin/config/hierarchyKeys';
import './InfrastructureTree.scss';
import {AddIcon} from 'common/icons';
import _ from 'lodash';
type FieldColumn = {
    [key: string]: Array<string>;
};
export const fieldColumns: FieldColumn = {
    buildings: [
        'name',
        'description',
        'address',
        'area',
        '',
        'timezone',
        'working_hours',
        'created_at',
    ],
    floors: [
        'name',
        'description',
        'area',
        '',
        'floorplan_name',
        '',
        '',
        'created_at',
    ],
    zones: ['name', 'description', 'area', '', '', '', '', ''],
};

export interface InfrastructureTreeProps {
    data: any;
    isLoading: boolean;
    isEmptyState: boolean;
    errorMessage?: string | null;
    hierarchy: HierarchyLevel[];
    handleAddRow: (type: HierarchyLevel, row?: TreeNode) => void;
    handleEditRow: (type: HierarchyLevel, row: TreeNode) => void;
}

const NUM_COLS = 8;

const createAccessor = (index: number) => (row: TreeNode) => {
    const type = row.type;
    const rowFields = fieldColumns[type];
    const field = rowFields[index] as keyof TreeNode;
    const formatter = fieldFormatters[field];
    return !!formatter ? formatter(row[field], row) : row[field];
};

export default function InfrastructureTree({
    data,
    isLoading,
    isEmptyState,
    errorMessage,
    hierarchy,
    handleAddRow,
    handleEditRow,
}: InfrastructureTreeProps) {
    const [tableWidth, setTableWidth] = useState<number>(0);

    const rowActions = useMemo(
        () => ({
            onClickAdd: (row: TreeNode) =>
                handleAddRow(
                    !row?.type
                        ? 'buildings'
                        : row.type === 'buildings'
                        ? 'floors'
                        : 'zones',
                    row
                ),
            onClickEdit: (row: TreeNode) => handleEditRow(row.type, row),
            onClickDelete: (row: TreeNode) => {},
            onClickVisible: (row: TreeNode) => {},
        }),
        [handleAddRow, handleEditRow]
    );

    const addButton = useMemo(
        () => (
            <Button
                size="large"
                variant="contained"
                onClick={() => handleAddRow(hierarchy[0])}
                buttonSx={{
                    width: 'fit-content',
                    mb: 2,
                    pl: 1.5,
                    pr: 2,
                    py: 1,
                }}
            >
                <Box display="flex" alignItems="center">
                    <AddIcon />
                    <Typography variant="button" sx={{ml: 1}}>
                        {hierarchy[0] === 'buildings'
                            ? 'Add building'
                            : 'Add floor'}
                    </Typography>
                </Box>
            </Button>
        ),
        [hierarchy, handleAddRow]
    );

    const cols = useMemo(() => {
        const result: Array<Column<TreeNode>> = [];
        const firstColumn = {
            className: 'col-1',
            Header: 'col-1',
            Cell: (props: any) => {
                const row: TreeNode = props.row.original;
                const type = row.type;

                const level = type as keyof typeof categoryLevels;
                const hierarchyLevel = categoryLevels[level];

                return (
                    <InfrastructureTreeColumn
                        hierarchyLevel={hierarchyLevel}
                        value={props.value}
                        columnType="hierarchy"
                        tableWidth={tableWidth}
                    />
                );
            },
            accessor: createAccessor(0),
        };

        for (let i = 1; i < NUM_COLS; i++) {
            const header = `col-${i + 1}`;

            //push middle colmns
            result.push({
                className: header,
                Header: header,
                Cell: (props: any) => {
                    const row: TreeNode = props.row.original;
                    const type = row.type;
                    const rowFields = fieldColumns[type];
                    const field = rowFields[i];
                    const keyFormatter = fieldToLabel[field];

                    return (
                        <InfrastructureTreeColumn
                            keyParam={keyFormatter}
                            value={props.value}
                            columnType="middle"
                            tableWidth={tableWidth}
                        />
                    );
                },
                accessor: createAccessor(i),
            });
        }

        return [
            firstColumn,
            ...result,
            {
                id: '_actions_',
                className: 'col-actions', 
                Cell: ({row}: {row: Row<TreeNode>}) => (
                    <TableRowActions
                        showWeatherIndicator={true}
                        row={row}
                        type={row.original.type}
                        hierarchy={hierarchy}
                        {..._.mapValues(
                            rowActions,
                            (func) => () => func(row.original)
                        )}
                    />
                ),
            },
        ];
    }, [tableWidth, rowActions, hierarchy]);

    const theme = useTheme();
    const {brandColor} = theme.palette.common;
    const colorMap = useMemo(
        () => {
            return [
                brandColor[100],
                brandColor[300],
                brandColor[400],
            ] as const;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Box height="100%" width="100%" p={2}>
            {addButton}
            <DataTreeTable
                isLoading={isLoading}
                virtualized
                categoryLevels={categoryLevels}
                fieldColumnMap={fieldColumns}
                columns={cols}
                colorMap={colorMap}
                data={data}
                label="Infrastructure hierarchy"
                onTableWidthChange={setTableWidth}
                tableWidth={tableWidth}
                rowActions={rowActions}
                isEmptyState={isEmptyState}
                errorMessage={errorMessage}
            />
        </Box>
    );
}
