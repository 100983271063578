export type Operator = {
    name: string;
    id: string;
    predicate?: string[];
    filterType:
        | 'number'
        | 'text'
        | 'date'
        | 'range'
        | 'singledropdown'
        | 'multidropdown'
        | 'daterange';
};

const numberOperators: Operator[] = [
    {
        name: 'Equals',
        id: 'equals',
        predicate: ['EQ'],
        filterType: 'number',
    },
    {
        name: 'Not equals',
        id: 'notEquals',
        predicate: ['NEQ'],
        filterType: 'number',
    },
    {
        name: 'Greater than',
        predicate: ['GT'],
        id: 'greaterThan',
        filterType: 'number',
    },
    {
        name: 'Less than',
        id: 'lessThan',
        predicate: ['LS'],
        filterType: 'number',
    },
    {
        name: 'Between',
        id: 'betweenNumbers',
        predicate: ['GT', 'LS'],
        filterType: 'range',
    },
];

const textOperators: Operator[] = [
    {
        name: 'Contains',
        id: 'contains',
        predicate: ['LIKE'],
        filterType: 'text',
    },
    {
        name: 'Does not contain',
        id: 'doesNotContain',
        filterType: 'text',
    },
];

const multiDropdownOperators: Operator[] = [
    {
        name: 'Contains',
        id: 'containsMultiDropdown',
        predicate: ['IN'],
        filterType: 'multidropdown',
    },
];
const singleDropdownOperators: Operator[] = [
    {
        name: 'Contains',
        id: 'containsSingleDropdown',
        predicate: ['IN'],
        filterType: 'singledropdown',
    },
];

const dateOperators: Operator[] = [
    {
        name: 'Specific date',
        id: 'specificDate',
        filterType: 'date',
    },
    {
        name: 'Days after',
        id: 'daysAfter',
        filterType: 'date',
    },
    {
        name: 'Days before',
        id: 'daysBefore',
        filterType: 'date',
    },
    {
        name: 'Between',
        id: 'betweenDates',
        filterType: 'daterange',
    },
];

export const operators = {
    Number: numberOperators,
    String: textOperators,
    Date: dateOperators,
    SingleDropdown: singleDropdownOperators,
    MultiDropdown: multiDropdownOperators,
};
