import {Box} from '@mui/system';

export interface AppBarTabsProps {
    children?: React.ReactNode;
}
export default function AppBarTabs({children}: AppBarTabsProps) {
    return (
        <Box
            className="secondary-nav"
            typography="body2"
            color="primary.main"
            display="flex"
            alignItems="center"
            height={54}
            bgcolor="grey.100"
            px={1}
        >
            {children}
        </Box>
    );
}
