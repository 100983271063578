import {Area} from 'common/components';
import AppLayout from 'modules/app/components/AppLayout';

export default function OverviewContainer() {
    return (
        <AppLayout>
            <Area variant="higher" sx={{p: 1}}>
                Overview
            </Area>
        </AppLayout>
    );
}
