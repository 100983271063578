import React from 'react';
import {Box} from '@mui/material';
import _ from 'lodash';
import HorizontalLine from './HorizontalLine';
import VerticalLine from './VerticalLine';
import NodeBox from './NodeBox';
import {categoryLevels} from 'modules/admin/config/hierarchyKeys';

const calculateSpacing = (index: number, level: number): number => {
    return index === 0 ? (level === 0 ? 4 : (level * 20 - 4)) : 0;
};

export type TreeCellProps = {
    index: number;
    level: number;
    rowSize: number;
    rowsInBetween: number;
    children: React.ReactNode;
};

export default function TreeCell({
    index,
    level,
    rowSize,
    rowsInBetween,
    children,
}: TreeCellProps) {
    const BOX_SIZE = 25;
    const spacing = calculateSpacing(index, level);
    const verticalLineHeight =
        rowSize * rowsInBetween - _.divide(BOX_SIZE, 2);
    const isHorizontalLineHidden = level === 0;
    const caluclateContentWidth = (level: number) => {
        let width = 185;
        return `${width - level * 20}px`;
    };

    return (
        <Box
            className="icon-and-text"
            sx={{
                pl: `${spacing}px`,
            }}
        >
            <Box
                className="horizontal-line-box-label-vertical-line-wrapper"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <VerticalLine height={verticalLineHeight} />

                <Box
                    className="horizontal-line-box-label-wrapper"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        className="horizontal-line-box-wrapper"
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        {isHorizontalLineHidden ? null : (
                            <HorizontalLine />
                        )}
                        <NodeBox
                            hierarchyLevel={_.keys(categoryLevels)[level]}
                            size={BOX_SIZE}
                        />
                    </Box>
                    <Box
                        typography="body2"
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            minWidth: caluclateContentWidth(level),
                            maxWidth: caluclateContentWidth(level),
                        }}
                    >
                        {children}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
