import {
    Box,
    ButtonBase,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import {grey} from 'common/colors';
import {
    BuildingsSmallIcon,
    DropdownCheckboxSelectedIcon,
    DropdownCheckboxUnselectedIcon,
} from 'common/icons';
import {
    ComponentProps,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import SelectionArrow from './SelectionArrow';

export interface SelectionProps {
    isExpandable: boolean;
    isSelected?: boolean;
    hasCheckbox?: boolean;
    matchesSearch?: boolean;
    isExpanded?: boolean;
    onExpansionChange?: ComponentProps<'button'>['onClick'];
    onClick?: ComponentProps<'div'>['onClick'];
    children: string;
    isActive?: boolean;
    icon?: ReactNode;
    checkboxDisabled?: boolean;
    disabled?: boolean;
}

function Selection({
    isExpandable,
    icon = <BuildingsSmallIcon />,
    isSelected,
    isActive = true,
    disabled,
    matchesSearch,
    onExpansionChange = () => {},
    isExpanded,
    children,
    hasCheckbox,
    onClick = () => {},
    checkboxDisabled,
}: SelectionProps) {
    const [innerHover, setInnerHover] = useState(false);
    // truncation management
    const [outerTextElementWidth, setOuterTextElementWidth] = useState<
        null | number
    >(null);
    const [firstTextElementWidth, setFirstTextElementWidth] = useState<
        null | number
    >(null);
    const [secondTextElementWidth, setSecondTextElementWidth] = useState<
        null | number
    >(null);
    const outerTextElementRef = useRef<HTMLSpanElement>(null);
    const firstTextElementRef = useRef<HTMLSpanElement>(null);
    const secondTextElementRef = useRef<HTMLSpanElement>(null);
    useEffect(() => {
        if (outerTextElementRef.current) {
            setOuterTextElementWidth(
                outerTextElementRef.current.offsetWidth
            );
        }
    }, [outerTextElementRef?.current?.offsetWidth]);
    useEffect(() => {
        if (firstTextElementRef.current) {
            setFirstTextElementWidth(
                (state) =>
                    state ?? firstTextElementRef!.current!.offsetWidth
            );
        }
    }, [secondTextElementRef?.current?.offsetWidth]);
    useEffect(() => {
        if (secondTextElementRef.current) {
            setSecondTextElementWidth(
                secondTextElementRef.current.offsetWidth
            );
        }
    }, [secondTextElementRef?.current?.offsetWidth]);
    // styling
    const theme = useTheme();
    const brandLight = theme.palette.common.dynamicBrandLight;
    const brandDark = theme.palette.common.dynamicBrandDark;
    const backgroundColor = useMemo(() => {
        if (isSelected) {
            return theme.palette.primary.main;
        } else if (isActive) {
            return theme.palette.primary.light;
        } else {
            return grey[100];
        }
    }, [isActive, isSelected, theme]);
    const textColor = useMemo(() => {
        if (disabled) {
            return theme.palette.common.disabled;
        } else if (isSelected) {
            return 'grey.100';
        } else if (isActive) {
            return theme.palette.primary.main;
        } else {
            return 'grey.900';
        }
    }, [
        disabled,
        isActive,
        isSelected,
        theme.palette.common.disabled,
        theme.palette.primary.main,
    ]);
    const iconColor = useMemo(() => {
        if (isSelected) {
            return theme.palette.primary.light;
        } else if (isActive) {
            return theme.palette.primary.main;
        } else {
            return 'grey.400';
        }
    }, [isActive, isSelected, theme]);
    const hoverColor = useMemo(() => {
        if (isSelected || checkboxDisabled || innerHover || disabled) {
            return backgroundColor;
        }
        return brandLight.hover(backgroundColor);
    }, [
        backgroundColor,
        brandLight,
        checkboxDisabled,
        disabled,
        innerHover,
        isSelected,
    ]);
    const checkboxColor = useCallback(() => {
        if (checkboxDisabled) {
            return theme.palette.common.disabled;
        } else if (isSelected) {
            return 'grey.100';
        } else {
            return 'grey.900';
        }
    }, [checkboxDisabled, isSelected, theme.palette.common.disabled]);
    const focusColor = useMemo(() => {
        if (isSelected || checkboxDisabled || innerHover || disabled) {
            return backgroundColor;
        }
        return brandLight.focus(backgroundColor);
    }, [
        backgroundColor,
        brandLight,
        checkboxDisabled,
        disabled,
        innerHover,
        isSelected,
    ]);
    const pressedColor = useMemo(() => {
        if (isSelected || checkboxDisabled || innerHover || disabled) {
            return backgroundColor;
        }
        return brandLight.pressed(backgroundColor);
    }, [
        backgroundColor,
        brandLight,
        checkboxDisabled,
        disabled,
        innerHover,
        isSelected,
    ]);
    // ------
    const textTooltip = useMemo(
        () =>
            firstTextElementWidth &&
            secondTextElementWidth &&
            outerTextElementWidth &&
            firstTextElementWidth >
                outerTextElementWidth - secondTextElementWidth - 5
                ? children
                : '',
        [
            children,
            firstTextElementWidth,
            outerTextElementWidth,
            secondTextElementWidth,
        ]
    );
    return (
        <Box
            display="flex"
            role="treeitem"
            aria-label={
                matchesSearch
                    ? 'Matches current search pattern'
                    : undefined
            }
            aria-disabled={disabled}
            aria-checked={isSelected && hasCheckbox}
            aria-selected={isSelected}
            onClick={(e) => onClick(e)}
            sx={{
                borderRadius: '4px',
                alignItems: 'center',
                cursor: checkboxDisabled ? 'default' : 'pointer',
                height: '36px',
                width: '100%',
                pl: '2px',
                backgroundColor,
                '&:hover': {
                    backgroundColor: hoverColor,
                },
                '&:focus': {
                    backgroundColor: focusColor,
                },
                '&:active': {
                    backgroundColor: pressedColor,
                },

                justifyContent: 'flex-start',
            }}
        >
            {isExpandable && (
                <SelectionArrow
                    isExpanded={isExpanded}
                    onClick={onExpansionChange}
                    onHoverEnter={() => setInnerHover(true)}
                    onHoverLeave={() => setInnerHover(false)}
                    backgroundColor={
                        isActive && !isSelected
                            ? 'grey.100'
                            : 'primary.light'
                    }
                    hoverColor={
                        isSelected
                            ? brandDark.hover(backgroundColor)
                            : brandLight.hover(backgroundColor)
                    }
                    focusColor={
                        isSelected
                            ? brandDark.focus(backgroundColor)
                            : brandLight.focus(backgroundColor)
                    }
                    pressedColor={
                        isSelected
                            ? brandDark.pressed(backgroundColor)
                            : brandLight.pressed(backgroundColor)
                    }
                />
            )}
            <Box color={iconColor} sx={{display: 'flex', mb: '0.5px'}}>
                {icon}
            </Box>
            <Tooltip title={textTooltip} enterDelay={700} arrow>
                <Typography
                    noWrap
                    ref={outerTextElementRef}
                    sx={{
                        lineHeight: '16px',
                        height: '16px',
                        textAlign: 'left',
                        pl: '2px',
                        backgroundColor: (theme) =>
                            matchesSearch
                                ? theme.palette.secondary.light
                                : undefined,
                        color: textColor,
                        width: '100%',
                    }}
                    variant="overline"
                >
                    <Typography
                        ref={firstTextElementRef}
                        variant="overline"
                        sx={{
                            display: 'inline-block',
                            maxWidth:
                                firstTextElementWidth &&
                                secondTextElementWidth &&
                                outerTextElementWidth
                                    ? `${
                                          outerTextElementWidth -
                                          secondTextElementWidth -
                                          5
                                      }px`
                                    : 'none',
                            textOverflow: 'ellipsis',
                            overflow: 'clip',
                            verticalAlign: 'middle',
                        }}
                    >
                        {children.slice(0, children.length - 5)}
                    </Typography>
                    <Typography
                        ref={secondTextElementRef}
                        sx={{
                            display: 'inline-block',
                            overflow: 'hidden',
                            verticalAlign: 'middle',
                        }}
                        variant="overline"
                    >
                        {children.slice(
                            children.length - 5,
                            children.length
                        )}
                    </Typography>
                </Typography>
            </Tooltip>
            {hasCheckbox && (
                <Tooltip
                    title="Compare value"
                    placement="right"
                    arrow
                    enterDelay={700}
                >
                    <ButtonBase
                        sx={{mr: '8px'}}
                        disabled={checkboxDisabled}
                    >
                        {isSelected ? (
                            <DropdownCheckboxSelectedIcon
                                sx={{
                                    color: checkboxColor,
                                }}
                            />
                        ) : (
                            <DropdownCheckboxUnselectedIcon
                                sx={{
                                    color: checkboxColor,
                                }}
                            />
                        )}
                    </ButtonBase>
                </Tooltip>
            )}
        </Box>
    );
}

export default Selection;
