import {
    InputAdornment,
    InputBaseComponentProps,
    SxProps,
    TextField,
    TextFieldProps,
    useTheme,
} from '@mui/material';
import React, {forwardRef, PropsWithoutRef} from 'react';
import IconButton from '../IconButton';
import {CloseLargeIcon} from '../../icons';
import {grey} from '../../colors';

export interface InputProps {
    variant?: 'Counter' | 'NoMessage';
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    onChange?: (text: string) => void;
    placeholder?: string;
    sx?: SxProps;
    inputSx?: SxProps;
    cursor?: 'pointer' | 'text' | 'default';
    value?: string;
    width?: string | number;
    ariaLabel?: string;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    maxLength?: number;
    readOnly?: boolean;
    clearable?: boolean;
    inputProps?: InputBaseComponentProps;
    overrideTextFieldProps?: PropsWithoutRef<TextFieldProps>;
    noAutocomplete?: boolean;
    autoExpand?: boolean;
    disabled?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const {
        variant = 'NoMessage',
        placeholder = 'Placeholder',
        onChange = (_) => {},
        onClick = (_) => {},
        sx,
        value = '',
        cursor = 'text',
        inputSx,
        inputProps,
        readOnly,
        startAdornment,
        endAdornment,
        width = '184px',
        ariaLabel = 'Text input',
        maxLength,
        clearable = false,
        noAutocomplete = false,
        overrideTextFieldProps,
        autoExpand,
        disabled,
    } = props;

    const handleClear = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        onChange('');
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (maxLength && event.target.value.length > maxLength) {
            return;
        }
        onChange(event.target.value);
    };
    const {typography} = useTheme();
    function getHelperText() {
        return maxLength && variant === 'Counter' && value !== null
            ? `${value.length}/${maxLength}`
            : null;
    }
    function getFocusedBorderColor() {
        return value !== null &&
            value?.length > 0 &&
            (!maxLength || value.length < maxLength)
            ? grey[400]
            : grey[800];
    }

    return (
        <TextField
            fullWidth={autoExpand}
            aria-label={ariaLabel}
            sx={{
                '.MuiInputBase-input': {
                    '&.Mui-disabled': {
                        WebkitTextFillColor: (theme) =>
                            theme.palette.common.disabled,
                    },
                    '::-webkit-input-placeholder': {
                        opacity: 1,
                    },
                    '::-moz-placeholder ': {
                        opacity: 1,
                    },
                },
                pr: '0px',
                '& label.Mui-focused': {
                    color: grey[400],
                    '&:hover': {
                        color: grey[400],
                    },
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: grey[800],
                    borderWidth: '1px',
                },
                '& .MuiOutlinedInput-root': {
                    bgcolor: 'white',
                    color:
                        value !== null && value.length > 0
                            ? grey[900]
                            : grey[500],
                    '& fieldset': {
                        borderColor: grey[400],
                    },
                    '&:hover fieldset': {
                        borderColor: grey[600],
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: getFocusedBorderColor(),
                        borderWidth: '1px',
                    },
                    '&.Mui-disabled': {
                        bgcolor: 'grey.300',
                        fieldset: {
                            borderColor: 'common.disabled',
                        },
                    },
                    cursor,
                },
                '& .MuiOutlinedInput-input:hover': {
                    cursor,
                },
                '& .MuiOutlinedInput-input:focus': {
                    cursor,
                },
                '& .MuiFormHelperText-root': {
                    textAlign: 'right',
                    mx: '1px',
                    whiteSpace: 'nowrap',
                    ...typography.overline,
                },
                width: autoExpand ? null : width,
                ...sx,
            }}
            value={value}
            variant="outlined"
            label=""
            inputRef={ref}
            placeholder={placeholder}
            onChange={handleChange}
            onClick={onClick}
            helperText={getHelperText()}
            inputProps={{
                ...inputProps,
            }}
            InputProps={{
                disabled: disabled,
                readOnly: readOnly,
                autoComplete: noAutocomplete ? 'off' : undefined,
                sx: {
                    height: '40px',
                    pl: '10px',
                    pr: '4px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: 'auto',
                    fontSize: '14px',
                    lineHeight: '16px',
                    '& .MuiInputBase-input': {
                        textOverflow: 'ellipsis',
                    },
                    ...inputSx,
                },
                startAdornment: (
                    <InputAdornment
                        position="start"
                        sx={{fontSize: '21px'}}
                    >
                        {startAdornment}
                    </InputAdornment>
                ),
                endAdornment: (
                    <>
                        {clearable && (
                            <InputAdornment
                                position="start"
                                sx={{
                                    width: '17px',
                                    pr: '24px',
                                }}
                            >
                                {value.length > 0 && (
                                    <IconButton
                                        variant="dark"
                                        aria-label="clear input"
                                        onClick={handleClear}
                                    >
                                        <CloseLargeIcon />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        )}
                        {endAdornment && (
                            <InputAdornment
                                position={clearable ? 'end' : 'start'}
                                sx={{width: '24px'}}
                            >
                                {endAdornment}
                            </InputAdornment>
                        )}
                    </>
                ),
            }}
            {...overrideTextFieldProps}
        />
    );
});
export default Input;
