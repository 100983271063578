import {
    Box,
    ClickAwayListener,
    Popper,
} from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList, VariableSizeList as List} from 'react-window';
import {CSSProperties, useCallback, useMemo, useState} from 'react';
import TreeMobileItem, {
    emptyMobileTreeItemHeight,
    mobileTreeItemHeightPerTile,
    TreeMobileItemProps,
} from './TreeMobileItem';
import TreeMobilePopupContent from './TreeMobilePopupContent';
import {FieldColumnMap, NodeType, RowActions} from '..';
import {TreeItemTypes} from '../../InfrastructureTree/InfrastructureTree.types';
import _ from 'lodash';
import EmptyState from 'common/components/EmptyStates';
export interface DataTreeMobileProps<T extends NodeType> {
    fieldColumnMap: FieldColumnMap;
    data: T[];
    isLoading?: boolean;
    errorMessage?: string | null;
    isEmptyState?: boolean;
    rowActions: RowActions;
}

function DataTreeMobile<T extends NodeType>({
    fieldColumnMap,
    data,
    isLoading,
    rowActions,
    errorMessage,
    isEmptyState,
}: DataTreeMobileProps<T>) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorItemIndex, setAnchorItemIndex] = useState<null | number>(
        null
    );

    const childWithMaxDepth = useMemo(() => {
        const result: {
            parentId: string | null;
            maxDepthIndex: number;
        }[] = [];
        _.forEach(data, (item, index: number) => {
            //if current parentId exist update index
            const isExist = _.findIndex(
                result,
                (i) => i.parentId === item.parentId
            );
            if (isExist !== -1) {
                result[isExist].maxDepthIndex = index;
            } else {
                result.push({
                    parentId: item.parentId,
                    maxDepthIndex: index,
                });
            }
        });
        return result;
    }, [data]);

    const lastBuildingIndex = useMemo(() => {
        return _.findLastIndex(data, (d) => d.type === 'buildings');
    }, [data]);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLElement>, index: number) => {
            event?.stopPropagation();
            setAnchorEl(anchorEl ? null : event.currentTarget);
            setAnchorItemIndex(anchorEl ? null : index);
        },
        [anchorEl]
    );
    const listItemProps = useMemo(() => {
        return _.map(data, (item, index) => {
            let isLastChild = false;
            let isFirstChild = false;
            let hasChildren = false;
            let parentIsLastChild = false;

            const variant = data[index].type as TreeItemTypes;
            if (variant === 'buildings') {
                isLastChild = index === lastBuildingIndex;
                hasChildren =
                    index + 1 < data.length
                        ? data[index + 1].type !== 'buildings'
                        : false;
            } else {
                isLastChild =
                    _.findIndex(
                        childWithMaxDepth,
                        (item) => item.maxDepthIndex === index
                    ) > -1;
                hasChildren =
                    index + 1 < data.length
                        ? data[index + 1].type === 'zones'
                        : false;
                if (variant === 'floors') {
                    parentIsLastChild = index > lastBuildingIndex;
                    if (index - 1 >= 0) {
                        isFirstChild =
                            'buildings' === data[index - 1].type;
                    }
                } else {
                    const parentIndex = data.findIndex(
                        (el) => el.iRef === data[index].parentId
                    );
                    parentIsLastChild =
                        _.findIndex(
                            childWithMaxDepth,
                            (item) => item.maxDepthIndex === parentIndex
                        ) > -1;
                    if (index - 1 >= 0) {
                        isFirstChild = 'floors' === data[index - 1].type;
                    }
                }
            }
            const itemProps: TreeMobileItemProps = {
                isFirstChild,
                isLastChild,
                hasChildren,
                parentIsLastChild,
                onClick: (e) => handleClick(e, index),
                isLastBuilding: index > lastBuildingIndex,
                fieldColumns: fieldColumnMap[data[index].type],
                variant: variant,
                fieldData: data[index],
            };
            return itemProps;
        });
    }, [
        childWithMaxDepth,
        data,
        fieldColumnMap,
        handleClick,
        lastBuildingIndex,
    ]);

    function renderListSkeleton({
        style,
        index,
    }: {
        index: number;
        style: CSSProperties | undefined;
    }) {
        const types: TreeItemTypes[] = ['buildings', 'floors', 'zones'];
        return (
            <TreeMobileItem
                height={332}
                isLoading={true}
                key={`skeleton-${index}`}
                style={style}
                onClick={(e) => handleClick(e, index)}
                fieldColumns={fieldColumnMap[types[index % 3]]}
                variant={types[index % 3]}
                fieldData={{id: index}}
            />
        );
    }
    const rowHeight = useCallback(
        (index) => {
            if (isLoading) {
                return 332;
            }
            const fieldColumns = fieldColumnMap[data[index].type];
            return (
                fieldColumns.filter((b) => !_.isEmpty(b) && b !== 'name')
                    .length *
                    mobileTreeItemHeightPerTile +
                emptyMobileTreeItemHeight
            );
        },
        [data, fieldColumnMap, isLoading]
    );
    const estimatedItemSize = useMemo(() => {
        const building = _.findIndex(data, (b) => b.type === 'buildings');
        const floor = _.findIndex(data, (b) => b.type === 'floors');
        const zone = _.findIndex(data, (b) => b.type === 'zones');
        if (building === -1 || floor === -1 || zone === -1) {
            return 332;
        }

        return (
            (rowHeight(building) + rowHeight(floor) + rowHeight(zone)) / 3
        );
    }, [data, rowHeight]);

    const emptyState = useMemo(() => {
        if (!isLoading && (isEmptyState || errorMessage)) {
            return (
                <EmptyState
                type={errorMessage?'noData':'noResults'}
                    errorCode={errorMessage}
                />
            );
        }
    }, [errorMessage, isEmptyState, isLoading]);

    return (
        <Box height="100%" data-testid="mobile-tree-table">
            {emptyState ? (
                emptyState
            ) : isLoading ? (
                <AutoSizer>
                    {({height, width}) => (
                        <FixedSizeList
                            onScroll={() => setAnchorEl(null)}
                            itemCount={3}
                            height={height}
                            width={width}
                            itemSize={332}
                        >
                            {renderListSkeleton}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <AutoSizer>
                    {({height, width}) => (
                        <List
                            itemData={listItemProps}
                            estimatedItemSize={estimatedItemSize}
                            onScroll={() => setAnchorEl(null)}
                            itemCount={data.length}
                            height={height}
                            width={width}
                            itemSize={rowHeight}
                        >
                            {ListItem}
                        </List>
                    )}
                </AutoSizer>
            )}
            <ClickAwayListener onClickAway={(_) => setAnchorEl(null)}>
                <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    sx={{
                        zIndex: (theme) => theme.zIndex.modal,
                    }}
                >
                    <TreeMobilePopupContent
                        variant={
                            anchorItemIndex !== null
                                ? (data[anchorItemIndex]
                                      .type as TreeItemTypes | null)
                                : null
                        }
                        onAdd={() =>
                            rowActions.onClickAdd({
                                ...data[anchorItemIndex!],
                            })
                        }
                        onEdit={() =>
                            rowActions.onClickEdit({
                                ...data[anchorItemIndex!],
                            })
                        }
                        onRemove={() => {}}
                    />
                </Popper>
            </ClickAwayListener>
        </Box>
    );
}

function ListItem({
    data,
    style,
    index,
}: {
    data: TreeMobileItemProps[];
    index: number;
    style: any;
}) {
    return (
        <TreeMobileItem
            key={index}
            style={style}
            height={style?.height}
            {...data[index]}
        />
    );
}

export default DataTreeMobile;
