import {Box, Typography} from '@mui/material';
import {DropdownNoResultIcon} from 'common/icons/index';

export default function NoResultsFound() {
    return (
        <Box
            data-testid="table-no-results"
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <DropdownNoResultIcon
                sx={{
                    height: '80px',
                    width: '80px',
                    color: 'common.brandColor.300',
                    marginBottom: (theme) => theme.spacing(2),
                }}
                viewBox="0 0 80 80"
                fill="none"
            />
            <Typography
                variant="body1"
                sx={{marginBottom: (theme) => theme.spacing(1)}}
            >
                {' '}
                No results found
            </Typography>
            <Typography variant="overline">
                {' '}
                You need to adjust filters
            </Typography>
        </Box>
    );
}
