import {Business, Map} from '@mui/icons-material';
import {YodideskSectionName} from '../config/sections';

export interface SectionIconProps {
    name: YodideskSectionName;
}
const iconSectionMap: {[key in YodideskSectionName]: JSX.Element} = {
    overview: <Business fontSize="small" />,
    versions: <Map fontSize="small" />,
};

export default function SectionIcon({
    name,
}: SectionIconProps): JSX.Element {
    return iconSectionMap[name];
}
