import {SxProps, Paper, alpha} from '@mui/material';
import {grey, white} from 'common/colors';
import {ReactNode} from 'react';
export interface AreaProps {
    children: ReactNode;
    variant: 'higher' | 'lower';
    sx?: SxProps;
}
export default function Area({children, sx = {}, variant}: AreaProps) {
    const shadows = {
        higher: `0px 8px 24px ${alpha(grey[800], 0.08)}`,
        lower: `0px 8px 16px ${alpha(grey[800], 0.04)}`,
    };
    return (
        <Paper
            sx={{
                height: '100%',
                overflow: 'auto',
                borderRadius: 2,
                bgcolor: white,
                boxShadow: shadows[variant],
                ...sx,
            }}
        >
            {children}
        </Paper>
    );
}
