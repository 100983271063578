import _ from 'lodash';
import {HierarchyLevel} from '../config/hierarchyKeys';

type UnknownObject = Record<string, unknown>;

export const dropdownLabelsLevelsMap: {[key in HierarchyLevel]: string} = {
    buildings: 'Building',
    floors: 'Floor',
    zones: 'Zone',
};

const hierarchyChildRelationshipMap = {
    organization: 'deployment',
    deployment: 'building',
    building: 'floor',
    floor: 'zone',
    zone: '',
};

const allLevels = Object.keys(hierarchyChildRelationshipMap);

type Level = typeof allLevels[number];
type Data = Record<Level, UnknownObject> &
    Record<string, UnknownObject | null | undefined>;

export const flattenDataTree = (
    data: Data,
    level: Level = 'organization',
    parentId: string | null = null
): UnknownObject[] | UnknownObject => {
    const nextLevelChildren = hierarchyNameTransform(level, [
        'Child',
        'PluralForm',
    ]);
    const nextLevelIndex =
        allLevels.findIndex((item) => item === level) + 1;
    const newLevel = hierarchyNameTransform(level, ['PluralForm']);
    const nodeId = data[level].iRef as string;
    if (
        level !== allLevels[allLevels.length - 1] &&
        data[nextLevelChildren] !== null &&
        data.hasOwnProperty(nextLevelChildren)
    ) {
        const result = Object.values(
            data[nextLevelChildren as Level]
        ).reduce<UnknownObject[]>((prev, cur: any) => {
            const flatted = flattenDataTree(
                cur,
                allLevels[nextLevelIndex],
                nodeId
            );
            if (flatted.length) {
                prev = prev.concat(flatted);
            } else {
                prev.push({parentId: nodeId, ...flatted} as UnknownObject);
            }
            return prev;
        }, []);
        return [{type: newLevel, parentId, ...data[level]}, ...result];
    } else {
        const levelData = {type: newLevel, ...data[level]};
        return newLevel === 'organizations' ? [levelData] : levelData;
    }
};

type NamingOptionTag = 'Capitalize' | 'PluralForm' | 'Child' | 'Parent';

// This takes a hierarchy level in any of the used formats (e.g. building/Building/buildings)
// and transforms it (or it's parent/child) to Capitalized/Uncapitalized and Singular or Plural form
// This is intended to simplify the mismatch between labeling requirements and internally used variables

// NOTE: Ideally we still want to keep these transformations to a minimum
export const hierarchyNameTransform = (
    input: string,
    options: NamingOptionTag[] = []
) => {
    let transformedInput = input.toLowerCase();
    transformedInput = transformedInput.endsWith('s')
        ? transformedInput.slice(0, input.length - 1)
        : transformedInput;
    if (options.includes('Child')) {
        transformedInput =
            hierarchyChildRelationshipMap[
                transformedInput as keyof typeof hierarchyChildRelationshipMap
            ];
    }
    if (options.includes('Parent')) {
        transformedInput = _.findKey(
            hierarchyChildRelationshipMap,
            (value) => value === transformedInput
        )!;
    }
    if (options.includes('Capitalize')) {
        transformedInput = _.capitalize(transformedInput);
    }
    if (options.includes('PluralForm')) {
        transformedInput = transformedInput + 's';
    }
    return transformedInput;
};
