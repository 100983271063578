import {IconButton} from '@mui/material';
import Input from 'common/components/Inputs';
import {CalendarIcon} from 'common/icons';
import {format, getYear, isValid, parse} from 'date-fns';
import {useCallback, useState} from 'react';
import {ReactDatePickerCustomHeaderProps} from 'react-datepicker';
import DatePickerHeader from './DatePickerHeader';
import DatePickerBase from './DatePickerBase';
import './index.scss';
import {grey} from '@mui/material/colors';
import theme from 'theme';

export type DatePickerProps = {
    onChange?: (date: Date | null) => void;
    disableFuture?: boolean;
    disablePast?: boolean;
    datePredicate?: (date: Date) => boolean;
    autoExpand?: boolean;
    width?: number;
};

export default function DatePicker({
    onChange = () => {},
    disableFuture = false,
    disablePast = false,
    datePredicate,
    autoExpand,
    width = 300,
}: DatePickerProps) {
    const [selected, setSelected] = useState<Date | null>(null);
    const [text, setText] = useState<string | undefined>(undefined);
    const [showYear, setShowYear] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const customHeader = useCallback(
        ({...props}: ReactDatePickerCustomHeaderProps) => {
            return (
                <DatePickerHeader
                    {...props}
                    onButtonPressed={() => setShowYear(!showYear)}
                    isPressed={showYear}
                />
            );
        },
        [showYear]
    );
    const DATE_FORMAT = 'dd / MM / yyyy';

    const handleChange = useCallback(
        (selection: Date | null) => {
            if (!showYear) {
                setOpen(false);
            }
            setSelected(selection);
            onChange(selection);
            let formattedSelection = selection
                ? format(selection, DATE_FORMAT)
                : '';
            setText(formattedSelection);
        },
        [onChange, showYear]
    );
    const handleRawChange = useCallback(
        (c) => {
            if (typeof c !== 'string') {
                return;
            }
            const textChange = c as string;
            setText(textChange);
            const trimmedText = textChange.replaceAll(' ', '');
            const parsedDate = parse(
                trimmedText,
                DATE_FORMAT.replaceAll(' ', ''),
                new Date()
            );
            if (
                isValid(parsedDate) &&
                getYear(parsedDate).toString().length === 4 &&
                (parsedDate >= new Date() || !disablePast) &&
                (parsedDate <= new Date() || !disableFuture) &&
                (!datePredicate || datePredicate(parsedDate))
            ) {
                if (parsedDate !== selected) {
                    onChange(parsedDate);
                }
                setSelected(parsedDate);
                setText(format(parsedDate, DATE_FORMAT));
            }
        },
        [datePredicate, disableFuture, disablePast, onChange, selected]
    );

    return (
        <DatePickerBase
            autoExpand={autoExpand}
            sx={{
                '.react-datepicker': {
                    width: '297px',
                    color: grey[900],
                    height: '326px',
                    scrollbarColor: `${theme.palette.grey[800]} ${grey[100]}`,
                    ...theme.typography.body2,
                },
            }}
            onChange={handleChange}
            selected={selected}
            value={text}
            onChangeRaw={handleRawChange}
            shouldCloseOnSelect={!showYear}
            showYearPicker={showYear}
            yearItemNumber={40}
            open={open}
            minDate={disablePast ? new Date() : null}
            maxDate={disableFuture ? new Date() : null}
            filterDate={datePredicate}
            customInput={
                <Input
                    autoExpand={autoExpand}
                    width={width}
                    endAdornment={
                        <IconButton
                            aria-label="Choose date"
                            sx={{
                                width: '32px',
                                height: '32px',
                                ':hover': {
                                    color: 'black',
                                },
                                color: open ? 'white' : 'black',
                                backgroundColor: open
                                    ? 'grey.600'
                                    : undefined,
                            }}
                            edge="start"
                            onClick={() => setOpen(!open)}
                        >
                            <CalendarIcon />
                        </IconButton>
                    }
                />
            }
            renderCustomHeader={customHeader}
        />
    );
}
