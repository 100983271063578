import {Box, Dialog as MuiDialog, alpha, Typography} from '@mui/material';
import Button from 'common/components/Button';
import Breadcrumbs from 'common/components/Breadcrumbs';

type DialogProps = {
    isOpen: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
    name: string;
    subtitleHierarchy?: string[];
    isMobile?: boolean;
};

export default function Dialog({
    isOpen,
    subtitleHierarchy = [],
    handleClose,
    handleConfirm,
    name,
    isMobile,
}: DialogProps) {
    return (
        <MuiDialog
            open={isOpen}
            onClose={handleClose}
            sx={{
                boxShadow: (theme) =>
                    `0px 2px 10px ${alpha(theme.palette.grey[800], 0.24)}`,
                borderRadius: 1,
            }}
        >
            <Box width={isMobile ? '83vw' : '552px'}>
                <Box
                    sx={{
                        mb: 4,
                        pt: 1,
                        px: 2,
                    }}
                >
                    <Box
                        sx={{
                            pt: 1.25,
                            pb: 1,

                            boxShadow: 'inset 0px -1px 0px #EEEEEE',
                        }}
                    >
                        <Typography variant="subtitle">
                            Leave {name}
                        </Typography>
                        <Box mt={1}>
                            <Breadcrumbs
                                items={subtitleHierarchy.map((e) => {
                                    return {label: e, value: e};
                                })}
                                variant="default"
                            />
                        </Box>
                    </Box>
                </Box>
                <Box px={2}>
                    <Typography variant="body2">
                        Are you sure you want to leave {name}?
                    </Typography>
                    <Typography variant="body2" sx={{mt: 2}}>
                        If you leave this drawer, all changes will be lost.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 4,
                        p: 2,
                        bgcolor: 'grey.200',
                    }}
                >
                    <Button
                        variant="outlined"
                        size="large"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                            handleConfirm();
                            handleClose();
                        }}
                        sx={{marginLeft: 3}}
                    >
                        Yes, leave
                    </Button>
                </Box>
            </Box>
        </MuiDialog>
    );
}
