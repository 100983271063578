import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';
import {ModuleName} from 'common/constants/modules';
import {getModuleByName} from 'common/lib';
import {fetchOrganization} from 'common/services/hierarchy.service';

export interface Organization {
    iRef: string;
    id: number;
    logo: string;
    name: string;
    orgId: number;
    uiAccessLevel: number;
    url: string;
}
export type SideNavWidth = 'closed' | 'opened' | 'extended';

interface AppState {
    activeModuleName: ModuleName;
    activeModuleLabel?: string;
    isAdminSelected: boolean;
    layout: {
        sideNavWidth: SideNavWidth;
        sideNavPinned: boolean;
    };
    selectedOrganization: Organization | null;
    organizations: {
        loading: boolean;
        data: Organization[] | null;
        error?: string | null;
    };
    title: string;
}
const initialState: AppState = {
    activeModuleName: '',
    activeModuleLabel: '',
    isAdminSelected: false,
    selectedOrganization: null,
    layout: {sideNavWidth: 'closed', sideNavPinned: false},
    title: '',
    organizations: {
        loading: false,
        data: null,
    },
};

// will be retrieved from API once ready
const DEFAULT_MODULE: ModuleName = 'yodisense';

export const fetchOrganizationStructure = createAsyncThunk(
    'app/fetchOrganizations',
    async (_, {rejectWithValue}) => {
        return fetchOrganization()
            .then((data) => data.json())
            .catch((e) => rejectWithValue({error: 'Error ' + e}));
    }
);

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setActiveProduct: (state, action: PayloadAction<ModuleName>) => {
            const moduleName = action.payload || DEFAULT_MODULE;
            if (state.activeModuleName !== moduleName) {
                state.activeModuleName = moduleName;
                state.isAdminSelected = action.payload === 'admin';
                state.activeModuleLabel =
                    getModuleByName(moduleName)?.label;
                state.layout.sideNavWidth = 'closed';
            }
        },
        setAdminSelected: (state, action: PayloadAction<boolean>) => {
            state.isAdminSelected = action.payload;
        },
        setTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload;
        },
        setOrganization: (state, action: PayloadAction<number>) => {
            const organization = state.organizations.data?.find(
                (org: Organization) => org.id === action.payload
            );
            state.selectedOrganization = organization || null;
        },
        setSideNavWidth: (state, action: PayloadAction<SideNavWidth>) => {
            state.layout.sideNavWidth = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchOrganizationStructure.pending,
            (state: AppState) => {
                state.organizations.loading = true;
            }
        );
        builder.addCase(
            fetchOrganizationStructure.fulfilled,
            (state: AppState, {payload}) => {
                const {organizations} = state;
                organizations.loading = false;
                organizations.data = payload.items as Organization[];
                if (organizations.data) {
                    state.selectedOrganization = organizations.data[0];
                }
            }
        );
        builder.addCase(
            fetchOrganizationStructure.rejected,
            (state: AppState, {payload}) => {
                const {organizations} = state;
                organizations.loading = false;
                organizations.error = (payload as any)?.error as string;
            }
        );
    },
});

export const {
    setActiveProduct,
    setAdminSelected,
    setTitle,
    setOrganization,
    setSideNavWidth,
} = appSlice.actions;

export default appSlice.reducer;
