import {
    TypedUseSelectorHook,
    useDispatch as _useDispatch,
    useSelector as _useSelector,
} from 'react-redux';
import type {RootState, AppDispatch} from '../../store';

// Use throughout the app instead of `useDispatch` and `useSelector` exproted by react-redux'
export const useDispatch = () => _useDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = _useSelector;
export {default as useLocationChange} from './useLocationChange';
export {default as useRedirectToModule} from './useRedirectToModule';
export {useModuleRouter} from './moduleRouter';
export {default as useWindowSize} from './useWindowSize';
