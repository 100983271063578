import modules, {Module, ModuleName} from '../constants/modules';

export function getModuleNameFromPathname(pathname = '') {
    return pathname.split('/')[1] as ModuleName;
}
export function getModuleByName(
    productName: ModuleName | ''
): Module | null {
    const product = modules.find(
        (product) => product.name === productName
    );
    return product || null;
}
