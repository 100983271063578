import {useTheme, useMediaQuery} from '@mui/material';
import {ReactElement} from 'react';
import FilterAreaDesktop from './FilterAreaDesktop';
import FilterAreaMobile from './FilterAreaMobile';

export type FilterAreaProps = {
    isMobileOpen: boolean;
    isDesktopOpen: boolean;
    resetEnabled: boolean;
    onClose: () => void;
    onApply: () => void;
    onReset: () => void;
    renderToggleComponent: () => ReactElement;
    renderFilterComponent: () => ReactElement;
    applyEnabled: boolean;
};

export default function FilterArea({
    isMobileOpen,
    isDesktopOpen,
    renderFilterComponent,
    renderToggleComponent,
    resetEnabled,
    onClose,
    onReset,
    onApply,
    applyEnabled,
}: FilterAreaProps) {
    const {
        breakpoints: {up},
    } = useTheme();

    const isMobile = !useMediaQuery(up('desktopSm'));

    return isMobile ? (
        <FilterAreaMobile
            isOpen={isMobileOpen}
            onClose={onClose}
            onApply={onApply}
            onReset={onReset}
            renderToggleComponent={renderToggleComponent}
            renderFilterComponent={renderFilterComponent}
            resetEnabled={resetEnabled}
            applyEnabled={applyEnabled}
        />
    ) : (
        <FilterAreaDesktop
            isOpen={isDesktopOpen}
            renderToggleComponent={renderToggleComponent}
            renderFilterComponent={renderFilterComponent}
            onReset={onReset}
            resetEnabled={resetEnabled}
        />
    );
}
