import {
    Pagination as MuiPagination,
    PaginationProps as MuiPaginationProps,
    PaginationItem,
} from '@mui/material';
import grey from 'common/colors/grey';
import {ArrowRightIcon, ArrowLeftIcon} from '../../icons';
type PaginationProps = MuiPaginationProps & {
    isMobile?: boolean;
    isSinglePage?: boolean;
    totalPages: number;
};

export default function PagePicker({
    isMobile,
    isSinglePage,
    count,
    page,
    totalPages,
    onChange,
    ...rest
}: PaginationProps) {
    const lowerPagesBound = 7;

    return (
        <MuiPagination
            siblingCount={1}
            count={count}
            onChange={onChange}
            page={page}
            hideNextButton={totalPages <= lowerPagesBound && isMobile}
            hidePrevButton={totalPages <= lowerPagesBound && isMobile}
            renderItem={(item) => {
                let disablePrevsNextOnSinglePage =
                    (item.type === 'next' || item.type === 'previous') &&
                    isSinglePage;

                let disableNextOnLastPage =
                    item.type === 'next' && page === totalPages;

                let disablePrevsOnFirstPage =
                    item.type === 'previous' && page === 1;
                return (
                    <PaginationItem
                        components={{
                            previous: ArrowLeftIcon,
                            next: ArrowRightIcon,
                        }}
                        {...item}
                        disabled={
                            disablePrevsNextOnSinglePage ||
                            disablePrevsOnFirstPage ||
                            disableNextOnLastPage
                        }
                    />
                );
            }}
            sx={{
                '.MuiPaginationItem-page': {
                    color: 'grey.900',
                    ':hover': {
                        bgcolor: (theme) =>
                            theme.palette.common.dynamicLight.hover(
                                grey[100]
                            ),
                        color: 'grey.900',
                    },
                    ':active': {
                        bgcolor: (theme) =>
                            theme.palette.common.dynamicLight.pressed(
                                grey[100]
                            ),
                        color: 'grey.900',
                    },
                    '&.Mui-selected': {
                        bgcolor: 'grey.600',
                        color: 'grey.100',
                    },
                },
                '.MuiPaginationItem-previousNext': {
                    color: 'grey.600',
                    ':disabled': {
                        color: 'common.disabled',
                        opacity: 1,
                    },
                },
            }}
        />
    );
}
