import NoData from './NoData';
import NoResultsFound from './NoResultsFound';

export interface EmptyStateProps {
    type: 'noData' | 'noResults';
    errorCode?: string | null;
}
function EmptyState({type, errorCode}: EmptyStateProps) {
    if (type === 'noData') {
        return <NoData refCode={errorCode!} />;
    } else {
        return <NoResultsFound />;
    }
}

export default EmptyState;
export {NoData, NoResultsFound};
