import {Redirect, Route} from 'react-router-dom';
import {ModuleProvider} from 'common/hooks/moduleRouter';
import YodideskContainer from './containers/YodideskContainer';
import VersionsContainer from './containers/VersionsContainer';
import OverviewContainer from './containers/OverviewContainer';
export default function Yodidesk() {
    return (
        <ModuleProvider>
            <Route exact path="/yodidesk">
                <Redirect to="/yodidesk/overview"></Redirect>
            </Route>
            <Route path="/yodidesk/:name">
                <YodideskContainer>
                    <Route path="/yodidesk/overview">
                        <OverviewContainer />
                    </Route>
                    <Route path="/yodidesk/versions">
                        <VersionsContainer />
                    </Route>
                </YodideskContainer>
            </Route>
        </ModuleProvider>
    );
}
