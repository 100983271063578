import {
    Box,
    MenuItem,
    MenuItemProps,
    alpha,
    Typography,
} from '@mui/material';
import {useRef} from 'react';
import {useDrop, useDrag, DropTargetMonitor, XYCoord} from 'react-dnd';
import {
    DropdownCheckboxUnselectedIcon,
    DropdownCheckboxSelectedIcon,
    RemovePositionIcon,
} from 'common/icons/index';

import clsx from 'clsx';

import _ from 'lodash';
export const DND_ITEM_TYPE = 'column';

export type ColumnSelectionProps = {
    hiddenColumns: string[];
    column: any;
    columnIds: string[];
    moveColumn: (
        dragIndex: number,
        hoverIndex: number,
        columnIds: string[]
    ) => void;
    isDisabled: boolean;
} & MenuItemProps;

export default function ColumnSelection({
    isDisabled,
    hiddenColumns,
    column,
    columnIds,
    moveColumn,
    ...rest
}: ColumnSelectionProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [{handlerId}, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: any, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = columnIds.findIndex(
                (item) => item === column.id
            ) as number;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY =
                (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveColumn(dragIndex, hoverIndex, columnIds);

            item.index = hoverIndex;
        },
    });

    const [{isDragging}, drag] = useDrag(() => ({
        type: DND_ITEM_TYPE,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    if (!isDisabled) {
        drag(drop(ref));
    }

    const Seperator = () => {
        return (
            <Box
                sx={{
                    border: (theme) =>
                        `1px solid ${theme.palette.grey[300]}`,
                }}
            />
        );
    };

    let isVisible =
        _.findIndex(hiddenColumns, (id: any) => id === column.id) === -1;

    const leftElement = isVisible ? (
        <DropdownCheckboxSelectedIcon
            sx={{
                color: 'primary.main',
                margin: '0px 8px 0 12px',
            }}
        />
    ) : (
        <DropdownCheckboxUnselectedIcon
            sx={{
                color: 'grey.600',
                margin: '0px 8px 0 12px',
            }}
        />
    );

    const middleElement = <Typography>{column.Header}</Typography>;

    const rightElement = (
        <RemovePositionIcon
            sx={{
                color: 'grey.600',
            }}
        />
    );

    return (
        <Box key={column.id} ref={ref} data-handler-id={handlerId}>
            <MenuItem
                className={clsx({
                    isSelected: isVisible,
                    isDisabled: isDisabled,
                })}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    height: '40px',
                    margin: '4px 0px',
                    backgroundColor: (theme) => theme.palette.grey[100],
                    '&:hover': {
                        bgcolor: 'common.light.hover',
                    },
                    '&:active': {
                        bgcolor: 'common.light.pressed',
                    },
                    boxShadow: (theme: any) =>
                        isDragging
                            ? `0px 2px 6px ${alpha(
                                  theme.palette.grey[800],
                                  0.24
                              )}`
                            : 'unset',

                    '&.isSelected': {
                        bgcolor: 'common.brandLight.pressed',

                        '&:hover': {
                            bgcolor: 'common.brandLight.hover',
                        },
                        '&:active': {
                            bgcolor: 'common.brandLight.pressed',
                        },
                    },
                }}
                disabled={isDisabled}
                {...rest}
            >
                <Box
                    key={column.id}
                    sx={{
                        width: '264px',
                        display: 'flex',
                        alignItems: 'center',
                        color: (theme) => theme.palette.grey[900],
                    }}
                >
                    {leftElement}
                    {middleElement}
                </Box>
                {!isDisabled && rightElement}
            </MenuItem>
            <Seperator />
        </Box>
    );
}
