import config from '../../config';

const {SERVICE} = config;

const service = SERVICE.infrastructure;

export const fetchEntityNames = () => {
    return 'EntityFilter/search-input/entities-names';
};

export const fetchFullEntityInfo = () => {
    return 'EntityFilter/search-input/entities-full-info';
};

export const fetchPredicates = () => {
    return `EntityFilter/search-input/search-predicates`;
};

export const fetchCombinators = () => {
    return `EntityFilter/search-input/combinators`;
};

export const fetchValuesInfo = () => {
    return `EntityFilterValues/search-input/entities/attributes/values-info`;
};

export const fetchValues = (
    entityName: string,
    attributeName: string,
    org: number
) => {
    return `EntityFilterValues/search-input/orgs/${org}/entity/${entityName}/attributes/${attributeName}/values`;
};

export const filterBaseApi = service;
