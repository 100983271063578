import {DropdownMultiple} from 'common/components/Dropdown';
import {useCallback} from 'react';

interface DropdownEntityNameProps {
    selectedLevelNamesOptions?: string[];
    selectedLevelsItems?: string[];
    onChangeSelectedItems: (values: string[]) => void;
    disabled?: boolean;
}
export default function DropdownEntityName({
    selectedLevelNamesOptions = [],
    selectedLevelsItems = [],
    onChangeSelectedItems,
    disabled,
}: DropdownEntityNameProps) {
    const onChange = useCallback(
        (values) => {
            onChangeSelectedItems(values);
        },
        [onChangeSelectedItems]
    );
    return (
        <DropdownMultiple<string>
            supportDynamicHeight
            virtualized
            autoExpand
            variant="outlined"
            disabled={disabled}
            formatLabel={(val) => val}
            options={selectedLevelNamesOptions}
            value={selectedLevelsItems}
            placeholder="Select value"
            searchPlaceholder="Search"
            onChange={onChange}
            clearable={true}
        />
    );
}
