import {
    alpha,
    Box,
    ButtonBase,
    SxProps,
    Tooltip,
    useTheme,
} from '@mui/material';
import {ComponentProps, ReactElement, useMemo, useState} from 'react';
interface SubMenuHeaderButtonProps {
    ariaLabel: string;
    onClick: ComponentProps<'button'>['onClick'];
    children: ReactElement;
    sx: SxProps;
    isPressed?: boolean;
    tooltip: string;
}

function SubMenuHeaderButton({
    ariaLabel,
    onClick,
    children,
    sx,
    isPressed,
    tooltip,
}: SubMenuHeaderButtonProps) {
    const [hover, setHover] = useState(false);
    const theme = useTheme();
    const bgColor = useMemo(() => {
        if (hover) {
            if (isPressed) {
                return theme.palette.common.dynamicBrandDark.hover(
                    alpha(theme.palette.primary.main, 0.32)
                );
            } else {
                return theme.palette.common.dynamicBrandLight.hover(
                    alpha(theme.palette.primary.light, 0.32)
                );
            }
        }
        return 'transparent';
    }, [hover, isPressed, theme]);
    return (
        <Tooltip title={tooltip} arrow placement="top" enterDelay={700}>
            <ButtonBase
                aria-pressed={isPressed}
                aria-label={ariaLabel}
                onClick={onClick}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                sx={{
                    color: 'primary.main',
                    width: '40px',
                    height: '40px',
                    ...sx,
                }}
            >
                <Box
                    sx={{
                        backgroundColor: bgColor,
                        width: '32px',
                        height: '32px',
                        borderRadius: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {children}
                </Box>
            </ButtonBase>
        </Tooltip>
    );
}

export default SubMenuHeaderButton;
