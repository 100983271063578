import {Box, Typography, IconButton, Divider} from '@mui/material';
import {ExportIcon, MenuCloseIcon} from 'common/icons/index';
import _ from 'lodash';

export interface ExportMobilePopupProps {
    onClose: () => void;
    onClick: (value: 'pdf' | 'csv' | 'xls') => void;
    selections: {
        label: string;
        icon: any;
        value: 'pdf' | 'csv' | 'xls';
    }[];
}

export default function ExportMobilePopup({
    onClose,
    onClick,
    selections,
}: ExportMobilePopupProps) {
    const renderSelections = () => {
        return _.map(selections, (item) => {
            return (
                <Box
                    sx={{
                        height: '40px',
                        display: 'flex',
                        flexDiretction: 'row',
                        alignItems: 'center',
                        marginBottom: (theme) => theme.spacing(1),
                    }}
                    onClick={() => {
                        onClick(item.value);
                    }}
                >
                    <Box
                        sx={{
                            margin: (theme) =>
                                `0px ${theme.spacing(
                                    1
                                )} 0px ${theme.spacing(2)}`,
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {item.icon}
                    </Box>
                    <Typography variant="button">{item.label}</Typography>
                </Box>
            );
        });
    };

    const Header = () => {
        return (
            <Box
                className="filter-area-mobile-header"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        width: 'calc(100% - 32px)',
                        margin: '0px auto',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <ExportIcon />
                    <Typography variant="subtitle" mx={1}>
                        Export data
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        size="small"
                        sx={{
                            my: 1,
                            mr: 1,
                            ml: 'auto',
                        }}
                    >
                        <MenuCloseIcon />
                    </IconButton>
                </Box>
            </Box>
        );
    };

    const Body = () => {
        return (
            <Box
                id="popup-card-test"
                sx={{
                    height: '136px',
                    marginTop: (theme) => theme.spacing(3),
                }}
            >
                {renderSelections()}
            </Box>
        );
    };

    return (
        <Box
            id="table-mobile-options-container"
            sx={{
                position: 'absolute',
                zIndex: 1000,
                top: 0,
                left: 0,
                m: 0,
                width: '100%',
                height: '100%',
                bgcolor: 'grey.200',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                }}
            >
                <Header />
                <Divider
                    sx={{width: 'calc(100% - 32px)', margin: '0 auto'}}
                />
                <Body />
            </Box>
        </Box>
    );
}
