import {Box, alpha, Slide} from '@mui/material';
import {grey} from 'common/colors';
import {ReactNode, useRef, useState} from 'react';
import SubMenuHeader from './SubMenuHeader';

export interface MainMenuDesktopSubMenu {
    isOpen: boolean;
    onClose: () => void;
    onExtensionChange: (extended: boolean) => void;
    extended: boolean;
    header?: ReactNode;
    children?: ReactNode;
}

export default function SubMenuPortal({
    isOpen,
    onClose,
    extended,
    header,
    children,
    onExtensionChange,
}: MainMenuDesktopSubMenu) {
    const offsetHeight = 40;
    const top = `${offsetHeight / 2}px`;
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [pinned, setPinned] = useState(false);
    return (
        <Box
            position="absolute"
            className="sub-menu-wrap"
            ref={containerRef}
            sx={{
                overflow: 'hidden',
                top: 0,
                left: '0px',
                bottom: 0,
                width: '100%',
                height: '100%',
            }}
        >
            <Slide
                direction="right"
                container={containerRef?.current}
                in={isOpen}
            >
                <Box
                    className="sidenav-expand-area"
                    position="absolute"
                    width={extended ? 400 : 208}
                    top={top}
                    bottom={offsetHeight / 2}
                    left={78}
                    sx={{
                        borderRadius: '0px 8px 8px 8px',
                        bgcolor: 'grey.100',
                        boxShadow: `0px 8px 16px ${alpha(
                            grey[800],
                            0.04
                        )}`,
                    }}
                >
                    <Box className="sidenav-expand-inner" height="100%">
                        <SubMenuHeader
                            onExtensionChange={onExtensionChange}
                            isExtended={extended}
                            onClose={onClose}
                            onPinChange={(pinned) => {
                                setPinned(pinned);
                            }}
                            isPinned={pinned}
                        >
                            {header}
                        </SubMenuHeader>
                        {children}
                    </Box>
                </Box>
            </Slide>
        </Box>
    );
}
