import getRequest, {postRequest} from '../lib/request';
import config from '../../config';
import {QueryArgument} from 'modules/admin/helpers/filterQuery';

const {SERVICE} = config;

const service = SERVICE.infrastructure;

type UnknownObject = {[key: string]: string};

export const fetchHierarchy = async (
    params: {filteringQuery: QueryArgument[]},
    orgId: number
) => {
    const url = `${service}Hierarchies/orgs/${orgId}/facilities/buildings/floors/zones`;
    return await postRequest(url, {
        filterOperation:
            params.filteringQuery.length !== 0
                ? 'APPLY_FILTER'
                : 'NO_FILTER',
        searchOps: params.filteringQuery,
    });
};

const fetchSingleHierarchyLevel =
    (type: 'buildings' | 'floors' | 'zones') =>
    async (
        params: {
            page?: number;
            limit?: number;
            filteringQuery: QueryArgument[];
        },
        orgId: number
    ) => {
        const strParams = new URLSearchParams({
            page: String(params.page ?? ''),
            size: String(params.limit ?? ''),
        }).toString();
        return await postRequest(
            `${service}Orgs/${orgId}/${type}?${strParams}`,
            {
                filterOperation:
                    params.filteringQuery.length !== 0
                        ? 'APPLY_FILTER'
                        : 'NO_FILTER',
                searchOps: params.filteringQuery,
            }
        );
    };

export const fetchBuilding = fetchSingleHierarchyLevel('buildings');
export const fetchFloor = fetchSingleHierarchyLevel('floors');
export const fetchZone = fetchSingleHierarchyLevel('zones');

export const fetchOrganization = async (params: UnknownObject = {}) => {
    const strParams = new URLSearchParams({
        ...params,
    }).toString();
    const url = `${service}Orgs?${strParams}`;
    return await getRequest(url);
};
