import AppContainer from 'modules/app/containers/AppContainer';
import {useParams} from 'react-router-dom';
import {YodideskSectionName} from 'modules/yodidesk/config/sections';
import {useDispatch, useModuleRouter} from 'common/hooks';
import sections, {YodisenseSection} from '../config/section';
import {useEffect} from 'react';
import {setTitle} from 'common/reducers/app.slice';
import {useMediaQuery, useTheme} from '@mui/material';
import {MainMenuMobile} from 'common/components/MainMenu';
import YodisenseSideNav from '../components/SideNavigation/YodisenseSideNav';

export interface YodisenseContainerProps {
    children?: React.ReactNode | React.ReactNode[];
}

export default function YodisenseContainer({
    children,
}: YodisenseContainerProps) {
    const {name} = useParams<{name: YodideskSectionName}>();
    const dispatch = useDispatch();

    const {transitionTo} = useModuleRouter();
    const handleChangeTab = (tab: string) => {
        transitionTo(tab);
    };
    const getSectionLabel = (name: string): string => {
        const section = sections.find(
            (item: YodisenseSection) => item.name === name
        );
        if (section) {
            return section.label;
        } else {
            return '';
        }
    };
    useEffect(() => {
        return () => {
            dispatch(setTitle(''));
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(setTitle(getSectionLabel(name)));
    }, [name, dispatch]);
    const {
        breakpoints: {down},
    } = useTheme();
    const isMobile = useMediaQuery(down('mobile'));
    return (
        <AppContainer
            mobileMenu={
                <MainMenuMobile
                    options={sections.map((section) => ({
                        ...section,
                        value: name,
                    }))}
                    value={name}
                    onChange={handleChangeTab}
                />
            }
        >
            {!isMobile && (
                <YodisenseSideNav
                    defaultValue={name}
                    onChangeTab={handleChangeTab}
                />
            )}
            {children}
        </AppContainer>
    );
}
