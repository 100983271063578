import {Box, Typography} from '@mui/material';
import {NoDataIcon} from 'common/icons/index';

export default function NoData({refCode}: {refCode: string}) {
    return (
        <Box
            data-testid="no-data"
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <NoDataIcon
                sx={{
                    height: '80px',
                    width: '80px',
                    color: 'common.brandColor.300',
                    marginBottom: (theme) => theme.spacing(2),
                }}
                viewBox="0 0 80 80"
                fill="none"
            />
            <Typography
                variant="body1"
                sx={{marginBottom: (theme) => theme.spacing(1)}}
            >
                No results found
            </Typography>
            <Typography
                variant="overline"
                maxWidth="184px"
                color="grey.900"
                textAlign="center"
            >
                {`All entries will be displayed on the page later (Ref.code:
                ${refCode})`}
            </Typography>
        </Box>
    );
}
