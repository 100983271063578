import React, {ReactElement, createContext, useContext} from 'react';
import {useRouteMatch, useHistory, Route} from 'react-router-dom';

export const ModuleContext = createContext({
    transitionTo: (item: string) => {},
});

export interface ModuleProviderProps {
    children: ReactElement | ReactElement[];
}
export function ModuleProvider({children}: ModuleProviderProps) {
    const history = useHistory();
    let {path} = useRouteMatch();

    const transitionTo = (section: string) => {
        history.push(`${path}/${section}`);
    };

    return (
        <ModuleContext.Provider value={{transitionTo}}>
            {children}
        </ModuleContext.Provider>
    );
}

export interface ModuleRouterProps {
    children: ReactElement | ReactElement[];
    path: string;
}
export function ModuleRouter({children, path}: ModuleRouterProps) {
    return (
        <Route path={path}>
            <ModuleProvider>{children}</ModuleProvider>
        </Route>
    );
}

export const useModuleRouter = () => useContext(ModuleContext);
