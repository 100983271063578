import {Business} from '@mui/icons-material';
import {ReactElement} from 'react';

export type YodisenseSectionName = 'overview' | 'demo';
export type YodisenseSection = {
    name: YodisenseSectionName;
    label: string;
    icon: ReactElement;
};

const sections: YodisenseSection[] = [
    {
        name: 'overview',
        label: 'Overview',
        icon: <Business fontSize="small" />,
    },
    {
        name: 'demo',
        label: 'demo',
        icon: <Business fontSize="small" />,
    },
];

export default sections;
