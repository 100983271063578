import {Box, Typography, alpha} from '@mui/material';
import {
    DropdownOpenIcon,
    DropdownCloseIcon,
    FilterIcon,
} from 'common/icons';

export interface FilterTriggerDesktopProps {
    isDesktopFilterExpanded: boolean;
    setDesktopFilterExpanded: (expanded: boolean) => void;
}
export default function FilterTriggerDesktop({
    isDesktopFilterExpanded,
    setDesktopFilterExpanded,
}: FilterTriggerDesktopProps) {
    return (
        <Box
            onClick={() => {
                setDesktopFilterExpanded(!isDesktopFilterExpanded);
            }}
            sx={{
                cursor: 'pointer',
                marginLeft: 'auto',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: (theme) =>
                    alpha(theme.palette.primary.light, 0.32),
                width: '149px',
                height: '56px',
                padding: '8px 8px 8px 16px',
                borderRadius: isDesktopFilterExpanded
                    ? '8px 8px 0 0'
                    : '8px 8px 8px 0px',
            }}
        >
            <FilterIcon sx={{marginx: '5px'}} />
            <Typography variant="subtitle" sx={{margin: '0px 8px'}}>
                Filters
            </Typography>
            <Box
                sx={{
                    width: '32px',
                    height: '32px',
                    borderRadius: '50%',
                    padding: '4px',
                    marginx: '4px',
                    backgroundColor: 'none',
                }}
            >
                {isDesktopFilterExpanded ? (
                    <DropdownOpenIcon sx={{color: 'primary.main'}} />
                ) : (
                    <DropdownCloseIcon />
                )}
            </Box>
        </Box>
    );
}
