import {useTheme, useMediaQuery} from '@mui/material';
import {ReactNode} from 'react';
import AppBarDesktop, {AppBarDesktopProps} from './AppBarDesktop';
import AppBarMobile from './AppBarMobile';
export interface AppBarProps extends AppBarDesktopProps {
    mobileMenu: ReactNode;
}

export default function AppBar(props: AppBarProps) {
    const {
        breakpoints: {up},
    } = useTheme();
    const isMobile = !useMediaQuery(up('desktopSm'));
    return (
        <>
            {isMobile && <AppBarMobile {...props} />}
            {!isMobile && <AppBarDesktop {...props} />}
        </>
    );
}
