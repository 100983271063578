import {Box, SwitchProps, Typography, SxProps} from '@mui/material';
import Switch from './Switch';

export type ToggleProps = {
    containerSx?: SxProps;
    label?: string;
} & Pick<
    SwitchProps,
    'checked' | 'defaultChecked' | 'disabled' | 'onChange'
>;

export default function Toggle({
    containerSx,
    label,
    ...props
}: ToggleProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ...containerSx,
            }}
        >
            <Switch {...props} />
            <Typography variant="body2">{label}</Typography>
        </Box>
    );
}
