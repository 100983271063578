import {Box, Typography, alpha} from '@mui/material';
import {FilterIcon} from 'common/icons';

export interface FilterTriggerMobileProps {
    setMobileFilterExpanded: (expanded: boolean) => void;
    hasIndicator?: boolean;
}

export default function FilterTriggerMobile({
    setMobileFilterExpanded,
    hasIndicator,
}: FilterTriggerMobileProps) {
    return (
        <Box
            onClick={() => setMobileFilterExpanded(true)}
            sx={{
                marginLeft: 'auto',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '56px',
                padding: '8px 16px 8px 12px',
                backgroundColor: (theme) =>
                    alpha(theme.palette.primary.light, 0.32),
                '&:active': {
                    backgroundColor: (theme) =>
                        theme.palette.common.dynamicBrandLight.pressed(
                            alpha(theme.palette.primary.light, 0.32)
                        ),
                },
            }}
        >
            <Typography variant="subtitle" sx={{margin: '0px 4px'}}>
                Filters
            </Typography>
            <Box
                sx={{
                    position: 'relative',
                    width: '40px',
                    height: '40px',
                }}
            >
                <FilterIcon
                    sx={{
                        position: 'absolute',
                        top: 'calc(50% - 12px)',
                        left: 'calc(50% - 12px)',
                        color: (theme) => theme.palette.primary.main,
                    }}
                ></FilterIcon>
                {hasIndicator && (
                    <Box
                        sx={{
                            width: '8px',
                            height: '8px',
                            backgroundColor: (theme) =>
                                theme.palette.secondary.main,
                            borderRadius: '3px',
                            position: 'absolute',
                            top: '10%',
                            right: '10%',
                        }}
                    ></Box>
                )}
            </Box>
        </Box>
    );
}
