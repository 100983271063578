import MainMenuDesktop, {
    MainMenuDesktopTab,
} from 'modules/app/components/AppSideNav';
import SectionIcon from './SectionIcon';
import sectionsConfig from '../config/sections';

export interface YodideskSideNavProps {
    onChange: (tab: string) => void;
    defaultValue: string;
}

export default function YodideskSideNav({
    onChange,
    defaultValue,
}: YodideskSideNavProps) {
    return (
        <MainMenuDesktop
            onChange={onChange}
            defaultValue={defaultValue}
        >
            {sectionsConfig.map(({name, label}) => (
                <MainMenuDesktopTab
                    key={name}
                    value={name}
                    label={label}
                    icon={<SectionIcon name={name} />}
                />
            ))}
        </MainMenuDesktop>
    );
}
