import {Box, Stack} from '@mui/material';
import {AddIcon, DeleteIcon, EditIcon} from 'common/icons';
import {useMemo} from 'react';
import {TreeItemTypes} from '../../InfrastructureTree/InfrastructureTree.types';
import TreeMobilePopupButton from './TreeMobilePopupButton';

export interface TreeMobilePopupContentProps {
    variant: TreeItemTypes | null;
    onAdd?: () => void;
    onEdit: () => void;
    onRemove: () => void;
}
function TreeMobilePopupContent({
    variant,
    onAdd,
    onEdit,
    onRemove,
}: TreeMobilePopupContentProps) {
    const addButton = useMemo(() => {
        let element;
        if (variant === 'buildings') {
            element = (
                <TreeMobilePopupButton
                    onClick={onAdd}
                    text="Add floor"
                    icon={<AddIcon />}
                />
            );
        } else if (variant === 'floors') {
            element = (
                <TreeMobilePopupButton
                    onClick={onAdd}
                    text="Add zone"
                    icon={<AddIcon />}
                />
            );
        }
        return element;
    }, [onAdd, variant]);
    return (
        <Box
            sx={{
                width: 'fit-content',
                borderRadius: '8px',
                height: 'fit-content',
                backgroundColor: 'grey.100',
                boxShadow: `0px 2px 10px rgba(49, 49, 49, 0.24)`,
                py: '8px',
            }}
        >
            <Stack>
                {onAdd && addButton}
                <TreeMobilePopupButton
                    onClick={onEdit}
                    text={`Edit ${variant?.substring(
                        0,
                        variant.length - 1
                    )}`}
                    icon={<EditIcon />}
                />
                <TreeMobilePopupButton
                    onClick={onRemove}
                    text={`Remove ${variant?.substring(
                        0,
                        variant.length - 1
                    )}`}
                    icon={<DeleteIcon />}
                />
            </Stack>
        </Box>
    );
}

export default TreeMobilePopupContent;
