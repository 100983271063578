export const ORIGIN = window.location.origin;
export const ROOT_PARAM = process.env.PUBLIC_URL;
export const BASE_URL = ORIGIN + ROOT_PARAM;
export const ASSETS_URL = `${BASE_URL}/assets`;
export const IMAGES_URL = `${ASSETS_URL}/images`;

const config = {
    BASE_URL,
    ASSETS_URL,
    IMAGES_URL,
    SENTRY_PROFILE_NAME: 'YodiFEM-frontend',
    SENTRY_PROJECT_ID:
        'https://97747633adc74544aa6c858aa583e40f@sentry.yodiwo.com/23',
    SERVICE: {
        infrastructure: 'http://20.126.161.108:8084/',
        userRights: 'http://20.103.184.168:8085/',
    },
    TEST_USER: {
        UserName: 'demo@yodiwo.com',
        Password: 'ArDN3fe!7afs',
    },
    AUTH: {
        authority: 'https://identity.yodiwo.com/',
        client_id: 'Yodiwo.NewDev',
        client_secret: 'e692791f-a774-a76a-7049-857f50b57a93',
        scope: 'profile email openid',
        redirect_uri: `${ORIGIN}/login/signin_callback.html`,
        silent_redirect_uri: `${ORIGIN}/login/silent_renew.html`,
        post_logout_redirect_uri: ORIGIN,

        // Code based auth
        response_type: 'code',
    },
};

export default config;
