import {forwardRef, createContext, useContext, useMemo} from 'react';
import {Box} from '@mui/material';
import DropdownOption from '../ItemRenderers/DropdownOption';
import {OptionRendererProps} from './index';
import {VariableSizeList} from 'react-window';
import {grey} from 'common/colors';

const OuterElementContext = createContext({});
const OuterElementType = forwardRef<HTMLDivElement, any>((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return (
        <Box
            ref={ref}
            {...props}
            {...outerProps}
            sx={{
                '& ul': {margin: 0},
            }}
        />
    );
});

const allOptionsSelected = (
    children: [unknown, unknown, boolean, string, number][]
) => {
    return children.every((item) => !!item[2]);
};

const SELECT_ALL = '__selectAll';

export default forwardRef<any, any>(function ListboxComponent(props, ref) {
    const {
        children,
        showSearchBar,
        selectionVariant,
        selectedOptions,
        searchInput,
        ...other
    } = props;

    const availableOptions = useMemo(
        () => children.map((item: any) => item[1]),
        [children]
    );

    const Row = useMemo(
        () =>
            ({index, style, data, ...rest}: any) => {
                const [
                    props,
                    itemData,
                    selected,
                    selectionVariant,
                    width,
                ]: OptionRendererProps<any> = data[index];

                const {label, value, item} = itemData;

                return (
                    <DropdownOption
                        {...props}
                        onClick={(e) => {
                            if (value !== SELECT_ALL) {
                                return (props as any)?.onClick(e);
                            } else {
                                if (!selected) {
                                    //select only those that are not already selected from the available
                                    other.onChange(availableOptions);
                                } else {
                                    // if all selected, deselect the available ones
                                    other.onChange([]);
                                }
                            }
                        }}
                        style={{...style, minHeight: 'auto'}}
                        label={label ?? itemData}
                        item={item ?? itemData}
                        value={value ?? itemData}
                        selectionVariant={selectionVariant}
                        selected={selected}
                        width={width}
                        sx={
                            value === SELECT_ALL
                                ? {
                                      mb: '4px',
                                      height: '36px',
                                      pb: '8px',
                                      borderBottom: `1px solid ${grey[300]}`,
                                  }
                                : {}
                        }
                    />
                );
            },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [children]
    );
    const itemData = useMemo(() => {
        const itemData = [...children];
        const selectAll = [
            {},
            {label: 'Select all', value: SELECT_ALL},
            allOptionsSelected(children),
            'multiple',
        ];
        if (!searchInput && selectionVariant === 'multiple') {
            itemData.unshift(selectAll);
        }
        return itemData;
    }, [children, searchInput, selectionVariant]);

    return (
        <div
            ref={ref}
            className="out"
            style={{paddingTop: '4px', borderRadius: '0 0 4px 4px'}}
        >
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    style={{
                        overflow: undefined,
                        borderRadius: 'inherit',
                        marginBottom: '4px',
                    }}
                    className="list-virtualized"
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    overscanCount={5}
                    itemCount={
                        selectionVariant === 'multiple' && !searchInput
                            ? children.length + 1
                            : children.length
                    }
                    estimatedItemSize={
                        selectionVariant !== 'unique-with-details'
                            ? 32
                            : 64
                    }
                    itemSize={(index) => {
                        if (selectionVariant !== 'unique-with-details') {
                            if (itemData[index][1].value === SELECT_ALL) {
                                return 40;
                            }
                            return 32;
                        } else {
                            return 64;
                        }
                    }}
                    height={240}
                    width="100%"
                >
                    {Row}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});
