import {Button, Typography} from '@mui/material';
import {ComponentProps, ReactNode} from 'react';

export interface TreeMobilePopupButtonProps {
    icon?: ReactNode;
    text: string;
    onClick: ComponentProps<'button'>['onClick'];
}

function TreeMobilePopupButton({
    icon,
    text,
    onClick,
}: TreeMobilePopupButtonProps) {
    return (
        <Button
            onClick={onClick}
            sx={{
                height: '40px',
                justifyContent: 'flex-start',
                color: 'grey.600',
                px: '16px',
            }}
        >
            {icon}
            <Typography variant="button" color="grey.900" pl="8px">
                {text}
            </Typography>
        </Button>
    );
}

export default TreeMobilePopupButton;
