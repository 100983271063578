import {Box, Skeleton, Stack, Typography} from '@mui/material';
import {Button, DropdownSingle} from 'common/components';
import SearchInput from 'common/components/SearchInput';
import Toggle from 'common/components/Toggle';
import {useSelector} from 'common/hooks';
import {OrganizationOption} from 'modules/admin/components/OrganizationSelector';
import {
    ChangeEvent,
    ComponentProps,
    useCallback,
    useMemo,
    useState,
} from 'react';

export interface YodisenseSubMenuHeaderProps {
    extended?: boolean;
    onSearchChange?: (value: string) => void;
    onComparisonChange?: (
        event: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => void;
    onOptionsPressed?: ComponentProps<'button'>['onClick'];
    hasSearch?: boolean;
}
function YodisenseSubMenuHeader({
    extended,
    onSearchChange = () => {},
    onComparisonChange,
    onOptionsPressed,
    hasSearch,
}: YodisenseSubMenuHeaderProps) {
    const [searchValue, setSearchValue] = useState<string>('');
    const handleSearchChange = useCallback(
        (value: string) => {
            setSearchValue(value);
            onSearchChange(value);
        },
        [onSearchChange]
    );
    const loading = useSelector(
        (state) => state.app.organizations.loading
    );
    const orgs = useSelector((state) => state.app.organizations.data);
    const organizationOptions = useMemo(() => {
        return (
            orgs?.map((org) => {
                return {
                    value: org.id.toString(),
                    Name: org.name,
                    Url: org.url,
                };
            }) ?? []
        );
    }, [orgs]);

    const orgElement = useMemo(() => {
        if (loading) {
            return <Skeleton width="100%" height="40px" />;
        } else if (organizationOptions.length === 1) {
            return (
                <Typography variant="button" height="20px">
                    {organizationOptions[0].Name}
                </Typography>
            );
        } else {
            return (
                <DropdownSingle<OrganizationOption>
                    formatLabel={(org) => org.Name}
                    autoExpand
                    variant="standard"
                    options={organizationOptions}
                    value={organizationOptions[0]}
                    onChange={() => {}}
                />
            );
        }
    }, [loading, organizationOptions]);
    return (
        <Stack px="12px" pt="16px" direction="column" width="100%">
            <Stack
                direction={extended ? 'row' : 'column'}
                bgcolor="grey.100"
                px="12px"
                py="8px"
                rowGap="16px"
                columnGap="40.5px"
                sx={{borderRadius: '8px'}}
                mb="16px"
                justifyContent="center"
                alignItems="center"
            >
                <Button
                    variant="outlined"
                    size="small"
                    buttonSx={{width: extended ? '113px' : '160px'}}
                    onClick={onOptionsPressed}
                >
                    View Options
                </Button>

                {extended && (
                    <Box
                        height="40px"
                        width="1px"
                        sx={{backgroundColor: 'grey.300'}}
                    />
                )}
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Toggle onChange={onComparisonChange} />
                    <Typography color="grey.900" variant="body2">
                        Compare values
                    </Typography>
                </Stack>
            </Stack>

            <Box width="100%">{orgElement}</Box>
            {hasSearch && (
                <SearchInput
                    sx={{mt: '8px'}}
                    clearable={true}
                    value={searchValue}
                    autoExpand
                    placeholder="Search by name"
                    onChange={handleSearchChange}
                />
            )}
        </Stack>
    );
}

export default YodisenseSubMenuHeader;
