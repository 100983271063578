import {styled, Popper, alpha, autocompleteClasses} from '@mui/material';

type StyledPopperProps = {
    showSearchBar: boolean;
    width?: number | string;
};
export default styled(Popper, {
    shouldForwardProp: (prop) => prop !== 'showSearchBar',
})<StyledPopperProps>(({theme, showSearchBar, width}) => ({
    paddingRight: '4px',
    width: width,
    borderRadius: '4px',
    boxShadow: `0px 2px 4px ${alpha(theme.palette.grey[800], 0.24)}`,
    zIndex: theme.zIndex.modal,
    [`& .${autocompleteClasses.listbox}`]: {
        padding: 0,
        maxHeight: 'inherit',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    [`& .${autocompleteClasses.paper}`]: {
        borderRadius: '4px',
    },
    [`& .${autocompleteClasses.root}`]: {
        borderRadius: '4px',
    },
}));
