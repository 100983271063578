import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'common/hooks';

import {flattenDataTree} from '../lib';

import {
    hierarchyDataKeyMap,
    HierarchyCount,
    HierarchyLevel,
} from '../config/hierarchyKeys';
import {setOrganization} from 'common/reducers/app.slice';
import {UnknownObject} from '../components/DataTreeTable';
import _ from 'lodash';
import {limitOptions} from 'common/components/Pagination';
import {
    fetchHierarchyLevel,
    fetchHierarchyAll,
} from '../reducers/infrastructure.slice';
import {QueryArgument} from '../helpers/filterQuery';

export function useHierarchy() {
    const dispatch = useDispatch();

    const [fetchParameters, setFetchParameters] = useState<{
        hierarchy: Array<HierarchyLevel>;
        filteringQuery: QueryArgument[];
        page: number;
    }>({
        hierarchy: [],
        filteringQuery: [],
        page: 1,
    });
    const [dataTable, setDataTable] = useState<any>(null);
    const [dataTree, setDataTree] = useState<any>(null);
    const [limit, setLimit] = useState<limitOptions>(13);
    const [count, setCount] = useState<HierarchyCount>({});

    useEffect(() => {
        setCount({});
    }, [fetchParameters.hierarchy]);

    const loadingInfrastructure = useSelector(
        (state) => state.infrastructure.loading
    );
    const errorInfrastructure = useSelector(
        (state) => state.infrastructure.error
    );
    const loadingOrganizations = useSelector(
        (state) => state.app.organizations.loading
    );
    const errorOrganizations = useSelector(
        (state) => state.app.organizations.error
    );
    const currentOrganization = useSelector(
        (state) => state.app.selectedOrganization
    );

    const isLoading = useMemo(
        () => loadingInfrastructure || loadingOrganizations,
        [loadingInfrastructure, loadingOrganizations]
    );

    const getDataKey = useCallback(
        (key: keyof typeof hierarchyDataKeyMap) =>
            hierarchyDataKeyMap[key],
        []
    );

    const getHierarchyCount = useCallback(
        (
            data: Array<any>,
            hierarchy: HierarchyLevel[]
        ): HierarchyCount => {
            const result: HierarchyCount = {};
            hierarchy.forEach((level) => {
                const apiKey = getDataKey(level) as keyof typeof data;
                result[level] = data[apiKey].length;
            });
            return result;
        },
        [getDataKey]
    );
    const setHierarchyCount = useCallback(
        (data: Array<any>, hierarchy: HierarchyLevel[]) => {
            setCount(getHierarchyCount(data, hierarchy));
        },
        [setCount, getHierarchyCount]
    );

    const handleSetHierarchy = useCallback(
        (hierarchy: Array<HierarchyLevel>) => {
            setFetchParameters({
                filteringQuery: [],
                hierarchy,
                page: 1,
            });
        },
        []
    );

    const handleSetFilteringQuery = useCallback(
        (filters: QueryArgument[]) => {
            setFetchParameters((state) => ({
                hierarchy: state.hierarchy,
                filteringQuery: filters,
                page: 1,
            }));
        },
        []
    );

    const handleSetPage = useCallback((page: number) => {
        setFetchParameters((state) => ({
            ...state,
            page,
        }));
    }, []);

    useMemo(() => {
        setFetchParameters((state) => ({
            hierarchy: state.hierarchy,
            filteringQuery: [],
            page: 1,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrganization]);

    useEffect(() => {
        const {hierarchy, filteringQuery, page} = fetchParameters;
        setDataTable(null);
        setDataTree(null);
        if (_.isEmpty(hierarchy)) {
            return;
        }

        if (hierarchy.length > 1) {
            dispatch(fetchHierarchyAll({filteringQuery}))
                .unwrap()
                .then((data: any) => {
                    const itemCount = hierarchy.reduce<{
                        [key: string]: number;
                    }>((prev, item) => {
                        prev[item] = 0;
                        return prev;
                    }, {});
                    const result = (
                        flattenDataTree(data) as UnknownObject[]
                    ).filter((item: any) => {
                        if (Object.keys(itemCount).includes(item.type)) {
                            itemCount[
                                item.type as keyof typeof itemCount
                            ] += 1;
                            return true;
                        }
                        return false;
                    });
                    setDataTree(result);
                    setCount(itemCount);
                });
        } else {
            const hierarchyLevel = hierarchy[0];
            dispatch(
                fetchHierarchyLevel({
                    key: hierarchyLevel,
                    page,
                    limit,
                    filteringQuery,
                })
            )
                .unwrap()
                .then((data) => {
                    const itemCount = data.totalItems;
                    const result = data.items;

                    setDataTable(result);
                    setCount({[hierarchy[0]]: itemCount});
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, getDataKey, setHierarchyCount, limit, fetchParameters]);

    return {
        hierarchy: fetchParameters.hierarchy,
        setHierarchy: handleSetHierarchy,
        isLoading,
        count,
        errorOrganizations,
        errorInfrastructure,
        dataTable,
        dataTree,
        setPage: handleSetPage,
        setLimit,
        setFilteringQuery: handleSetFilteringQuery,
    };
}

export function useOrganization() {
    const dispatch = useDispatch();

    const selectedOrganization = useSelector(
        (state) => state.app.selectedOrganization
    );
    const allOrganizations = useSelector(
        (state) => state.app.organizations?.data
    );
    const isLoading = useSelector(
        (state) => state.app.organizations.loading
    );
    const error = useSelector((state) => state.app.organizations.error);
    const handleOrgSelection = (orgId: number) => {
        if (orgId !== selectedOrganization?.id) {
            dispatch(setOrganization(orgId));
        }
    };

    return {
        selectedOrganization,
        allOrganizations,
        handleOrgSelection,
        isLoading,
        error,
    };
}

export {default as useFilters} from './useFilters';
export {default as useClickOutside} from './useClickOutside';
