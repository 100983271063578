import {
    Box,
    Grid,
    IconButton as MuiIconButton,
    Stack,
} from '@mui/material';
import {grey} from 'common/colors';
import {IconButton} from 'common/components';
import {FilterAddIcon, FilterRemoveIcon} from 'common/icons';
import {ReactNode} from 'react';

export interface FieldFilterDesktopBlockProps {
    fieldInput: ReactNode;
    operatorInput: ReactNode;
    filterInput: ReactNode;
    onFilterAdd: () => void;
    onFilterRemove: () => void;
    showRemove?: boolean;
    showAdd?: boolean;
    disabledAdd?: boolean;
}

function FieldFilterDesktopBlock({
    fieldInput,
    operatorInput,
    filterInput,
    showRemove,
    showAdd,
    onFilterAdd,
    onFilterRemove,
    disabledAdd,
}: FieldFilterDesktopBlockProps) {
    return (
        <Stack direction="row" maxWidth="830px">
            <Grid
                className="filteringBlock"
                columnSpacing={2}
                container
                sx={{
                    bgcolor: 'grey.100',
                    py: '14px',
                    borderRadius: '4px',
                    width: '750px',
                    mx: '0',
                }}
            >
                <Grid item>{fieldInput}</Grid>
                <Grid item>{operatorInput}</Grid>
                <Grid item>{filterInput}</Grid>
            </Grid>

            {showRemove && (
                <Box
                    width="40px"
                    height="40px"
                    bgcolor="white"
                    borderRadius="0px 20px 20px 0px"
                    display="flex"
                    sx={{
                        position: 'relative',
                        left: '-12px',
                        alignSelf: 'center',
                    }}
                >
                    <IconButton
                        aria-label="remove filter"
                        onClick={onFilterRemove}
                    >
                        <FilterRemoveIcon />
                    </IconButton>
                </Box>
            )}
            {showAdd && (
                <MuiIconButton
                    aria-label="add filter"
                    disabled={disabledAdd}
                    onClick={onFilterAdd}
                    sx={{
                        width: '40px',
                        height: '40px',
                        alignSelf: 'center',
                        backgroundColor: 'grey.100',
                        border: `1px solid ${grey[800]}`,
                        borderRadius: '4px',
                        position: 'relative',
                        left: showRemove ? '-4px' : '16px',
                    }}
                >
                    <FilterAddIcon />
                </MuiIconButton>
            )}
        </Stack>
    );
}

export default FieldFilterDesktopBlock;
