import AppLayout from './../../app/components/AppLayout';
import AppContainer from 'modules/app/containers/AppContainer';

export default function YodiSafe() {
    return (
        <AppContainer>
            <AppLayout sideNavWidth={0}>YodiSafe</AppLayout>
        </AppContainer>
    );
}
