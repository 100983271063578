import {hexToRgb} from '@mui/material';
import {normal} from 'color-blend';
import {RGBA} from 'color-blend/dist/types';

export const getMaskedColor = (baseColor: string, mask: string) => {
    const baseColorObj = rgbStringToObject(baseColor);
    const maskObj = rgbStringToObject(mask);
    if (!baseColorObj || !maskObj) {
        return baseColor;
    }
    const result = normal(baseColorObj, maskObj);
    return rgbObjectToString(result);
};

const rgbStringToObject = (str: string) => {
    let r, g, b, a;
    if (str.includes('#')) {
        str = hexToRgb(str);
    }
    if (str.includes('rgba')) {
        [r, g, b, a] = str
            .split('(')[1]
            .split(')')[0]
            .split(',')
            .map((n) => Number.parseFloat(n));
    } else if (str.includes('rgb')) {
        [r, g, b] = str
            .split('(')[1]
            .split(')')[0]
            .split(',')
            .map((n) => Number.parseInt(n));
        a = 1;
    } else {
        return null;
    }
    return {r, g, b, a};
};

const rgbObjectToString = (c: RGBA) => `rgba(${c.r},${c.g},${c.b},${c.a})`;
