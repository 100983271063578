import {
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
    Box,
    alpha,
    SxProps,
} from '@mui/material';
import {useMemo} from 'react';
import {grey} from '../../colors';
import CircularProgressIndicator from '../Loaders/CircularProgressIndicator';

export type ButtonProps = Omit<MuiButtonProps, 'size' | 'variant'> & {
    size: 'small' | 'large';
    isLoading?: boolean;
    variant: 'contained' | 'outlined' | 'text';
    buttonSx?: SxProps;
};

export default function Button(props: ButtonProps) {
    const isContained = props.variant === 'contained';
    const isText = props.variant === 'text';
    const isLarge = props.size === 'large';
    const buttonProps = Object.assign({}, props);
    delete buttonProps.isLoading;
    delete buttonProps.buttonSx;
    delete buttonProps.onClick;
    const bgColor = useMemo(() => {
        if (props.disabled) {
            return 'none';
        } else if (isContained) {
            return grey[800];
        } else {
            return grey[100];
        }
    }, [isContained, props.disabled]);
    const height = useMemo(() => {
        if (isText) {
            return 'fit-content';
        } else if (isLarge) {
            return '40px';
        } else {
            return '32px';
        }
    }, [isLarge, isText]);
    return (
        <Box
            sx={{
                width: 'fit-content',
                height: 'fit-content',
                ...props.sx,
            }}
        >
            <MuiButton
                {...buttonProps}
                onClick={props.isLoading ? undefined : props.onClick}
                disableElevation
                disabled={props.disabled || (isText && props.isLoading)}
                disableRipple
                sx={{
                    borderRadius: '4px',
                    outline:
                        isContained || isText || props.disabled
                            ? 'none'
                            : `1px solid ${grey[800]}`,
                    outlineOffset: '-1px',
                    width: '100%',
                    height,
                    backgroundColor: bgColor,
                    borderColor: alpha('#fff', 0),
                    p: isLarge ? '12px 24px' : '8px 16px',
                    '&.MuiButton-contained': {
                        color: grey[100],
                        '&:hover': {
                            bgcolor: (theme) =>
                                theme.palette.common.dynamicDark.hover(
                                    bgColor
                                ),
                        },
                        '&:active': {
                            bgcolor: (theme) =>
                                theme.palette.common.dynamicDark.focus(
                                    bgColor
                                ),
                        },
                        '&.isPressed': {
                            bgcolor: (theme) =>
                                theme.palette.common.dynamicDark.pressed(
                                    bgColor
                                ),
                        },
                        '&:disabled': {
                            bgcolor: grey[300],
                            color: 'common.disabled',
                        },
                    },
                    '&.MuiButton-outlined': {
                        color: grey[900],
                        '&:hover': {
                            bgcolor: (theme) =>
                                theme.palette.common.dynamicLight.hover(
                                    bgColor
                                ),
                            borderColor: 'common.light.hover',
                        },
                        '&:active': {
                            bgcolor: (theme) =>
                                theme.palette.common.dynamicLight.focus(
                                    bgColor
                                ),
                            borderColor: 'common.light.pressed',
                        },
                        '&.isPressed': {
                            bgcolor: (theme) =>
                                theme.palette.common.dynamicLight.pressed(
                                    bgColor
                                ),
                        },
                        '&:disabled': {
                            bgcolor: grey[100],
                            borderColor: 'common.disabled',
                            color: 'common.disabled',
                        },
                        '&:focus': {
                            bgcolor: (theme) =>
                                theme.palette.common.dynamicDark.focus(
                                    bgColor
                                ),
                        },
                    },
                    '&.MuiButton-text': {
                        backgroundColor: 'transparent',
                        padding: 0,
                        color: 'primary.main',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                        '&:active': {
                            textDecoration: 'underline',
                        },
                        '&:disabled': {
                            color: 'common.disabled',
                        },
                    },
                    ...props.buttonSx,
                }}
            >
                {props.isLoading && !isText && (
                    <Box position="absolute" height="20px">
                        <CircularProgressIndicator
                            variant={
                                props.variant === 'contained'
                                    ? 'smallLight'
                                    : 'smallDark'
                            }
                        />
                    </Box>
                )}
                <Box sx={{opacity: props.isLoading ? 0 : 1}}>
                    {props.children}
                </Box>
            </MuiButton>
        </Box>
    );
}
