import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch} from './index';

export default function useLocationChange(fn: () => void) {
    const dispatch = useDispatch();
    let location = useLocation();

    useEffect(() => {
        fn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, location]);
}
