import {Box} from '@mui/material';
import Input from 'common/components/Inputs';
import SearchInput from 'common/components/SearchInput';
import {Chip, Selection} from 'common/components';
import {useState} from 'react';

function YodisenseSubMenuBody() {
    const [text, setText] = useState('');
    return (
        <Box>
            <SearchInput onChange={(text) => {}} />
            <Input
                variant="Counter"
                maxLength={50}
                onChange={(text) => {
                    setText(text);
                }}
                value={text}
            />
            <Chip label="chip" onClick={() => {}} onDelete={() => {}} />
            <Selection
                isExpanded={true}
                isExpandable={true}
                isSelected={false}
                matchesSearch={false}
                hasCheckbox={true}
                isActive={false}
                checkboxDisabled={false}
            >
                Level 01
            </Selection>
            <Selection
                isExpanded={true}
                isExpandable={true}
                isSelected={false}
                matchesSearch={false}
                hasCheckbox={true}
                checkboxDisabled={false}
            >
                Level 02
            </Selection>
            <Selection
                hasCheckbox={true}
                isExpanded={true}
                isExpandable={true}
                isSelected={true}
                matchesSearch={false}
                checkboxDisabled={false}
            >
                Very very long Level 03
            </Selection>
        </Box>
    );
}

export default YodisenseSubMenuBody;
