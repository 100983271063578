import {Typography} from '@mui/material';
import React, {ErrorInfo, ReactNode} from 'react';
interface Props {
    children: ReactNode;
}
interface State {
    hasError: boolean;
}
class ErrorBoundary extends React.Component<Props, State> {
    public state: State = {
        hasError: false,
    };
    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }
    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error: ', error, errorInfo);
    }
    public render() {
        if (this.state.hasError) {
            // Fallback UI
            return (
                <Typography
                    variant="h4"
                    sx={{
                        p: 3,
                    }}
                >
                    Something went wrong.
                </Typography>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
