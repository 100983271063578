import {Stack, Typography} from '@mui/material';
import {forwardRef} from 'react';
import Input, {InputProps} from '../';

export interface LabelledInputProps extends InputProps {
    label: string;
    optional?: boolean;
}

const LabelledInput = forwardRef<HTMLInputElement, LabelledInputProps>(
    (props, ref) => {
        return (
            <Stack spacing="4px">
                <label htmlFor="custom-input">
                    <Stack
                        direction="row"
                        spacing="2px"
                        whiteSpace="nowrap"
                    >
                        <Typography color="grey.600" variant="overline">
                            {`${props.label}`}
                        </Typography>
                        {props.optional && (
                            <Typography
                                color="grey.500"
                                variant="menu"
                                height="13px"
                                alignSelf="flex-end"
                            >
                                (optional)
                            </Typography>
                        )}
                    </Stack>
                </label>
                <Input
                    {...props}
                    ref={ref}
                    inputProps={{id: 'custom-input'}}
                />
            </Stack>
        );
    }
);
export default LabelledInput;
