import {z} from 'zod';

const hierarchyLevelEssentialSchema = {
    id: z.number().positive(),
    iRef: z.string(),
    name: z.string().nonempty(),
    created: z.string().nullable(),
    description: z.string().nullable(),
};

const organizationSchema = z.object({
    ...hierarchyLevelEssentialSchema,
    address: z.string().nullable(),
    url: z.string().url().nullable(),
});

const deploymentSchema = z.object({
    ...hierarchyLevelEssentialSchema,
});

const buildingSchema = z.object({
    ...hierarchyLevelEssentialSchema,
    timezone: z.string().nullable(),
    workingHours: z.string().nullable(),
    lat: z.number().nullable(),
    lon: z.number().nullable(),
});

const floorSchema = z.object({
    ...hierarchyLevelEssentialSchema,
    area: z.number().nullable(),
});

const zoneSchema = z.object({
    ...hierarchyLevelEssentialSchema,
});

const pagedSchema = z.object({
    pageSize: z.number().nonnegative(),
    currentPage: z.number().nonnegative(),
    totalItems: z.number().nonnegative(),
    totalPages: z.number().nonnegative(),
});

export type BuildingType = z.infer<typeof buildingSchema>;
export type FloorType = z.infer<typeof floorSchema>;
export type ZoneType = z.infer<typeof zoneSchema>;
export type BuildingDTType = z.infer<typeof buildingValidator>;
export type FloorDTType = z.infer<typeof floorValidator>;
export type ZoneDTType = z.infer<typeof zoneValidator>;

export const fetchHierarchyValidator = z.object({
    organization: organizationSchema,
    deployments: z
        .record(
            z.object({
                deployment: deploymentSchema,
                buildings: z
                    .record(
                        z.object({
                            building: buildingSchema,
                            floors: z
                                .record(
                                    z.object({
                                        floor: floorSchema,
                                        zones: z
                                            .record(
                                                z.object({
                                                    zone: zoneSchema,
                                                })
                                            )
                                            .nullable(),
                                    })
                                )
                                .nullable(),
                        })
                    )
                    .nullable(),
            })
        )
        .nullable(),
});

export const buildingValidator = pagedSchema.merge(
    z.object({
        items: buildingSchema.array(),
    })
);

export const floorValidator = pagedSchema.merge(
    z.object({
        items: floorSchema.array(),
    })
);

export const zoneValidator = pagedSchema.merge(
    z.object({
        items: floorSchema.array(),
    })
);
