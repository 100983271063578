import {Box, Typography} from '@mui/material';
import IconButton from 'common/components/IconButton';
import Breadcrumbs from 'common/components/Breadcrumbs';
import {CloseLargeIcon} from '../../../../../common/icons';

type DrawerHeaderProps = {
    title?: string;
    subtitleHierarchy?: string[];
    onClose: () => void;
};

export default function DrawerHeader({
    title = '',
    subtitleHierarchy = [],
    onClose,
}: DrawerHeaderProps) {
    return (
        <Box borderBottom="1px solid #EEEEEE">
            <Box display="flex" alignItems="center">
                <Typography variant="subtitle">{title}</Typography>
                <IconButton
                    variant="dark"
                    onClick={onClose}
                    sx={{ml: 'auto', mr: 0}}
                >
                    <CloseLargeIcon />
                </IconButton>
            </Box>
            <Box my={1}>
                <Breadcrumbs
                    items={subtitleHierarchy.map((e) => {
                        return {label: e, value: e};
                    })}
                    variant="default"
                />
            </Box>
        </Box>
    );
}
