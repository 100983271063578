import {
    MainMenuDesktop,
    MainMenuDesktopProps,
} from 'common/components/MainMenu';
import React, {ReactNode, useCallback} from 'react';
import {useMediaQuery, useTheme} from '@mui/material';

import {setSideNavWidth} from 'common/reducers/app.slice';
import {useDispatch} from 'react-redux';
import {useSelector} from 'common/hooks';
export interface SubmenuInstance {
    body: ReactNode;
    header: ReactNode;
}

export default function AppSideNav(props: MainMenuDesktopProps) {
    const {onChange} = props;
    const dispatch = useDispatch();
    const handleChange = useCallback(
        (newValue: string) => {
            React.Children.forEach(props.children, (element) => {
                if (!React.isValidElement(element)) {
                    return;
                }
            });
            dispatch(setSideNavWidth('closed'));
            if (onChange) {
                onChange(newValue);
            }
        },
        [dispatch, props, onChange]
    );
    const {
        breakpoints: {down},
    } = useTheme();
    const isLessThanMediumDesktop = useMediaQuery(down('desktopMd'));
    const theme = useTheme();
    const spacing = isLessThanMediumDesktop
        ? theme.spacing(2)
        : theme.spacing(3);
    const sideNavState = useSelector(
        (state) => state.app.layout.sideNavWidth
    );
    return (
        <MainMenuDesktop
            {...props}
            sx={{
                bottom: spacing,
                left: spacing,
                position: 'absolute',
                top: theme.spacing(3),
            }}
            onChange={handleChange}
            onExpansionChange={(isExpanded) => {
                if (isExpanded) {
                    dispatch(setSideNavWidth('opened'));
                } else {
                    dispatch(setSideNavWidth('closed'));
                }
            }}
            onExtensionChange={(isExtended) => {
                if (isExtended) {
                    dispatch(setSideNavWidth('extended'));
                } else {
                    dispatch(setSideNavWidth('opened'));
                }
            }}
            expanded={
                sideNavState === 'opened' || sideNavState === 'extended'
            }
            extended={sideNavState === 'extended'}
        />
    );
}

export {
    MainMenuDesktopTab,
    MainMenuDesktopSeparator,
} from 'common/components/MainMenu/MainMenuDesktop';
