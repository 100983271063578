import {useMediaQuery, useTheme} from '@mui/material';
import {Module, ModuleName} from 'common/constants/modules';
import ProductMenuDesktop from './ProductMenuDesktop';
import ProductMenuMobile from './ProductMenuMobile';
import {SvgIconComponent} from '@mui/icons-material';
import {useMemo} from 'react';

interface ProductMenuProps {
    isOpen: boolean;
    drawerWidth: string | number;
    products: Module[];
    children?: React.ReactNode;
    activeProductIcon?: SvgIconComponent;
    activeProductName: ModuleName;
    activeProductLabel?: string;
    onBackdropClick: () => void;
    onProductClick: (productName: ModuleName) => void;
    onClickSettings: () => void;
    adminSelected: boolean;
}

export default function ProductMenu(props: ProductMenuProps) {
    // Information about disabled products should be available here when the relevant api is integrated.
    // This is needed to place the promotional modules at the bottom.
    const sortedProducts = useMemo(() => {
        const activeProducts: Module[] = [];
        const disabledProducts: Module[] = [];
        props.products.forEach((product) => {
            if (product.name === 'yodispace') {
                disabledProducts.push(product);
            } else {
                activeProducts.push(product);
            }
        });
        return [...activeProducts, ...disabledProducts];
    }, [props.products]);
    const {
        breakpoints: {up},
    } = useTheme();
    const isMobile = !useMediaQuery(up('desktopSm'));
    return (
        <>
            {!isMobile && (
                <ProductMenuDesktop {...props} products={sortedProducts} />
            )}
            {isMobile && (
                <ProductMenuMobile {...props} products={sortedProducts} />
            )}
        </>
    );
}
