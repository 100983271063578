import {ClearSharp} from '@mui/icons-material';
import {Chip as MuiChip, useTheme} from '@mui/material';
import {grey} from 'common/colors';

export interface ChipProps {
    label: string;
    onClick?: () => void;
    onDelete?: () => void;
}

function Chip({label, onClick, onDelete}: ChipProps) {
    const {typography} = useTheme();
    return (
        <MuiChip
            aria-label={label}
            onClick={onClick}
            label={label}
            onDelete={onDelete}
            deleteIcon={
                <ClearSharp sx={{height: '17px', width: '17px'}} />
            }
            sx={{
                '&:hover': {
                    backgroundColor: (theme) =>
                        onClick
                            ? theme.palette.common.dynamicLight.hover(
                                  grey[300]
                              )
                            : grey[300],
                },
                '&:active': {
                    backgroundColor: (theme) =>
                        onClick
                            ? theme.palette.common.dynamicLight.pressed(
                                  grey[300]
                              )
                            : grey[300],
                    boxShadow: 'none',
                },
                backgroundColor: grey[300],
                height: '24px',
                borderRadius: '24px',
                ...typography.overline,
            }}
        />
    );
}

export default Chip;
