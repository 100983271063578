import {alpha, Box} from '@mui/material';
import {grey} from 'common/colors';
import {ReactNode} from 'react';
import {CalendarContainer} from 'react-datepicker';

export default function DatePickerContainer({
    className,
    children,
}: {
    className: string;
    children: ReactNode[];
}) {
    return (
        <Box
            sx={{
                background: '#fff',
                boxShadow: `0px 8px 24px ${alpha(grey[800], 0.08)}`,
                borderRadius: '4px',
            }}
        >
            <CalendarContainer className={className}>
                <div style={{position: 'relative'}}>{children}</div>
            </CalendarContainer>
        </Box>
    );
}
