import {MenuIcon, CloseLargeIcon} from 'common/icons';
import {Grid, styled} from '@mui/material';
import {IconButton} from 'common/components';
import {BASE_URL} from '../../../../../config';
import {SvgIconComponent} from '@mui/icons-material';

export interface AppBarMobileProps {
    activeProductLabel?: string;
    activeProductIcon?: SvgIconComponent;
    menuIsOpen?: boolean;
    adminSelected: boolean;
    onClickMenuButton: () => void;
}

export default function AppBarLogoMobile(props: AppBarMobileProps) {
    const logoSrc = `${BASE_URL}/logo.svg`;
    const LogoImage = styled('img')({
        display: 'block',
        width: '160px',
        height: '40px',
        margin: 'auto',
    });

    return props.menuIsOpen ? (
        <Grid
            container
            height={56}
            position="relative"
            bgcolor="grey.200"
            alignItems="center"
            py={1}
            px={2}
            sx={{zIndex: (theme) => theme.zIndex.appBar}}
        >
            <Grid item>
                <IconButton
                    sx={{
                        p: 0,
                    }}
                    onClick={props.onClickMenuButton}
                >
                    <CloseLargeIcon fontSize="medium" />
                </IconButton>
            </Grid>
            <Grid m="auto">
                <LogoImage
                    onClick={props.onClickMenuButton}
                    alt="YodiFEM logo"
                    src={logoSrc}
                />
            </Grid>
        </Grid>
    ) : (
        <Grid
            container
            height={56}
            bgcolor="grey.100"
            py={1}
            px={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{zIndex: (theme) => theme.zIndex.appBar}}
        >
            <Grid item>
                <IconButton
                    sx={{
                        p: 0,
                    }}
                    onClick={props.onClickMenuButton}
                >
                    <MenuIcon fontSize="medium" />
                </IconButton>
            </Grid>
        </Grid>
    );
}
