import {Drawer as MuiDrawer, Box, alpha} from '@mui/material';
import {useState} from 'react';
import DrawerHeader from './DrawerHeader';
import Button from 'common/components/Button';

export type DrawerProps = {
    children?: React.ReactNode;
    isDrawerOpen: boolean;
    title?: string;
    subtitleHierarchy?: string[];
    submitButtonLabel?: string;
    handleClose: () => void;
    handleSave: () => void;
};

export default function Drawer({
    children,
    title,
    subtitleHierarchy,
    submitButtonLabel,
    isDrawerOpen,
    handleClose,
    handleSave,
}: DrawerProps) {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <MuiDrawer
            anchor="right"
            open={isDrawerOpen}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    width: '552px',
                    boxShadow: (theme) =>
                        `0px 8px 24px ${alpha(
                            theme.palette.grey[800],
                            0.08
                        )}`,
                    borderRadius: '8px 0px 0px 8px',
                },
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                py={3}
            >
                <Box flexShrink={0} px={3}>
                    <DrawerHeader
                        title={title}
                        subtitleHierarchy={subtitleHierarchy}
                        onClose={handleClose}
                    />
                </Box>
                <Box mt={4} px={3} flexGrow={1} overflow="auto">
                    {children}
                </Box>
                <Box flexShrink={0} mt={4} px={3}>
                    <Button
                        size="large"
                        variant="contained"
                        sx={{
                            ml: 'auto',
                            mt: 'auto',
                        }}
                        isLoading={isLoading}
                        onClick={(_) => {
                            setIsLoading(true);
                            handleSave();
                            // setTimeout(() => setIsLoading(false), 2000); FOR TESTING
                            setIsLoading(false);
                        }}
                    >
                        {submitButtonLabel}
                    </Button>
                </Box>
            </Box>
        </MuiDrawer>
    );
}
