const darkBlue = {
    100: '#CCDDDE',
    200: '#9DBABD',
    300: '#80BEC0',
    400: '#406D7B',
    500: '#134459',
    600: '#0E3C4A',
    700: '#09323A',
    800: '#052529',
    900: '#021718',
};
export default darkBlue;
