import {
    Box,
    Typography,
    Link,
    alpha,
    Divider,
    IconButton,
} from '@mui/material';
import {MenuCloseIcon} from 'common/icons';
import Button from 'common/components/Button/index';
import ColumnSelection from 'common/components/Table/TableDesktop/TableActionsMenu/TablePopups/ColumnsManagment/ColumnSelection';
import _ from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';

export interface TableDesktopColumnsManagmentPopupProps {
    onClose: () => void;
    isOpen: boolean;
    onApply: (hiddenColumns: any, orederedColumns: any) => void;
    allColumns: any;
    initColumnsState: any;
}

type UnknownColumn = Record<string, any>;
export default function TableDesktopColumnsManagmentPopup({
    onClose,
    onApply,
    isOpen,
    allColumns,
    initColumnsState,
}: TableDesktopColumnsManagmentPopupProps) {
    let limitCols = 4;
    let cardMaxheight = limitCols * (40 + 4 + 4) + 20 + 220;

    const [columns, setColumns] = useState<UnknownColumn[]>([]);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

    useEffect(() => {
        setColumns(allColumns);
        setHiddenColumns(
            allColumns
                .filter((col: UnknownColumn) => !col.isVisible)
                .map((col: UnknownColumn) => col.id)
        );
    }, [allColumns, isOpen]);

    const columnsIds: string[] = useMemo(
        () =>
            _.map(columns, (item) => {
                return item.id as string;
            }),
        [columns]
    );

    const orderChangeDetected = useMemo(
        () =>
            !_.isEqual(
                _.map(allColumns, (item) => {
                    return item.id;
                }),
                columnsIds
            ) ||
            !_.isEqual(
                _.map(initColumnsState, (item) => {
                    return item.id;
                }),
                columnsIds
            ),
        [allColumns, columnsIds, initColumnsState]
    );

    const visibilityChangeDetected = useMemo(
        () => !_.isEmpty(hiddenColumns),
        [hiddenColumns]
    );
    const changeDetected = useMemo(
        () => orderChangeDetected || visibilityChangeDetected,
        [orderChangeDetected, visibilityChangeDetected]
    );

    const handleApply = useCallback(() => {
        onApply(hiddenColumns, columnsIds);
    }, [onApply, hiddenColumns, columnsIds]);

    const handleReset = useCallback(() => {
        setHiddenColumns([]);

        //Apply initial order
        setColumns(initColumnsState);
    }, [initColumnsState]);

    const handleMoveColumn = useCallback(
        (dragIndex: number, hoverIndex: number, columnIds: string[]) => {
            const result: UnknownColumn[] = [...columns];
            if (!_.isUndefined(dragIndex)) {
                const dragColumnItem = columnIds[dragIndex];
                const dropColumnItem = columnIds[hoverIndex];

                columnIds[dragIndex] = dropColumnItem;
                columnIds[hoverIndex] = dragColumnItem;

                const draggedColumnIndex = _.findIndex(
                    columns,
                    (column: UnknownColumn) => {
                        return column.id === dropColumnItem;
                    }
                );
                const draggedColumn = _.find(
                    columns,
                    (column: UnknownColumn) => {
                        return column.id === dropColumnItem;
                    }
                );

                const droppedColumn = _.find(
                    columns,
                    (column: UnknownColumn) => {
                        return column.id === dragColumnItem;
                    }
                );

                const droppedColumnIndex = _.findIndex(
                    columns,
                    (column: UnknownColumn) => {
                        return column.id === dragColumnItem;
                    }
                );

                if (droppedColumn) {
                    result[draggedColumnIndex] = droppedColumn;
                }
                if (draggedColumn) {
                    result[droppedColumnIndex] = draggedColumn;
                }

                //sort columns
                setColumns(result);
            }
        },
        [setColumns, columns]
    );

    const handleHideColumns = useCallback(
        (column: any) => {
            let hiddenColsIds = [...hiddenColumns];

            let isPrevsVisible =
                _.findIndex(hiddenColsIds, (item) => {
                    return item === column.id;
                }) === -1;

            if (isPrevsVisible) {
                hiddenColsIds.push(column.id);
            } else {
                hiddenColsIds = _.filter(
                    hiddenColumns,
                    (id) => id !== column.id
                );
            }

            setHiddenColumns(hiddenColsIds);
        },
        [hiddenColumns, setHiddenColumns]
    );

    const renderGroupOfSelections = useCallback(() => {
        // columns prop defines column order in popup
        return _.map(columns, (column: UnknownColumn) => {
            return _.isUndefined(column.Header) ||
                _.isEmpty(column.Header) ? null : (
                <ColumnSelection
                    key={column.id}
                    hiddenColumns={hiddenColumns}
                    column={column}
                    columnIds={columnsIds}
                    moveColumn={handleMoveColumn}
                    onClick={() => handleHideColumns(column)}
                    isDisabled={column.isAlwaysVisible ?? false}
                />
            );
        });
    }, [
        columns,
        hiddenColumns,
        handleMoveColumn,
        handleHideColumns,
        columnsIds,
    ]);

    const Header = () => {
        return (
            <>
                <Box
                    sx={{
                        width: 'calc(100% - 32px)',
                        margin: '0px auto',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="subtitle"
                        sx={{color: 'grey.900'}}
                    >
                        Columns management
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        size="small"
                        sx={{
                            my: 1,
                            mr: 1,
                            ml: 'auto',
                        }}
                    >
                        <MenuCloseIcon />
                    </IconButton>
                </Box>

                <Divider
                    sx={{
                        width: 'calc(100% - 32px)',
                        margin: (theme) =>
                            `0 auto ${theme.spacing(2)} auto`,
                    }}
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: 'calc(100% - 32px)',
                        margin: (theme) =>
                            `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
                    }}
                >
                    <Link
                        onClick={handleReset}
                        underline="none"
                        component="button"
                        disabled={!changeDetected}
                        sx={{
                            height: '14px',
                            '&:disabled': {
                                color: 'common.disabled',
                            },
                        }}
                    >
                        <Typography variant="caption">
                            Reset to default
                        </Typography>
                    </Link>
                </Box>
            </>
        );
    };

    const Body = () => {
        let limit = 5.5;
        let height = limit * (40 + 4 + 4) + 12;

        return (
            <Box
                id="popup-card-test"
                sx={{
                    overflow: 'auto',
                    maxHeight: height,
                }}
            >
                {renderGroupOfSelections()}
            </Box>
        );
    };

    const Footer = () => {
        return (
            <Box
                sx={{
                    height: '64px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Box
                    sx={{
                        margin: (theme) =>
                            `${theme.spacing(2)} ${theme.spacing(
                                2
                            )} ${theme.spacing(2)} 0}`,
                    }}
                >
                    <Button
                        size="small"
                        variant="contained"
                        onClick={handleApply}
                        sx={{
                            whiteSpace: 'nowrap',
                            '&:disabled': {
                                backgroundColor: 'grey.300',
                            },
                        }}
                    >
                        <Typography variant="button">
                            Apply changes
                        </Typography>
                    </Button>
                </Box>
            </Box>
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '328px',
                height: `${cardMaxheight}px`,
                maxHeight: '384px',
                backgroundColor: (theme) => theme.palette.grey[100],
                boxShadow: (theme) =>
                    `0px 4px 10px ${alpha(theme.palette.grey[800], 0.24)}`,
                zIndex: 15000000,
            }}
        >
            <Header />
            <Body />
            <Footer />
        </Box>
    );
}
