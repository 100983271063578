import {Redirect, Route} from 'react-router-dom';
import {ModuleProvider} from 'common/hooks/moduleRouter';
import YodisenseContainer from './containers/YodisenseContainer';
import OverviewContainer from './containers/OverviewContainer';
export default function Yodisense() {
    return (
        <ModuleProvider>
            <Route exact path="/yodisense">
                <Redirect to="/yodisense/overview"></Redirect>
            </Route>
            <Route path="/yodisense/:name">
                <YodisenseContainer>
                    <Route path="/yodisense/overview">
                        <OverviewContainer />
                    </Route>
                </YodisenseContainer>
            </Route>
        </ModuleProvider>
    );
}
