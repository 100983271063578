import _ from 'lodash';

import SentryRRWeb from '@sentry/rrweb';
import * as Sentry from '@sentry/react';
import {SeverityLevel} from '@sentry/react';
import {Integrations} from '@sentry/tracing';

import Config from '../../config';
import GitInfo from '../../generatedGitInfo.json';

export enum LogLevel {
    Verbose = 0,
    Debug = 1,
    Info = 2,
    Warning = 3,
    Error = 4,
    Critical = 5,
    Exception = 6,
    Off = 7,
}

export interface LogEntry {
    Level: LogLevel;
    Timestamp: Date;
    //StackTrace: StackTrace.StackFrame[]; // The stack trace in array
    StackTrace: string[] | null;
    StackError: Error;
    Message?: any[];
}

export interface Log {
    InDebug: boolean;
    Version: string;
    GitBranch: string;
    GitVersion: string;
    GitLocalChanges: string;
    NodeEnv: string;
    URLParams: string;
    Log: LogEntry[];
    UseSentryIO: boolean;
    MaxMessagesQueueLen: number;
    Timestamp: Date;
}

// Build time parameters
declare var process: any;
var __IN_DEBUG__: boolean = false;
var __VERSION__: string = 'v0.0';
var __GIT_BRANCH__: string = GitInfo.gitBranch;
var __GIT_VERSION__: string = GitInfo.gitCommitHash;
var __GIT_LOCAL_CHANGES__: string = GitInfo.gitLocalChanges;
var __TIMESTAMP__: string = GitInfo.buildTime;
var __USE_REPORTING__: boolean = true;

(window as any).__LOG_ENABLE_STACK__ = false;
(window as any).__LOG_DISABLE_STACK__ = true;
(window as any).__LOG_LEVEL__ = LogLevel.Info; // INFO and up is on
var __LOG__: Log = {
    Timestamp: new Date(__TIMESTAMP__),
    InDebug: __IN_DEBUG__,
    Version: __VERSION__,
    GitBranch: __GIT_BRANCH__,
    GitVersion: __GIT_VERSION__,
    GitLocalChanges: __GIT_LOCAL_CHANGES__,
    NodeEnv: process.env.NODE_ENV,
    URLParams: window.location.search.substring(1),
    Log: [],
    UseSentryIO: false,
    MaxMessagesQueueLen: 10,
};

let __ON_ERROR_EXTERNAL_HANDLER__: OnErrorEventHandler = _.noop;

// Reduce the debug messages in normal operations
if (!__IN_DEBUG__) {
    (window as any).__LOG_LEVEL__ = LogLevel.Warning;
}

type LogArg = [
    printFunc: any,
    level: LogLevel,
    externalEx: Error,
    ...args: any[]
];
export class DebugEx {
    static get LogLevel(): LogLevel {
        return (window as any).__LOG_LEVEL__;
    }
    static set LogLevel(level: LogLevel) {
        (window as any).__LOG_LEVEL__ = level;
    }
    static get LogHistory(): LogEntry[] {
        return __LOG__.Log;
    }
    static get Info(): Log {
        return __LOG__;
    }

    static get OnError(): OnErrorEventHandler {
        return __ON_ERROR_EXTERNAL_HANDLER__;
    }
    static set OnError(handler: OnErrorEventHandler) {
        __ON_ERROR_EXTERNAL_HANDLER__ = _.isFunction(handler)
            ? handler
            : _.noop;
    }

    static GetRavenLogLevel(level: LogLevel): SeverityLevel {
        switch (level) {
            case LogLevel.Exception:
                return 'error';
            case LogLevel.Critical:
                return 'fatal';
            case LogLevel.Error:
                return 'error';
            case LogLevel.Warning:
                return 'warning';
            case LogLevel.Info:
                return 'info';
            case LogLevel.Debug:
                return 'debug';
            case LogLevel.Verbose:
                return 'log';
        }
        return 'log';
    }

    static getUrlParameters(): any {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        var res: {[key: string]: string} = {};
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameter = sURLVariables[i].split('=');
            res[sParameter[0]] = sParameter[1];
        }
        return res;
    }

    private static async Log(
        printFunc: any,
        level: LogLevel,
        externalEx: Error,
        ...args: any[]
    ) {
        // Use external error
        let ex = externalEx;
        if (ex === null) {
            try {
                throw new Error('Stack');
            } catch (localErr: any) {
                ex = localErr;
            }
        }

        let stack = ex.stack as string;
        let stringifiedStack = _.isString(stack)
            ? stack.split('\n')
            : null;

        // Add maximum number of data
        if (__LOG__.Log.length > __LOG__.MaxMessagesQueueLen) {
            __LOG__.Log.shift();
        }

        // Add the log to a global variable
        // TODO: Add max size of this log
        __LOG__.Log.push({
            Level: level,
            Timestamp: new Date(),
            StackTrace: stringifiedStack,
            StackError: ex,
            Message: _.clone(args),
        });

        // Add to the end of the log the stack trace
        args.push(ex);

        if ((window as any).__LOG_LEVEL__ <= level) {
            printFunc.apply(this, args);
        } else {
            // Create mapping of multiple arguments for extra data
            let dataMap: {[key: string]: string} = {};
            for (var i = 1; i < args.length; i++) {
                try {
                    let data = JSON.stringify(args[i]);
                    if (data.length > 1000) {
                        data = data.slice(0, 1000);
                    }
                    if (data.length > 0 && data !== '{}') {
                        dataMap['arg-' + i] = data;
                    }
                } catch (ex) {}
            }
            // Add as breadcrumb the messages
            Sentry.addBreadcrumb({
                category: this.GetRavenLogLevel(level),
                data: dataMap,
                level: this.GetRavenLogLevel(level),
                message: args[0],
            });
        }

        // Raven support
        if (externalEx !== null && __LOG__.UseSentryIO) {
            Sentry.captureException(externalEx);
        }
    }

    public static TraceVerbose(...args: any[]) {
        args.unshift(null);
        args.unshift(LogLevel.Verbose);
        args.unshift(console.log);
        this.Log.apply(this, args as LogArg);
    }

    public static TraceDebug(...args: any[]) {
        args.unshift(null);
        args.unshift(LogLevel.Debug);
        args.unshift(console.debug);
        this.Log.apply(this, args as LogArg);
    }

    public static TraceLog(...args: any[]) {
        args.unshift(null);
        args.unshift(LogLevel.Info);
        args.unshift(console.info);
        this.Log.apply(this, args as LogArg);
    }

    public static TraceWarning(...args: any[]) {
        args.unshift(null);
        args.unshift(LogLevel.Warning);
        args.unshift(console.warn);
        this.Log.apply(this, args as LogArg);
    }

    public static TraceError(...args: any[]) {
        args.unshift(null);
        args.unshift(LogLevel.Error);
        args.unshift(console.error);
        this.Log.apply(this, args as LogArg);
    }

    public static TraceCritical(...args: any[]) {
        args.unshift(null);
        args.unshift(LogLevel.Critical);
        args.unshift(console.error);
        this.Log.apply(this, args as LogArg);
    }

    public static TraceCriticalException(ex: Error, ...args: any[]) {
        args.unshift(ex);
        args.unshift(LogLevel.Critical);
        args.unshift(console.error);
        this.Log.apply(this, args as LogArg);
    }

    public static TraceAlways(...args: any[]) {
        args.unshift(null);
        args.unshift(LogLevel.Off);
        args.unshift(console.log);
        this.Log.apply(this, args as LogArg);
    }

    public static TraceException(ex: Error, ...args: any[]) {
        // The stack trace resolving take too much time and we make full
        // stack only if we have errors in more than 100ms delay
        args.unshift(ex);
        args.unshift(LogLevel.Exception);
        args.unshift(console.error);
        this.Log.apply(this, args as LogArg);
    }

    static LogLogin(email: string, name?: string, domain?: string) {
        Sentry.configureScope((scope) => {
            scope.setUser({
                email: email,
                username: name,
                segment: domain,
            });
        });
    }

    public static async LogAppInfo(appName: string) {
        // Get informations from url params
        var params = this.getUrlParameters();

        // Init Raven Sentry.io
        if (
            __USE_REPORTING__ === true &&
            !__LOG__.UseSentryIO &&
            window.location.host.indexOf('localhost') === -1
        ) {
            // Select project
            let projectId = Config.SENTRY_PROJECT_ID;

            try {
                Sentry.init({
                    dsn: projectId,
                    release: __LOG__.Version + ' - ' + __LOG__.GitVersion,
                    environment: __LOG__.NodeEnv,
                    debug: false,
                    integrations: [
                        new Integrations.BrowserTracing(),
                        new SentryRRWeb(),
                    ],
                    normalizeDepth: 10,
                    tracesSampleRate: 1,
                });
                Sentry.setTag('rrweb.active', 'yes');
                __LOG__.UseSentryIO = true;
            } catch (ex) {
                console.error(ex);
            }
        }

        console.log('===> Application Starting  <===');
        console.log('App Name       :', appName);
        console.log('__IN_DEBUG__   :', __IN_DEBUG__);
        console.log('__VERSION__    :', __VERSION__);
        console.log('__GIT_BRANCH__:', __GIT_BRANCH__);
        console.log('__GIT_VERSION__:', __GIT_VERSION__);
        console.log('__GIT_LOCAL_CHANGES__:', __GIT_LOCAL_CHANGES__);
        console.log('__TIMESTAMP__  :', __TIMESTAMP__);
        console.log('NODE_ENV       :', process.env.NODE_ENV);
        console.log('URLParams      :', params);

        // Update tag in reven with app name
        if (__LOG__.UseSentryIO) {
            Sentry.configureScope((scope) => {
                scope.setTag('app_name', appName);
                scope.setTag('git_commit', __LOG__.GitVersion);
                scope.setTag('git_branch', __LOG__.GitBranch);
                scope.setTag(
                    'git_local_changes',
                    __LOG__.GitLocalChanges.length > 0
                );
                scope.setTag(
                    'InDebug',
                    __LOG__.InDebug ? 'true' : 'false'
                );
                scope.setTag('Domain', window.location.host);
            });
        }
    }

    public static inDev() {
        return (
            process.env.NODE_ENV !== 'production' &&
            process.env.NODE_ENV !== 'prod'
        );
    }
}

//Automatically handle errors
window.onerror = function (
    event: Event | string,
    source?: string,
    lineno?: number,
    colno?: number,
    error?: Error
) {
    if (error !== null && error !== undefined) {
        DebugEx.TraceCriticalException(
            error,
            event,
            source,
            lineno,
            colno
        );
    } else {
        DebugEx.TraceCritical(event, source, lineno, colno);
    }
};
