import {
    Box,
    MenuItem,
    MenuItemProps,
    Typography,
    IconButton,
} from '@mui/material';
import {
    DropdownCheckboxUnselectedIcon,
    DropdownCheckboxSelectedIcon,
    AscendingIcon,
    DescendingIcon,
} from 'common/icons/index';
import _ from 'lodash';
import clsx from 'clsx';
import {UnknownObject, AnyObject} from '../../../../index';
import {SortingRule} from 'react-table';

export type ColumnsSelectionMobileProps<
    T extends AnyObject = UnknownObject
> = {
    hiddenColumnsIds: string[];
    column: any;
    isDisabled: boolean;
    onChangeSorting: (sortingProps: SortingRule<T>) => void;
    sortBy?: Array<{id: string; desc: Boolean}>;
} & MenuItemProps;

export default function s({
    hiddenColumnsIds,
    column,
    isDisabled,
    onChangeSorting,
    sortBy,
    ...rest
}: ColumnsSelectionMobileProps) {
    let isVisible =
        _.findIndex(hiddenColumnsIds, (id: any) => id === column.id) ===
        -1;
    let isSortingAsc =
        _.findIndex(sortBy, (item) => {
            return item.id === column.id && item.desc === false;
        }) !== -1;

    let isSortingDesc =
        _.findIndex(sortBy, (item) => {
            return item.id === column.id && item.desc === true;
        }) !== -1;
    const leftElement = isVisible ? (
        <DropdownCheckboxSelectedIcon
            sx={{
                color: 'primary.main',
                margin: '0px 8px 0 12px',
            }}
        />
    ) : (
        <DropdownCheckboxUnselectedIcon
            sx={{
                color: 'grey.600',
                margin: '0px 8px 0 12px',
            }}
        />
    );

    const middleElement = <Typography>{column.Header}</Typography>;

    const rightElement = (
        <Box
            sx={{
                width: '82px',
                height: '40px',
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <IconButton
                size="small"
                aria-label="sort desc"
                className={clsx({
                    isSelected: isSortingDesc,
                })}
                sx={{
                    marginRight: '4px',
                    height: '40px',
                    width: '40px',
                    color: 'grey.600',

                    '&.isSelected': {
                        bgcolor: 'grey.600',
                        color: 'grey.100',
                    },
                }}
                onClick={(e) => {
                    onChangeSorting({
                        id: column.id,
                        desc: true,
                    });
                    e.stopPropagation();
                }}
            >
                <DescendingIcon
                    className={clsx({
                        isSelected: isVisible,
                        isSortingDesc: isSortingDesc,
                    })}
                    sx={{
                        color: 'common.disabled',
                        '&.isSelected': {
                            color: 'grey.600',
                            '&.isSortingDesc': {
                                color: 'grey.100',
                            },
                        },
                    }}
                />
            </IconButton>
            <IconButton
                size="small"
                aria-label="sort asc"
                className={clsx({
                    isSelected: isSortingAsc,
                })}
                sx={{
                    height: '40px',
                    width: '40px',
                    color: 'grey.600',

                    '&.isSelected': {
                        bgcolor: 'grey.600',
                        color: 'grey.100',
                    },
                }}
                onClick={(e) => {
                    onChangeSorting({
                        id: column.id,
                        desc: false,
                    });
                    e.stopPropagation();
                }}
            >
                <AscendingIcon
                    className={clsx({
                        isSelected: isVisible,
                        isSortingAsc: isSortingAsc,
                    })}
                    sx={{
                        color: 'common.disabled',
                        '&.isSelected': {
                            color: 'grey.600',
                            '&.isSortingAsc': {
                                color: 'grey.100',
                            },
                        },
                    }}
                />
            </IconButton>
        </Box>
    );

    return (
        <MenuItem
            key={column.id}
            className={clsx({
                isSelected: isVisible,
            })}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: 'calc(100% - 32px)',
                height: '40px',
                padding: '4px 0px',
                margin: '8px auto',
                borderRadius: '4px',
                backgroundColor: (theme) => theme.palette.grey[100],
            }}
            disabled={isDisabled}
            {...rest}
        >
            <Box
                key={column.id}
                sx={{
                    width: '264px',
                    display: 'flex',
                    alignItems: 'center',
                    color: (theme) => theme.palette.grey[900],
                }}
            >
                {leftElement}
                {middleElement}
            </Box>
            {rightElement}
        </MenuItem>
    );
}
