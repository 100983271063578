import {Box, Stack, useMediaQuery, useTheme} from '@mui/material';
import {useState} from 'react';
import {FormProps, FloorFormValues} from './forms.types';
import Dialog from './Dialog';
import LabelledInput from 'common/components/Inputs/LabelledInput';
import NumericalInput from 'common/components/Inputs/NumericalInput';
import FormContainer from './FormContainer';

const emptyState: FloorFormValues = {
    name: '',
    area: 1,
    maxPeople: 1,
    type: 'floors',
};

export default function FloorForm({
    isOpen,
    isEdit,
    values,
    subtitleHierarchy,
    handleSave,
    handleClose,
}: FormProps<FloorFormValues>) {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [hasBeenEditted, setHasBeenEditted] = useState(false);
    const [formData, setFormData] = useState<FloorFormValues>(
        values ?? emptyState
    );

    const handleChange = (value: any, key: keyof FloorFormValues) => {
        if (!hasBeenEditted) {
            setHasBeenEditted(true);
        }
        setFormData({
            ...formData,
            [key]: value,
        });
    };

    const {
        breakpoints: {up},
    } = useTheme();

    const isMobile = !useMediaQuery(up('desktopSm'));

    const validateValues = (values: FloorFormValues) =>
        values.name !== '' &&
        (values.area === undefined || values.area > 0) &&
        (values.maxPeople === undefined || values.maxPeople > 0);

    const onSubmit = () => {
        if (validateValues(formData)) {
            handleSave(formData);
        } else {
            //TODO: create throw error
        }
    };

    return (
        <FormContainer
            isMobile={isMobile}
            isOpen={isOpen}
            isEdit={isEdit}
            onSubmit={onSubmit}
            handleClose={() =>
                hasBeenEditted ? setDialogOpen(true) : handleClose()
            }
            subtitleHierarchy={subtitleHierarchy}
            values={values}
            hierarchyLevel="floor"
        >
            <Box
                display="flex"
                flexDirection="column"
                gap={3}
                height="100%"
            >
                <LabelledInput
                    label="Name"
                    placeholder="Enter name"
                    variant="Counter"
                    maxLength={128}
                    value={formData?.name}
                    onChange={(v) => handleChange(v, 'name')}
                    width="100%"
                />
                <LabelledInput
                    optional
                    label="Description"
                    placeholder="Enter description"
                    variant="Counter"
                    maxLength={256}
                    value={formData?.description}
                    onChange={(v) => handleChange(v, 'description')}
                    width="100%"
                    overrideTextFieldProps={{multiline: true, minRows: 3}}
                    inputSx={{height: 'auto'}}
                />

                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    gap={isMobile ? 2 : 6}
                >
                    <NumericalInput
                        autoExpand={isMobile}
                        optional
                        label="Area(sq.m)"
                        placeholder="Enter area"
                        value={formData?.area?.toString()}
                        min={1}
                        width="100%"
                        onChange={(v) => handleChange(v, 'area')}
                        overrideTextFieldProps={{
                            helperText: 'the minimum value is 1',
                        }}
                        onClickUp={() =>
                            handleChange(
                                formData.area !== undefined
                                    ? formData.area + 1
                                    : 1,
                                'area'
                            )
                        }
                        onClickDown={() =>
                            handleChange(
                                formData.area !== undefined
                                    ? formData.area - 1
                                    : 1,
                                'area'
                            )
                        }
                    />
                    <NumericalInput
                        optional
                        autoExpand={isMobile}
                        label="Max people count"
                        placeholder="Enter max people"
                        value={formData?.maxPeople?.toString()}
                        min={1}
                        width="100%"
                        onChange={(v) => handleChange(v, 'maxPeople')}
                        overrideTextFieldProps={{
                            helperText: 'the minimum value is 1',
                        }}
                        onClickUp={() =>
                            handleChange(
                                formData.maxPeople !== undefined
                                    ? formData.maxPeople + 1
                                    : 1,
                                'maxPeople'
                            )
                        }
                        onClickDown={() =>
                            handleChange(
                                formData.maxPeople !== undefined
                                    ? formData.maxPeople - 1
                                    : 1,
                                'maxPeople'
                            )
                        }
                        containerSx={{justifySelf: 'end'}}
                    />
                </Stack>
            </Box>
            <Dialog
                isOpen={isDialogOpen}
                name={values?.name ?? 'new floor'}
                handleClose={() => setDialogOpen(false)}
                handleConfirm={handleClose}
                subtitleHierarchy={subtitleHierarchy}
                isMobile={isMobile}
            />
        </FormContainer>
    );
}
