import {alpha, CircularProgress} from '@mui/material';
import {grey} from 'common/colors';
import {useMemo} from 'react';

export interface CircularProgressIndicatorProps {
    variant: 'large' | 'smallLight' | 'smallDark' | 'auto';
}

function CircularProgressIndicator({
    variant,
}: CircularProgressIndicatorProps) {
    const size = useMemo(() => {
        switch (variant) {
            case 'large':
                return '48px';
            case 'smallLight':
            case 'smallDark':
                return '20px';
            case 'auto':
                return undefined;
        }
    }, [variant]);
    return (
        <>
            <svg height="0px" width="0px">
                <defs>
                    <linearGradient
                        id="gradient"
                        x1="100%"
                        y1="100%"
                        x2="0%"
                        y2="100%"
                    >
                        <stop
                            offset="0%"
                            stopColor={
                                variant === 'smallLight'
                                    ? `${alpha(grey[100], 0)}`
                                    : 'white'
                            }
                        />
                        <stop
                            offset={
                                variant === 'smallLight' ? '50%' : '100%'
                            }
                            stopColor={
                                variant === 'smallLight'
                                    ? 'white'
                                    : 'black'
                            }
                        />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress
                size={size}
                sx={{
                    'svg circle': {
                        stroke: 'url(#gradient)',
                    },
                }}
            />
        </>
    );
}

export default CircularProgressIndicator;
