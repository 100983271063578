import {Redirect, Route, Switch} from 'react-router-dom';
import {ModuleProvider} from 'common/hooks/moduleRouter';
import AdminContainer from './containers/AdminContainer';
import InfrastructureContainer from './containers/InfrastructureContainer';

export default function Admin() {
    return (
        <>
            <Route exact path="/admin">
                <Redirect to="/admin/infrastructure-management"></Redirect>
            </Route>
            <ModuleProvider>
                <AdminContainer>
                    <Switch>
                        <Route path="/admin/infrastructure-management">
                            <InfrastructureContainer />
                        </Route>
                        <Route path="/admin/user-management">
                            <span>Placeholder</span>
                        </Route>
                    </Switch>
                </AdminContainer>
            </ModuleProvider>
        </>
    );
}
