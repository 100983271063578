import {grey} from 'common/colors';
import {SearchIcon} from 'common/icons';
import {forwardRef} from 'react';
import Input, {InputProps} from '../Inputs/index';

const SearchInput = forwardRef<HTMLInputElement, InputProps>(
    (props, ref) => {
        const {...searchProps} = props;

        return (
            <Input
                ref={ref}
                ariaLabel="Search"
                startAdornment={
                    <SearchIcon
                        fontSize="inherit"
                        sx={{color: grey[400]}}
                    />
                }
                {...searchProps}
            />
        );
    }
);

export default SearchInput;
