import {UserManager} from './UserManager';
import * as serviceWorker from './serviceWorker';
import {DebugEx} from './DebugEx';

function initSetup(appRender: () => void): void {
    let userManager = UserManager.GetInstance();

    //DebugEx.LogAppInfo(Config.SENTRY_PROFILE_NAME);
    // Check if path if is auth and run sign in
    if (window.location.pathname?.endsWith('/login/signin_callback')) {
        userManager
            .loginCb()
            .then((user) => {
                (
                    window as unknown as any
                ).location = `${process.env.PUBLIC_URL}${user?.state?.urlSearch}`;
            })
            .catch((e) => {
                console.error(e);
            });
    } else {
        userManager.init().then((user) => {
            if (user !== null) {
                // Start the initial rendering
                appRender();

                // Save the login info for error reporting
                DebugEx.LogLogin(
                    user.profile.preferred_username ?? 'Not Connected',
                    user.profile.name,
                    user.profile.domain
                );
            } else {
                // TODO: Render a message for redirect
            }
        });
    }

    serviceWorker.unregister();
}
export {initSetup};
