import React, {useState, useCallback, useRef, useMemo} from 'react';
import {
    SxProps,
    Tabs,
    alpha,
    Box,
    TabScrollButtonProps,
} from '@mui/material';
import {grey} from '../../../colors';
import MainMenuScrollButton from './MainMenuDesktopScrollButton';
import MainMenuSubMenu from './MainMenuDesktopSubMenu';
import {SubmenuInstance} from 'modules/app/components/AppSideNav';
export interface MainMenuDesktopProps {
    onExpansionChange?: (isExpanded: boolean) => void;
    onExtensionChange?: (isExtended: boolean) => void;
    onChange?: (newValue: string) => void;
    ariaLabel?: string;
    defaultValue: string;
    sx?: SxProps;
    children: React.ReactNode | React.ReactNode[];
    expanded?: boolean;
    extended?: boolean;
    submenu?: SubmenuInstance;
}

const MainMenuDesktop = ({
    defaultValue,
    expanded = false,
    extended = false,
    submenu,
    sx = {},
    ariaLabel,
    onExpansionChange = (_) => {},
    onExtensionChange = (_) => {},
    onChange = () => {},
    children: _children,
}: MainMenuDesktopProps) => {
    const [value, setValue] = useState(defaultValue);
    const handleChange = (
        event: React.SyntheticEvent,
        newValue: string
    ) => {
        onExpansionChange(false);
        setValue(newValue);
        onChange(newValue);
    };

    const [scrollShown, setScrollShown] = useState(false);
    const handleToggleScroll = useCallback(
        (shown: boolean) => {
            setScrollShown(shown);
        },
        [setScrollShown]
    );

    const scrollButtonComponent = useCallback(
        (props: TabScrollButtonProps) => (
            <MainMenuScrollButton
                {...props}
                onToggleScroll={handleToggleScroll}
            />
        ),
        [handleToggleScroll]
    );

    const tabsRef = useRef(null);

    const children = useMemo(
        () =>
            React.Children.map(_children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, {
                        tabsRef,
                        isExpanded: expanded,
                        onClickExpand: () => {
                            setValue(child.props.value);
                            onChange(child.props.value);
                            onExpansionChange(true);
                        },
                    });
                }
            }),
        [_children, expanded, onChange, onExpansionChange]
    );

    const widthExpanded = extended ? 478 : 286;

    return (
        <Box
            className="tabs-wrapper"
            position="relative"
            mr={3}
            sx={{borderRadius: 2, ...sx}}
            minWidth={expanded ? widthExpanded : undefined}
        >
            <Tabs
                ref={tabsRef}
                aria-label={ariaLabel}
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                TabScrollButtonProps={{
                    sx: {
                        color: 'grey.600',
                        m: 0,
                    },
                }}
                sx={{
                    position: 'relative',
                    py: scrollShown ? 0 : 2,
                    zIndex: (theme) => theme.zIndex.appBar - 1,
                    width: '78px',
                    borderColor: 'primary.dark',
                    borderRadius: 2,
                    boxShadow: `0px 8px 24px ${alpha(grey[800], 0.08)}`,
                    backgroundColor: grey[100],
                    height: '100%',
                }}
                ScrollButtonComponent={scrollButtonComponent}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: `transparent`,
                    },
                }}
            >
                {children}
            </Tabs>
            <MainMenuSubMenu
                onExtensionChange={onExtensionChange}
                header={submenu?.header}
                extended={extended}
                isOpen={expanded}
                onClose={() =>    onExpansionChange(false)}
            >
                {submenu?.body}
            </MainMenuSubMenu>
        </Box>
    );
};
export {default as MainMenuDesktopTab} from './MainMenuDesktopTab/index';
export {default as MainMenuDesktopSeparator} from './MainMenuDesktopSeparator';
export default MainMenuDesktop;
