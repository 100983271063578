import {Box, Typography, Stack} from '@mui/material';
import {DropdownSingle} from 'common/components/Dropdown';
import {Hours} from '../forms.types';

type DoubleDropdownInputProps = {
    label?: string;
    optional?: boolean;
    onChange: (val: Hours, key: 'from' | 'to') => void;
    dropdownWidth: number | string;
    fromValue: Hours;
    toValue: Hours;
    autoExpand?: boolean;
};

const hourOptions: Hours[] = Array(24)
    .fill({label: '', value: ''})
    .map((_, i) => {
        let value = i.toString().padStart(2, '0') + ':00';
        return {label: value, value: value};
    });

export default function DoubleDropdownInput({
    optional,
    label,
    dropdownWidth = 237,
    fromValue,
    toValue,
    onChange,
    autoExpand,
}: DoubleDropdownInputProps) {
    return (
        <Box width="100%">
            <Stack
                direction="row"
                spacing="2px"
                whiteSpace="nowrap"
                marginBottom="4px"
            >
                <Typography color="grey.600" variant="overline">
                    {label}
                </Typography>
                {optional && (
                    <Typography
                        color="grey.500"
                        variant="menu"
                        height="13px"
                        alignSelf="flex-end"
                    >
                        (optional)
                    </Typography>
                )}
            </Stack>
            <Box display="flex" width="100%">
                <DropdownSingle<Hours>
                    width={dropdownWidth}
                    options={hourOptions}
                    formatLabel={(option) => option.label}
                    variant="outlined"
                    onChange={(value) => onChange(value, 'from')}
                    value={fromValue}
                    autoExpand={autoExpand}
                />
                <Typography
                    variant="body2"
                    color="grey.600"
                    sx={{mx: 1, mb: 1.5, alignSelf: 'flex-end'}}
                >
                    to
                </Typography>
                <DropdownSingle<Hours>
                    width={dropdownWidth}
                    options={hourOptions}
                    formatLabel={(option) => option.label}
                    variant="outlined"
                    onChange={(value) => onChange(value, 'from')}
                    value={toValue}
                    autoExpand={autoExpand}
                />
            </Box>
        </Box>
    );
}
