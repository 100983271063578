import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {UserManager} from 'common/lib/UserManager';
import {
    fetchCombinators,
    fetchEntityNames,
    fetchFullEntityInfo,
    fetchPredicates,
    fetchValues,
    fetchValuesInfo,
    filterBaseApi,
} from 'common/services/entityFilters.service';
import {z} from 'zod';

const fullEntityInfoDTOSchema = z
    .object({
        entityName: z.string(),
        supportedEntityFilterEntityInfo: z.object({
            supportedAttributes: z
                .object({
                    attributeName: z.string(),
                    internal: z.boolean(),
                    attributeTypeName: z.enum([
                        'String',
                        'Int32',
                        'UInt32',
                        'Int16',
                        'UInt16',
                        'Int64',
                        'UInt64',
                        'Single',
                        'Double',
                        'Decimal',
                        'Date',
                        'DateTime',
                    ]),
                    applicablePredicates: z.string().array(),
                })
                .array(),
        }),
    })
    .array();
const predicatesDTOSchema = z.string().array().nonempty();
const combinatorsDTOSchema = z.string().array().nonempty();
const valuesInfoDTOSchema = z
    .object({
        entityName: z.string(),
        attributeValueExtractorInfos: z
            .object({
                attributeName: z.string(),
                hasDefinedValuesExtractor: z.boolean(),
            })
            .array(),
    })
    .array();
const valuesDTOSchema = z.string().array();

export const entityFilterApi = createApi({
    reducerPath: 'infrastructureEntityFilter',
    baseQuery: fetchBaseQuery({
        baseUrl: filterBaseApi,
        prepareHeaders: (headers) => {
            headers.set(
                'Authorization',
                UserManager.GetInstance()?.getBearer()
            );
            return headers;
        },
    }),
    keepUnusedDataFor: 180,
    endpoints: (builder) => ({
        fetchEntityNames: builder.query({
            query: fetchEntityNames,
        }),
        fetchFullEntityInfo: builder.query({
            query: fetchFullEntityInfo,
            transformResponse: (data) => {
                return fullEntityInfoDTOSchema.parse(data);
            },
        }),
        fetchPredicates: builder.query({
            query: fetchPredicates,
            transformResponse: (data) => {
                return predicatesDTOSchema.parse(data);
            },
        }),
        fetchCombinators: builder.query({
            query: fetchCombinators,
            transformResponse: (data) => {
                return combinatorsDTOSchema.parse(data);
            },
        }),
        fetchValuesInfo: builder.query({
            query: fetchValuesInfo,
            transformResponse: (data) => {
                return valuesInfoDTOSchema.parse(data);
            },
        }),
        fetchValues: builder.query({
            query: (arg: {
                entityName: string;
                attributeName: string;
                org: number;
            }) => fetchValues(arg.entityName, arg.attributeName, arg.org),
            transformResponse: (data) => {
                return valuesDTOSchema.parse(data);
            },
        }),
    }),
});

export const {
    useFetchCombinatorsQuery,
    useFetchPredicatesQuery,
    useFetchFullEntityInfoQuery,
    useFetchEntityNamesQuery,
    useFetchValuesInfoQuery,
    useLazyFetchValuesQuery,
} = entityFilterApi;
