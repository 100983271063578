import {Typography, useMediaQuery, useTheme} from '@mui/material';
import {Box} from '@mui/system';
import React from 'react';
import {Module} from 'common/constants/modules';
import TooltipNotAllowed from './TooltipNotAllowed';
import {grey} from 'common/colors';
import {Link} from '@mui/material';
export interface ProductCardBodyProps {
    product: Module;
    showProductIcon: boolean;
    productDisabled?: boolean;
    onClick: (e: React.SyntheticEvent) => void;
    isActive: boolean;
}

export default function ProductCardBody({
    product,
    showProductIcon,
    productDisabled,
    onClick,
    isActive,
}: ProductCardBodyProps) {
    const ProductIcon = ({label}: {label: string}) => {
        const ProductIcon = product.icon;
        return <ProductIcon aria-label={label} />;
    };
    const {
        breakpoints: {up},
    } = useTheme();

    const isMobile = !useMediaQuery(up('desktopSm'));

    return (
        <Box
            className="card-header"
            sx={[
                {
                    display: 'flex',
                    flexDirection: 'column',
                },
            ]}
        >
            <TooltipNotAllowed show={productDisabled}>
                <Box
                    onClick={onClick}
                    sx={[
                        {
                            display: 'flex',
                            flexDirection: 'row',
                            cursor: isActive ? 'default' : 'pointer',
                            alignItems: 'center',
                        },
                    ]}
                >
                    {showProductIcon && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'center',
                                flexDirection: 'row',
                                height: isMobile ? '48px' : '60px',
                                width: isMobile ? '48px' : '60px',
                                justifyContent: 'center',
                                color: productDisabled
                                    ? 'grey.300'
                                    : 'primary.light',
                                p: 3,
                                bgcolor: productDisabled
                                    ? grey[800]
                                    : 'primary.main',
                                borderRadius: '50%',
                            }}
                        >
                            <ProductIcon label={product.label} />
                        </Box>
                    )}

                    <Box
                        sx={{
                            flexGrow: 1,
                            pl: '16px',
                            pr: '24px',
                            display: 'flex',
                            textAlign: 'left',
                            alignItems: isMobile ? 'end' : '',
                            justifyContent: 'space-between',
                            flexDirection: isMobile ? 'row' : 'column',
                        }}
                    >
                        <Typography
                            variant="body2"
                            data-testid="header-label"
                            fontSize={16}
                            fontWeight={500}
                            width="142px"
                        >
                            {product.label}
                        </Typography>

                        {productDisabled && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    typography: 'caption',
                                }}
                            >
                                <Link
                                    onClick={onClick}
                                    href="#"
                                    variant="caption"
                                    sx={{
                                        textDecoration: 'none',
                                        '&:hover': {
                                            textDecoration: 'underline',
                                        },
                                    }}
                                >
                                    Contact us
                                </Link>
                            </Box>
                        )}
                    </Box>
                </Box>
            </TooltipNotAllowed>
        </Box>
    );
}
