import {Divider} from '@mui/material';

export default function FilterAreaMobileDivider() {
    return (
        <Divider
            sx={{
                borderColor: 'primary.light',
                m: '8px 16px 0px 16px',
            }}
        />
    );
}
