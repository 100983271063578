import {Box, IconButton, Typography} from '@mui/material';
import {
    AddIcon,
    PositionAndAscDescIcon,
    ExportIcon,
} from 'common/icons/index';
import {Entry} from '../../../index';
import Button from 'common/components/Button';
import {HierarchyLevel} from 'modules/admin/config/hierarchyKeys';

export interface TableActionsMobileProps {
    onColsManagmentClick?: (e: any) => void;
    onAddRow: (type: HierarchyLevel, row?: Entry) => void;
    hierarchyLevel: HierarchyLevel;
    onExportClick?: () => void;
}
export default function TableActionsMobile({
    onColsManagmentClick,
    onAddRow,
    hierarchyLevel,
    onExportClick,
}: TableActionsMobileProps) {
    const IconButtons = () => {
        return (
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <IconButton
                    data-testid="table-actions-mobile-cols-mgmnt"
                    sx={{height: '40px', width: '40px'}}
                    onClick={onColsManagmentClick}
                >
                    <PositionAndAscDescIcon />
                </IconButton>

                <IconButton
                    data-testid="table-actions-mobile-export"
                    sx={{
                        height: '40px',
                        width: '40px',
                        marginLeft: '2px',
                    }}
                    onClick={onExportClick}
                >
                    <ExportIcon />
                </IconButton>
            </Box>
        );
    };

    return (
        <Box
            id="header-table-actions-mobile"
            sx={{
                display: 'flex',
                flexdirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <IconButtons />
            <Button
                data-testid="table-actions-mobile-add-row"
                size="small"
                variant="contained"
                onClick={() => {
                    onAddRow(hierarchyLevel, undefined);
                }}
                buttonSx={{
                    width: 'fit-content',
                    pl: 1.5,
                    pr: 2,
                    py: 1,
                }}
            >
                <Box display="flex" alignItems="center">
                    <AddIcon />
                    <Typography variant="button" sx={{ml: 1}}>
                        {hierarchyLevel === 'buildings'
                            ? 'Add building'
                            : hierarchyLevel === 'floors'
                            ? 'Add floor'
                            : 'Add zone'}
                    </Typography>
                </Box>
            </Button>
        </Box>
    );
}
