import {Button, Stack, Typography} from '@mui/material';
import {IconButton} from 'common/components';
import {DropdownCloseIcon} from 'common/icons';
import {format, getYear} from 'date-fns';
import {ReactDatePickerCustomHeaderProps} from 'react-datepicker';

export interface DatePickerHeaderProps {
    onButtonPressed: () => void;
    isPressed: boolean;
}

export default function DatePickerHeader({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    onButtonPressed,
    isPressed,
}: ReactDatePickerCustomHeaderProps & DatePickerHeaderProps) {
    return (
        <Stack direction="row" justifyContent="space-between">
            <IconButton
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
            >
                <DropdownCloseIcon sx={{transform: 'rotate(90deg)'}} />
            </IconButton>
            <Button
                onClick={onButtonPressed}
                sx={{
                    backgroundColor: (theme) =>
                        isPressed
                            ? theme.palette.common.brandLight.pressed
                            : '',
                    pl: '16px',
                    pb: '10px',
                    pt: '12px',
                    pr: '11px',
                    color: 'grey.900',
                }}
            >
                <>
                    <Typography pr="8px" variant="button">
                        {`${format(date, 'MMMM')} ${getYear(date)}`}
                    </Typography>
                    <DropdownCloseIcon
                        sx={{
                            lineHeight: '27px',
                            transform: isPressed
                                ? 'rotate(180deg)'
                                : 'none',
                        }}
                    />
                </>
            </Button>
            <IconButton
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
            >
                <DropdownCloseIcon sx={{transform: 'rotate(-90deg)'}} />
            </IconButton>
        </Stack>
    );
}
