import MainMenuDesktop, {
    MainMenuDesktopTab,
} from 'modules/app/components/AppSideNav';
import {useSelector} from 'common/hooks';
import sectionsConfig from '../../config/section';
import YodisenseSubMenuHeader from './YodisenseSubMenuHeader';
import YodisenseSubMenuBody from './YodisenseSubMenuBody';
export interface YodisenseSideNavProps {
    onChangeTab: (tab: string) => void;
    defaultValue: string;
}
/*
We can use this in a way analogous to a container, meaning we can hold the state here for 
    all the functionality provided by the yodisense side nav.
The goal is for this to output just the nessecary information (user selection/selections)
    to the containers via updating a yodisense slice.
*/
export default function YodisenseSideNav({
    onChangeTab,
    defaultValue,
}: YodisenseSideNavProps) {
    const sideNavWidth = useSelector(
        (state) => state.app.layout.sideNavWidth
    );
    return (
        <MainMenuDesktop
            onChange={onChangeTab}
            defaultValue={defaultValue}
            submenu={{
                body: <YodisenseSubMenuBody />,
                header: (
                    <YodisenseSubMenuHeader
                        hasSearch={true}
                        extended={sideNavWidth === 'extended'}
                    />
                ),
            }}
        >
            {sectionsConfig.map(({name, label, icon}) => (
                <MainMenuDesktopTab
                    key={name}
                    hasSubMenu={true}
                    value={name}
                    label={label}
                    icon={icon}
                />
            ))}
        </MainMenuDesktop>
    );
}
