import {SvgIconComponent} from '@mui/icons-material';
import {SvgIcon} from '@mui/material';
import clsx from 'clsx';
export interface SelectionButtonIconProps {
    label: string;
    icon: SvgIconComponent;
    isSelected: boolean;
    disabled?: boolean;
}
export default function SelectionButtonIcon({
    label,
    isSelected,
    disabled,
    icon,
}: SelectionButtonIconProps) {
    return (
        <SvgIcon
            data-testid="selection-button-test"
            component={icon}
            className={clsx({
                isUnselected: !isSelected,
                disabled,
            })}
            sx={{
                height: '24px',
                width: '24px',
                marginRight: '4px',
                color: !isSelected ? 'grey.600' : 'primary.main.100',
                '&.isUnselected': {
                    '&.disabled': {
                        color: 'common.disabled',
                    },
                },
            }}
            aria-label={label}
        />
    );
}
