import ExportDesktopPopup from './ExportData/index';
import {ClickAwayListener, Popper, Box} from '@mui/material';
import TableDesktopColumnsManagmentPopup from './ColumnsManagment/index';
import {XLSIcon, CSVIcon, PDFIcon} from 'common/icons/index';
export type TableActionsDesktopPopupsProps = {
    initColumnsState: any;
    allColumns: any;
    isOpen: boolean;
    onClose: () => void;
    anchorEl: Element | null;
    onApply: (hiddenColumns: any, orderedColumns: any) => void;
    sortBy?: Array<{id: string; desc: Boolean}>;
    popperVariant: 'export' | 'columns-managment' | null;
    onDownload: (value: 'csv' | 'pdf' | 'xls') => void;
};
export default function TableActionsDesktopPopups({
    isOpen,
    onClose,
    anchorEl,
    onApply,
    popperVariant,
    allColumns,
    initColumnsState,
    onDownload,
}: TableActionsDesktopPopupsProps) {
    const selections: {
        label: string;
        icon: any;
        value: 'pdf' | 'csv' | 'xls';
    }[] = [
        {
            label: 'CSV',
            icon: <CSVIcon />,
            value: 'csv',
        },
        {
            label: 'PDF',
            icon: <PDFIcon />,
            value: 'pdf',
        },
        {
            label: 'XLS',
            icon: <XLSIcon />,
            value: 'xls',
        },
    ];

    const handlePopoverClickAway = () => {
        onClose();
    };

    return (
        <Popper
            open={isOpen}
            anchorEl={anchorEl}
            placement="bottom-start"
            style={{zIndex: 150000}}
        >
            <ClickAwayListener onClickAway={handlePopoverClickAway}>
                <Box>
                    {popperVariant === 'columns-managment' ? (
                        <TableDesktopColumnsManagmentPopup
                            onApply={(hiddenColumns, orderedColumns) => {
                                onApply(hiddenColumns, orderedColumns);
                                onClose();
                            }}
                            isOpen={isOpen}
                            onClose={onClose}
                            allColumns={allColumns}
                            initColumnsState={initColumnsState}
                        />
                    ) : (
                        <ExportDesktopPopup
                            onClose={onClose}
                            onClick={onDownload}
                            selections={selections}
                        />
                    )}
                </Box>
            </ClickAwayListener>
        </Popper>
    );
}
