import {SxProps, ToggleButton, Typography, useTheme} from '@mui/material';

import SelectionButtonIcon from './SelectionButtonIcon';
import {SvgIconComponent} from '@mui/icons-material';
import clsx from 'clsx';
import {grey} from 'common/colors';
import {useMemo} from 'react';

export type SelectionButtonProps = {
    sx?: SxProps;
    label: string;
    icon?: SvgIconComponent;
    isSelected: boolean;
    disabled?: boolean;
    onClick: (value: any) => void;
    isClickable?: boolean;
};

export default function SelectionButton({
    sx,
    label,
    icon,
    isSelected,
    disabled,
    onClick,
    isClickable,
}: SelectionButtonProps) {
    const theme = useTheme();
    const backgroundColor = useMemo(() => {
        if (disabled) {
            return grey[300];
        } else if (isSelected) {
            return theme.palette.primary.main;
        } else {
            return grey[100];
        }
    }, [disabled, isSelected, theme.palette.primary.main]);
    return (
        <ToggleButton
            data-testid="toggle-button"
            disabled={disabled}
            className={clsx({
                disabled: disabled,
                isUnselected: !isSelected,
            })}
            sx={{
                height: '32px',
                width: '115px',
                backgroundColor,
                cursor: isClickable ? 'pointer' : 'default',
                color: isSelected ? 'common.white' : 'grey.900',
                borderRadius: '100px',
                '&:hover': {
                    backgroundColor: (theme: any) =>
                        isClickable
                            ? theme.palette.common.dynamicDark.hover(
                                  backgroundColor
                              )
                            : backgroundColor,
                },
                '&:active': {
                    backgroundColor: (theme: any) =>
                        isClickable
                            ? theme.palette.common.dynamicDark.pressed(
                                  backgroundColor
                              )
                            : backgroundColor,
                },
                '&.isUnselected': {
                    border: `1px solid ${grey[800]}`,
                    color: grey[900],

                    '&:hover': {
                        backgroundColor: (theme) =>
                            theme.palette.common.dynamicLight.hover(
                                backgroundColor
                            ),
                    },
                    '&:active': {
                        backgroundColor: (theme) =>
                            theme.palette.common.dynamicLight.pressed(
                                backgroundColor
                            ),
                    },
                    '&.Mui-disabled': {
                        backgroundColor: grey[100],
                        color: 'common.disabled',
                        border: `1px solid ${theme.palette.common.disabled}`,
                    },
                },
                '&.Mui-disabled': {
                    color: 'common.disabled',
                    border: 'unset',
                },
                ...sx,
            }}
            onClick={onClick}
            value={label}
        >
            {icon && (
                <SelectionButtonIcon
                    label={label}
                    disabled={disabled}
                    isSelected={isSelected}
                    icon={icon}
                />
            )}
            <Typography variant="button" textTransform="initial">
                {label}
            </Typography>
        </ToggleButton>
    );
}
