import {Box, Stack, Typography} from '@mui/material';
import {IconButton} from 'common/components';
import {DropdownCloseIcon} from 'common/icons';
import {format, getYear} from 'date-fns';
import {ReactDatePickerCustomHeaderProps} from 'react-datepicker';

export default function DateRangeHeader({
    monthDate,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    customHeaderCount,
}: ReactDatePickerCustomHeaderProps) {
    return (
        <Stack direction="row" justifyContent="space-between">
            <IconButton
                onClick={decreaseMonth}
                hidden={customHeaderCount === 1}
                disabled={prevMonthButtonDisabled}
                sx={{
                    visibility:
                        customHeaderCount === 1 ? 'hidden' : 'inherit',
                }}
            >
                <DropdownCloseIcon sx={{transform: 'rotate(90deg)'}} />
            </IconButton>
            <Box
                sx={{
                    pl: '16px',
                    pb: '10px',
                    pt: '12px',
                    pr: '11px',
                    color: 'grey.900',
                }}
            >
                <Typography pr="8px" variant="button">
                    {`${format(monthDate, 'MMMM')} ${getYear(monthDate)}`}
                </Typography>
            </Box>
            <IconButton
                sx={{
                    visibility:
                        customHeaderCount === 0 ? 'hidden' : 'inherit',
                }}
                hidden={customHeaderCount === 0}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
            >
                <DropdownCloseIcon sx={{transform: 'rotate(-90deg)'}} />
            </IconButton>
        </Stack>
    );
}
