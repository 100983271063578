import {FetchBaseQueryError} from '@reduxjs/toolkit/query';

function isFetchBaseQueryError(
    error: unknown
): error is FetchBaseQueryError {
    return (
        typeof error === 'object' && error !== null && 'status' in error
    );
}

function isErrorWithMessage(error: unknown): error is {Message: string} {
    return (
        typeof error === 'object' &&
        error !== null &&
        'Message' in error &&
        typeof (error as any).Message === 'string'
    );
}

function isFetchError(error: unknown): error is {message: string} {
    return (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        typeof (error as any).message === 'string'
    );
}

function isZodError(error: unknown): error is {message: string} {
    if (typeof error === 'object' && error !== null && 'name' in error) {
        return (error as {name: string}).name === 'ZodError';
    }
    return false;
}

export function getErrorMessage(error: unknown) {
    if (isFetchBaseQueryError(error)) {
        return 'error' in error ? error.error : JSON.stringify(error.data);
    } else if (isZodError(error)) {
        return 'Validation Error';
    } else if (isFetchError(error)) {
        return error.message;
    } else if (isErrorWithMessage(error)) {
        return error.Message;
    } else if (error) {
        return 'Unhandled error';
    }
}
