import {
    Autocomplete,
    AutocompleteChangeReason,
    AutocompleteRenderInputParams,
    Box,
    ClickAwayListener,
    PopperProps,
    styled,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {Base, DropdownProps, Value, getStringFromValue} from '../index';
import {
    HTMLAttributes,
    ReactElement,
    ReactNode,
    SyntheticEvent,
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Tooltip, {
    TooltipProps,
    tooltipClasses,
} from '@mui/material/Tooltip';

import DropdownListbox from './DropdownListbox';
import DropdownNoResults from '../DropdownNoResults';
import DropdownPopper from '../DropdownPopper';
import DropdownSearchInput from '../DropdownSearchInput';
import DropdownTriggerButton from '../DropdownTriggerButton/DropdownTriggerButton';
import DropdownTriggerInput from '../DropdownTriggerInput/DropdownTriggerInput';
import {grey} from 'common/colors';

type ListboxRenderer = (
    props: HTMLAttributes<HTMLElement>
) => ReactElement<any, any> | null;

function PopperComponent(props: PopperProps) {
    const {disablePortal, anchorEl, open, ...other} = props;
    return (
        <Box
            className="popper-component"
            {...other}
            style={{width: '100%'}}
        />
    );
}

const StyledAutocomplete = styled(Autocomplete)(({theme: {spacing}}) => ({
    '& .MuiOutlinedInput-root': {
        padding: 0,
    },
    '&.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root': {
        paddingRight: 0,
    },
})) as typeof Autocomplete;
const StyledTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        marginLeft: 70,
    },
}));
const characterWidthPx = 6.7;

export type OptionRendererProps<T> = [
    HTMLAttributes<HTMLLIElement>,
    T,
    boolean,
    DropdownProps<T, false, false>['selectionVariant'],
    number
];

export default function DropdownVirtualized<
    T extends Base,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined
>({
    disabled,
    clearable,
    formatLabel,
    onChange,
    options,
    placeholder,
    backgroundColor = grey[100],
    selectionVariant,
    value,
    autoExpand,
    variant,
    width = 320,
}: DropdownProps<T, Multiple, DisableClearable>) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [inputAutoWidth, setInputAutoWidth] = useState<number>(320);
    const [inputValue, setInputValue] = useState('');
    const [tooltip, setTooltip] = useState('');
    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            !disabled && setAnchorEl(e.currentTarget);
        },
        [disabled]
    );
    const handleClose = useCallback(() => {
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    }, [anchorEl, setAnchorEl]);
    const handleSearchChange = useCallback(
        (searchedValue: string) => {
            setInputValue(searchedValue);
        },
        [setInputValue]
    );
    const open = Boolean(anchorEl);
    const showSearchBar = useMemo(() => options.length > 7, [options]);
    const {
        breakpoints: {up},
    } = useTheme();
    const isMobile = !useMediaQuery(up('desktopSm'));
    const handleChange = useCallback(
        (
            _event: SyntheticEvent<Element, Event>,
            value: Value<T, Multiple, DisableClearable>,
            _reason: AutocompleteChangeReason
        ) => {
            if (!isMobile) {
                var valueAsStringArray = value as string[];
                if (
                    valueAsStringArray.toString().length *
                        characterWidthPx >
                    width
                ) {
                    setTooltip(valueAsStringArray.join(', '));
                }
            }
            if (selectionVariant !== 'multiple') {
                handleClose();
            }
            onChange(value);
        },
        [isMobile, selectionVariant, onChange, width, handleClose]
    );
    const controlLabel = useMemo(() => {
        if (value !== null) {
            if (Array.isArray(value)) {
                return value.map(formatLabel).join(', ');
            } else {
                return value ? formatLabel(value as T) : '';
            }
        }
        return '';
    }, [value, formatLabel]);
    const isMultiple = selectionVariant === 'multiple';
    const listComponent: ListboxRenderer = useMemo(
        () =>
            forwardRef((props, ref) => {
                return (
                    <DropdownListbox
                        ref={ref}
                        showSearchBar={showSearchBar}
                        {...props}
                        selectionVariant={selectionVariant}
                        options={options}
                        width={width}
                        searchInput={inputValue}
                        selectedOptions={value}
                        onChange={onChange}
                    />
                );
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [options, selectionVariant, showSearchBar, width, inputValue]
    );
    const inputComponent = useCallback(
        (params: AutocompleteRenderInputParams) => (
            <DropdownSearchInput
                onSearchChange={handleSearchChange}
                params={params}
                showSearchBar={showSearchBar}
            />
        ),
        [showSearchBar, handleSearchChange]
    );
    const renderOption = useCallback(
        (
            props: HTMLAttributes<HTMLLIElement>,
            option: T,
            {selected}: {selected: boolean}
        ) => [props, option, selected, selectionVariant, width],
        [selectionVariant, width]
    );
    const noOptionsText: ReactNode = useMemo(
        () => (
            <Box height={240}>
                <DropdownNoResults />
            </Box>
        ),
        []
    );
    const handleControlChange = useCallback(
        (value: string) => {
            // clear gets clicked
            if (value === '' && selectionVariant === 'multiple') {
                onChange(
                    [] as unknown as Value<T, Multiple, DisableClearable>
                );
            }
        },
        [selectionVariant, onChange]
    );
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputRef.current) {
            setInputAutoWidth(inputRef.current.offsetWidth);
        }
    }, [inputRef?.current?.offsetWidth]);
    const buttonRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        if (buttonRef.current) {
            setInputAutoWidth(buttonRef.current.offsetWidth);
        }
    }, [buttonRef?.current?.offsetWidth]);
    const getDropdownPopperWidth = useCallback(() => {
        if (autoExpand) {
            if (isMultiple) {
                return inputAutoWidth + 85;
            } else {
                return inputAutoWidth + 50;
            }
        }
        if (typeof width === 'number') {
            return width - 2;
        }
        if (typeof width === 'string') {
            if (isMultiple) {
                return inputAutoWidth + 48 + 40;
            }
            return inputAutoWidth + 48;
        }
        return width;
    }, [autoExpand, inputAutoWidth, isMultiple, width]);
    let dropdownTriggerComponent = null;
    if (variant === 'outlined') {
        dropdownTriggerComponent = (
            <StyledTooltip
                arrow
                enterDelay={1000}
                placement="bottom"
                title={open ? '' : tooltip}
            >
                <div>
                    <DropdownTriggerInput
                        clearable={clearable}
                        autoExpand={autoExpand}
                        disabled={disabled}
                        controlLabel={controlLabel}
                        onClick={handleClick}
                        onControlChange={handleControlChange}
                        open={open}
                        placeholder={placeholder}
                        ref={inputRef}
                        backgroundColor={backgroundColor}
                        width={width}
                    />
                </div>
            </StyledTooltip>
        );
    } else {
        dropdownTriggerComponent = (
            <DropdownTriggerButton
                autoExpand={autoExpand}
                backgroundColor={backgroundColor}
                clearable={clearable}
                disabled={disabled}
                controlLabel={controlLabel}
                onClick={handleClick}
                onControlChange={handleControlChange}
                open={open}
                placeholder={placeholder}
                ref={buttonRef}
                width={width}
            />
        );
    }
    return (
        <>
            <Box
                data-testid="mui-dropdown-test"
                sx={{flexGrow: autoExpand ? 1 : undefined}}
            >
                {dropdownTriggerComponent}
            </Box>
            <DropdownPopper
                anchorEl={anchorEl}
                open={open}
                placement="bottom-start"
                showSearchBar
                width={getDropdownPopperWidth()}
                sx={{
                    pr: 0,
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <div
                        data-testid="dropdown-test"
                        style={{
                            backgroundColor: 'white',
                            borderRadius: '4px',
                        }}
                    >
                        <StyledAutocomplete<
                            T,
                            Multiple,
                            DisableClearable,
                            false
                        >
                            open
                            ListboxComponent={listComponent}
                            PopperComponent={PopperComponent}
                            disableCloseOnSelect={isMultiple}
                            forcePopupIcon={false}
                            fullWidth
                            inputValue={inputValue}
                            multiple={isMultiple as Multiple}
                            noOptionsText={noOptionsText}
                            onChange={handleChange}
                            options={options}
                            renderInput={inputComponent}
                            renderOption={renderOption}
                            componentsProps={{
                                paper: {
                                    elevation: 0,
                                },
                            }}
                            getOptionLabel={(option) => {
                                if (
                                    Array.isArray(option) &&
                                    option.length === 0
                                ) {
                                    return '';
                                }
                                return option ? formatLabel(option) : '';
                            }}
                            isOptionEqualToValue={(option, value) => {
                                return (
                                    getStringFromValue(option) ===
                                    getStringFromValue(value)
                                );
                            }}
                            onClose={() => {
                                handleSearchChange('');
                            }}
                            onInputChange={(e, value, reason) => {
                                if (reason !== 'reset') {
                                    handleSearchChange(value);
                                }
                            }}
                            value={
                                value as Value<
                                    T,
                                    Multiple,
                                    DisableClearable
                                >
                            }
                        />
                    </div>
                </ClickAwayListener>
            </DropdownPopper>
        </>
    );
}
