import {Box, SxProps, useTheme} from '@mui/material';
import {grey} from 'common/colors';
import {useMemo} from 'react';
import ReactDatepicker, {ReactDatePickerProps} from 'react-datepicker';
import DatePickerContainer from '../DatePickerContainer';

export interface DatePickerBaseProps extends ReactDatePickerProps {
    sx?: SxProps;
    rangeSelector?: boolean;
    autoExpand?: boolean;
}

export default function DatePickerBase({...props}: DatePickerBaseProps) {
    const theme = useTheme();
    const theming = useMemo(() => {
        return {
            '.react-datepicker__input-container': {width: '100%'},
            '.react-datepicker__day-names': {
                color: grey[600],
                ...theme.typography.caption,
            },
            '.react-datepicker__day--selected,.react-datepicker__day--in-selecting-range,.react-datepicker__day--in-range,.react-datepicker__month-text--selected,.react-datepicker__month-text--in-selecting-range,.react-datepicker__month-text--in-range,.react-datepicker__quarter-text--selected,.react-datepicker__quarter-text--in-selecting-range,.react-datepicker__quarter-text--in-range,.react-datepicker__year-text--selected,.react-datepicker__year-text--in-selecting-range,.react-datepicker__year-text--in-range':
                {
                    backgroundColor: grey[600],
                },
            '.react-datepicker__day--today,.react-datepicker__month-text--today,.react-datepicker__quarter-text--today,.react-datepicker__year-text--today':
                {
                    borderColor: grey[600],
                },
            '.react-datepicker__day:hover,.react-datepicker__month-text:hover,.react-datepicker__quarter-text:hover,.react-datepicker__year-text:hover ':
                {
                    backgroundColor: theme.palette.common.light.hover,
                },
            '.react-datepicker__day:active,.react-datepicker__month-text:active,.react-datepicker__quarter-text:active,.react-datepicker__year-text:active ':
                {
                    backgroundColor: theme.palette.common.light.pressed,
                },
            '.react-datepicker': {
                color: 'grey[900]',
                height: '338px',
                scrollbarColor: `${theme.palette.grey[800]} ${grey[100]}`,
                ...theme.typography.body2,
            },
            '.react-datepicker__day--selected:hover,.react-datepicker__day--in-selecting-range:hover,.react-datepicker__day--in-range:hover,.react-datepicker__month-text--selected:hover,.react-datepicker__month-text--in-selecting-range:hover,.react-datepicker__month-text--in-range:hover,.react-datepicker__quarter-text--selected:hover,.react-datepicker__quarter-text--in-selecting-range:hover,.react-datepicker__quarter-text--in-range:hover,.react-datepicker__year-text--selected:hover,.react-datepicker__year-text--in-selecting-range:hover,.react-datepicker__year-text--in-rang:hover':
                {
                    backgroundColor: grey[600],
                },
            '.react-datepicker__day--range-end.react-datepicker__day--in-range':
                {
                    backgroundColor: grey[400],
                    color: grey[900],
                    border: 'none',
                },
            '.react-datepicker__day--in-range:not(.react-datepicker__day--range-end):not(.react-datepicker__day--range-start)':
                {
                    backgroundColor: grey[400],
                    color: grey[900],
                    borderRadius: '0px',
                },
            '.react-datepicker__day--range-start.react-datepicker__day--in-range':
                {
                    backgroundColor: grey[400],
                    color: grey[900],
                    borderTop: 'none',
                    borderBottom: 'none',
                    borderLeft: 'none',
                },
            '.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start):not(.react-datepicker__day--selecting-range-end)':
                {
                    borderTop: `1px dashed ${grey[400]}`,
                    borderBottom: `1px dashed ${grey[400]}`,
                    backgroundColor: 'white',
                    color: grey[900],
                    borderRadius: '0px',
                },
            '.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start:not(.react-datepicker__day--range-start,.react-datepicker__day--range-end)':
                {
                    border: `1px dashed ${grey[400]}`,
                    borderRight: 'none',
                    backgroundColor: 'white',
                    color: grey[900],
                },
            '.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--selecting-range-start,.react-datepicker__days--selecting-range-end)':
                {
                    border: `1px dashed ${grey[400]}`,
                    borderLeft: 'none',
                    backgroundColor: 'white',
                    color: grey[900],
                },
            '.react-datepicker__day--in-selecting-range.react-datepicker__day--range-start.react-datepicker__day--range-end:not(.react-datepicker__day--selecting-range-start,.react-datepicker__day--selecting-range-end)':
                {
                    borderRadius: '18px',
                    width: '32px',
                    px: '0px',
                    mx: '4px',
                },
            '.react-datepicker__day--today.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range.react-datepicker__day--today':
                {
                    border: 'none',
                },
            '.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--in-range)':
                {
                    border: `1px dashed ${grey[400]}`,
                    borderRight: 'none',
                    borderRadius: '18px 0 0 18px',
                    color: grey[900],
                },
            '.react-datepicker-popper': {
                zIndex: theme.zIndex.tooltip,
            },
            ...props?.sx,
        };
    }, [theme, props.sx]);

    return (
        <Box
            sx={{
                display: 'inline-block',
                ...theming,
                width: props.autoExpand ? '100%' : 'fit-content',
            }}
        >
            <ReactDatepicker
                selectsRange={props.rangeSelector}
                formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
                showPopperArrow={false}
                placeholderText="dd / mm / yyyy"
                dateFormat="dd / MM / yyyy"
                calendarContainer={DatePickerContainer}
                allowSameDay={false}
                calendarStartDay={1}
                {...props}
            />
        </Box>
    );
}
