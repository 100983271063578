import {useMemo, useCallback} from 'react';
import {Row, SortingRule} from 'react-table';
import Table, {
    UnknownObject,
    TableRowContainer,
    TableRowActions,
    Entry,
} from 'common/components/Table';
import {Box} from '@mui/material';
import {HierarchyLevel} from 'modules/admin/config/hierarchyKeys';
import infrastructureTableColumns from './infrastructureTableColumns';
import {limitOptions} from 'common/components/Pagination';

export interface InfrastructureTableProps {
    height: string;
    isDesktopOpen: boolean;
    data: Entry[];
    hierarchy: HierarchyLevel;
    isEmptyState?: boolean;
    errorMessage?: string | null;
    tableInitialState: UnknownObject;
    isLoading: boolean;
    sortBy?: Array<{id: string; desc: Boolean}>;
    onAddRow: (type: HierarchyLevel, row?: Entry) => void;
    onEditRow: (type: HierarchyLevel, row: Entry) => void;
    onDeleteRow: (row: Row<Entry>) => void;
    onClickExpander: (row: Row<Entry>) => void;
    onChangeSorting?: (columnProps: SortingRule<Entry>) => void;
    dataTotal?: number;
    onPageChange: (page: number) => void;
    onLimitChange: (limit: limitOptions) => void;
}

export default function InfrastructureTable({
    height,
    isDesktopOpen,
    data: tableData,
    hierarchy,
    onAddRow,
    onEditRow,
    isLoading,
    onDeleteRow,
    isEmptyState,
    onClickExpander,
    tableInitialState,
    errorMessage,
    dataTotal,
    onPageChange,
    onLimitChange,
    onChangeSorting = (columnProps) => {},
    sortBy,
}: InfrastructureTableProps) {
    const allHierarchyInfrastructureColumns = useMemo(
        () => [
            ...infrastructureTableColumns[hierarchy],
            {
                id: '_actions_',
                className: 'col-actions-table',
                Cell: ({row}: {row: Row<Entry>}) => (
                    <TableRowActions
                        type={hierarchy}
                        showWeatherIndicator={true}
                        row={row}
                        onClickEdit={(row) => {
                            onEditRow(hierarchy, row);
                        }}
                        onClickDelete={onDeleteRow}
                        onClickVisible={() => {}}
                        hierarchy={[hierarchy]}
                    />
                ),
                maxWidth: 192,
                minWidth: 192,
            },
        ],
        [onEditRow, onDeleteRow, hierarchy]
    );

    const renderRowSubComponent = useCallback(
        ({row, rowProps, visibleInfrastructureColumns}) => {
            return (
                <TableRowContainer
                    colSpan={visibleInfrastructureColumns.length}
                    sx={{
                        minHeight: '50px',
                        p: 2,
                    }}
                >
                    Content for {row.values.id}
                </TableRowContainer>
            );
        },
        []
    );

    const initialState = useMemo(
        () => tableInitialState,
        [tableInitialState]
    );

    return (
        <Box height={height} width="100%">
            <Table
                dataTotal={dataTotal}
                errorMessage={errorMessage}
                borderCollapse="initial"
                stickyHeader
                onPageChange={onPageChange}
                onLimitChange={onLimitChange}
                columns={allHierarchyInfrastructureColumns}
                data={tableData}
                initialState={initialState}
                label="Buildings"
                onChangeSorting={onChangeSorting}
                renderRowSubComponent={renderRowSubComponent}
                isLoading={isLoading}
                sortBy={sortBy}
                isEmptyState={isEmptyState}
                controlled={true}
                hierarchyLevel={hierarchy}
                onAddRow={onAddRow}
                onEditRow={onEditRow}
            />
        </Box>
    );
}
