import {AutocompleteValue} from '@mui/material';
import {ComponentClass, FunctionComponent} from 'react';
import DropdownSimple from './DropdownSimple/index';
import DropdownVirtualized from './DropdownVirtualized/index';

export type Base = {value: string} | string;

export type Value<T, Multiple, DisableClearable> = AutocompleteValue<
    T,
    Multiple,
    DisableClearable,
    false
>;

type SelectionVariant = 'unique' | 'multiple';

export type ItemRendererProps<T> = {
    option: T;
    label: string;
    value: string;
    selected: boolean;
    selectionVariant: SelectionVariant;
    width: number;
};

export type DropdownProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined
> = {
    virtualized?: boolean;
    clearable?: boolean;
    formatLabel: (value: T) => string;
    variant: 'outlined' | 'standard';
    selectionVariant: SelectionVariant;
    ItemRenderer?:
        | FunctionComponent<ItemRendererProps<T>>
        | ComponentClass<ItemRendererProps<T>>;
    readonly options: T[];
    readonly value: Multiple extends true ? T[] : T | null;
    width?: number | string;
    onChange: (value: Value<T, Multiple, DisableClearable>) => void;
    disabled?: boolean;
    searchPlaceholder?: string;
    backgroundColor?: string;
    placeholder?: string;
    autoExpand?: boolean;
    supportDynamicHeight?: boolean;
};

export type DropdownSingleProps<T> = Omit<
    DropdownProps<T, false, true>,
    'selectionVariant'
>;
export type DropdownMultipleProps<T> = Omit<
    DropdownProps<T, true, false>,
    'selectionVariant'
>;

const isStringValue = <TValue extends Base>(
    value: TValue | string
): value is string => {
    return typeof value === 'string';
};

export function getStringFromValue<TValue extends Base>(value: TValue) {
    if (isStringValue(value)) {
        return value;
    }
    return value.value;
}

export function DropdownSingle<T extends Base = Base>(
    props: DropdownSingleProps<T>
) {
    const singleProps = {
        ...props,
        selectionVariant: 'unique' as SelectionVariant,
    };
    return props.virtualized ? (
        <DropdownVirtualized<T, false, true> {...singleProps} />
    ) : (
        <DropdownSimple<T, false, true> {...singleProps} />
    );
}

export function DropdownMultiple<T extends Base = Base>(
    props: DropdownMultipleProps<T>
) {
    const multipleProps = {
        ...props,
        selectionVariant: 'multiple' as SelectionVariant,
    };
    return props.virtualized ? (
        <DropdownVirtualized<T, true, false> {...multipleProps} />
    ) : (
        <DropdownSimple<T, true, false> {...multipleProps} />
    );
}
