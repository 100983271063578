import {useContext} from 'react';
import {OrgSelectorContext} from 'modules/admin/containers/InfrastructureContainer';
import _ from 'lodash';
import {DropdownSingle} from 'common/components/Dropdown';
import {OrganizationRenderer} from 'common/components/Dropdown/ItemRenderers';
import {useMediaQuery, useTheme} from '@mui/material';

export type OrganizationOption = {
    value: string;
    Name: string;
    Url: string;
    CreationDate?: string;
};

export default function OrganizationSelector() {
    const {
        allOrganizations,
        handleOrgSelection,
        isLoading,
        selectedOrganization,
    } = useContext(OrgSelectorContext);

    const organizationToOption = (org: any) => {
        return {
            value: String(org.id),
            Name: org.name,
            Url: org.url,
            CreationDate: org.created,
        };
    };

    const options: OrganizationOption[] = _.map(
        allOrganizations || [],
        organizationToOption
    );
    const {
        breakpoints: {down},
        palette,
    } = useTheme();
    const isMobile = useMediaQuery(down('mobile'));

    return (
        <DropdownSingle<OrganizationOption>
            backgroundColor={palette.primary.light}
            value={
                selectedOrganization
                    ? organizationToOption(selectedOrganization)
                    : null
            }
            options={options}
            width={isMobile ? 190 : 227}
            disabled={isLoading}
            formatLabel={(option) => option.Name}
            variant="standard"
            ItemRenderer={OrganizationRenderer}
            onChange={(org) => {
                handleOrgSelection(_.toInteger(org.value));
            }}
        />
    );
}
