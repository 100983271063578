import {AscendingIcon, DescendingIcon} from 'common/icons/index';
import {
    Box,
    Table as MuiTable,
    Paper,
    Skeleton,
    TableBody,
    TableCell,
    TableContainer,
    TableContainerProps,
    TableHead,
    TableRow,
    TableRowProps,
    TableSortLabel,
    Typography,
    tableCellClasses,
} from '@mui/material';
import {
    Column,
    HeaderGroup,
    Row,
    SortingRule,
    useColumnOrder,
    useExpanded,
    useSortBy,
    useTable,
} from 'react-table';
import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {Area} from 'common/components';
import {Entry} from '../index';
import {HierarchyLevel} from 'modules/admin/config/hierarchyKeys';
import TableActionsDesktop from './TableActionsMenu/TableActions/index';
import TableActionsDesktopPopups from './TableActionsMenu/TablePopups/index';
import _ from 'lodash';
import {limitOptions} from 'common/components/Pagination/index';
import {styled} from '@mui/material/styles';
import useMoveColumn from '../useMoveColumn';
import EmptyState from 'common/components/EmptyStates';

export const CUSTOM_COLUMNS = ['_actions_', '_menu_'];

export type UnknownObject = {[key: string]: unknown};

// eslint-disable-next-line @typescript-eslint/ban-types
export type AnyObject = object;

export interface RowSubComponentProps<
    T extends AnyObject = UnknownObject
> {
    row: Row<T>;
    rowProps: TableRowProps;
    visibleColumns: any;
}

export type TableDesktopProps<T extends AnyObject = UnknownObject> = {
    limit: limitOptions;
    data: T[];
    stickyHeader?: boolean;
    columns: Array<Column<T>>;
    initialState?: Partial<{sortBy: Array<SortingRule<T>>}>;
    manualSortBy?: boolean;
    borderCollapse?: string;
    disableSortRemove?: boolean;
    label?: string;
    onChangeSorting?: (sortingProps: SortingRule<T>) => void;
    onLimitChange: (limit: limitOptions) => void;
    caption?: React.ReactNode | string;
    renderRowSubComponent?: (props: RowSubComponentProps<T>) => void;
    isLoading?: boolean;
    onPageChange: (page: number) => void;
    page: number;
    controlled?: boolean;
    dataTotal?: number;
    onAddRow: (type: HierarchyLevel, row?: Entry) => void;
    hierarchyLevel?: HierarchyLevel;
    errorMessage?: string | null;
    isEmptyState?: boolean;
};

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[900],
        boxShadow: 'inset -1px 0px 0px #EEEEEE',
        span: {
            color: theme.palette.grey[900],
            borderRadius: '4px',
            padding: '2px 0px 2px 0px',

            '&:hover': {
                backgroundColor: theme.palette.common.light.hover,
            },
            '&:active': {
                backgroundColor: theme.palette.common.light.pressed,
            },
        },
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

type TableDesktopSkeletonProps<T extends AnyObject> = {
    headers?: HeaderGroup<T>[];
    rowNumber: number;
};

export default function TableDesktop<T extends AnyObject = UnknownObject>({
    limit,
    columns,
    data,
    errorMessage,
    page,
    isEmptyState,
    caption,
    stickyHeader,
    onChangeSorting = (sortingProps: SortingRule<T>) => {},
    manualSortBy = true,
    borderCollapse,
    disableSortRemove = true,
    controlled,
    dataTotal,
    initialState = {},
    label = 'table',
    sx,
    renderRowSubComponent = () => {},
    isLoading,
    onPageChange = () => {},
    onLimitChange = () => {},
    onAddRow,
    hierarchyLevel = 'buildings',
}: TableDesktopProps<T> & TableContainerProps) {
    const [downloadAs, setDownloadType] = useState<'csv' | 'pdf' | 'xls'>(
        'pdf'
    );
    const [popperVariant, setPopperVariant] = useState<
        'export' | 'columns-managment' | null
    >(null);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const TableInstance = useTable(
        {columns, data, manualSortBy, disableSortRemove, initialState},
        useColumnOrder,
        useMoveColumn,
        useSortBy,
        useExpanded
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        visibleColumns,
        prepareRow,
        allColumns,
        state: {sortBy},
        toggleHideColumn,
        setHiddenColumns,
        setColumnOrder,
    } = TableInstance;

    const firstHeaderGroup = _.first(headerGroups);

    const handleChangeSorting = useCallback(
        (sortingProps: SortingRule<T>) => {
            onChangeSorting(sortingProps);
        },
        [onChangeSorting]
    );

    useEffect(() => {
        if (sortBy.length === 1) {
            handleChangeSorting(sortBy[0]);
        }
    }, [sortBy, handleChangeSorting]);

    const TableDesktopSkeleton = useCallback(
        ({headers, rowNumber}: TableDesktopSkeletonProps<T>) => {
            return (
                <TableBody data-testid="table-skeleton">
                    {_.range(rowNumber).map((index) => {
                        return (
                            <React.Fragment key={index + '-fragment'}>
                                <TableRow>
                                    {_.map(headers, (column: any) => {
                                        const {maxWidth, minWidth} =
                                            column;
                                        return (
                                            <TableCell
                                                key={column.Header + index}
                                                sx={{
                                                    maxWidth,
                                                    minWidth,
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow:
                                                        'ellipsis',
                                                }}
                                            >
                                                <Skeleton variant="rectangular"></Skeleton>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </React.Fragment>
                        );
                    })}
                </TableBody>
            );
        },
        []
    );

    const handleApplyChanges = (
        hiddenColumns: any,
        orderedColumns: any
    ) => {
        _.forEach(hiddenColumns, (id) => {
            //Update react table columns show state
            toggleHideColumn(id, true);
        });

        //Apply hide in the table
        setHiddenColumns(hiddenColumns);
        //Apply ordering
        setColumnOrder(orderedColumns);
    };

    const handlePopperClose = () => {
        setPopperVariant(null);
    };

    const handleColumnsManagementMenuClicked = () => {
        if (popperVariant === 'columns-managment') {
            setPopperVariant(null);
        } else {
            setPopperVariant('columns-managment');
        }
    };

    const handleExportClicked = () => {
        if (popperVariant === 'export') {
            setPopperVariant(null);
        } else {
            setPopperVariant('export');
        }
    };

    const handleDownload = (value: 'csv' | 'pdf' | 'xls') => {
        console.log('downloadAs->', downloadAs);
        setDownloadType(value);
    };

    let start = (page - 1) * limit;
    let end = start + limit;
    let limitedRows = controlled ? rows : _.slice(rows, start, end);
    const emptyState = useMemo(() => {
        if (!isLoading && (isEmptyState || errorMessage)) {
            return (
                <EmptyState
                    type={errorMessage?'noData':'noResults'}
                    errorCode={errorMessage}
                />
            );
        }
    }, [errorMessage, isEmptyState, isLoading]);
    return (
        <Fragment>
            <TableActionsDesktopPopups
                isOpen={!_.isNull(popperVariant)}
                onClose={handlePopperClose}
                onApply={handleApplyChanges}
                anchorEl={anchorEl}
                allColumns={allColumns}
                initColumnsState={columns}
                popperVariant={popperVariant}
                onDownload={handleDownload}
            />
            <Box
                id="table-wrapper-test"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    bgcolor: 'grey.100',
                    borderRadius: '8px',
                    p: 2,
                }}
            >
                <TableActionsDesktop
                    pressedButtonsrc={popperVariant}
                    onExportClick={(event: any) => {
                        setAnchorEl(event.currentTarget as Element);
                        handleExportClicked();
                    }}
                    onAddRow={onAddRow}
                    onLimitChange={onLimitChange}
                    onPageChange={onPageChange}
                    onColsManagmentClick={(event: any) => {
                        setAnchorEl(event.currentTarget as Element);
                        handleColumnsManagementMenuClicked();
                    }}
                    total={
                        controlled && dataTotal ? dataTotal : data.length
                    }
                    page={page}
                    limit={limit}
                    hierarchyLevel={hierarchyLevel}
                />
                <TableContainer
                    id="table-container-test"
                    data-testid="infrastucture-table-container"
                    component={Paper}
                    sx={{
                        ...sx,
                        height: '100%',
                        mt: 2,
                        boxShadow: 'none',
                    }}
                >
                    {emptyState ? (
                        emptyState
                    ) : (
                        <Area
                            variant="lower"
                            sx={{
                                width: '100%',
                            }}
                        >
                            <MuiTable
                                {...getTableProps()}
                                stickyHeader
                                aria-label={label}
                                sx={
                                    {
                                        borderCollapse,
                                        borderLeft: '1px solid #eeeeee',
                                    } as any
                                }
                                size="small"
                                className="contained"
                            >
                                {caption && <caption>{caption}</caption>}
                                <TableHead>
                                    {_.map(headerGroups, (headerGroup) => {
                                        let visibleHeaders = _.filter(
                                            headerGroup.headers,
                                            (header) => {
                                                return (
                                                    _.findIndex(
                                                        visibleColumns,
                                                        (column) => {
                                                            return (
                                                                column.id ===
                                                                header.id
                                                            );
                                                        }
                                                    ) !== -1
                                                );
                                            }
                                        );

                                        return (
                                            <TableRow
                                                {...headerGroup.getHeaderGroupProps()}
                                            >
                                                {visibleHeaders.map(
                                                    (column) => {
                                                        const {
                                                            maxWidth,
                                                            minWidth,
                                                        } = column;
                                                        return (
                                                            <StyledTableCell
                                                                sx={{
                                                                    maxWidth,
                                                                    minWidth,
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    textAlign:
                                                                        'left',
                                                                }}
                                                                {...column.getHeaderProps(
                                                                    column.getSortByToggleProps()
                                                                )}
                                                                className={
                                                                    column.className
                                                                }
                                                                onClick={() => {
                                                                    handleChangeSorting(
                                                                        {
                                                                            id: column.id,
                                                                            desc: !column.isSortedDesc,
                                                                        }
                                                                    );
                                                                }}
                                                                {...column.getHeaderProps(
                                                                    column.getSortByToggleProps()
                                                                )}
                                                            >
                                                                {column.canSort ? (
                                                                    <TableSortLabel
                                                                        id="header-content"
                                                                        active={
                                                                            column.isSorted
                                                                        }
                                                                        direction={
                                                                            column.isSortedDesc
                                                                                ? 'desc'
                                                                                : 'asc'
                                                                        }
                                                                        IconComponent={() => {
                                                                            return column.isSorted ? (
                                                                                column.isSortedDesc ? (
                                                                                    <DescendingIcon
                                                                                        sx={{
                                                                                            color: 'grey.600',
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <AscendingIcon
                                                                                        sx={{
                                                                                            color: 'grey.600',
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            ) : null;
                                                                        }}
                                                                        component="div"
                                                                        sx={{
                                                                            padding:
                                                                                '4px',
                                                                            borderRadius:
                                                                                '4px',
                                                                            bgcolor:
                                                                                'grey.200',
                                                                            '&:hover':
                                                                                {
                                                                                    bgcolor:
                                                                                        'common.light.hover',
                                                                                },
                                                                            '&:active':
                                                                                {
                                                                                    bgcolor:
                                                                                        'common.light.pressed',
                                                                                },
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant="caption"
                                                                            sx={{
                                                                                '&:hover':
                                                                                    {
                                                                                        bgcolor:
                                                                                            'transparent !important',
                                                                                    },
                                                                            }}
                                                                        >
                                                                            {column.render(
                                                                                'Header'
                                                                            )}
                                                                        </Typography>
                                                                    </TableSortLabel>
                                                                ) : (
                                                                    column.render(
                                                                        'Header'
                                                                    )
                                                                )}
                                                            </StyledTableCell>
                                                        );
                                                    }
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                </TableHead>
                                {isLoading && firstHeaderGroup && (
                                    <TableDesktopSkeleton
                                        headers={firstHeaderGroup?.headers}
                                        rowNumber={15}
                                    />
                                )}
                                {!isLoading && (
                                    <TableBody {...getTableBodyProps()}>
                                        {limitedRows.map((row) => {
                                            prepareRow(row);
                                            const rowProps =
                                                row.getRowProps();
                                            return (
                                                <React.Fragment
                                                    key={
                                                        row.getRowProps()
                                                            .key +
                                                        '-fragment'
                                                    }
                                                >
                                                    <TableRow
                                                        {...row.getRowProps()}
                                                    >
                                                        {row.cells.map(
                                                            (cell) => {
                                                                const {
                                                                    maxWidth,
                                                                    minWidth,
                                                                } =
                                                                    cell.column;
                                                                return (
                                                                    <TableCell
                                                                        sx={{
                                                                            maxWidth,
                                                                            minWidth,
                                                                            p: '0px 16px',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                            overflow:
                                                                                'hidden',
                                                                            textOverflow:
                                                                                'ellipsis',
                                                                            boxShadow:
                                                                                'inset -1px 0px 0px #EEEEEE',
                                                                        }}
                                                                        {...cell.getCellProps()}
                                                                        className={
                                                                            cell
                                                                                .column
                                                                                .className
                                                                        }
                                                                    >
                                                                        {cell.render(
                                                                            'Cell'
                                                                        )}
                                                                    </TableCell>
                                                                );
                                                            }
                                                        )}
                                                    </TableRow>
                                                    {row.isExpanded &&
                                                        renderRowSubComponent(
                                                            {
                                                                row,
                                                                rowProps,
                                                                visibleColumns,
                                                            }
                                                        )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </TableBody>
                                )}
                            </MuiTable>
                        </Area>
                    )}
                </TableContainer>
            </Box>
        </Fragment>
    );
}
