import {Box, styled, SvgIcon} from '@mui/material';
import {SvgIconComponent} from '@mui/icons-material';
import FabButton from 'common/components/FabButton/index';

export const LogoImage = styled('img')({
    display: 'block',
    width: '252px',
    height: '46px',
    padding: '8px 0',
    margin: 'auto',
});

export interface AppBarLogoProps {
    adminSelected?: boolean;
    menuIsOpen?: boolean;
    activeProductLabel?: string;
    activeProductIcon?: SvgIconComponent;
    togglerWidth: string | number;
    logoSrc: string;
    onClickMenuButton: () => void;
    onClickLogo: () => void;
}

export default function AppBarLogo({
    adminSelected,
    menuIsOpen,
    activeProductLabel,
    activeProductIcon,
    togglerWidth,
    onClickMenuButton,
    onClickLogo,
    logoSrc,
}: AppBarLogoProps) {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            borderRight="2px solid"
            borderColor="grey.200"
            bgcolor="grey.100"
            minWidth={togglerWidth}
            sx={{
                cursor: 'pointer',
            }}
        >
            <LogoImage
                onClick={onClickLogo}
                alt="YodiFEM logo"
                src={logoSrc}
            />
            <FabButton
                ariaLabel="Menu button"
                sx={{
                    width: '172px',
                    position: 'absolute',
                    top: '82px',
                    left: 'auto',
                    right: 'auto',
                }}
                isUnselected={adminSelected}
                icon={
                    activeProductIcon && (
                        <SvgIcon
                            component={activeProductIcon}
                            aria-label={activeProductLabel}
                        />
                    )
                }
                label={activeProductLabel || 'Select Module'}
                isOpen={menuIsOpen ?? false}
                onClick={onClickMenuButton}
            />
        </Box>
    );
}
