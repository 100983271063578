import {Redirect, Switch, Route} from 'react-router-dom';
import YodiTask from './modules/yoditask/containers/YodiTask';
import YodiSafe from './modules/yodisafe/containers/YodiSafe';
import YodiEnergy from './modules/yodienergy/containers/YodiEnergy';
import YodiSpace from './modules/yodispace/containers/YodiSpace';

import YodideskRoutes from './modules/yodidesk';
import YodisenseRoutes from './modules/yodisense';
import AdminRoutes from './modules/admin';

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/yodidesk"></Redirect>
            </Route>
            <Route path="/yodidesk">
                <YodideskRoutes />
            </Route>
            <Route path="/yoditask">
                <YodiTask />
            </Route>
            <Route path="/yodisafe">
                <YodiSafe />
            </Route>
            <Route path="/yodisense">
                <YodisenseRoutes />
            </Route>
            <Route path="/yodienergy">
                <YodiEnergy />
            </Route>
            <Route path="/yodispace">
                <YodiSpace />
            </Route>
            <Route path="/admin">
                <AdminRoutes />
            </Route>
            <Route path="*"></Route>
        </Switch>
    );
}
