import {Box, Stack, Typography, useTheme} from '@mui/material';
import {DropdownCloseIcon} from 'common/icons';
import {ReactElement} from 'react';

export interface MenuOptionProps {
    icon?: ReactElement;
    label: string;
    selected: boolean;
    isRoot?: boolean;
    isExpanded?: boolean;
}

const MenuOptionBody = ({
    selected,
    icon,
    label,
    isRoot = false,
    isExpanded = false,
}: MenuOptionProps) => {
    const theme = useTheme();
    const primary = theme.palette.primary.main;
    return (
        <Stack
            height="100%"
            alignContent="center"
            spacing="6px"
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
        >
            {selected ? (
                <Box
                    flexGrow="0"
                    sx={{
                        width: '4px',
                        height: '32px',
                        borderWidth: `2px`,
                        borderStyle: 'solid',
                        borderColor: primary,
                        borderRadius: '0 24px 24px 0',
                    }}
                />
            ) : (
                <Box
                    flexGrow="0"
                    sx={{
                        width: '4px',
                        height: '32px',
                    }}
                />
            )}
            {icon && (
                <Box
                    flexGrow="0"
                    width="30px"
                    height="30px"
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    bgcolor={(theme) =>
                        selected || isRoot
                            ? theme.palette.primary.light
                            : 'white'
                    }
                    borderRadius="8px"
                >
                    {icon}
                </Box>
            )}
            <Typography
                flexGrow="3"
                sx={{
                    width: '45%',
                    verticalAlign: 'middle',
                    lineHeight: 'normal',
                    color: selected || isRoot ? primary : 'black',
                    fontSize: '10px',
                }}
            >
                {label}
            </Typography>
            {isRoot && (
                <DropdownCloseIcon
                    sx={{
                        flexGrow: '1',
                        pr: '6px',
                        fontSize: '20px',
                        transition: 'transform 0.0s 0.2s linear',
                        transform:
                            isRoot && isExpanded
                                ? 'rotate(180deg)'
                                : 'none',
                    }}
                />
            )}
        </Stack>
    );
};

const MainMenuMobileOption = ({...props}: MenuOptionProps) => {
    return <MenuOptionBody {...props} />;
};

const MainMenuMobileHeader = ({
    isExpanded,
    label,
    icon,
}: Omit<MenuOptionProps, 'selected'>) => {
    return (
        <MenuOptionBody
            label={label}
            icon={icon}
            selected={false}
            isRoot={true}
            isExpanded={isExpanded}
        />
    );
};

export default MainMenuMobileOption;
export {MainMenuMobileHeader};
