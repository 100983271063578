const sections = [
    {
        name: 'infrastructure-management',
        label: 'Infrastructure Management',
    },
    {
        name: 'user-management',
        label: 'User Management',
    },
];

export default sections;
