import {useParams} from 'react-router-dom';
import {useDispatch, useModuleRouter} from 'common/hooks';
import {setTitle} from 'common/reducers/app.slice';
import sections, {
    YodideskSection,
    YodideskSectionName,
} from '../config/sections';
import YodideskSideNav from '../components/YodideskSideNav';
import {useEffect} from 'react';
import AppContainer from 'modules/app/containers/AppContainer';
import {MainMenuMobile} from 'common/components/MainMenu';
import {useMediaQuery, useTheme} from '@mui/material';
import SectionIcon from '../components/SectionIcon';

export interface YodideskContainerProps {
    children?: React.ReactNode | React.ReactNode[];
}
export default function YodideskContainer({
    children,
}: YodideskContainerProps) {
    const {name} = useParams<{name: YodideskSectionName}>();
    const dispatch = useDispatch();

    const {transitionTo} = useModuleRouter();
    const handleChangeTab = (tab: string) => {
        transitionTo(tab);
    };
    const getSectionLabel = (name: string): string => {
        const section = sections.find(
            (item: YodideskSection) => item.name === name
        );
        if (section) {
            return section.label;
        } else {
            return '';
        }
    };
    useEffect(() => {
        return () => {
            dispatch(setTitle(''));
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(setTitle(getSectionLabel(name)));
    }, [name, dispatch]);
    const {
        breakpoints: {up},
    } = useTheme();
    const isMobile = !useMediaQuery(up('desktopSm'));
    return (
        <AppContainer
            mobileMenu={
                <MainMenuMobile
                    options={mobileOptions}
                    value={name}
                    onChange={handleChangeTab}
                />
            }
        >
            {!isMobile && (
                <YodideskSideNav
                    defaultValue={name}
                    onChange={handleChangeTab}
                />
            )}
            {children}
        </AppContainer>
    );
}

const mobileOptions = [
    {
        value: 'overview',
        label: 'Overview',
        icon: <SectionIcon name="overview" />,
    },
    {
        value: 'versions',
        label: 'Versions',
        icon: <SectionIcon name="versions" />,
    },
];
