import {Tooltip, TooltipProps} from '@mui/material';

type TooltipNotAllowedProps = {show?: boolean} & Pick<
    TooltipProps,
    'children'
>;

export default function TooltipNotAllowed({
    show = true,
    children,
}: TooltipNotAllowedProps) {
    return (
        <>
            {show ? (
                <Tooltip
                    arrow
                    enterDelay={700}
                    title="You don't have permissions to access this service"
                    placement="top"
                >
                    {children}
                </Tooltip>
            ) : (
                <>{children}</>
            )}
        </>
    );
}
