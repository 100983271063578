import {Stack, Box, useTheme, Skeleton} from '@mui/material';
import ContentTile from 'common/components/ContentTile';
import {
    BuildingsSmallIcon,
    FloorsSmallIcon,
    ZonesSmallIcon,
} from 'common/icons';
import _ from 'lodash';
import {ComponentProps, CSSProperties, useMemo} from 'react';
import {TreeItemTypes} from '../../InfrastructureTree/InfrastructureTree.types';
import {
    fieldFormatters,
    fieldToLabel,
} from '../../InfrastructureTree/InfrastructureTreeFormats';
import TreeMobileItemHeader from './TreeMobileItemHeader';

export interface TreeMobileItemProps {
    style?: CSSProperties;
    onClick: ComponentProps<'button'>['onClick'];
    variant: TreeItemTypes;
    fieldColumns: string[];
    fieldData: any;
    hasChildren?: boolean;
    isFirstChild?: boolean;
    isLastChild?: boolean;
    parentIsLastChild?: boolean;
    isLoading?: boolean;
    height?: number;
    isLastBuilding?: boolean;
}
function TreeMobileItem({
    style,
    onClick,
    variant,
    fieldData,
    fieldColumns,
    isLoading,
    height = 332,
    hasChildren,
    isLastChild,
    isFirstChild,
    isLastBuilding,
    parentIsLastChild,
}: TreeMobileItemProps) {
    const theme = useTheme();
    const bgColor = useMemo(() => {
        if (variant === 'buildings') {
            return theme.palette.common.brandColor[100];
        } else if (variant === 'floors') {
            return theme.palette.common.brandColor[300];
        } else {
            return theme.palette.common.brandColor[400];
        }
    }, [theme.palette.common.brandColor, variant]);
    const sidebarIcon = useMemo(() => {
        if (variant === 'buildings') {
            return (
                <BuildingsSmallIcon
                    sx={{color: 'grey.400', pl: '1px', width: '25px'}}
                />
            );
        } else if (variant === 'floors') {
            return (
                <FloorsSmallIcon
                    sx={{color: 'grey.400', pl: '1px', width: '25px'}}
                />
            );
        } else {
            return (
                <ZonesSmallIcon
                    sx={{color: 'grey.400', pl: '1px', width: '25px'}}
                />
            );
        }
    }, [variant]);
    const sidebar = useMemo(() => {
        const isBuilding = variant === 'buildings';
        const isFloor = variant === 'floors';
        const isZone = variant === 'zones';
        return (
            <Stack direction="row">
                <Box
                    width="5px"
                    height={`${height}px`}
                    sx={{
                        backgroundColor: bgColor,
                        mr: isBuilding ? '4px' : '16px',
                    }}
                    component="div"
                />
                {isZone && (
                    <Box
                        sx={{alignSelf: 'center', mr: '19px'}}
                        width="1px"
                        height={`${height + 4}px`}
                        bgcolor={(theme) =>
                            isLastBuilding
                                ? 'transparent'
                                : theme.palette.primary.light
                        }
                    />
                )}
                {(isFloor || isZone) && (
                    <Stack direction="column">
                        <Box
                            sx={{alignSelf: 'center'}}
                            width="8px"
                            height="20px"
                            borderLeft={(theme) =>
                                !isFirstChild &&
                                parentIsLastChild &&
                                isFloor
                                    ? 'transparent'
                                    : `1px solid ${theme.palette.primary.light}`
                            }
                            borderBottom={(theme) =>
                                !isFirstChild &&
                                parentIsLastChild &&
                                isFloor
                                    ? 'transparent'
                                    : `1px solid ${theme.palette.primary.light}`
                            }
                        />
                        <Box
                            sx={{alignSelf: 'center'}}
                            width="8px"
                            height={`${height - 16}px`}
                            borderLeft={(theme) =>
                                (parentIsLastChild &&
                                    isZone &&
                                    isLastChild) ||
                                (isFloor && parentIsLastChild)
                                    ? 'transparent'
                                    : `1px solid ${theme.palette.primary.light}`
                            }
                        />
                    </Stack>
                )}
                <Stack direction="column" pr="4px">
                    <Box
                        sx={{alignSelf: 'center'}}
                        width="1px"
                        height="8px"
                        bgcolor={(theme) =>
                            isBuilding
                                ? theme.palette.primary.light
                                : 'transparent'
                        }
                    />
                    {sidebarIcon}
                    <Box
                        sx={{alignSelf: 'center'}}
                        width="1px"
                        height={`${height - 28}px`}
                        bgcolor={(theme) =>
                            (isLastChild && !hasChildren) || isZone
                                ? 'transparent'
                                : theme.palette.primary.light
                        }
                    />
                </Stack>
            </Stack>
        );
    }, [
        bgColor,
        hasChildren,
        height,
        isFirstChild,
        isLastBuilding,
        isLastChild,
        parentIsLastChild,
        sidebarIcon,
        variant,
    ]);
    const title = useMemo(() => {
        if (isLoading) {
            return '';
        }
        const formatter = fieldFormatters['name'];
        return formatter('', fieldData);
    }, [fieldData, isLoading]);
    const contentTiles = useMemo(() => {
        const columns = fieldColumns
            .filter((col) => !_.isEmpty(col))
            .slice(1);
        const tiles: JSX.Element[] = [];
        if (isLoading) {
            columns.forEach((col) => {
                tiles.push(
                    <Stack
                        direction="column"
                        height="26px"
                        key={`${fieldData.id}-${col}`}
                    >
                        <Skeleton
                            variant="rectangular"
                            height="12px"
                            width="60px"
                            sx={{mb: '4px'}}
                        />
                        <Skeleton
                            variant="rectangular"
                            height="12px"
                            width="100px"
                        />
                    </Stack>
                );
            });
        } else {
            columns.forEach((col) => {
                const formatter = fieldFormatters[col];
                const content = formatter('', fieldData);
                tiles.push(
                    <Box
                        my={1}
                        key={`${fieldData.id}-${fieldToLabel[col]}`}
                    >
                        <ContentTile
                            content={content}
                            title={fieldToLabel[col]}
                        />
                    </Box>
                );
            });
        }
        return tiles;
    }, [fieldColumns, fieldData, isLoading]);
    return (
        <div style={style}>
            <Stack
                data-testid="infrastructure-tree-list-item"
                direction="row"
                width="100%"
                key={fieldData?.id}
                height="inherit"
            >
                {sidebar}
                <Stack
                    sx={{overflowX: 'clip'}}
                    direction="column"
                    width="100%"
                    alignItems="start"
                    mr="8px"
                >
                    <TreeMobileItemHeader
                        isLoading={isLoading}
                        onClickMore={onClick}
                        title={title}
                        sx={{height: headerHeight + 'px'}}
                    />
                    {contentTiles}
                </Stack>
            </Stack>
        </div>
    );
}
const contentTileHeight = 26;
const contentTileGap = 16;
const headerHeight = 40;
export const emptyMobileTreeItemHeight = headerHeight;
export const mobileTreeItemHeightPerTile =
    contentTileGap + contentTileHeight;
export default TreeMobileItem;
