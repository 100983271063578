import {Box, Typography} from '@mui/material';

import {Button} from 'common/components';

export interface FilterAreaMobileProps {
    onApply: () => void;
    disabled?: boolean;
}

export default function FilterAreaMobileFooter({
    onApply,
    disabled,
}: FilterAreaMobileProps) {
    return (
        <Box className="filter-area-footer">
            <Box
                sx={{
                    p: 2,
                    mt: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Button
                    size="large"
                    variant="contained"
                    onClick={onApply}
                    disabled={disabled}
                    data-testid="apply-filters-button"
                >
                    <Typography variant="subtitle">
                        Apply filters
                    </Typography>
                </Button>
            </Box>
        </Box>
    );
}
