import MainMenuDesktop, {
    MainMenuDesktopTab,
} from 'modules/app/components/AppSideNav';
import {InfrastructureIcon, UserManagementIcon} from 'common/icons';
export interface AdminSideNavProps {
    onChange: (tab: string) => void;
    defaultValue: string;
}

export default function AdminSideNav({
    onChange,
    defaultValue,
}: AdminSideNavProps) {
    return (
        <MainMenuDesktop onChange={onChange} defaultValue={defaultValue}>
            <MainMenuDesktopTab
                value="infrastructure-management"
                label="Infrastructure management"
                icon={<InfrastructureIcon />}
            />
            <MainMenuDesktopTab
                icon={<UserManagementIcon />}
                label="User Management"
                value="user-management"
            />
        </MainMenuDesktop>
    );
}
