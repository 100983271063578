import {useCallback, useEffect, useState} from 'react';
import {InfrastructureFilterProps} from '../index';
import FilterArea from '../FilterArea';
import HierarchyToggleButtons from '../HierarchyToggleButtons';
import {FilterField} from 'modules/admin/hooks/useFilters.types';
import _ from 'lodash';
import FieldFilter from './FieldFilter';
import {Operator, operators} from './operators';
import {useSelector} from 'common/hooks';

export type FilterDescriptor = {
    field?: FilterField | null;
    operator?: Operator | null;
    value?:
        | string
        | number
        | Date
        | string[]
        | (Date | null)[]
        | (number | null)[]
        | null;
};
export type CompleteFilterDescriptor = {
    hierarchy: string;
    field: FilterField;
    operator: Operator;
    value: string | number | Date | Date[] | number[] | string[];
};

export default function FilterFormSingle({
    isMobileOpen,
    isDesktopOpen,
    hierarchy,
    isLoading,
    hierarchyOptions,
    onChangeHierarchy,
    fullEntityInfo,
    onClose,
    onChangeFilters,
    initialFilters = [{}],
}: InfrastructureFilterProps) {
    const FILTER_LIMIT = 10;

    const [filters, setFilters] =
        useState<FilterDescriptor[]>(initialFilters);

    const [completeFilters, setCompleteFilters] = useState<
        CompleteFilterDescriptor[]
    >([]);

    const [isApplyEnabled, setApplyEnabled] = useState<boolean>(false);

    const handleCompleteFilterChange = useCallback(
        (newFilters: FilterDescriptor[]) => {
            const newCompleteFilters: CompleteFilterDescriptor[] =
                newFilters
                    .map((filter) => ({
                        ...filter,
                        hierarchy: hierarchy[0],
                    }))
                    .filter((filt) => {
                        const fieldDefined = !_.isNil(filt.field);
                        const opDefined = !_.isNil(filt.operator);
                        const multipleValuesDefined =
                            Array.isArray(filt.value) &&
                            filt.field?.attributeTypeName ===
                                'MultiDropdown' &&
                            filt.value.length > 0;
                        const valueDefined = Array.isArray(filt.value)
                            ? _.compact(filt.value as any[]).length === 2
                            : !_.isNil(filt.value);
                        return (
                            fieldDefined &&
                            opDefined &&
                            (valueDefined || multipleValuesDefined)
                        );
                    }) as CompleteFilterDescriptor[];

            setCompleteFilters((state) => {
                if (
                    !isMobileOpen &&
                    !_.isEqual(state, newCompleteFilters)
                ) {
                    onChangeFilters(newCompleteFilters);
                } else if (!_.isEqual(state, newCompleteFilters)) {
                    setApplyEnabled(true);
                }
                return newCompleteFilters;
            });
        },
        [hierarchy, isMobileOpen, onChangeFilters]
    );

    useEffect(() => {
        handleCompleteFilterChange(initialFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilterChange = useCallback(
        (
            index: number,
            filterChanged: FilterDescriptor,
            fieldChange?: boolean,
            operatorChange?: boolean
        ) => {
            setFilters((state) => {
                const newFilters = _.cloneDeep(state);
                newFilters[index] = filterChanged;
                const update = newFilters.find(
                    (f) => f === filterChanged
                )!;
                if (fieldChange) {
                    //preset operator
                    const hasNoOperator =
                        update.field?.attributeName && !update.operator;
                    const hasWrongOperator =
                        operators &&
                        update.operator &&
                        update.field?.attributeTypeName &&
                        !operators[update.field.attributeTypeName].find(
                            (op) => op.id === update.operator!.id
                        );
                    if (hasWrongOperator || hasNoOperator) {
                        update.operator =
                            operators![update.field!.attributeTypeName][0];
                    }
                    update.value = null;
                } else if (operatorChange) {
                    //clear filter value
                    update.value = null;
                }
                handleCompleteFilterChange(newFilters);
                return newFilters;
            });
        },
        [handleCompleteFilterChange]
    );
    const handleFilterAddition = useCallback(() => {
        if (filters.length === FILTER_LIMIT) {
            return;
        }
        setFilters([...filters, {}]);
    }, [filters]);
    const handleFilterRemoval = useCallback(() => {
        if (filters.length === 1) {
            return;
        }
        const newFilters = filters.slice(0, -1);
        setFilters(newFilters);
        handleCompleteFilterChange(newFilters);
    }, [filters, handleCompleteFilterChange]);

    const renderToggleComponent = useCallback(
        () => (
            <HierarchyToggleButtons
                hierarchy={hierarchy}
                isLoading={isLoading}
                hierarchyOptions={hierarchyOptions}
                onChange={onChangeHierarchy}
            />
        ),
        [hierarchy, isLoading, hierarchyOptions, onChangeHierarchy]
    );
    const renderFilterComponent = useCallback(() => {
        let fields: FilterField[] | undefined;
        if (hierarchy.length === 1) {
            fields = _.find(
                fullEntityInfo,
                (info) => info.entityName === hierarchy[0]
            )?.supportedAttributes;
        }
        return (
            <FieldFilter
                maxFilters={FILTER_LIMIT}
                hierarchy={hierarchy[0]}
                isMobile={isMobileOpen}
                fields={fields}
                filters={filters}
                onChange={handleFilterChange}
                onFilterAdd={handleFilterAddition}
                onFilterRemove={handleFilterRemoval}
                disabledAdd={
                    completeFilters.length !== filters.length ||
                    (!!fields && filters.length === fields.length)
                }
            />
        );
    }, [
        completeFilters.length,
        filters,
        fullEntityInfo,
        handleFilterAddition,
        handleFilterChange,
        handleFilterRemoval,
        hierarchy,
        isMobileOpen,
    ]);
    const handleApply = useCallback(() => {
        setApplyEnabled(false);
        onChangeFilters(completeFilters);
        onClose();
    }, [completeFilters, onChangeFilters, onClose]);
    const handleReset = useCallback(() => {
        setFilters([{}]);
        handleCompleteFilterChange([{}]);
    }, [handleCompleteFilterChange]);

    //reset fitlers on org change
    const currentOrg = useSelector(
        (state) => state.app.selectedOrganization
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setFilters([{}]);
        setCompleteFilters([]);
    }, [currentOrg]);

    return (
        <FilterArea
            resetEnabled={completeFilters.length > 0}
            applyEnabled={isApplyEnabled}
            isMobileOpen={isMobileOpen}
            isDesktopOpen={isDesktopOpen}
            onClose={onClose}
            onApply={handleApply}
            onReset={handleReset}
            renderToggleComponent={renderToggleComponent}
            renderFilterComponent={renderFilterComponent}
        />
    );
}
