import {Box, Typography, Divider, SxProps, useTheme} from '@mui/material';
import {forwardRef} from 'react';
import Input, {InputProps} from '..';
import IconButton from 'common/components/IconButton';
import {DropdownOpenIcon, DropdownCloseIcon} from 'common/icons';

export interface NumericalInputProps extends InputProps {
    label: string;
    optional?: boolean;
    onClickDown?: () => void;
    onClickUp?: () => void;
    containerSx?: SxProps;
    min?: number;
    max?: number;
}

const NumericalInput = forwardRef<HTMLInputElement, NumericalInputProps>(
    (
        {
            label,
            optional,
            onClickDown,
            onClickUp,
            min = -Infinity,
            max = Infinity,
            width,
            disabled,
            value,
            ...other
        },
        ref
    ) => {
        const {typography} = useTheme();
        return (
            <Box sx={other.containerSx} width={width}>
                <label htmlFor="custom-input">
                    <Box display="flex" gap={0.25}>
                        <Typography color="grey.600" variant="overline">
                            {`${label}`}
                        </Typography>
                        {optional && (
                            <Typography
                                color="grey.500"
                                variant="menu"
                                height="13px"
                                alignSelf="flex-end"
                            >
                                (optional)
                            </Typography>
                        )}
                    </Box>
                </label>
                <Box display="flex">
                    <Input
                        {...other}
                        value={value}
                        ref={ref}
                        overrideTextFieldProps={{
                            ...other.overrideTextFieldProps,
                            type: 'number',
                        }}
                        sx={{
                            '& .MuiFormHelperText-root': {
                                textAlign: 'left',
                                mx: '1px',
                                whiteSpace: 'nowrap',
                                ...typography.overline,
                            },
                            mr: 1,
                        }}
                        width="100%"
                        inputSx={{
                            ...other.inputSx,
                            '& input::-webkit-outer-spin-button,input::-webkit-inner-spin-button':
                                {
                                    WebkitAppearance: 'none',
                                    margin: 0,
                                },
                            '&input[type=number]': {
                                MozAppearance: 'textfield',
                            },
                        }}
                        inputProps={{id: 'custom-input'}}
                    />
                    <Box
                        display="flex"
                        border={(theme) =>
                            `1px solid ${
                                disabled
                                    ? theme.palette.common.disabled
                                    : theme.palette.grey[400]
                            }`
                        }
                        borderRadius="4px"
                        boxSizing="border-box"
                        position="relative"
                        ml="auto"
                        py={0.5}
                        px={0.375}
                        height={40}
                        alignItems="center"
                        sx={{
                            '&:hover': {
                                borderColor: disabled
                                    ? 'common.disabled'
                                    : 'grey.600',
                            },
                        }}
                    >
                        <IconButton
                            sx={{ml: 0}}
                            variant="dark"
                            onClick={onClickDown}
                            disabled={
                                disabled || Number(value ?? min) <= min
                            }
                        >
                            <DropdownCloseIcon />
                        </IconButton>
                        <Divider
                            orientation="vertical"
                            sx={{
                                position: 'absolute',
                                left: 40,
                                height: 32,
                            }}
                        />
                        <IconButton
                            sx={{mr: 0}}
                            variant="dark"
                            onClick={onClickUp}
                            disabled={
                                disabled ||
                                Number(value ?? -Infinity) >= max
                            }
                        >
                            <DropdownOpenIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        );
    }
);
export default NumericalInput;
