import {DropdownOption} from '../ItemRenderers/index';
import {Base} from '../index';

export type SelectAllDropdownOptionProps = {
    availableOptions: Base[];
    width?: number;
    selected: boolean;
    handleClick: (availableOptions: Base[]) => void;
};

export default function SelectAllDropdownOption({
    availableOptions,
    width,
    selected,
    handleClick,
}: SelectAllDropdownOptionProps) {
    return (
        <DropdownOption
            label="Select all"
            value="__selectAll"
            style={{minHeight: 'auto'}}
            selected={selected}
            selectionVariant="multiple"
            width={width}
            onClick={() => {
                handleClick(availableOptions);
            }}
        />
    );
}
