import {Grid} from '@mui/material';
import {IconButton} from 'common/components';
import {FilterRemoveIcon} from 'common/icons';
import {FieldFilterDesktopBlockProps} from './FieldFilterDesktopBlock';

export interface FieldFilterMobileBlockProps
    extends Omit<FieldFilterDesktopBlockProps, 'onFilterAdd'> {}

function FieldFilterMobileBlock({
    fieldInput,
    operatorInput,
    filterInput,
    showRemove,
    onFilterRemove,
}: FieldFilterMobileBlockProps) {
    return (
        <Grid
            className="filteringBlock"
            rowGap={2}
            justifyContent="space-between"
            container
            sx={{
                bgcolor: 'grey.100',
                p: '12px',
                borderRadius: '4px',
                mx: '0',
            }}
        >
            <Grid item xs={9} pr="15px">
                {fieldInput}
            </Grid>
            {showRemove && (
                <Grid item>
                    <IconButton onClick={onFilterRemove}>
                        <FilterRemoveIcon />
                    </IconButton>
                </Grid>
            )}
            <Grid item xs={9} pr="15px">
                {operatorInput}
            </Grid>
            <Grid item xs={12}>
                {filterInput}
            </Grid>
        </Grid>
    );
}

export default FieldFilterMobileBlock;
