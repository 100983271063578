import {Grid} from '@mui/material';
import {
    AdminIcon,
    IncidentsIcon,
    ProfileIcon,
    ReportingIcon,
    RulesIcon,
} from 'common/icons';
import {IconButton} from 'common/components';

export interface AppBarActionsProps {
    adminSelected: boolean;
    onClickReporting: () => void;
    onClickSettings: () => void;
    onClickAccount: () => void;
    onClickIncidents: () => void;
    onClickRules: () => void;
    xs?: number;
}
export default function AppBarActions({
    adminSelected,
    onClickReporting = () => {},
    onClickSettings = () => {},
    onClickAccount = () => {},
    onClickIncidents = () => {},
    onClickRules = () => {},
    xs,
}: AppBarActionsProps) {
    return (
        <Grid
            container
            item
            xs={xs}
            direction="row"
            alignItems="center"
            justifyContent="end"
            height="inherit"
        >
            <Grid item>
                <IconButton
                    tooltip="Reporting"
                    variant="dark"
                    aria-label="reporting"
                    onClick={onClickReporting}
                >
                    <ReportingIcon fontSize="inherit" />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton
                    tooltip="Device rules"
                    variant="dark"
                    aria-label="rules"
                    onClick={onClickRules}
                >
                    <RulesIcon fontSize="inherit" />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton
                    tooltip="Incidents"
                    variant="dark"
                    aria-label="incidents"
                    onClick={onClickIncidents}
                    counter={22}
                >
                    <IncidentsIcon fontSize="inherit" />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton
                    variant="dark"
                    tooltip="Admin"
                    onClick={onClickSettings}
                    isSelected={adminSelected}
                    aria-label="user administration settings"
                >
                    <AdminIcon fontSize="inherit" />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton
                    tooltip="Profile"
                    variant="brandDark"
                    aria-label="account settings"
                    onClick={onClickAccount}
                >
                    <ProfileIcon fontSize="inherit" />
                </IconButton>
            </Grid>
        </Grid>
    );
}
