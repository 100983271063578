import {alpha, Box, Stack} from '@mui/material';
import {
    ExpandedAreaIcon,
    ExtendIcon,
    NarrowIcon,
    PinIcon,
} from 'common/icons';
import SubMenuHeaderButton from './SubmenuHeaderButton';

export interface SubMenuHeaderProps {
    children: React.ReactNode | React.ReactNode[];
    onClose: () => void;
    onPinChange: (pinned: boolean) => void;
    isPinned?: boolean;
    isExtended?: boolean;
    onExtensionChange: (expanded: boolean) => void;
}

export default function SubMenuHeader({
    children,
    onClose,
    onPinChange,
    isPinned,
    isExtended,
    onExtensionChange,
}: SubMenuHeaderProps) {
    return (
        <Box
            role="heading"
            minHeight="96px"
            pb="12px"
            sx={{
                width: '100%',
                borderRadius: '0px 8px 0px 0px  ',
                backgroundColor: (theme) =>
                    alpha(theme.palette.primary.light, 0.32),
            }}
        >
            <Stack direction="row" justifyItems="flex-end" spacing={0}>
                <SubMenuHeaderButton
                    tooltip="Collapse submenu"
                    ariaLabel="Close"
                    onClick={onClose}
                    sx={{
                        ml: '4px',
                        justifySelf: 'flex-start',
                    }}
                >
                    <ExpandedAreaIcon sx={{transform: 'rotate(180deg)'}} />
                </SubMenuHeaderButton>
                <Stack
                    direction="row"
                    width="calc(100% - 46px);"
                    justifyContent="flex-end"
                >
                    <SubMenuHeaderButton
                        tooltip={`${
                            isExtended ? 'Reduce' : 'Extend'
                        } submenu`}
                        ariaLabel={`${
                            isExtended ? 'Reduce' : 'Extend'
                        } submenu`}
                        onClick={() => {
                            onExtensionChange(!isExtended);
                        }}
                        sx={{
                            mr: '4px',
                        }}
                    >
                        {isExtended ? (
                            <NarrowIcon sx={{color: 'primary.main'}} />
                        ) : (
                            <ExtendIcon sx={{color: 'primary.main'}} />
                        )}
                    </SubMenuHeaderButton>
                    <SubMenuHeaderButton
                        tooltip={`${
                            isPinned ? 'Unpin submenu' : 'Pin submenu'
                        }`}
                        ariaLabel={`${
                            isPinned ? 'Unpin submenu' : 'Pin submenu'
                        }`}
                        isPressed={isPinned}
                        onClick={() => {
                            onPinChange(!isPinned);
                        }}
                        sx={{
                            backgroundColor: (theme) =>
                                isPinned
                                    ? 'primary.main'
                                    : 'primary.light',
                            borderRadius: '0px 8px',
                        }}
                    >
                        <PinIcon
                            sx={{
                                color: (theme) =>
                                    isPinned
                                        ? 'white'
                                        : theme.palette.primary.main,
                            }}
                            inheritViewBox
                        />
                    </SubMenuHeaderButton>
                </Stack>
            </Stack>
            <Stack gap={1} alignItems="center">
                {children}
            </Stack>
        </Box>
    );
}
