import {
    IconButton,
    Box,
    Typography,
    alpha,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import PagePicker from './PagePicker';
import Input from '../Inputs';
import {DropdownSingle} from '../Dropdown';
import {NextIcon} from '../../icons';
import {useState} from 'react';
export type limitOptions = 13 | 26 | 52 | 100;

export type PaginationProps = {
    total: number;
    limit: limitOptions;
    page: number;
    onChangePage: (page: number) => void;
    onChangeLimit: (limit: limitOptions) => void;
};

type RowsPerPageOption = {
    label: string;
    value: string;
};

export default function Pagination({
    total,
    limit,
    page,
    onChangePage,
    onChangeLimit,
}: PaginationProps) {
    const {
        breakpoints: {up},
    } = useTheme();
    const isMobile = !useMediaQuery(up('desktopSm'));

    let [inputText, setInputText] = useState('');
    const totalPages = Math.ceil(total / limit);
    const isSinglePage = total <= limit;

    let rowsPerPageDropdownOptions = [
        {label: '13 / Per page', value: '13'},
        {label: '26 / Per page', value: '26'},
        {label: '52 / Per page', value: '52'},
        {label: '100 / Per page', value: '100'},
    ];

    if (total >= 13 && total < 26) {
        rowsPerPageDropdownOptions.splice(-2, 2);
    } else if (total >= 26 && total < 52) {
        rowsPerPageDropdownOptions.pop();
    }

    return (
        <Box
            data-testid="pagination"
            sx={{
                width: '100%',
                px: 2,
                py: 0.5,
                bgcolor: 'grey.100',
                borderRadius: '8px 8px 0px 0px',
                boxShadow: (theme: any) =>
                    `0px 8px 16px ${alpha(theme.palette.grey[800], 0.04)}`,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 2,
                justifyContent: 'center',
            }}
        >
            {isMobile ? null : (
                <DropdownSingle<RowsPerPageOption>
                    supportDynamicHeight={true}
                    disabled={isSinglePage}
                    width={136}
                    onChange={(value) => {
                        onChangePage(1);
                        onChangeLimit(
                            parseInt(value.value) as limitOptions
                        );
                    }}
                    options={rowsPerPageDropdownOptions}
                    formatLabel={(option) => option.label}
                    variant="outlined"
                    value={
                        rowsPerPageDropdownOptions.find(
                            (e) => e.value === limit.toString()
                        ) ?? rowsPerPageDropdownOptions[0]
                    }
                />
            )}
            <PagePicker
                count={totalPages}
                page={page}
                onChange={(e, p) => {
                    setInputText('');
                    onChangePage(p);
                }}
                isMobile={isMobile}
                isSinglePage={isSinglePage}
                totalPages={totalPages}
            />
            {isMobile ? null : (
                <>
                    <Typography variant="body2">Go to page</Typography>
                    <Input
                        disabled={isSinglePage}
                        width={88}
                        placeholder="00"
                        value={inputText}
                        onChange={(t) => setInputText(t)}
                        endAdornment={
                            <IconButton
                                aria-label="Go to page"
                                sx={{
                                    color: 'grey.600',
                                }}
                                edge="start"
                                onClick={(e) => {
                                    const reqPage = parseInt(inputText);
                                    if (isNaN(reqPage)) {
                                        return;
                                    }
                                    if (reqPage > totalPages) {
                                        return;
                                    }
                                    setInputText('');
                                    onChangePage(reqPage);
                                }}
                                disabled={isSinglePage}
                            >
                                <NextIcon />
                            </IconButton>
                        }
                    />
                </>
            )}
        </Box>
    );
}
