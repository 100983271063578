import {Box} from '@mui/material';
import {useEffect, useState, useCallback, useMemo} from 'react';
import {
    setActiveProduct,
    fetchOrganizationStructure,
} from 'common/reducers/app.slice';
import {useDispatch, useSelector} from 'common/hooks';
import products, {ModuleName} from 'common/constants/modules';
import {useLocation} from 'react-router-dom';
import {getModuleByName, getModuleNameFromPathname} from 'common/lib';
import {ProductMenu, AppBar} from '../components';
import {useLocationChange, useRedirectToModule} from 'common/hooks';
import {useTheme, useMediaQuery} from '@mui/material';

export interface AppContainerProps {
    children?: React.ReactNode;
    mobileMenu?: React.ReactNode;
}
function AppContainer({children, mobileMenu}: AppContainerProps) {
    const dispatch = useDispatch();
    /* const {loading, data} = useAppSelector((state) => state.user); */

    let location = useLocation();

    const redirectToModule = useRedirectToModule();

    const handleLocationChange = useCallback(() => {
        const productName = getModuleNameFromPathname(location.pathname);
        dispatch(setActiveProduct(productName));
    }, [location.pathname, dispatch]);

    useLocationChange(handleLocationChange);

    const {
        breakpoints: {up},
    } = useTheme();
    const isMobile = !useMediaQuery(up('desktopSm'));

    useEffect(() => {
        dispatch(fetchOrganizationStructure());
    }, [dispatch]);

    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const activeProductName = useSelector(
        (state) => state.app.activeModuleName
    );
    const activeProductLabel = useSelector(
        (state) => state.app.activeModuleLabel
    );

    const isAdminSelected = useMemo(
        () => activeProductName === 'admin',
        [activeProductName]
    );

    const handleBackdropClick = useCallback(() => {
        setMenuIsOpen(false);
    }, []);
    const handleModuleClick = useCallback(
        (productName: ModuleName) => {
            if (!isMobile) {
                setMenuIsOpen(false);
            } else {
                setMenuIsOpen(true);
            }
            redirectToModule(productName);
        },
        [isMobile, redirectToModule]
    );
    const handleClickSettings = useCallback(() => {
        if (!isAdminSelected) {
            setMenuIsOpen(false);
            redirectToModule('admin');
        }
    }, [isAdminSelected, redirectToModule]);
    const handleClickAccount = useCallback(() => {}, []);
    const handleClickReporting = useCallback(() => {}, []);
    const handleClickIncidents = useCallback(() => {}, []);
    const handleClickRules = useCallback(() => {}, []);

    const handleClickMenuButton = useCallback(() => {
        setMenuIsOpen((state) => !state);
    }, []);

    const handleClickLogo = () => {
        redirectToModule(products[0].name);
    };
    const title = useSelector((state) => state.app.title);

    const activeProduct = useMemo(
        () => getModuleByName(activeProductName),
        [activeProductName]
    );

    const drawerWidth = useMemo(
        () => (isMobile ? '100%' : 302),
        [isMobile]
    );
    return (
        <Box display="flex" height="100vh">
            <Box display="flex" flex={1} flexDirection="column">
                <AppBar
                    onClickLogo={handleClickLogo}
                    onClickIncidents={handleClickIncidents}
                    onClickRules={handleClickRules}
                    onClickMenuButton={handleClickMenuButton}
                    onClickSettings={handleClickSettings}
                    onClickAccount={handleClickAccount}
                    onClickReporting={handleClickReporting}
                    togglerWidth={drawerWidth}
                    title={title}
                    adminSelected={isAdminSelected}
                    activeProductLabel={activeProductLabel}
                    activeProductIcon={activeProduct?.icon}
                    menuIsOpen={menuIsOpen}
                    mobileMenu={mobileMenu}
                />
                <Box
                    position="relative"
                    display="flex"
                    overflow="hidden"
                    height="100%"
                    width="100%"
                    bgcolor="grey.200"
                    flex={1}
                >
                    {children}
                </Box>
                <ProductMenu
                    products={products}
                    adminSelected={isAdminSelected}
                    isOpen={menuIsOpen}
                    activeProductName={activeProductName}
                    activeProductLabel={activeProductLabel}
                    activeProductIcon={activeProduct?.icon}
                    onProductClick={handleModuleClick}
                    onBackdropClick={handleBackdropClick}
                    onClickSettings={handleClickSettings}
                    drawerWidth={drawerWidth}
                />
            </Box>
        </Box>
    );
}

export default AppContainer;
