import {Box, IconButton, Stack, Skeleton} from '@mui/material';
import {WeatherIcon, MoreIcon, DataIcon} from 'common/icons';
import ContentTile from 'common/components/ContentTile';
import _ from 'lodash';
import {ComponentProps} from 'react';

export interface TableTileItemProps {
    onClickMore?: ComponentProps<'button'>['onClick'];
    isLoading?: boolean;
    data: any;
    isHeader?: boolean;
}

export default function TableTileItem({
    data,
    isHeader = false,
    isLoading,
    onClickMore,
}: TableTileItemProps) {
    function ActionsButtonsSkeleton() {
        return (
            <Stack
                direction="row"
                width="120px"
                columnGap="2px"
                height="40px"
                alignItems="center"
                marginLeft="4px"
                justifyContent="space-between"
            >
                <Skeleton
                    variant="circular"
                    sx={{
                        width: '24px',
                        height: '24px',
                    }}
                />
                <Skeleton
                    variant="circular"
                    sx={{
                        width: '24px',
                        height: '24px',
                    }}
                />
                <Skeleton
                    variant="circular"
                    sx={{
                        width: '24px',
                        height: '24px',
                    }}
                />
            </Stack>
        );
    }

    function ContentSkeleton({index}: {index: number}) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: isHeader
                        ? 'calc(100% - 132px)'
                        : index % 2
                        ? 'calc(50% - 8px)'
                        : 'calc(50% - 16px)',
                    marginLeft: (theme) =>
                        index % 2 ? theme.spacing(2) : theme.spacing(1),
                    marginBottom: (theme) => theme.spacing(2),
                    height: '32px',
                }}
            >
                <Skeleton
                    variant="text"
                    sx={{
                        height: '14px',
                    }}
                />
                <Skeleton
                    variant="text"
                    sx={{
                        height: '16px',
                    }}
                />
            </Box>
        );
    }

    function ActionButtons() {
        return (
            <Stack
                direction="row"
                width="120px"
                columnGap="2px"
                height="40px"
                alignItems="center"
                justifyContent="space-between"
                ml="auto"
            >
                <IconButton
                    size="small"
                    disabled
                    sx={{
                        '&.Mui-disabled': {
                            bgcolor: 'common.messages.verificationBg',
                        },
                    }}
                >
                    <WeatherIcon />
                </IconButton>
                <IconButton size="small">
                    <DataIcon
                        sx={{
                            m: 'auto',
                            color: (theme) => theme.palette.primary.main,
                        }}
                    />
                </IconButton>
                <IconButton size="small" onClick={onClickMore}>
                    <MoreIcon
                        sx={{
                            width: '24px',
                            height: '24px',
                            m: 'auto',
                            color: 'grey.600',
                        }}
                    />
                </IconButton>
            </Stack>
        );
    }

    function Content({index, item}: {index: number; item: any}) {
        return (
            <Box
                sx={{
                    mx: 1,
                    my: isHeader ? 0.875 : 1,
                    width: 'calc(50% - 16px)',
                }}
            >
                <ContentTile title={item.label} content={item.value} />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                backgroundColor: isHeader ? 'grey.200' : 'grey.100',
            }}
        >
            {_.map(data, (item: any, index: number) => {
                return isLoading ? (
                    <ContentSkeleton key={index} index={index} />
                ) : (
                    <Content key={index} index={index} item={item} />
                );
            })}
            {isHeader ? (
                isLoading ? (
                    <ActionsButtonsSkeleton />
                ) : (
                    <ActionButtons />
                )
            ) : null}
        </Box>
    );
}
