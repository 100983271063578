import {Container as MuiContainer, styled, alpha} from '@mui/material';
import MainMenuMobileOption, {
    MainMenuMobileHeader,
} from './MainMenuMobileOption';
import {ReactElement, useEffect, useRef, useState} from 'react';
import {useSelect} from '@mui/base';
import clsx from 'clsx';

const Container = styled(MuiContainer)(({theme}) => {
    const {
        breakpoints,
        zIndex,
        palette: {grey},
    } = theme;
    return {
        verticalAlign: 'baseline',
        color: 'black',
        zIndex: zIndex.appBar,
        background: 'white',
        boxShadow: `0px 8px 24px ${alpha(grey[800], 0.08)}`,
        borderRadius: '8px',
        border: 'none',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0',
        height: '48px',
        position: 'absolute',
        bottom: '-4px',
        left: '0',
        right: '0',
        transition: ' width 0.2s 0.2s linear',
        [breakpoints.up(780)]: {
            padding: '0px',
        },
    };
});

const Toggle = styled('div')(({theme: {palette}}) => ({
    position: 'static',
    width: '100%',
    borderRadius: '8px',
    height: ' 100%',
    backgroundColor: 'white',
    color: palette.primary.main,
    '&:hover': {
        transition: 'background-color 0.2s 0.2s ease',
        backgroundColor: palette.common.brandLight.hover,
    },
    '&.toggle-open': {
        backgroundColor: palette.common.brandLight.pressed,
    },
}));

const Listbox = styled('ul')(
    ({
        theme: {
            palette: {primary},
        },
    }) => `
    background: white;
    border-radius: 0px 0px 8px 8px;
    list-style: none;
    padding: 0;
    margin: -4px 0 0 0;
    height: 88vh;
    transition: opacity 0.3s ease 0.2s;
    width: 100%;
    box-shadow: 0px 4px 10px ${alpha(primary.main, 0.24)};

    &.hidden {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.01s 0.2s ease, visibility 0.01s 0.2s step-end;
    }

    & > li {
        padding: 0px;
        height: 48px;
    }
`
);

export interface MainMenuMobileProps {
    onChange: (newValue: string) => void;
    ariaLabel?: string;
    options: {
        label: string;
        value: any;
        icon: ReactElement;
    }[];
    value: string;
}

export default function MainMenuMobile({
    options,
    ariaLabel,
    value,
    onChange = (_) => {},
}: MainMenuMobileProps) {
    useEffect(() => {
        if (!options?.map((op) => op.value).includes(value)) {
            console.error(
                'Default main menu value does not map to any given option'
            );
        }
    }, [value, options]);

    const listboxRef = useRef<HTMLUListElement>(null);
    const [listboxVisible, setListboxVisible] = useState(false);
    const {getListboxProps, getOptionProps} = useSelect({
        listboxRef,
        options,
        value,
        onChange: (value: string | null) => {
            if (value !== null) {
                onChange(value);
            }
        },
    });

    const selectedItem = options.find((op) => op.value === value);

    useEffect(() => {
        setListboxVisible(false);
    }, [value, onChange]);

    return (
        <div>
            <Container
                className="container-root"
                maxWidth={false}
                onFocus={() => setListboxVisible(true)}
                aria-label={ariaLabel}
                onBlur={() => setListboxVisible(false)}
                sx={{width: listboxVisible ? '95%' : '148px'}}
            >
                <Toggle
                    role="Toggle"
                    onClick={() => {
                        setListboxVisible(!listboxVisible);
                    }}
                    className={clsx({'toggle-open': listboxVisible})}
                >
                    <MainMenuMobileHeader
                        label={selectedItem!.label}
                        icon={selectedItem?.icon}
                        isExpanded={listboxVisible}
                    />
                </Toggle>
                <Listbox
                    {...getListboxProps()}
                    className={clsx({
                        hidden: !listboxVisible,
                    })}
                    aria-hidden={!listboxVisible}
                >
                    {options.map((option) => (
                        <li key={option.value} {...getOptionProps(option)}>
                            <MainMenuMobileOption
                                selected={option.value === value}
                                label={option.label}
                                icon={option?.icon}
                            />
                        </li>
                    ))}
                </Listbox>
            </Container>
        </div>
    );
}
