import Typography from '@mui/material/Typography';
import {default as MuiBreadcrumbs} from '@mui/material/Breadcrumbs';
import _ from 'lodash';
import {grey} from 'common/colors';
import {ArrowRightIcon} from 'common/icons';
import {Box} from '@mui/material';

export type BreadcrumbsProps = {
    variant: 'default' | 'header';
    items: {
        label: string;
        value: string;
    }[];
};

export default function Breadcrumbs({variant, items}: BreadcrumbsProps) {
    return (
        <Box role="presentation">
            <MuiBreadcrumbs
                separator={<ArrowRightIcon sx={{color: grey[400]}} />}
                aria-label="breadcrumb"
                sx={{
                    '.MuiBreadcrumbs-separator': {
                        mx: variant === 'default' ? 0 : 0.5,
                    },
                }}
            >
                {_.map(items, (item, index) => {
                    return variant === 'default' ? (
                        <Box key={index}>
                            <Typography color="grey.600" variant="body2">
                                {item.label}
                            </Typography>
                        </Box>
                    ) : (
                        <Box key={index} pb={index === 0 ? 0.5 : 0}>
                            <Typography
                                color={
                                    index === 0
                                        ? 'grey.900'
                                        : index < items.length - 1
                                        ? 'grey.600'
                                        : 'primary.main'
                                }
                                variant={index === 0 ? 'h1' : 'body1'}
                            >
                                {item.label}
                            </Typography>
                        </Box>
                    );
                })}
            </MuiBreadcrumbs>
        </Box>
    );
}
