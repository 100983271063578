import {Grid} from '@mui/material';
import ResultsCard from './ResultsCard';
import {HierarchyLevel, HierarchyCount} from '../../config/hierarchyKeys';
import _ from 'lodash';
import {SvgIconComponent} from '@mui/icons-material';

export interface ResultsProps {
    hierarchy: HierarchyLevel[];
    count: HierarchyCount;
    isLoading: boolean;
    hierarchyOptions: {
        value: HierarchyLevel;
        label: string;
        icon?: SvgIconComponent;
    }[];
}

export default function Results({
    hierarchy,
    count,
    isLoading,
    hierarchyOptions,
}: ResultsProps) {
    return (
        <Grid
            data-testid="results-cards"
            container
            justifyContent="center"
            gap={1}
            my={2}
        >
            {_.map(hierarchy, (level, index) => {
                const {label, icon} = _.find(
                    hierarchyOptions,
                    (item) => item.value === level
                ) || {label: '', icon: undefined};

                return (
                    <ResultsCard
                        key={index}
                        icon={icon}
                        isLoading={isLoading}
                        label={label}
                        total={count[level] || 0}
                    />
                );
            })}
        </Grid>
    );
}
