import {createTheme, Theme, alpha} from '@mui/material';
//import {Shadows} from '@mui/material/styles/shadows';
import {darkBlue, grey, greyStates} from 'common/colors';

import {createBreakpoints} from '@mui/system';
import {getMaskedColor} from './dynamicColoring';

const fontFamily = `"Roboto", "Helvetica", "Arial", sans-serif`;

const brandColor = darkBlue;
const brandColorMain = darkBlue[500];

const BREAKPOINTS = {
    xs: 0,
    sm: 768,
    md: 960,
    lg: 1280,
    xl: 1920,
    mobile: 1280,
    desktopSm: 1280,
    desktopMd: 1440,
    desktopLg: 1920,
};

let theme: Theme = createTheme({
    breakpoints: createBreakpoints({step: 0, values: BREAKPOINTS}),
    palette: {
        primary: {
            main: brandColorMain,
            light: brandColor[100],
            contrastText: grey[100],
        },
        secondary: {
            main: '#89CEB4',
            light: '#CDEDDB',
        },
        common: {
            brandLight: {
                hover: alpha(brandColor[500], 0.08),
                focus: alpha(brandColor[500], 0.1),
                pressed: alpha(brandColor[500], 0.12),
            },
            brandDark: {
                hover: alpha(brandColor[900], 0.32),
                focus: alpha(brandColor[900], 0.4),
                pressed: alpha(brandColor[900], 0.48),
            },
            dark: {
                hover: alpha(greyStates.dark, 0.48),
                focus: alpha(greyStates.dark, 0.72),
                pressed: alpha(greyStates.dark, 0.8),
            },
            light: {
                hover: alpha(greyStates.light, 0.32),
                focus: alpha(greyStates.light, 0.56),
                pressed: alpha(greyStates.light, 0.72),
            },
            brandColor: brandColor,
            disabled: '#B8B8B8',
            overlay: {
                light: alpha(grey[100], 0.8),
                dark: alpha(grey[900], 0.7),
            },
            messages: {
                info: '#1F67FF',
                error: '#B60000',
                verification: '#117C00',
                verificationBg: '#D7FFD1',
                errorBg: '#F2D0D0',
                infoBg: '#EBF1FF',
            },
            dynamicLight: {
                hover: (color) =>
                    getMaskedColor(
                        color,
                        theme.palette.common.light.hover
                    ),
                focus: (color) =>
                    getMaskedColor(
                        color,
                        theme.palette.common.light.focus
                    ),
                pressed: (color) =>
                    getMaskedColor(
                        color,
                        theme.palette.common.light.pressed
                    ),
            },
            dynamicBrandLight: {
                hover: (color) =>
                    getMaskedColor(
                        color,
                        theme.palette.common.brandLight.hover
                    ),
                focus: (color) =>
                    getMaskedColor(
                        color,
                        theme.palette.common.brandLight.focus
                    ),
                pressed: (color) =>
                    getMaskedColor(
                        color,
                        theme.palette.common.brandLight.pressed
                    ),
            },
            dynamicBrandDark: {
                hover: (color) =>
                    getMaskedColor(
                        color,
                        theme.palette.common.brandDark.hover
                    ),
                focus: (color) =>
                    getMaskedColor(
                        color,
                        theme.palette.common.brandDark.focus
                    ),
                pressed: (color) =>
                    getMaskedColor(
                        color,
                        theme.palette.common.brandDark.pressed
                    ),
            },
            dynamicDark: {
                hover: (color) =>
                    getMaskedColor(color, theme.palette.common.dark.hover),
                focus: (color) =>
                    getMaskedColor(color, theme.palette.common.dark.focus),
                pressed: (color) =>
                    getMaskedColor(
                        color,
                        theme.palette.common.dark.pressed
                    ),
            },
        },
        grey,
    },
    //shadows: Array(25).fill('none') as Shadows,
    typography: {
        fontFamily,
        h1: {
            fontSize: 20,
            fontWeight: 500,
            lineHeight: '24px',
        },
        h2: {
            fontSize: 20,
            fontWeight: 300,
            lineHeight: '24px',
        },
        subtitle: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '20px',
        },
        title: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1.25,
            fontFamily,
        },
        body1: {
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '20px',
        },
        body2: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '16px',
        },
        button: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '16px',
            textTransform: 'none',
            letterSpacing: 'inherit',
        },
        menu: {
            fontSize: 10,
            fontWeight: 400,
            lineHeight: '12px',
        },
        overline: {
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '14px',
            textTransform: 'none',
            letterSpacing: 'inherit',
        },
        caption: {
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '14px',
        },
        total: {
            fontSize: 20,
            fontWeight: 300,
            lineHeight: 1.25,
        },
    },
});

const white = theme.palette.common.white;

theme = createTheme(theme, {
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '*::-webkit-scrollbar': {
                    width: '4px',
                },
                '*::-webkit-scrollbar-track': {
                    backgroundColor: alpha(
                        theme.palette.primary.light,
                        0.32
                    ),
                    marginTop: '4px',
                    marginBottom: '4px',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.grey[800],
                    outline: `1px solid ${alpha(
                        theme.palette.grey[100],
                        0.32
                    )}`,
                    borderRadius: '8px',
                },
            },
        },
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true,
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    textTransform: 'initial',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: grey[800],
                    ...theme.typography.menu,
                    minHeight: '24px',
                    color: grey[100],
                    display: 'flex',
                    alignItems: 'center',
                    maxWidth: '142px',
                },
                arrow: {color: grey[800]},
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    fontSize: 20,
                    padding: '2px',
                },
                root: {
                    '&.Mui-disabled': {
                        color: grey[600],
                    },
                    '&:active': {
                        backgroundColor:
                            theme.palette.common.light.pressed,
                    },
                },
            },
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    '& .icon': {
                        opacity: 1,
                        color: white,
                    },
                    '&:hover, &:focus': {
                        color: white,
                        '&& .icon': {
                            opacity: 1,
                            color: white,
                        },
                    },
                    '&.Mui-active': {
                        color: white,
                        '& .MuiTableSortLabel-icon': {
                            opacity: 1,
                            color: white,
                        },
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {...theme.typography.body2},
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.common.overlay.dark,
                },
            },
        },
    },
});

export default theme;
