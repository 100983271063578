import {CSSProperties, ComponentProps} from 'react';
import {Box} from '@mui/material';
import TableTileItem from './TableTileItem/index';
import _ from 'lodash';
import {UnknownObject} from '../..';

export interface TableTileProps {
    isLoading?: boolean;
    height?: number;
    style?: CSSProperties;
    fieldData: UnknownObject;
    columns: any;
    onClickMore: ComponentProps<'button'>['onClick'];
}
export default function TableTile({
    style,
    fieldData,
    columns,
    isLoading,
    onClickMore,
}: TableTileProps) {
    let data: {
        label: string;
        value: any;
    }[] = _.map(columns, (column: any) => {
        return {
            label: column.Header,
            value: _.get(fieldData, column.accessor) ?? '-',
        };
    });

    let headerData = _.slice(data, 0, 1);
    let otherColumnsData = _.slice(data, 1, data.length - 1);

    return (
        <Box
            id="table-mobile-item-card"
            style={style}
            sx={{
                borderStyle: 'inset',
                border: '1px solid #EEEEEE',
                width: 'calc(100% - 8px)',
            }}
        >
            <TableTileItem
                data={headerData}
                isHeader={true}
                onClickMore={onClickMore}
                isLoading={isLoading}
            />
            <TableTileItem data={otherColumnsData} isLoading={isLoading} />
        </Box>
    );
}
