import {Area} from 'common/components';
import AppLayout from 'modules/app/components/AppLayout';
//import Dropdown, {DropdownOption} from 'common/components/Dropdown';

export default function VersionsContainer() {
    /*

    const [isPinned, setIsPinned] = useState(true);
    const handleSubMenuBack = () => {
        setIsPinned(false);
    };
    const handleBackdropClick = () => {
        setIsPinned(true);
    };
    const handleClickPin = () => {
        setIsPinned(true);
    };
    const optionsSample: DropdownOption[] = [
        {
            name: 'vodafone',
            label: 'Vodafone',
        },
        {name: 'yodiwo_demo', label: 'Yodiwo Demo'},
        {name: 'yodiwo_demo2', label: 'Yodiwo Demo 2'},
    ];
    const [selectedItem, setSelectedItem] = useState('vodafone');

    const handleClickItem = (item: DropdownOption) => {
        setSelectedItem(item.name);
    };
    const togglerWidth = 302;
    const sideNavWidth = 85;
    */

    return (
        <AppLayout>
            <Area variant="higher" sx={{p: 1}}>
                Versions container
            </Area>
        </AppLayout>
    );
}
