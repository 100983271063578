import {createPortal} from 'react-dom';
import {ExpandedAreaIcon, ExtendCurve} from 'common/icons';
import {Box} from '@mui/material';
import {useEffect, useState, useCallback} from 'react';

export interface SubMenuExpanderProps {
    isOpen?: boolean;
    children?: React.ReactNode;
    onClick?: () => void;
    tabRef: HTMLDivElement | null;
    tabsRef: HTMLDivElement | null;
    arrowColor?: string;
}
export default function SubMenuExpander({
    isOpen,
    tabRef,
    arrowColor,
    tabsRef,
    onClick,
}: SubMenuExpanderProps) {
    const [pos, setPos] = useState({
        top: 0,
        left: 0,
        height: 0,
        bottom: 0,
        width: 0,
        right: 0,
        scrollerBottom: 0,
    });

    const updateScroll = useCallback(() => {
        const {bottom} = tabsRef?.getBoundingClientRect() ?? {};
        const rect = tabRef?.getBoundingClientRect() ?? ({} as DOMRect);
        setPos({
            top: rect.top + window.scrollY,
            bottom: rect.bottom,
            left: rect.left + window.scrollX,
            height: rect.height,
            width: rect.width,
            right: rect.right,
            scrollerBottom: bottom || 0,
        });
    }, [tabRef, tabsRef]);

    useEffect(() => {
        const scrollerElem = tabsRef?.querySelector('.MuiTabs-scroller');
        scrollerElem?.addEventListener('scroll', updateScroll, false);
        const init = setTimeout(updateScroll);

        return () => {
            const scrollerElem = tabsRef?.querySelector(
                '.MuiTabs-scroller'
            );
            scrollerElem?.removeEventListener(
                'scroll',
                updateScroll,
                false
            );
            clearTimeout(init);
        };
    }, [tabsRef, tabRef, updateScroll]);

    if (isOpen) {
        return <></>;
    }

    const HEIGHT = 32;
    const FIRST_TAB_TOP = 32;

    const top = pos.top + (pos.height - HEIGHT) / 2;
    const left = pos.left + pos.width - 2;

    return (
        <>
            {pos.top >= FIRST_TAB_TOP &&
                pos.bottom <= pos.scrollerBottom &&
                createPortal(
                    <>
                        <Box
                            position="absolute"
                            top={top - 16}
                            left={left}
                            right={pos.right}
                            bottom={pos.bottom + 16}
                            width={8}
                            height={16}
                            bgcolor="transparent"
                        >
                            <ExtendCurve sx={{color: 'grey.100'}} />
                        </Box>
                        <Box
                            aria-label="expand sub-menu"
                            display="flex"
                            visibility={isOpen ? 'hidden' : 'visible'}
                            justifyContent="center"
                            alignItems="center"
                            className="submenu-expander"
                            position="absolute"
                            onClick={onClick}
                            top={top}
                            left={left}
                            right={pos.right}
                            bottom={pos.bottom}
                            width={24}
                            height={HEIGHT}
                            bgcolor="grey.100"
                            sx={{
                                borderRadius: '0 16px 16px 0',
                                cursor: 'pointer',
                            }}
                        >
                            <ExpandedAreaIcon
                                sx={{color: arrowColor}}
                                fontSize="small"
                            />
                        </Box>
                        <Box
                            position="absolute"
                            sx={{transform: 'scaleY(-1)'}}
                            top={top + HEIGHT}
                            left={left}
                            right={pos.right}
                            bottom={pos.bottom - HEIGHT}
                            width={8}
                            height={16}
                            bgcolor="transparent"
                        >
                            <ExtendCurve sx={{color: 'grey.100'}} />
                        </Box>
                    </>,
                    document.body
                )}
        </>
    );
}
