import React, {forwardRef} from 'react';

import DropdownToggleIcon from '../DropdownToggleIcon';
import Input from 'common/components/Inputs';

export type DropdownTriggerProps = {
    autoExpand?: boolean;
    clearable?: boolean;
    controlLabel: string;
    disabled?: boolean;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    onControlChange: (value: string) => void;
    open: boolean;
    placeholder?: string;
    backgroundColor: string;
    width: number | string;
};

const DropdownTriggerInput = forwardRef<
    HTMLInputElement,
    DropdownTriggerProps
>((props, ref) => {
    const {
        autoExpand,
        clearable,
        controlLabel,
        disabled,
        backgroundColor,
        onClick,
        onControlChange,
        open,
        placeholder,
        width,
    } = props;
    return (
        <Input
            autoExpand={autoExpand}
            clearable={clearable}
            cursor={disabled ? 'default' : 'pointer'}
            disabled={disabled}
            onChange={onControlChange}
            onClick={onClick}
            placeholder={placeholder}
            readOnly
            ref={ref}
            value={controlLabel}
            width={width}
            endAdornment={
                <DropdownToggleIcon
                    className={open ? 'MuiSelect-iconOpen' : ''}
                />
            }
            inputProps={{
                disabled: true,
            }}
            sx={{backgroundColor}}
        />
    );
});

export default DropdownTriggerInput;
