import {DropdownMultiple, DropdownSingle} from 'common/components';
import Input from 'common/components/Inputs';
import {useCallback, useEffect, useMemo} from 'react';
import {Operator, operators} from '../../operators';
import FieldFilterDesktopBlock from './FieldFilterDesktopBlock';
import FieldFilterMobileBlock from './FieldFilterMobile';
import DatePicker from 'common/components/Pickers/DatePicker';
import DateRangePicker from 'common/components/Pickers/DatePicker/DateRangePicker';
import {Stack, Typography} from '@mui/material';
import _ from 'lodash';
import {FilterField} from 'modules/admin/hooks/useFilters.types';
import {useLazyFetchValuesQuery} from 'modules/admin/reducers/infrastructureEntityFilter.slice';
import {HierarchyLevel} from 'modules/admin/config/hierarchyKeys';
import {dropdownLabelsLevelsMap} from 'modules/admin/lib';
import {FilterDescriptor} from '../..';
import {useSelector} from 'common/hooks';

export interface FieldFilterBlockProps {
    fields?: FilterField[] | null;
    filter: FilterDescriptor;
    onChange: (
        _: FilterDescriptor,
        isField?: boolean,
        isOperator?: boolean
    ) => void;
    isMobile?: boolean;
    onFilterAdd: () => void;
    onFilterRemove: () => void;
    showAdd: boolean;
    showRemove: boolean;
    disabledAdd: boolean;
    hierarchy: HierarchyLevel;
}
interface FieldOption extends FilterField {
    value: string;
}

interface OperatorOption extends Operator {
    value: string;
}

export default function FieldFilterBlock({
    fields,
    isMobile,
    filter,
    onChange,
    showAdd,
    showRemove,
    onFilterAdd,
    onFilterRemove,
    disabledAdd,
    hierarchy,
}: FieldFilterBlockProps) {
    const [valuesTrigger, possibleValues] = useLazyFetchValuesQuery();
    const currentOrg = useSelector(
        (state) => state.app.selectedOrganization
    );
    useEffect(() => {
        if (
            (filter.field?.attributeTypeName === 'MultiDropdown' ||
                filter.field?.attributeTypeName === 'SingleDropdown') &&
            filter.field?.attributeName &&
            currentOrg
        ) {
            valuesTrigger({
                entityName: dropdownLabelsLevelsMap[hierarchy],
                attributeName: filter.field.attributeName,
                org: currentOrg?.id,
            });
        }
    }, [currentOrg, filter.field, hierarchy, valuesTrigger]);
    const fieldValue = useMemo(() => {
        return filter.field?.attributeName
            ? {
                  ...filter.field,
                  value: filter.field.attributeName,
              }
            : null;
    }, [filter.field]);
    const operatorValue = useMemo(() => {
        return filter.operator?.name
            ? {
                  ...filter.operator,
                  value: filter.operator.name,
              }
            : null;
    }, [filter.operator]);
    const operatorOptions = useMemo(() => {
        const type = filter.field?.attributeTypeName;
        if (type) {
            return operators[type]
                .filter(
                    (op) =>
                        !filter.field?.applicablePredicates ||
                        filter.field?.applicablePredicates.includes(
                            _.first(op.predicate)!
                        )
                )
                .map((op) => ({...op, value: op.name}));
        } else {
            return [];
        }
    }, [
        filter.field?.applicablePredicates,
        filter.field?.attributeTypeName,
    ]);
    const fieldInput = useMemo(() => {
        return (
            <DropdownSingle<FieldOption>
                supportDynamicHeight
                width={isMobile ? 232 : 176}
                placeholder="Select Column"
                onChange={(value) =>
                    onChange({...filter, field: value}, true, false)
                }
                value={fieldValue}
                autoExpand={isMobile}
                formatLabel={(s) => s.attributeName}
                variant="outlined"
                options={
                    fields?.map((field) => ({
                        ...field,
                        value: field.attributeName,
                    })) ?? []
                }
            />
        );
    }, [fieldValue, fields, filter, isMobile, onChange]);
    const operatorInput = useMemo(() => {
        return (
            <DropdownSingle<OperatorOption>
                supportDynamicHeight
                width={isMobile ? 232 : 158}
                autoExpand={isMobile}
                disabled={filter.field === undefined}
                placeholder="Contains"
                onChange={(value) => {
                    onChange(
                        {
                            ...filter,
                            operator: value,
                        },
                        false,
                        true
                    );
                }}
                value={operatorValue}
                formatLabel={(s) => s.name}
                variant="outlined"
                options={operatorOptions}
            />
        );
    }, [filter, isMobile, onChange, operatorOptions, operatorValue]);
    const handleRangeChange = useCallback(
        (value, index) => {
            let range: (number | null)[] | null;
            range = _.cloneDeep(filter.value as (number | null)[]) ?? [
                null,
                null,
            ];
            if (isNaN(Number(value))) {
                return;
            }
            //cleanup
            if (value === '') {
                if (_.find(range, (v) => v === null)) {
                    range = null;
                } else {
                    range[index] = null;
                }
            } else {
                range[index] = Number(value);
            }
            onChange({
                ...filter,
                value: range,
            });
        },
        [filter, onChange]
    );
    const filterInput = useMemo(() => {
        switch (filter.operator?.filterType) {
            default:
            case 'text':
                return (
                    <Input
                        disabled={filter.field === undefined}
                        width="352px"
                        autoExpand={isMobile}
                        placeholder="Insert value"
                        value={filter.value?.toString()}
                        onChange={(value) => {
                            onChange({
                                ...filter,
                                value: value,
                            });
                        }}
                    />
                );
            case 'date':
                return (
                    <DatePicker
                        width={352}
                        autoExpand={isMobile}
                        onChange={(value) => {
                            onChange({
                                ...filter,
                                value: value,
                            });
                        }}
                    />
                );
            case 'number':
                return (
                    <Input
                        autoExpand={isMobile}
                        noAutocomplete
                        width="352px"
                        placeholder="Insert value"
                        value={filter.value?.toString()}
                        onChange={(value) => {
                            if (isNaN(Number(value))) {
                                return;
                            }
                            onChange({
                                ...filter,
                                value: value,
                            });
                        }}
                    />
                );
            case 'daterange':
                return (
                    <DateRangePicker
                        width={352}
                        autoExpand={isMobile}
                        onChange={(start, end) => {
                            const daterange = [];
                            if (start) {
                                daterange.push(start);
                            }
                            if (end) {
                                daterange.push(end);
                            }
                            onChange({
                                ...filter,
                                value: daterange,
                            });
                        }}
                    />
                );
            case 'range':
                return (
                    <Stack direction="row">
                        <Input
                            autoExpand={isMobile}
                            width="161px"
                            placeholder="Insert value"
                            value={
                                filter.value?.constructor.name === 'Array'
                                    ? (
                                          filter.value as any[]
                                      )[0]?.toString()
                                    : undefined
                            }
                            onChange={(value) => {
                                handleRangeChange(value, 0);
                            }}
                        />
                        <Typography
                            alignSelf="center"
                            variant="body2"
                            px="8px"
                        >
                            to
                        </Typography>
                        <Input
                            autoExpand={isMobile}
                            width="161px"
                            placeholder="Insert value"
                            value={
                                filter.value?.constructor.name === 'Array'
                                    ? (
                                          filter.value as number[]
                                      )[1]?.toString()
                                    : undefined
                            }
                            onChange={(value) => {
                                handleRangeChange(value, 1);
                            }}
                        />
                    </Stack>
                );
            case 'multidropdown':
                return (
                    <DropdownMultiple<string>
                        virtualized
                        supportDynamicHeight
                        clearable
                        formatLabel={(value) => value}
                        variant="outlined"
                        options={possibleValues.currentData ?? []}
                        disabled={filter.field === undefined}
                        width="352px"
                        autoExpand={isMobile}
                        placeholder="Select value"
                        value={(filter.value as string[]) ?? []}
                        onChange={(value) => {
                            onChange({
                                ...filter,
                                value: value,
                            });
                        }}
                    />
                );
            case 'singledropdown':
                return (
                    <DropdownSingle<string>
                        supportDynamicHeight
                        virtualized
                        formatLabel={(value) => value}
                        variant="outlined"
                        options={possibleValues.currentData ?? []}
                        disabled={filter.field === undefined}
                        width="352px"
                        autoExpand={isMobile}
                        placeholder="Select value"
                        value={filter.value as string}
                        onChange={(value) => {
                            onChange({
                                ...filter,
                                value: value,
                            });
                        }}
                    />
                );
        }
    }, [
        filter,
        handleRangeChange,
        isMobile,
        onChange,
        possibleValues.currentData,
    ]);

    if (isMobile) {
        return (
            <FieldFilterMobileBlock
                fieldInput={fieldInput}
                filterInput={filterInput}
                operatorInput={operatorInput}
                onFilterRemove={onFilterRemove}
                showAdd={showAdd}
                showRemove={showRemove}
                disabledAdd={disabledAdd}
            />
        );
    } else {
        return (
            <FieldFilterDesktopBlock
                showAdd={showAdd}
                showRemove={showRemove}
                fieldInput={fieldInput}
                filterInput={filterInput}
                operatorInput={operatorInput}
                onFilterAdd={onFilterAdd}
                onFilterRemove={onFilterRemove}
                disabledAdd={disabledAdd}
            />
        );
    }
}
