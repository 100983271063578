import {z} from 'zod';

const filterFieldTypeSchema = z.enum([
    'String',
    'Number',
    'Date',
    'SingleDropdown',
    'MultiDropdown',
]);

const filterFieldSchema = z.object({
    attributeName: z.string(),
    attributeTypeName: filterFieldTypeSchema,
    applicablePredicates: z.string().array().optional(),
});

export const fullEntityInfoSchema = z
    .object({
        entityName: z.string(),
        supportedAttributes: filterFieldSchema.array(),
    })
    .array();

export type FilterFieldType = z.infer<typeof filterFieldTypeSchema>;
export type FullEntityInfo = z.infer<typeof fullEntityInfoSchema>;
export type FilterField = z.infer<typeof filterFieldSchema>;

const attributeToTypeMapOverride: {[attrName: string]: FilterFieldType} = {
} as const;

// This defines the standard mapping of C# system types to FilterFieldType, which defines the operator.
// Use attributeToTypeMapOverride to override the default mappings for a specific attribute. e.g. Name: "String" or Name: "SingleDropdown"
export const filterFieldTypeFromAttribute = (
    attribute: any,
    hasValues?: boolean
) => {
    if (attributeToTypeMapOverride[attribute.attributeName]) {
        return attributeToTypeMapOverride[attribute.attributeName];
    }
    const type = attribute.attributeTypeName;
    if (hasValues) {
        return 'MultiDropdown';
    } else if (type === 'String') {
        return 'String';
    } else if (type.includes('Date')) {
        return 'Date';
    } else {
        return 'Number';
    }
};
