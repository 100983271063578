import {ReactElement} from 'react';
import {Box, alpha, Button as MuiButton, Typography} from '@mui/material';
import FilterAreaMobileHeader from './FilterAreaMobileHeader';
import FilterAreaMobileFooter from './FilterAreaMobileFooter';
import FilterAreaMobileDivider from './FilterAreaMobileDivider';

export type FilterAreaMobileProps = {
    isOpen: boolean;
    onClose: () => void;
    onReset: () => void;
    onApply: () => void;
    renderToggleComponent: () => ReactElement;
    renderFilterComponent: () => ReactElement;
    resetEnabled: boolean;
    applyEnabled: boolean;
};

export default function FilterAreaMobile({
    isOpen,
    onApply,
    onReset,
    onClose,
    renderToggleComponent,
    renderFilterComponent,
    resetEnabled,
    applyEnabled,
}: FilterAreaMobileProps) {
    let isApplyDisabled = false;

    const toggleComponent = renderToggleComponent();
    const filterComponent = renderFilterComponent();

    return isOpen ? (
        <Box
            sx={{
                position: 'fixed',
                zIndex: (theme) => theme.zIndex.modal,
                top: 0,
                left: 0,
                m: 0,
                width: '100%',
                height: '100%',
                bgcolor: 'grey.100',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    flexDirection: 'column',
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.light, 0.32),
                }}
            >
                <FilterAreaMobileHeader onClose={onClose} />
                <FilterAreaMobileDivider />
                <Box
                    className="filter-area-mobile-body"
                    p={2}
                    height="100%"
                    sx={{
                        overflowY: 'clip',
                        display: 'flex',
                        flexDirection: 'column',
                        pb: '0',
                    }}
                >
                    <Box sx={{textAlign: 'right'}}>
                        <MuiButton
                            onClick={onReset}
                            disabled={!resetEnabled}
                        >
                            <Typography
                                variant="caption"
                                sx={{
                                    color: isApplyDisabled
                                        ? 'common.disabled'
                                        : undefined,
                                }}
                            >
                                Reset filters
                            </Typography>
                        </MuiButton>
                    </Box>
                    <Box>{toggleComponent}</Box>
                    <Box sx={{overflowY: 'hidden', height: '100%'}}>
                        {filterComponent}
                    </Box>
                </Box>
                <FilterAreaMobileFooter
                    onApply={onApply}
                    disabled={!applyEnabled}
                />
            </Box>
        </Box>
    ) : null;
}
