import {
    Autocomplete,
    AutocompleteChangeReason,
    AutocompleteRenderInputParams,
    AutocompleteValue,
    Box,
    ClickAwayListener,
    PopperProps,
    styled,
} from '@mui/material';
import {Base, DropdownProps, Value, getStringFromValue} from '../index';
import {
    HTMLAttributes,
    ReactElement,
    ReactNode,
    SyntheticEvent,
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import DropdownNoResults from '../DropdownNoResults';
import DropdownOption from '../ItemRenderers/DropdownOption';
import DropdownPopper from '../DropdownPopper';
import DropdownSearchInput from '../DropdownSearchInput';
import DropdownTriggerButton from '../DropdownTriggerButton/DropdownTriggerButton';
import DropdownTriggerInput from '../DropdownTriggerInput/DropdownTriggerInput';
import SelectAllDropdownOption from './SelectAllDropdownOption';
import clsx from 'clsx';
import _ from 'lodash';
import { grey } from 'common/colors';
type ListboxRenderer = (
    props: HTMLAttributes<HTMLElement>
) => ReactElement<any, any> | null;

function PopperComponent(props: PopperProps) {
    const {disablePortal, anchorEl, open, ...other} = props;
    return (
        <Box
            className="popper-component"
            {...other}
            style={{width: '100%', borderRadius: '0 0 4px 4px'}}
        />
    );
}

const StyledAutocomplete = styled(Autocomplete)(({theme: {spacing}}) => ({
    '& .MuiOutlinedInput-root': {
        padding: 0,
    },
    '&.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root': {
        paddingRight: 0,
    },
})) as typeof Autocomplete;

const isStringValue = <TValue extends Base>(
    value: TValue | string
): value is string => {
    return typeof value === 'string';
};

export default function DropdownSimple<
    T extends Base,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined
>({
    ItemRenderer,
    autoExpand,
    clearable,
    disabled,
    backgroundColor = grey[100],
    formatLabel,
    onChange,
    options,
    placeholder,
    selectionVariant,
    value,
    variant,
    width = 320,
    supportDynamicHeight = false,
}: DropdownProps<T, Multiple, DisableClearable>) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [inputAutoWidth, setInputAutoWidth] = useState<number>(320);
    const [inputValue, setInputValue] = useState('');
    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            if (disabled) {
                return;
            }
            setAnchorEl(e.currentTarget);
        },
        [disabled]
    );
    const handleClose = useCallback(() => {
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    }, [anchorEl, setAnchorEl]);
    const open = Boolean(anchorEl);
    const showSearchBar = useMemo(() => options.length > 7, [options]);
    const [selectAll, setSelectAll] = useState(false);

    const handleChange = useCallback(
        (
            _event: SyntheticEvent<Element, Event>,
            value: Value<T, Multiple, DisableClearable>,
            _reason: AutocompleteChangeReason
        ) => {
            if (selectionVariant !== 'multiple') {
                handleClose();
            }
            setSelectAll(false);
            onChange(value);
        },
        [onChange, handleClose, selectionVariant, setSelectAll]
    );
    const controlLabel = useMemo(() => {
        if (value !== null) {
            if (Array.isArray(value)) {
                return value.map(formatLabel).join(', ');
            } else {
                return value ? formatLabel(value as T) : '';
            }
        }
        return '';
    }, [value, formatLabel]);
    const isMultiple = selectionVariant === 'multiple';
    const handleSearchChange = useCallback((searchedValue: string) => {
        setInputValue(searchedValue);
    }, []);
    const getDropdownPopperWidth = useCallback(() => {
        if (autoExpand) {
            return inputAutoWidth;
        }
        if (typeof width === 'number') {
            return width;
        }
        if (typeof width === 'string') {
            if (isMultiple) {
                return inputAutoWidth + 40;
            }
            return inputAutoWidth;
        }
        return width;
    }, [autoExpand, inputAutoWidth, isMultiple, width]);

    const handleClickAll = useCallback(
        (availableOptions: Base[]) => {
            setSelectAll((state) => {
                let newValue: Base[];
                if (state) {
                    //if all are selected, deselect
                    newValue = [];
                } else {
                    newValue = availableOptions;
                }
                onChange(
                    newValue as AutocompleteValue<
                        T,
                        Multiple,
                        DisableClearable,
                        false
                    >
                );
                return !state;
            });
        },
        [onChange]
    );
    const listComponent: ListboxRenderer = useMemo(
        () =>
            forwardRef(({children, ...otherProps}, ref) => {
                let availableOptions = children as ReactNode[];
                let estimatedHeight = availableOptions.length * 32;
                return (
                    <ul
                        data-testid="dropdown-selections-list"
                        {...otherProps}
                        className={clsx([
                            otherProps.className,
                            'listbox-simple',
                        ])}
                        style={{
                            height:
                                supportDynamicHeight && !isMultiple
                                    ? estimatedHeight
                                    : '240px',
                            maxHeight: '240px',
                        }}
                    >
                        {isMultiple && !inputValue ? (
                            <SelectAllDropdownOption
                                availableOptions={_.map(
                                    availableOptions,
                                    (e: any) => e.props.option
                                )}
                                selected={selectAll}
                                width={getDropdownPopperWidth()}
                                handleClick={handleClickAll}
                            />
                        ) : null}
                        {children}
                    </ul>
                );
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isMultiple, getDropdownPopperWidth, selectAll, inputValue]
    );
    const inputComponent = useCallback(
        (params: AutocompleteRenderInputParams) => (
            <DropdownSearchInput
                params={params}
                showSearchBar={showSearchBar}
                onSearchChange={handleSearchChange}
            />
        ),
        [showSearchBar, handleSearchChange]
    );
    const renderOption = useCallback(
        (
            props: HTMLAttributes<HTMLLIElement>,
            option: T,
            {selected}: {selected: boolean}
        ) => {
            const label = formatLabel(option);
            const value = isStringValue(option) ? option : option.value;
            const componentProps = {
                ...props,
                style: {minHeight: 'auto'},
                label,
                option,
                value,
                selectionVariant,
                selected,
                width: getDropdownPopperWidth(),
            };
            return ItemRenderer ? (
                <ItemRenderer
                    {...props}
                    option={option}
                    label={label}
                    value={value}
                    selected={selected}
                    selectionVariant={selectionVariant}
                    width={getDropdownPopperWidth()}
                />
            ) : (
                <DropdownOption {...componentProps} />
            );
        },
        [
            formatLabel,
            selectionVariant,
            getDropdownPopperWidth,
            ItemRenderer,
        ]
    );
    const noOptionsText: ReactNode = useMemo(
        () => (
            <Box height={240}>
                <DropdownNoResults />
            </Box>
        ),
        []
    );
    const handleControlChange = useCallback(
        (value: string) => {
            // clear gets clicked
            if (value === '' && selectionVariant === 'multiple') {
                onChange(
                    [] as unknown as Value<T, Multiple, DisableClearable>
                );
                setSelectAll(false);
            }
        },
        [selectionVariant, onChange]
    );
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputRef.current) {
            setInputAutoWidth(inputRef.current.offsetWidth + 44);
        }
    }, [inputRef?.current?.offsetWidth]);
    const buttonRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        if (buttonRef.current) {
            setInputAutoWidth(buttonRef.current.offsetWidth);
        }
    }, [buttonRef?.current?.offsetWidth]);
    return (
        <>
            <Box
                data-testid="mui-dropdown-test"
                sx={{flexGrow: autoExpand ? 1 : undefined}}
            >
                {variant === 'outlined' ? (
                    <DropdownTriggerInput
                        autoExpand={autoExpand}
                        clearable={clearable}
                        controlLabel={controlLabel}
                        disabled={disabled}
                        onClick={handleClick}
                        onControlChange={handleControlChange}
                        open={open}
                        placeholder={placeholder}
                        ref={inputRef}
                        width={width}
                        backgroundColor={backgroundColor}
                    />
                ) : (
                    <DropdownTriggerButton
                        autoExpand={autoExpand}
                        clearable={clearable}
                        controlLabel={controlLabel}
                        disabled={disabled}
                        onClick={handleClick}
                        onControlChange={handleControlChange}
                        open={open}
                        placeholder={placeholder}
                        ref={buttonRef}
                        width={width}
                        backgroundColor={backgroundColor}
                    />
                )}
            </Box>
            <DropdownPopper
                showSearchBar
                width={getDropdownPopperWidth()}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                sx={{
                    pr: 0,
                }}
            >
                <ClickAwayListener
                    onClickAway={handleClose}
                    touchEvent={false}
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            borderRadius: '4px',
                        }}
                        data-testid="dropdown-test"
                    >
                        <StyledAutocomplete<
                            T,
                            Multiple,
                            DisableClearable,
                            false
                        >
                            open
                            fullWidth
                            ListboxComponent={listComponent}
                            PopperComponent={PopperComponent}
                            disableCloseOnSelect={isMultiple}
                            forcePopupIcon={false}
                            inputValue={inputValue}
                            multiple={isMultiple as Multiple}
                            noOptionsText={noOptionsText}
                            onChange={handleChange}
                            options={options}
                            renderInput={inputComponent}
                            renderOption={renderOption}
                            componentsProps={{
                                paper: {
                                    elevation: 0,
                                },
                            }}
                            getOptionLabel={(option) => {
                                if (
                                    Array.isArray(option) &&
                                    option.length === 0
                                ) {
                                    return '';
                                }
                                return option ? formatLabel(option) : '';
                            }}
                            isOptionEqualToValue={(option, value) => {
                                return (
                                    getStringFromValue(option) ===
                                    getStringFromValue(value)
                                );
                            }}
                            onClose={() => {
                                handleSearchChange('');
                            }}
                            onInputChange={(_e, value, reason) => {
                                if (reason !== 'reset') {
                                    handleSearchChange(value);
                                }
                            }}
                            value={
                                value as Value<
                                    T,
                                    Multiple,
                                    DisableClearable
                                >
                            }
                        />
                    </div>
                </ClickAwayListener>
            </DropdownPopper>
        </>
    );
}
