import {useCallback, useState} from 'react';
import {SvgIconComponent} from '@mui/icons-material';
import {Box, Drawer, Typography, Collapse} from '@mui/material';
import {Module, ModuleName} from 'common/constants/modules';
import ProductMenuMobileLogoutButton from './ProductMenuMobileLogoutButton';
import ProductMenuMobileSelectionArea from './ProductMenuMobileSelectionArea';

import {
    ReportingIcon,
    AdminMobileIcon,
    ProfileIcon,
    RulesIcon,
    IncidentsIcon,
} from 'common/icons';
import ProductMenuMobileModuleDropdown from './ProductMenuMobileModuleDropdown';
interface ProductMenuMobileProps {
    isOpen: boolean;
    drawerWidth: string | number;
    isProductMenuExpanded?: boolean;
    products: Module[];
    activeProductName: ModuleName;
    activeProductLabel?: string;
    activeProductIcon?: SvgIconComponent;
    onBackdropClick: () => void;
    onProductClick: (productName: ModuleName) => void;
    onClickSettings: () => void;
    adminSelected: boolean;
}
export default function ProductMenuMobile({
    isOpen,
    drawerWidth,
    activeProductLabel,
    activeProductName,
    activeProductIcon,
    adminSelected,
    onClickSettings,

    //used when menu expanded
    products,
    onProductClick,
}: ProductMenuMobileProps) {
    const [isProductMenuExpanded, setProductMenu] =
        useState<boolean>(false);

    const handleProductClick = useCallback(
        (name: ModuleName) => {
            setProductMenu(false);
            onProductClick(name);
        },
        [onProductClick]
    );

    const selectionItems = [
        {
            leftEl: <ReportingIcon sx={{margin: '0px 16px'}} />,
            label: <Typography>Reporting </Typography>,
            onClick: () => {
                console.log('Reporting clicked...');
            },
        },
        {
            leftEl: <RulesIcon sx={{margin: '0px 16px'}} />,
            label: <Typography>Device Rules </Typography>,
            onClick: () => {
                console.log('Rules clicked...');
            },
        },
        {
            leftEl: <IncidentsIcon sx={{margin: '0px 16px'}} />,
            label: <Typography>Incidents </Typography>,
            onClick: () => {
                console.log('Incidents clicked...');
            },
            rightEl: (
                <Box
                    height="13px"
                    sx={{
                        borderRadius: '100px',
                        display: 'flex',
                        ml: 'auto',
                        mr: '24px',
                        px: '4.4px',
                        backgroundColor: (theme) =>
                            theme.palette.secondary.light,
                    }}
                >
                    <Typography variant="menu"> 22</Typography>
                </Box>
            ),
        },
        {
            leftEl: <AdminMobileIcon sx={{margin: '0px 16px'}} />,
            label: <Typography>Admin</Typography>,
            onClick: onClickSettings,
            selected: adminSelected,
        },
        {
            leftEl: <ProfileIcon sx={{margin: '0px 16px'}} />,
            label: <Typography>Profile</Typography>,
            onClick: () => {
                console.log('Profile clicked...');
            },
        },
    ] as const;

    return (
        <Drawer
            anchor="top"
            open={isOpen}
            sx={{
                zIndex: (theme) => theme.zIndex.mobileStepper,
                width: drawerWidth,
                display: 'flex',
                flexFlow: 'column',
                '& .MuiDrawer-paper': {
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'flex-start',
                    bgcolor: 'grey.200',
                },
            }}
        >
            <Collapse
                in={isProductMenuExpanded}
                collapsedSize={48}
                sx={{
                    '.MuiCollapse-root.MuiCollapse-vertical ': {
                        borderRadius: '24px',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'fixed',
                        width: '100%',
                        top: '56px',
                        height: 'calc(100% - 56px)',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 2,
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            px: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <ProductMenuMobileModuleDropdown
                            activeProductIcon={activeProductIcon}
                            isProductMenuExpanded={isProductMenuExpanded}
                            adminSelected={adminSelected}
                            activeProductLabel={activeProductLabel}
                            setProductMenu={setProductMenu}
                            products={products}
                            activeProductName={activeProductName}
                            onProductClick={handleProductClick}
                        />
                        <ProductMenuMobileSelectionArea
                            isProductMenuExpanded={isProductMenuExpanded}
                            selectionItems={selectionItems}
                        />
                    </Box>
                    <ProductMenuMobileLogoutButton />
                </Box>
            </Collapse>
        </Drawer>
    );
}
