import {Box, SxProps} from '@mui/material';
import {HierarchyLevel} from 'modules/admin/config/hierarchyKeys';
import DropdownEntityName from './DropdownEntityName';
import DropdownHierarchy from './DropdownHierarchy';

type HierarchyOption = {
    value: HierarchyLevel;
    label: string;
};

export type HierarchyFilterProps = {
    hierarchyDropdownOptions: HierarchyOption[];
    onChangeLevelFilter: (values: any) => void;
    selectedLevelFilter: HierarchyOption | null;
    selectedLevelNamesOptions?: string[];
    selectedLevelsItems?: string[];
    onChangeSelectedItems: (values: string[]) => void;
    sx?: SxProps;
};
export default function HierarchyFilter({
    sx,
    hierarchyDropdownOptions,
    selectedLevelFilter,
    selectedLevelNamesOptions,
    selectedLevelsItems,
    onChangeLevelFilter,
    onChangeSelectedItems,
}: HierarchyFilterProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'row',
                ...sx,
            }}
        >
            <Box
                sx={{
                    width: {
                        mobile: 142,
                    },
                }}
            >
                <DropdownHierarchy
                    hierarchyDropdownOptions={hierarchyDropdownOptions}
                    onChange={onChangeLevelFilter}
                    value={selectedLevelFilter}
                />
            </Box>
            <Box
                sx={{
                    width: {
                        mobile: 394,
                    },
                }}
            >
                <DropdownEntityName
                    disabled={!selectedLevelFilter}
                    selectedLevelNamesOptions={selectedLevelNamesOptions}
                    selectedLevelsItems={selectedLevelsItems}
                    onChangeSelectedItems={onChangeSelectedItems}
                />
            </Box>
        </Box>
    );
}
