export const hierarchyDataKeyMap = {
    organization: 'Organizations',
    buildings: 'Buildings',
    floors: 'Floors',
    zones: 'Zones',
};

export const categoryLevels = {
    buildings: 0,
    floors: 1,
    zones: 2,
};

export const queryLevels = {
    buildings: categoryLevels.buildings,
    floors: categoryLevels.floors,
    zones: categoryLevels.zones,
};

export type HierarchyLevel = keyof Omit<
    typeof hierarchyDataKeyMap,
    'organization'
>;

export type HierarchyCount = {[key in HierarchyLevel]?: number};
