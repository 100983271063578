import {Box} from '@mui/material';
import _ from 'lodash';
import HierarchyToggleButtons from '../HierarchyToggleButtons';
import {HierarchyLevel} from 'modules/admin/config/hierarchyKeys';
import {ComponentProps, useCallback, useEffect, useState} from 'react';
import {dropdownLabelsLevelsMap} from 'modules/admin/lib';
import HierarchyFilter from './HierarchyFilter';
import FilterArea from '../FilterArea';
import {InfrastructureFilterProps} from '../index';
import {useLazyFetchValuesQuery} from 'modules/admin/reducers/infrastructureEntityFilter.slice';
import {useSelector} from 'common/hooks';
import {CompleteFilterDescriptor} from '../FilterFormSingle';
import {operators} from '../FilterFormSingle/operators';

type HierarchyOption = {
    value: HierarchyLevel;
    label: string;
};

export default function FilterFormMultiple({
    hierarchy,
    hierarchyOptions,
    isLoading,
    isDesktopOpen,
    isMobileOpen,
    onChangeHierarchy,
    onClose,
    dataTree,
    onChangeFilters,
}: InfrastructureFilterProps) {
    const [hierarchyDropdownOptions, setHierarchyDropdownOptions] =
        useState<{label: string; value: HierarchyLevel}[]>([]);

    const [selectedLevelFilter, setSelectedLevelFilter] =
        useState<HierarchyOption | null>(null);
    const [selectedLevelsItems, setSelectedLevelsItems] = useState<
        string[]
    >([]);

    const [valuesTrigger, possibleValues] = useLazyFetchValuesQuery();
    const currentOrganization = useSelector(
        (state) => state.app.selectedOrganization
    );
    useEffect(() => {
        if (selectedLevelFilter && currentOrganization) {
            valuesTrigger({
                entityName:
                    dropdownLabelsLevelsMap[selectedLevelFilter.value],
                attributeName: 'Name',
                org: currentOrganization?.id,
            });
        }
    }, [
        hierarchy,
        selectedLevelFilter,
        valuesTrigger,
        currentOrganization,
    ]);

    const constructFiltersFromValues = useCallback(
        (values: any[]) => {
            const containsOperator = operators?.MultiDropdown.find(
                (op) => op.id === 'containsMultiDropdown'
            );
            if (
                selectedLevelFilter !== null &&
                !_.isEmpty(values) &&
                containsOperator
            ) {
                return [
                    {
                        hierarchy: selectedLevelFilter.value,
                        field: {
                            attributeName: 'Name',
                            attributeTypeName: 'String',
                        },
                        operator: containsOperator,
                        value: values,
                    },
                ] as CompleteFilterDescriptor[];
            }
            return [];
        },
        [selectedLevelFilter]
    );

    const handleSelectedItemsChange = useCallback<
        ComponentProps<typeof HierarchyFilter>['onChangeSelectedItems']
    >(
        (values) => {
            let newFilters: CompleteFilterDescriptor[] =
                constructFiltersFromValues(values);

            setSelectedLevelsItems((state) => {
                if (!isMobileOpen && !_.isEqual(state, values)) {
                    onChangeFilters(newFilters);
                }
                return values;
            });
        },
        [constructFiltersFromValues, isMobileOpen, onChangeFilters]
    );

    useEffect(() => {
        setSelectedLevelsItems([]);
    }, [hierarchy, currentOrganization]); //reset on hierarchy change, org change

    const handleChangeLevelFilter = useCallback(
        (value: HierarchyOption) => {
            setSelectedLevelFilter(value);
            handleSelectedItemsChange([]);
        },
        [handleSelectedItemsChange]
    );

    const handleChangeHierarchy = useCallback(
        (values: Array<HierarchyLevel>) => {
            onChangeHierarchy(values);
            if (!_.includes(values, selectedLevelFilter?.value)) {
                setSelectedLevelFilter(null);
            }
        },
        [selectedLevelFilter, setSelectedLevelFilter, onChangeHierarchy]
    );

    useEffect(() => {
        let options = hierarchy.map((level) => ({
            label: `${dropdownLabelsLevelsMap[level]} name`,
            value: level as HierarchyLevel,
        }));
        setSelectedLevelFilter(null);
        setHierarchyDropdownOptions(options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hierarchy]);

    const renderToggleComponent = useCallback(
        () => (
            <HierarchyToggleButtons
                hierarchy={hierarchy}
                isLoading={isLoading}
                hierarchyOptions={hierarchyOptions}
                onChange={handleChangeHierarchy}
            />
        ),
        [hierarchy, isLoading, hierarchyOptions, handleChangeHierarchy]
    );

    const renderFilterComponent = useCallback(
        () => (
            <Box
                sx={{
                    bgcolor: 'grey.100',
                    p: '12px',
                    borderRadius: '4px',
                }}
            >
                <HierarchyFilter
                    selectedLevelNamesOptions={possibleValues.currentData}
                    hierarchyDropdownOptions={hierarchyDropdownOptions}
                    onChangeLevelFilter={handleChangeLevelFilter}
                    selectedLevelFilter={selectedLevelFilter}
                    selectedLevelsItems={selectedLevelsItems}
                    onChangeSelectedItems={handleSelectedItemsChange}
                    sx={
                        isMobileOpen
                            ? {
                                  flexDirection: 'column',
                                  gap: '16px',
                              }
                            : {}
                    }
                />
            </Box>
        ),
        [
            possibleValues.currentData,
            hierarchyDropdownOptions,
            handleChangeLevelFilter,
            selectedLevelFilter,
            selectedLevelsItems,
            handleSelectedItemsChange,
            isMobileOpen,
        ]
    );
    const handleApply = useCallback(() => {
        const filters = constructFiltersFromValues(selectedLevelsItems);
        onChangeFilters(filters);
        onClose();
    }, [
        constructFiltersFromValues,
        onChangeFilters,
        onClose,
        selectedLevelsItems,
    ]);
    const handleReset = useCallback(() => {
        handleSelectedItemsChange([]);
        setSelectedLevelFilter(null);
    }, [handleSelectedItemsChange]);

    return (
        <FilterArea
            resetEnabled={selectedLevelsItems.length > 0}
            isMobileOpen={isMobileOpen}
            isDesktopOpen={isDesktopOpen}
            applyEnabled={true}
            onClose={onClose}
            onApply={handleApply}
            onReset={handleReset}
            renderToggleComponent={renderToggleComponent}
            renderFilterComponent={renderFilterComponent}
        />
    );
}
