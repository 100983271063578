import {
    Grid,
    IconButton as MuiIconButton,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {grey} from 'common/colors';
import {FilterAddIcon} from 'common/icons';
import _ from 'lodash';
import {HierarchyLevel} from 'modules/admin/config/hierarchyKeys';
import {FilterField} from 'modules/admin/hooks/useFilters.types';
import {useMemo} from 'react';
import {FilterDescriptor} from '..';
import FieldFilterBlock from './FieldFilterBlock';

export interface FieldFilterProps {
    isMobile?: boolean;
    fields?: FilterField[] | null;
    onChange: (
        index: number,
        filterChanged: FilterDescriptor,
        fieldChange?: boolean,
        operatorChange?: boolean
    ) => void;
    filters: FilterDescriptor[];
    onFilterAdd: () => void;
    onFilterRemove: () => void;
    maxFilters: number;
    hierarchy: HierarchyLevel;
    disabledAdd: boolean;
}

function FieldFilter({
    fields,
    filters,
    onChange,
    isMobile,
    hierarchy,
    onFilterAdd,
    onFilterRemove,
    maxFilters,
    disabledAdd,
}: FieldFilterProps) {
    const fieldFilterBlocks = useMemo(() => {
        const remainingFields = _.clone(fields);
        return filters.map((filter, index) => {
            const isLast = index === filters.length - 1;
            const showRemove = isLast && !(filters.length === 1);
            const selectableFieldsForCurrentBlock =
                _.clone(remainingFields);
            if (filter.field?.attributeName && remainingFields) {
                // when we have a tags field it will be exempt from this check
                _.remove(
                    remainingFields,
                    (field) =>
                        field.attributeName === filter.field?.attributeName
                );
            }
            const showAdd =
                isLast &&
                filters.length < maxFilters &&
                !(selectableFieldsForCurrentBlock?.length === 1);
            return (
                <FieldFilterBlock
                    hierarchy={hierarchy}
                    disabledAdd={disabledAdd}
                    key={index}
                    showAdd={showAdd}
                    showRemove={showRemove}
                    onFilterAdd={onFilterAdd}
                    onFilterRemove={onFilterRemove}
                    isMobile={isMobile}
                    fields={selectableFieldsForCurrentBlock}
                    filter={filter}
                    onChange={(...props) => onChange(index, ...props)}
                />
            );
        });
    }, [
        disabledAdd,
        fields,
        filters,
        hierarchy,
        isMobile,
        maxFilters,
        onChange,
        onFilterAdd,
        onFilterRemove,
    ]);
    const {
        breakpoints: {down},
    } = useTheme();
    const isSmallerDesktop = useMediaQuery(down('desktopMd'));
    const maxHeight = useMemo(() => {
        if (isMobile) {
            return '100%';
        } else if (isSmallerDesktop) {
            return '110px';
        } else {
            return '190px';
        }
    }, [isMobile, isSmallerDesktop]);
    return (
        <Grid
            maxHeight={maxHeight}
            sx={{
                overflowY: 'scroll',
                overflow: 'overlay',
            }}
            container
            rowGap="16px"
            columnGap="36px"
            justifyContent={isMobile ? 'flex-end' : 'flex-start'}
        >
            {fieldFilterBlocks}
            {isMobile && (
                <MuiIconButton
                    onClick={onFilterAdd}
                    disabled={disabledAdd}
                    sx={{
                        width: '40px',
                        height: '40px',
                        backgroundColor: 'grey.100',
                        border: `1px solid ${grey[800]}`,
                        borderRadius: '4px',
                    }}
                >
                    <FilterAddIcon />
                </MuiIconButton>
            )}
        </Grid>
    );
}

export default FieldFilter;
