import {configureStore} from '@reduxjs/toolkit';
import userReducer from './common/reducers/user.slice';
import infrastructureReducer from './modules/admin/reducers/infrastructure.slice';
import appReducer from './common/reducers/app.slice';
import {entityFilterApi} from 'modules/admin/reducers/infrastructureEntityFilter.slice';
import * as Sentry from '@sentry/react';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
});

export const store = configureStore({
    reducer: {
        app: appReducer,
        user: userReducer,
        infrastructure: infrastructureReducer,
        [entityFilterApi.reducerPath]: entityFilterApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(entityFilterApi.middleware),
    enhancers: [sentryReduxEnhancer],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
