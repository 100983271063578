import {
    Box,
    IconButtonProps as MuiIconButtonProps,
    SxProps,
    Tooltip,
    TooltipProps,
    Typography,
    useTheme,
} from '@mui/material';
import {IconButton as MuiIconButton} from '@mui/material';
import {useMemo} from 'react';

export interface IconButtonProps extends MuiIconButtonProps {
    variant?: 'dark' | 'brandDark' | 'brandLight';
    isSelected?: boolean;
    counter?: number;
    indicator?: boolean;
    tooltip?: string;
    tooltipProps?: TooltipProps;
}
function IconButton({
    isSelected,
    variant,
    tooltip,
    tooltipProps,
    counter,
    indicator,
    ...props
}: IconButtonProps) {
    const theme = useTheme();

    const commonSx: SxProps = {
        height: '32px',
        width: '32px',
        margin: '4px',
        '&.Mui-disabled': {
            color: theme.palette.common.disabled,
        },
    };
    const darkSx: SxProps = useMemo(() => {
        return {
            color: isSelected ? 'grey.100' : 'grey.600',
            backgroundColor: isSelected ? 'grey.600' : undefined,
            '&:focus': {
                color: 'grey.600',
                backgroundColor: theme.palette.common.light.focus,
            },
            '&:hover': {
                color: isSelected ? 'grey.100' : 'grey.600',
                backgroundColor: isSelected
                    ? 'grey.600'
                    : theme.palette.common.light.hover,
            },
            '&:active': {
                backgroundColor: theme.palette.common.light.pressed,
                color: 'grey.600',
            },
        };
    }, [isSelected, theme.palette]);

    const brandDarkSx: SxProps = useMemo(() => {
        return {
            backgroundColor: isSelected
                ? theme.palette.primary.main
                : 'grey.100',
            color: isSelected ? 'grey.100' : theme.palette.primary.main,
            '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.common.brandLight.hover,
            },
            '&:focus': {
                backgroundColor: theme.palette.common.brandLight.focus,
            },
            '&:active': {
                backgroundColor: theme.palette.common.brandLight.pressed,
            },
        };
    }, [isSelected, theme.palette]);
    const brandLightSx: SxProps = useMemo(() => {
        return {
            color: isSelected ? theme.palette.primary.main : 'grey.100',
            backgroundColor: 'grey.100',
            '&:hover': {
                color: 'grey.100',
                backgroundColor: theme.palette.common.brandDark.hover,
            },
            '&:focus': {
                backgroundColor: theme.palette.common.brandDark.focus,
            },
            '&:active': {
                backgroundColor: theme.palette.common.brandDark.pressed,
            },
        };
    }, [isSelected, theme.palette]);
    const themeSx = useMemo(() => {
        switch (variant) {
            default:
                return darkSx;
            case 'brandDark':
                return brandDarkSx;
            case 'brandLight':
                return brandLightSx;
        }
    }, [brandDarkSx, brandLightSx, darkSx, variant]);
    return (
        <Tooltip
            title={tooltip ?? ''}
            arrow
            enterDelay={500}
            {...tooltipProps}
        >
            <MuiIconButton
                {...props}
                sx={{...commonSx, ...themeSx, ...props.sx}}
                aria-selected={isSelected}
            >
                {(indicator || counter) && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: counter ? '-2px' : '0px',
                            right: counter ? '-2px' : '0px',
                            width: counter ? 'fit-content' : '8px',
                            height: counter ? '13px' : '8px',
                            backgroundColor: (theme) =>
                                counter
                                    ? theme.palette.secondary.light
                                    : theme.palette.secondary.main,
                            borderRadius: '100px',
                        }}
                    >
                        <Typography
                            px="4.4px"
                            variant="menu"
                            position="relative"
                            right="0"
                            top="-12px"
                        >
                            {counter ? counter : null}
                        </Typography>
                    </Box>
                )}
                {props.children}
            </MuiIconButton>
        </Tooltip>
    );
}

export default IconButton;
