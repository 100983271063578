import {
    Box,
    Card,
    CardProps,
    styled,
    Theme,
    alpha,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React from 'react';
import {Module, ModuleName} from 'common/constants/modules';
import ProductCardBody from './ProductCardBody';

interface ProductCardProps {
    product: Module;
    activeProductName: ModuleName;
    onClick: (productName: ModuleName) => void;
}

export default function ProductCard({
    product,
    activeProductName,
    onClick = () => {},
}: ProductCardProps) {
    const {
        breakpoints: {up},
    } = useTheme();

    // placeholder to indicate handling of disabled
    // workspace -- will get replaced once API has
    // been integrated
    const productDisabled = product.name === 'yodispace';
    const productIsActive = activeProductName === product.name;
    const isMobile = !useMediaQuery(up('desktopSm'));

    const handleClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        // placeholder check to be replaced
        if (!productDisabled && !productIsActive) {
            onClick(product.name);
        }
    };

    const disabledCardStyles = ({spacing, palette: {grey}}: Theme) => `
        border-radius: 50px; 
        margin-left: ${spacing(isMobile ? 2 : 3)};
        margin-right: ${spacing(isMobile ? 2 : 3)};
        & .wrapper {
            background-color: ${grey[300]};
            color: ${grey[900]};
        }
    `;
    const enabledCardStyles = ({
        palette: {primary, common},
        spacing,
    }: Theme) => `
        box-shadow: 0px 4px 10px ${alpha(primary.main, 0.24)};
        border-radius: 50px;
        margin-left: ${spacing(isMobile ? 2 : 3)};
        margin-right: ${spacing(isMobile ? 2 : 3)};
        color: ${primary.main};
        background-color: ${common.white};
        border-color: ${common.white};
        & hover: {
                bgcolor: ${primary.main};
             }
    `;
    const activeCardStyles = ({
        palette: {primary, common, success},
        spacing,
    }: Theme) => `
        border-radius: 50px;
        margin-left: ${spacing(isMobile ? 2 : 3)};
        margin-right: ${spacing(isMobile ? 2 : 3)};
        background-color: ${primary.main};
        border-color: ${common.white};
        color: ${primary.main};
        & .card-actions {
            color: ${success.main};
        }
        & .wrapper {
            background-color: ${primary.main};
            color: ${common.white};
        }
        & .wrapper:hover {    
            background-color: ${primary.main};       
        }
    `;

    const StyledCard = styled((props: CardProps) => (
        <Card elevation={0} {...props} />
    ))(({theme}) => {
        if (productDisabled) {
            return disabledCardStyles(theme);
        }
        if (productIsActive) {
            return activeCardStyles(theme);
        }
        return enabledCardStyles(theme);
    });

    return (
        <StyledCard
            sx={{
                position: 'relative',
                my: isMobile ? 0 : 3,
            }}
        >
            <Box
                sx={{
                    left: 0,
                    top: 'calc(50% - 30px)',
                    position: 'absolute',
                    height: isMobile ? '50px' : '60px',
                }}
            />
            <Box
                className="wrapper"
                sx={{
                    p: 0.5,
                    transition: '0.1s',
                    '&:hover': {
                        bgcolor: 'common.brandLight.hover',
                    },
                    '&:active': {
                        bgcolor: 'common.brandLight.pressed',
                    },
                    width: isMobile ? '100%' : '254px',
                }}
            >
                <ProductCardBody
                    showProductIcon
                    productDisabled={productDisabled}
                    onClick={handleClick}
                    product={product}
                    isActive={productIsActive}
                />
            </Box>
        </StyledCard>
    );
}
