import {
    Box,
    Card,
    Grid,
    Skeleton,
    SvgIcon,
    Typography,
} from '@mui/material';

import {SvgIconComponent} from '@mui/icons-material';

export interface ResultsCardProps {
    label: string;
    isLoading: boolean;
    total: number;
    icon?: SvgIconComponent;
}

export default function ResultsCard({
    label,
    total = 0,
    isLoading,
    icon,
}: ResultsCardProps) {
    return (
        <Grid item xs={12} md={6} lg marginLeft={1}>
            <Card
                sx={{
                    border: 'none',
                    boxShadow: 'none',
                    borderRadius: '8px',
                    bgcolor: 'common.brandColor.300',
                }}
            >
                <Grid
                    px={2}
                    height={56}
                    container
                    direction="row"
                    alignItems="center"
                    wrap="nowrap"
                >
                    <Grid item>
                        <Box
                            display="flex"
                            height={36}
                            width={36}
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            color="common.brandColor.100"
                            borderRadius="4px"
                            mr="12px"
                            bgcolor="primary.light"
                        >
                            {icon && (
                                <SvgIcon
                                    component={icon}
                                    sx={{color: 'primary.main'}}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item md={11}>
                        <Typography
                            variant="subtitle"
                            sx={{
                                color: (theme) =>
                                    theme.palette.common.brandColor[900],
                            }}
                        >
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item marginLeft="auto">
                        <Box
                            display="flex"
                            height={40}
                            minWidth={40}
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius="24px"
                            bgcolor="white"
                            ml="16px"
                        >
                            <Typography
                                variant="total"
                                color="grey.900"
                                p={1}
                                data-testid="result-card-typography"
                            >
                                {isLoading ? (
                                    <Skeleton data-testid="skeleton" />
                                ) : (
                                    total
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
}
