import {useMediaQuery, useTheme} from '@mui/material';
import {useEffect} from 'react';
import FilterTriggerDesktop from './FilterTriggerDesktop';
import FilterTriggerMobile from './FilterTriggerMobile';

export interface FilterTriggerProps {
    setMobileFilterExpanded: (expanded: boolean) => void;
    setDesktopFilterExpanded: (expanded: boolean) => void;
    isDesktopFilterExpanded: boolean;
    hasIndicator?: boolean;
}
export default function FilterTrigger({
    setMobileFilterExpanded,
    setDesktopFilterExpanded,
    isDesktopFilterExpanded,
    hasIndicator,
}: FilterTriggerProps) {
    const {
        breakpoints: {up},
    } = useTheme();

    const isMobile = !useMediaQuery(up('desktopSm'));
    useEffect(() => {
        if (!isMobile) {
            setMobileFilterExpanded(false);
        }
    }, [isMobile, setMobileFilterExpanded]);
    return isMobile ? (
        <FilterTriggerMobile
            setMobileFilterExpanded={setMobileFilterExpanded}
            hasIndicator={hasIndicator}
        />
    ) : (
        <FilterTriggerDesktop
            setDesktopFilterExpanded={setDesktopFilterExpanded}
            isDesktopFilterExpanded={isDesktopFilterExpanded}
        />
    );
}
