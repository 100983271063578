import {Collapse, Stack} from '@mui/material';
import _ from 'lodash';
import SelectionCard from '../SelectionCard';
export default function ModuleDropdown({
    isProductMenuExpanded,
    selectionItems,
}: {
    isProductMenuExpanded: boolean;
    selectionItems: any;
}) {
    return (
        <Collapse
            in={!isProductMenuExpanded}
            sx={{
                '.MuiCollapse-root.MuiCollapse-vertical ': {
                    box: 'transparent',
                },
            }}
            timeout={0}
        >
            <Stack
                gap="16px"
                sx={{
                    alignItems: 'flex-start',
                    padding: 0,

                    position: 'static',
                    width: '100%',
                    height: '176px',
                    left: `calc(50% - 328px/2)`,
                    top: '84px',
                    margin: '16px 0px',
                }}
            >
                {_.map(selectionItems, (item, index) => {
                    return (
                        <SelectionCard
                            key={index}
                            index={index}
                            length={selectionItems.length - 1}
                            {...item}
                        />
                    );
                })}
            </Stack>
        </Collapse>
    );
}
