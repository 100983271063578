import Layout, {LayoutProps} from './../../../common/components/Layout';
import {useSelector} from './../../../common/hooks';

const AppLayout = ({...props}: LayoutProps) => {
    const sideNavWidth = useSelector(
        (state) => state.app.layout.sideNavWidth
    );
    return (
        <Layout
            {...props}
            subMenuOpen={sideNavWidth === 'opened'}
            subMenuExtended={sideNavWidth === 'extended'}
        >
            {props.children}
        </Layout>
    );
};

export default AppLayout;
