import * as icons from 'common/icons';
import {SvgIconComponent} from '@mui/icons-material';

export type ProductName =
    | 'yodidesk'
    | 'yoditask'
    | 'yodisafe'
    | 'yodisense'
    | 'yodienergy'
    | 'yodispace';

export type GenericModuleName = 'admin';

export type ModuleName = '' | ProductName | GenericModuleName;

export interface Module {
    label: string;
    name: ModuleName;
    icon: SvgIconComponent;
}

const modules: Module[] = [
    {
        label: 'YodiSense',
        name: 'yodisense',
        icon: icons.YodiSenseIcon,
    },
    {
        label: 'YodiSpace',
        name: 'yodispace',
        icon: icons.YodiSpaceIcon,
    },
    {
        label: 'YodiTask',
        name: 'yoditask',
        icon: icons.YodiTaskIcon,
    },
    {
        label: 'YodiSafe',
        name: 'yodisafe',
        icon: icons.YodiSafeIcon,
    },
    {
        label: 'YodiDesk',
        name: 'yodidesk',
        icon: icons.YodiDeskIcon,
    },
    // {
    //     label: 'YodiEnergy',
    //     name: 'yodienergy',
    //     icon: icons.YodiEnergyIcon,
    // },
];

export default modules;
