import {useEffect} from 'react';
import {TabScrollButtonProps, Button} from '@mui/material';
import {DropdownCloseIcon, DropdownOpenIcon} from '../../../icons';
import clsx from 'clsx';
export interface MainMenuDesktopScrollButtonProps extends TabScrollButtonProps {
    onToggleScroll: (shown: boolean) => void;
}
export default function MainMenuDesktopScrollButton({
    disabled,
    direction,
    onClick = () => {},
    onToggleScroll = () => {},
    classes,
}: MainMenuDesktopScrollButtonProps) {
    useEffect(() => {
        onToggleScroll(true);
        return () => onToggleScroll(false);
    }, [onToggleScroll]);
    return (
        <Button
            variant="text"
            onClick={(e: any) => {
                onClick(e);
            }}
            disableRipple
            disabled={disabled}
            sx={{
                color: disabled ? 'grey.400' : 'grey.600',
            }}
            className={clsx(classes)}
        >
            {direction === 'left' && <DropdownOpenIcon />}
            {direction === 'right' && <DropdownCloseIcon />}
        </Button>
    );
}
