import {useState} from 'react';
import {Box, Typography, IconButton, Divider} from '@mui/material';
import {PositionAndAscDescIcon, MenuCloseIcon} from 'common/icons/index';
import {Column, SortingRule} from 'react-table';
import {UnknownObject, AnyObject} from '../../../../index';
import _ from 'lodash';
import ColumnsSelectionMobile from './ColumnsSelectionMobile';
import Button from 'common/components/Button/index';

type TableMobileColumnsManagmentPopoverProps<
    T extends AnyObject = UnknownObject
> = {
    hiddenColumnsIds: string[];
    onClose: () => void;
    onApply: (hiddenColumns: any) => void;
    onChangeSorting: (sortingProps: SortingRule<T>) => void;
    allColumns: Array<Column<T>>;
    sortBy?: Array<{id: string; desc: Boolean}>;
};

export default function TableMobileColumnsManagmentPopover({
    onClose,
    onApply,
    onChangeSorting,
    allColumns,
    hiddenColumnsIds,
    sortBy,
}: TableMobileColumnsManagmentPopoverProps) {
    const [hiddenColumns, sethiddenColumns] =
        useState<string[]>(hiddenColumnsIds);
    const [isRender, setRender] = useState<any>(false);

    const handleHideColumns = (column: any) => {
        let hiddenColsIds = hiddenColumns;

        let isPrevsVisible =
            _.findIndex(hiddenColsIds, (item) => {
                return item === column.id;
            }) === -1;

        if (isPrevsVisible) {
            hiddenColsIds.push(column.id);
        } else {
            hiddenColsIds = _.filter(
                hiddenColumns,
                (id) => id !== column.id
            );
        }

        sethiddenColumns(hiddenColsIds);
        setRender(!isRender); // todo remove
    };

    const renderGroupOfSelections = () => {
        return _.map(allColumns, (column: any) => {
            return _.isUndefined(column.Header) ||
                _.isEmpty(column.Header) ? null : (
                <ColumnsSelectionMobile
                    key={column.id}
                    hiddenColumnsIds={hiddenColumns}
                    column={column}
                    onClick={() => {
                        handleHideColumns(column);
                    }}
                    isDisabled={column.isAlwaysVisible ?? false}
                    onChangeSorting={onChangeSorting}
                    sortBy={sortBy}
                />
            );
        });
    };

    const Header = () => {
        return (
            <Box
                className="filter-area-mobile-header"
                sx={{
                    margin: (theme) =>
                        `0px ${theme.spacing(2)} 0px ${theme.spacing(2)}`,
                    width: 'calc(100% - 16px)',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <PositionAndAscDescIcon />
                <Typography variant="subtitle" mx={1}>
                    Table Options
                </Typography>
                <IconButton
                    onClick={onClose}
                    size="small"
                    sx={{
                        my: 1,
                        mr: 1,
                        ml: 'auto',
                    }}
                >
                    <MenuCloseIcon sx={{mr: 1}} />
                </IconButton>
            </Box>
        );
    };

    const Body = () => {
        return (
            <Box
                id="popup-card-test"
                sx={{
                    overflow: 'hidden',
                    height: '440px',
                }}
            >
                {renderGroupOfSelections()}
            </Box>
        );
    };

    const Footer = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    position: 'absolute',
                    bottom: 0,
                    marginBottom: (theme) => theme.spacing(2),
                }}
            >
                <Button
                    size="large"
                    variant="contained"
                    disabled={false}
                    onClick={() => {
                        onApply(hiddenColumns);
                    }}
                    sx={{whiteSpace: 'nowrap'}}
                >
                    <Typography variant="button">Apply changes</Typography>
                </Button>
            </Box>
        );
    };

    return (
        <Box
            id="table-mobile-options-container"
            sx={{
                position: 'absolute',
                zIndex: 1000,
                top: 0,
                left: 0,
                m: 0,
                height: '100%',
                bgcolor: 'grey.200',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Header />
            <Divider
                sx={{width: 'calc(100% - 32px)', margin: '0 auto'}}
            />{' '}
            <Body />
            <Footer />
        </Box>
    );
}
