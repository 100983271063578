import {Box} from '@mui/material';
import {DropdownOpenIcon, DropdownCloseIcon} from 'common/icons';
import _ from 'lodash';

export interface DropdownToggleIconProps {
    className: string;
}
export default function DropdownToggleIcon({
    className,
}: DropdownToggleIconProps) {
    const icon = _.includes(className, 'MuiSelect-iconOpen') ? (
        <DropdownOpenIcon sx={{color: 'grey.800'}} />
    ) : (
        <DropdownCloseIcon sx={{color: 'grey.600'}} />
    );
    return (
        <Box
            data-testid="mui-toggle-icon-test"
            sx={{
                pointerEvents: 'none',
                position: 'relative',
                right: 0,
                height: '24px',
                width: '24px',
            }}
        >
            {icon}
        </Box>
    );
}
