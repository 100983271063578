import {
    Tab,
    TabProps,
    Typography,
    Box,
    Stack,
    useTheme,
} from '@mui/material';
import {grey} from 'common/colors';
import clsx from 'clsx';
import {useMemo, useRef} from 'react';
import SubMenuExpander from './SubMenuExpander';

export type BaseTabProps = {
    selected?: boolean;
    hasSubMenu?: boolean;
    tabsRef?: React.MutableRefObject<HTMLDivElement>;
    onClickExpand?: () => void;
    isExpanded?: boolean;
};
export type MainMenuDesktopTabProps = TabProps & BaseTabProps;

const MainMenuDesktopTab = ({
    sx,
    label,
    icon,
    children,
    hasSubMenu,
    tabsRef,
    isExpanded,
    onClickExpand = () => {},
    ...props
}: MainMenuDesktopTabProps) => {
    const tabRef = useRef<HTMLDivElement | null>(null);
    const theme = useTheme();
    const indicatorColor = useMemo(() => {
        if (props.selected) {
            return theme.palette.primary.main;
        } else if (hasSubMenu) {
            return grey[400];
        } else {
            return 'transparent';
        }
    }, [hasSubMenu, props.selected, theme.palette.primary.main]);
    return (
        <Stack direction="row" mb="8px">
            <Box
                sx={{
                    borderRight: `2px solid ${indicatorColor}`,
                    borderRadius: '0px 8px 8px 0px',
                    width: 0,
                }}
            ></Box>
            <Tab
                ref={tabRef}
                {...props}
                wrapped
                disableRipple
                className={clsx({
                    'side-nav-item': true,
                })}
                icon={
                    <Box
                        pt="6px"
                        mb={0}
                        color={
                            props.selected ? 'primary.main' : 'grey.600'
                        }
                    >
                        {icon}
                    </Box>
                }
                label={
                    <Typography variant="menu" pb="12px">
                        {label}
                    </Typography>
                }
                sx={{
                    ...sx,
                    p: 0,
                    '&.MuiTab-root + .MuiTab-root': {
                        mt: 0.5,
                    },
                    '&.MuiTab-root': {
                        p: 0,
                        minWidth: '76px',
                        minHeight: '68px',
                        borderRadius: 1,
                    },
                    '& .MuiTab-iconWrapper': {
                        mb: 0,
                    },
                    '& svg': {
                        width: '36px',
                        height: '36px',
                        p: '6px',
                    },
                    fontSize: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    fontWeight: 'normal',
                    transition: '0.3s',
                    width: '100%',
                    '&:hover': {
                        bgcolor: (theme) =>
                            theme.palette.common.dynamicLight.hover(
                                grey[100]
                            ),
                    },
                    '&:active': {
                        bgcolor: (theme) =>
                            theme.palette.common.dynamicLight.pressed(
                                grey[100]
                            ),
                    },
                    '&.Mui-selected': {
                        color: 'primary.main',
                    },
                    '&.Mui-selected svg': {
                        bgcolor: 'primary.light',
                        borderRadius: 2,
                        width: '36px',
                        height: '36px',
                        p: '6px',
                    },
                    color: 'grey.900',
                    textTransform: 'capitalize',
                }}
            />
            {hasSubMenu && !isExpanded && (
                <SubMenuExpander
                    arrowColor={
                        props.selected ? 'primary.main' : 'grey.400'
                    }
                    onClick={onClickExpand}
                    tabsRef={tabsRef?.current ?? null}
                    tabRef={tabRef.current}
                />
            )}
        </Stack>
    );
};

export default MainMenuDesktopTab;
