import {AnyObject, NodeType, TreeTableProps, UnknownObject} from '.';
import {
    Box,
    Table as MuiTable,
    Paper,
    Skeleton,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import {Fragment, useCallback, useMemo} from 'react';
import {Row, useExpanded, useTable} from 'react-table';
import {alpha, styled} from '@mui/material';

import AutoSizer from 'react-virtualized-auto-sizer';
import TableRowContainer from './TableRowContainer';
import TreeCell from './TreeCell';
import {FixedSizeList} from 'react-window';
import _ from 'lodash';
import EmptyState from 'common/components/EmptyStates';

export default function DataTreeDesktop<T extends NodeType>({
    columns,
    isLoading,
    isEmptyState,
    data,
    categoryLevels,
    colorMap,
    caption,
    virtualized,
    label = 'table',
    onTableWidthChange,
    tableWidth,
    errorMessage,
}: TreeTableProps<T>) {
    const scrollBarWidth = 4;
    const TableInstance = useTable(
        {columns, data /*, getSubRows: (row: any) => row.type*/},
        useExpanded
    );
    const {
        getTableProps,
        getTableBodyProps,
        rows,
        prepareRow,
        visibleColumns,
    } = TableInstance;

    const calculateSpacing = useCallback(
        (index: number, level: number): number => {
            return 1 + (index === 0 ? level * 5 || 1 : 0);
        },
        []
    );

    const RowComponent = useCallback(
        <T extends AnyObject = UnknownObject>({
            row,
            level,
            index,
        }: {
            row: Row<T>;
            level: number;
            index: number;
        }) => {
            const rowToDataMapInfo = data[index];
            const rowParentIndex = _.findIndex(
                data,
                (item) => item.iRef === rowToDataMapInfo.parentId
            );

            //if child has parent create a line same height as the rows in between
            const getRowsInBetween = () => {
                if (rowParentIndex !== -1) {
                    return index - rowParentIndex;
                } else {
                    return 0;
                }
            };

            return (
                <TableRow
                    className="table-row-test"
                    {...row.getRowProps()}
                    sx={{
                        width: tableWidth - scrollBarWidth,
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid',
                        borderColor: 'grey.300',
                        height: 40,
                    }}
                    component={virtualized ? 'div' : 'tr'}
                >
                    {row.cells.map((cell, index) => {
                        let borderLeft;

                        if (index === 0 && colorMap) {
                            borderLeft = `4px solid ${colorMap[level]}`;
                        }
                        return (
                            <TableCell
                                className={cell.column.className}
                                component={virtualized ? 'div' : 'td'}
                                {...cell.getCellProps()}
                                sx={{
                                    borderLeft,
                                    width: 'fit-content',
                                    padding:
                                        cell.column.id === '_actions_'
                                            ? '0px 0px'
                                            : '6px 0px',
                                    borderBottom: 0,
                                }}
                            >
                                {index === 0 ? (
                                    <TreeCell
                                        index={index}
                                        level={level}
                                        rowSize={40}
                                        rowsInBetween={getRowsInBetween()}
                                    >
                                        {cell.render('Cell')}
                                    </TreeCell>
                                ) : (
                                    <Box
                                        typography="body2"
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {cell.render('Cell')}
                                    </Box>
                                )}
                            </TableCell>
                        );
                    })}
                </TableRow>
            );
        },
        // eslint-disable-next-line
        [
            prepareRow,
            rows,
            categoryLevels,
            virtualized,
            colorMap,
            visibleColumns,
        ]
    );

    const RenderSkeletonRow = useCallback(
        ({index, style}) => {
            const cutoff = colorMap?.length ?? 1;
            return (
                <Box style={style} key={index + '-fragment'}>
                    <TableRow component="div">
                        {_.range(10).map((cellIndex) => {
                            const spacing = calculateSpacing(
                                cellIndex,
                                index % cutoff
                            );
                            let borderLeft;
                            if (cellIndex === 0 && colorMap) {
                                borderLeft = `1px solid ${
                                    colorMap[index % cutoff]
                                }`;
                            }
                            const width = cellIndex === 0 ? 300 : 150;
                            return (
                                <TableCell
                                    component="div"
                                    key={cellIndex}
                                    sx={{
                                        width,
                                        pl: spacing,
                                        borderLeft,
                                        height: 40,
                                    }}
                                >
                                    <Skeleton variant="rectangular" />
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </Box>
            );
        },
        [colorMap, calculateSpacing]
    );

    const RenderRow = useCallback(
        ({index, style}) => {
            const row = rows[index];
            prepareRow(row);
            const type = row.original.type;
            const level = categoryLevels[type];

            return (
                <Box
                    id="render-row-test"
                    style={{
                        ...style,
                        width: tableWidth - scrollBarWidth,
                    }}
                    key={row.getRowProps().key + '-fragment'}
                >
                    <RowComponent level={level} row={row} index={index} />
                </Box>
            );
        },
        [prepareRow, rows, categoryLevels, RowComponent, tableWidth]
    );

    const StyledMuiFixedSizeList = styled(FixedSizeList)(({theme}) => {
        return {
            '&::-webkit-scrollbar': {
                height: '4px',
                width: '4px',
                borderRadius: '8px',
                backgroundColor: alpha(theme.palette.grey[100], 0.32),
            },
            '&::-webkit-scrollbar-thumb': {
                width: '4px',
                backgroundColor: theme.palette.grey[800],
                height: '48.53px',
                borderRadius: '8px',
            },
        };
    });

    const handleResize = ({width}: {width: number; height: number}) => {
        onTableWidthChange(width);
    };
    const emptyState = useMemo(() => {
        if (!isLoading && (isEmptyState || errorMessage)) {
            return (
                <EmptyState
                    type={errorMessage ? 'noData' : 'noResults'}
                    errorCode={errorMessage}
                />
            );
        }
    }, [errorMessage, isEmptyState, isLoading]);
    return (
        <Paper
            sx={{
                width: '100%',
                height: '100%',
            }}
        >
            {emptyState ? (
                emptyState
            ) : (
                <MuiTable
                    data-testid="infrastructure-muitable"
                    {...getTableProps()}
                    component={virtualized ? 'div' : 'table'}
                    aria-label={label}
                    size="small"
                    sx={{
                        height: '100%',
                        width: '100%',
                        borderCollapse: 'initial',
                    }}
                >
                    {caption && <caption>{caption}</caption>}
                    <TableBody
                        {...getTableBodyProps()}
                        component={virtualized ? 'div' : 'tbody'}
                        className="body"
                    >
                        {virtualized && (
                            <AutoSizer onResize={handleResize}>
                                {({height, width}) => {
                                    return (
                                        <StyledMuiFixedSizeList
                                            className="react-window-list"
                                            height={height}
                                            width={width}
                                            itemCount={
                                                isLoading
                                                    ? 20
                                                    : rows.length
                                            }
                                            itemSize={40}
                                        >
                                            {isLoading
                                                ? RenderSkeletonRow
                                                : RenderRow}
                                        </StyledMuiFixedSizeList>
                                    );
                                }}
                            </AutoSizer>
                        )}

                        {!virtualized &&
                            rows.map((row, index) => {
                                prepareRow(row);

                                const type = row.original.type;
                                const level = categoryLevels[type];

                                return (
                                    <Fragment
                                        key={
                                            row.getRowProps().key +
                                            '-fragment'
                                        }
                                    >
                                        <RowComponent
                                            level={level}
                                            row={row}
                                            index={index}
                                        />
                                    </Fragment>
                                );
                            })}
                    </TableBody>
                </MuiTable>
            )}
        </Paper>
    );
}

export {TableRowContainer};
