import {Box, ButtonBase, Tooltip, useTheme} from '@mui/material';
import {TreeCloseIcon, TreeOpenIcon} from 'common/icons';
import {ComponentProps} from 'react';

interface SelectionArrowProps {
    isExpanded?: boolean;
    onClick: ComponentProps<'button'>['onClick'];
    onHoverEnter: () => void;
    onHoverLeave: () => void;
    backgroundColor: string;
    hoverColor: string;
    focusColor: string;
    pressedColor: string;
}

function SelectionArrow({
    isExpanded,
    onClick = () => {},
    onHoverEnter,
    backgroundColor,
    hoverColor,
    pressedColor,
    focusColor,
    onHoverLeave,
}: SelectionArrowProps) {
    const theme = useTheme();
    return (
        <Tooltip
            title={`${isExpanded ? 'Collapse' : 'Expand'} tree`}
            arrow
            placement="top"
            enterDelay={700}
        >
            <ButtonBase
                onClick={(e) => {
                    e.stopPropagation();
                    onClick(e);
                }}
                onMouseEnter={() => {
                    onHoverEnter();
                }}
                onMouseLeave={() => {
                    onHoverLeave();
                }}
                sx={{
                    ml: '2px',
                    borderRadius: '12px',
                    height: '24px',
                    width: '24px',
                    '&:hover': {
                        backgroundColor: hoverColor,
                    },
                    '&:focus': {
                        backgroundColor: focusColor,
                    },
                    '&:active': {
                        backgroundColor: pressedColor,
                    },
                }}
                aria-label={`${isExpanded ? 'Collapse' : 'Expand'} tree`}
            >
                {isExpanded ? (
                    <TreeOpenIcon
                        sx={{
                            zIndex: theme.zIndex.mobileStepper,
                            color: 'primary.main',
                        }}
                    />
                ) : (
                    <TreeCloseIcon
                        sx={{
                            zIndex: theme.zIndex.appBar,
                            color: 'primary.main',
                        }}
                    />
                )}
                <Box
                    sx={{
                        position: 'absolute',
                        width: '16px',
                        height: '16px',
                        borderRadius: '30px',
                        backgroundColor,
                    }}
                ></Box>
            </ButtonBase>
        </Tooltip>
    );
}

export default SelectionArrow;
