import {ClearSharp} from '@mui/icons-material';
import {
    AutocompleteRenderInputParams,
    Box,
    IconButton,
    InputAdornment,
    TextField,
    styled,
} from '@mui/material';
import {grey} from 'common/colors';
import {SearchIcon} from 'common/icons';
import {HTMLAttributes, useCallback} from 'react';

export type DropdownSearchInputProps = {
    params: AutocompleteRenderInputParams;
    showSearchBar: boolean;
    onSearchChange: (searchedValue: string) => void;
};

const StyledTextField = styled(TextField)(
    ({
        theme: {
            palette: {grey, common},
        },
    }) => ({
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        width: '100%',
        '& input': {
            padding: '0',
            width: '100%',
        },
        '& .MuiInput-underline:after': {},
        '& .Mui-focused': {
            backgroundColor: common.dynamicLight.pressed(grey[100]),
            borderRadius: '4px 4px 0 0',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: '0px',
                borderBottomWidth: '1px',
                borderColor: grey[300],
                borderRadius: 0,
            },
            '&:hover fieldset': {
                borderColor: grey[300],
            },
            '&.Mui-focused fieldset': {
                borderWidth: '0px',
                borderBottomWidth: '1px',
                borderColor: grey[300],
            },
        },
    })
);

export default function DropdownSearchInput({
    showSearchBar,
    onSearchChange,
    params,
}: DropdownSearchInputProps) {
    const {value} =
        params.inputProps as HTMLAttributes<HTMLInputElement> & {
            value?: string;
        };

    const handleClear = useCallback(() => {
        onSearchChange('');
    }, [onSearchChange]);
    const bgColor = grey[100];
    return (
        <Box
            sx={{
                display: !showSearchBar ? 'none' : undefined,
            }}
        >
            <StyledTextField
                aria-label="Search"
                sx={{
                    backgroundColor: bgColor,
                    borderRadius: '4px 4px 0 0',
                    '&:hover': {
                        backgroundColor: (theme) =>
                            theme.palette.common.dynamicLight.hover(
                                bgColor
                            ),
                    },

                    '&:active': {
                        backgroundColor: (theme) =>
                            theme.palette.common.dynamicLight.focus(
                                bgColor
                            ),
                    },
                }}
                ref={params.InputProps.ref}
                inputProps={{
                    ...params.inputProps,
                    autoFocus: true,
                    style: {paddingLeft: 0},
                }}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                        <InputAdornment
                            position="start"
                            sx={{
                                pl: '14px',
                            }}
                        >
                            <SearchIcon
                                sx={{
                                    fontSize: '20px',
                                    color: 'grey.400',
                                }}
                            />
                        </InputAdornment>
                    ),
                    endAdornment: value && (
                        <InputAdornment
                            position="start"
                            sx={{
                                pl: '10px',
                            }}
                        >
                            <IconButton
                                aria-label="clear input"
                                onClick={handleClear}
                                edge="start"
                            >
                                <ClearSharp
                                    sx={{
                                        width: '17px',
                                        height: '17px',
                                        color: 'grey.600',
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                fullWidth
                placeholder="Search"
            />
        </Box>
    );
}
